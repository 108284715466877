import { FC, ReactNode, useState } from 'react';
import { UncontrolledTooltipProps, Tooltip } from 'reactstrap';

import SvgRender from '@/ts-common/components/general/SvgRender';
import info from '@/ts-common/assets/svg/common/info.svg';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

export interface InformationProps extends Omit<UncontrolledTooltipProps, 'target'> {
  message: ReactNode | string;
  className?: string;
  wrapperClassName?: string;
  tooltipClassname?: string;
  svgClassname?: string;
  svgStyle?: Record<string, unknown>;
  hoverIcon?: string;
  svgIcon?: string;
  renderComponent?: (isHovering: boolean) => ReactNode;
}

const Information: FC<InformationProps> = ({
  message,
  className = '',
  tooltipClassname = '',
  svgClassname = '',
  svgStyle,
  renderComponent,
  hoverIcon,
  placement = 'top',
  hideArrow,
  svgIcon,
  wrapperClassName = ''
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { avoidRender, tooltipID } = useTooltipID('information-tooltip');

  return (
    <div
      className={`d-inline-flex information-tooltip ${wrapperClassName}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={`${className} d-flex cursor-pointer`} id={tooltipID}>
        {renderComponent ? (
          renderComponent(isHovering)
        ) : (
          <SvgRender
            src={isHovering && hoverIcon ? hoverIcon : svgIcon || info}
            style={svgStyle || { width: 18, height: 18 }}
            className={`${svgClassname ? svgClassname : ''} pe-none`}
          />
        )}
      </div>

      {avoidRender || !isHovering ? null : (
        <Tooltip
          isOpen={isHovering}
          fade={false}
          target={tooltipID}
          innerClassName={tooltipClassname || 'show-more-tooltip'}
          hideArrow={hideArrow}
          placement={placement}
          boundariesElement="window"
        >
          {message}
        </Tooltip>
      )}
    </div>
  );
};

export default Information;
