import { FC, useState } from 'react';
import { VesselBase } from '@/common/types/vessel';
import { VesselSystemBase } from '@/common/types/vessel-systems';
import { Button, Col, Row } from 'reactstrap';
import { SingleValue, Option } from '@/common/components/selectors/_vesselSystemSelectComponents';

import ShadowBox from '@/common/components/general/ShadowBox';
import IconSelect from '@/ts-common/components/form/inputs/select/icon-select';

import burger from '@/common/assets/svg/common/chapter.svg';
import emptyRibbon from '@/common/assets/svg/common/ribbon-line.svg';
import link from '@/common/assets/svg/common/link.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';

type SelectionFormProps = {
  linkedVesselSystems: VesselSystemBase[];
  vessel: VesselBase;
  onSelect: (vesselSystem: VesselSystemBase) => void;
};

const SelectionForm: FC<SelectionFormProps> = ({ vessel, linkedVesselSystems, onSelect }) => {
  const [parentSystem, setParentSystem] = useState<VesselSystemBase | null>(null);
  const [childSystem, setChildSystem] = useState<VesselSystemBase | null>(null);

  return (
    <ShadowBox flat color="light-1" className="cpx-12 cpy-4">
      <Row className="flex-nowrap g-1 align-items-center">
        <Col className="flex-1 overflow-hidden">
          <IconSelect
            memoizedRequestParams={{
              path: 'lists',
              params: { list: 'vessel-systems', vessel_id: vessel.id }
            }}
            icon={burger}
            placeholder="Select system"
            value={parentSystem}
            isMulti={false}
            isAsync={true}
            getOptionValue={(option: VesselSystemBase) => option.id.toString()}
            getOptionLabel={(option: VesselSystemBase) => option.description}
            components={{ SingleValue, Option }}
            onChange={value => {
              setParentSystem(value as VesselSystemBase);
              setChildSystem(null);
            }}
            filterOption={option => !linkedVesselSystems.find(v => v.id === option.data.id)}
          />
        </Col>
        <Col className="flex-1 overflow-hidden">
          <IconSelect
            memoizedRequestParams={{
              path: 'lists',
              params: { list: 'vessel-systems', vessel_id: vessel.id, parent_id: parentSystem?.id }
            }}
            icon={emptyRibbon}
            placeholder="Select sub-system"
            value={childSystem}
            isMulti={false}
            isAsync={true}
            isClearable={true}
            getOptionValue={(option: VesselSystemBase) => option.id.toString()}
            getOptionLabel={(option: VesselSystemBase) => option.description}
            components={{ SingleValue, Option }}
            onChange={value => setChildSystem(value as VesselSystemBase | null)}
            filterOption={option => !linkedVesselSystems.find(v => v.id === option.data.id)}
            disabled={!parentSystem}
          />
        </Col>
        <Col xs="auto">
          <Button
            color="primary"
            size="sm"
            className="d-flex align-items-center cpy-2"
            onClick={() => onSelect((childSystem || parentSystem) as VesselSystemBase)}
            disabled={!parentSystem}
          >
            <SvgRender src={link} style={{ width: 12, height: 12 }} className="text-white cme-4" />
            <span className="fw-medium">Link</span>
          </Button>
        </Col>
      </Row>
    </ShadowBox>
  );
};

export default SelectionForm;
