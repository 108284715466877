import { FC, MouseEvent } from 'react';

import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import { VesselSystemMaintenanceJob } from '@/common/types/vessel-systems';
import CircledButton from '@/ts-common/components/buttons/CircledButton';

import Priority from 'common/components/general/Priority';
import DeactivatedVessels from './DeactivatedVessels';
import permissions from '@/common/utils/permissions/constants';
import AuthCheck from '@/components/permissions/AuthCheck';

type JobProps = {
  job: VesselSystemMaintenanceJob;
  onEdit: (
    event: MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent<HTMLDivElement>,
    id: number
  ) => void;
  onDelete: (
    event: MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent<HTMLDivElement>,
    job: VesselSystemMaintenanceJob
  ) => void;
};

const Job: FC<JobProps> = ({ job, onEdit, onDelete }) => {
  return (
    <div className="spare-parts cmb-4 d-flex align-items-center justify-content-between">
      <div className="pms-job-row flex-1 d-flex align-items-center justify-content-between overflow-hidden">
        <div className="d-flex align-items-center overflow-hidden">
          <Priority size="large" value={job?.importance_id} />

          <div className="text-primary fs-10 cme-12 cms-12">{job?.id}</div>

          <TextWithTooltip className="text-primary fw-medium fs-12">{job.title}</TextWithTooltip>
        </div>

        <div className="d-flex align-items-center">
          <DeactivatedVessels className="me-1" job={job} />

          {job.periodicity_description ? (
            <div className="periodicity d-flex align-items-center justify-content-center text-nowrap me-1">
              {job.periodicity_description}
            </div>
          ) : null}
        </div>
      </div>

      <div className=" ms-2 d-flex align-items-center justify-content-end">
        <AuthCheck permissions={[permissions.office.pms.library.systems.delete_jobs]}>
          <CircledButton
            type="remove"
            size={16}
            onClick={e => onDelete(e, job)}
            svgStyle={{ width: 6, height: 6 }}
            className="cme-6"
          />
        </AuthCheck>
        <AuthCheck permissions={[permissions.office.pms.library.systems.edit_jobs]}>
          <CircledButton
            size={16}
            type="edit"
            onClick={e => onEdit(e, job?.id)}
            svgStyle={{ width: 9, height: 9 }}
          />
        </AuthCheck>
      </div>
    </div>
  );
};

export default Job;
