import { MouseEventHandler, PropsWithChildren } from 'react';
import { FC } from 'react';

type ShadowBoxProps = PropsWithChildren<{
  className?: string;
  color?:
    | 'light-1'
    | 'light-2'
    | 'light-3'
    | 'light-4'
    | 'seasalt'
    | 'platinum-1'
    | 'white'
    | 'primary'
    | 'lighter-blue'
    | 'ligher-orange'
    | 'ligher-violet';
  flat?: boolean;
  whiteOnHover?: boolean;
  flatOnHover?: boolean;
  shadowOnHover?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}>;

const ShadowBox: FC<ShadowBoxProps> = ({
  className,
  children,
  flat = false,
  color = '',
  whiteOnHover = false,
  flatOnHover = false,
  shadowOnHover = false,
  onClick,
  ...rest
}) => {
  return (
    <div
      className={`shadow-box-wrap ${className ? className : ''} ${flat ? 'flat-box' : ''} ${
        whiteOnHover ? 'white-on-hover' : ''
      } ${flatOnHover ? 'flat-on-hover' : ''} ${shadowOnHover ? 'shadow-on-hover' : ''} ${color}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ShadowBox;
