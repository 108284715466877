import { useState, useMemo } from 'react';
import TreeTitle from 'common/components/tree-layout/main/TreeTitle';
import {
  selectSelectedSystem,
  selectSystem,
  selectIsSystemOpened,
  selectSystemHasSubSystems,
  selectCurrentFormSystem,
  selectPmsLibraryIsUsedInRequisition,
  selectGroupsCollapsed
} from 'common/components/pms/setup/store/selectors';
import { useActions } from 'utils/hooks';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import SubSystem from './SubSystem';
import PmsModal from 'common/components/pms/setup/views/components/modals/PmsModal';
import SvgRender from 'common/components/general/SvgRender';
import useRouter from 'use-react-router';
import permissions from '@/common/utils/permissions/constants';

import cloneFilledIcon from 'common/assets/svg/actions/clone-filled.svg';
// import UntriggeredIndicator from 'common/components/pms/setup/views/components/UntriggeredIndicator';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { useAppSelector } from '@/store/hooks';
import { getSubSystemIcon } from '@/common/components/pms/setup/helpers.jsx';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';

const AssignmentsCount = ({ count }) => {
  return (
    <div className="assignments-count d-flex align-items-center justify-content-center">
      {count}
    </div>
  );
};

const System = ({ id }) => {
  const [selected, setSelected] = useState(null);

  const { history, location } = useRouter();

  const activeSystem = useAppSelector(selectSelectedSystem);
  const currentFormSystem = useAppSelector(selectCurrentFormSystem);
  const isUsedInRequisition = useAppSelector(selectPmsLibraryIsUsedInRequisition);

  //delete modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  const closeCloneModal = () => setIsCloneModalOpen(false);

  const system = useAppSelector(state => selectSystem(state, id));
  const systemHasSubSystems = useAppSelector(state => selectSystemHasSubSystems(state, id));
  const isOpen = useAppSelector(state => selectIsSystemOpened(state, id));
  const groupsCollapsed = useAppSelector(selectGroupsCollapsed);

  const [
    setSelectedSystem,
    toggleSystem,
    getVesselSystemsAction,
    deleteSystem,
    setSystemFormType,
    setSystemFormParentId,
    setSelectedTab,
    cloneSystem,
    setIsCreatingSubSystem
  ] = useActions([
    pmsActions.setSelectedSystem,
    pmsActions.toggleSystem,
    pmsActions.getVesselSystemsAction,
    pmsActions.deleteSystem,
    pmsActions.setSystemFormType,
    pmsActions.setSystemFormParentId,
    pmsActions.setSelectedTab,
    pmsActions.cloneSystem,
    pmsActions.setIsCreatingSubSystem
  ]);

  const onClick = () => {
    if (currentFormSystem) {
      return;
    }
    setSelectedTab(null);
    !isUsedInRequisition && history.push(`${location.pathname}?system_id=${id}`);
    setSelectedSystem(id);
  };

  const onExpand = () => toggleSystem(id);

  const actions = useMemo(() => {
    return system.parent_id
      ? [
          {
            name: 'Clone sub-system',
            onClick: () => {
              setSelected(system);
              setIsCloneModalOpen(true);
            },
            permissions: [permissions.office.pms.library.systems.create]
          },
          {
            name: 'Delete',
            onClick: () => {
              setSelected(system);
              setIsDeleteModalOpen(true);
            },
            permissions: [permissions.office.pms.library.systems.delete]
          }
        ]
      : [
          {
            name: 'Add sub-system',
            onClick: () => {
              setSelectedTab(null);
              setSystemFormParentId(system.id);
              setSystemFormType('sub');
              setIsCreatingSubSystem(true);
            },
            permissions: [permissions.office.pms.library.systems.create]
          },
          {
            name: 'Clone system',
            onClick: () => {
              setSelected(system);
              setIsCloneModalOpen(true);
            },
            permissions: [permissions.office.pms.library.systems.create]
          },
          {
            name: 'Delete',
            onClick: () => {
              setSelected(system);
              setIsDeleteModalOpen(true);
            },
            permissions: [permissions.office.pms.library.systems.delete]
          }
        ];
  }, [system?.parent_id]);

  const onModalDelete = async () => {
    try {
      await deleteSystem({ id: selected.id });
      setSelectedSystem(null);
      await getVesselSystemsAction({ keepOpened: true });
    } catch (e) {}
  };

  const onModalClone = async () => {
    try {
      await cloneSystem({ id: selected.id });
      setSelectedSystem(null);
      await getVesselSystemsAction({ keepOpened: true });
    } catch (e) {
      console.error(e);
    }
  };

  if (!system) return null;

  return (
    <div>
      <TreeTitle
        isActive={id === activeSystem}
        hasChildren={systemHasSubSystems}
        title={system.description}
        onClick={onClick}
        parent_id={system.parent_id}
        onExpand={onExpand}
        isOpen={isOpen}
        actions={actions}
        disabledActions={isUsedInRequisition}
        customIconComponent={getSubSystemIcon(system, groupsCollapsed)}
        startComponent={
          groupsCollapsed && system.parent_id ? (
            <Col xs={1} className="d-flex ps-3 me-1 fw-medium overflow-hidden min-w-124 w-12p">
              <TextWithTooltip>{system.drawing_no || '-'}</TextWithTooltip>
            </Col>
          ) : null
        }
        rightComponent={
          system.assignments_count ? <AssignmentsCount count={system.assignments_count} /> : null
        }
        // leftComponent={
        //   showUntriggeredJobsIndicator &&
        //   system?.parent_id &&
        //   system?.untriggered_maintenance_jobs_count ? (
        //     <UntriggeredIndicator count={system.untriggered_maintenance_jobs_count} />
        //   ) : null
        // }
      />
      {systemHasSubSystems ? <SubSystem key={id.toString()} id={id} /> : null}
      <PmsModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        header={`Delete ${selected?.parent_id ? 'Sub-System' : 'System'}`}
        description={
          selected ? (
            <div>
              Are you sure you want to delete <strong>{selected.description}</strong>?
              <br /> This cannot be undone.
            </div>
          ) : (
            ''
          )
        }
        onAccept={onModalDelete}
      />
      <PmsModal
        isOpen={isCloneModalOpen}
        closeModal={closeCloneModal}
        acceptButtonText="CLONE"
        icon={cloneFilledIcon}
        svgColor="white"
        svgStyle={{ width: 85, height: 85 }}
        header={`Clone ${selected?.parent_id ? 'Sub-System' : 'System'}`}
        cancelButtonText="CANCEL"
        description={
          selected ? (
            <div>
              Are you sure you want to clone <strong>{selected.description}</strong> -{' '}
              {selected.parent_id ? 'sub-system' : 'system'}?
            </div>
          ) : (
            ''
          )
        }
        acceptButtonBackground={'primary'}
        onAccept={onModalClone}
      />
    </div>
  );
};

System.propTypes = {
  id: PropTypes.number
};

export default System;
