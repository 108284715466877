import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Select from 'common/components/form/inputs/Select';
import ListSelect from 'common/components/selectors/ListSelect';
import ImportanceSelector from 'common/components/selectors/ImportanceSelector';
import { periodicityEnums } from 'common/utils/fixed';

import { selectUnitsByType } from 'store/lists';
import { useEffect } from 'react';

const periodicityUnits = ['d', 'mon', 'y'];

const INITIAL_VALUE = 1;

const Header = ({ fields, selectField, changeField }) => {
  const importanceList = useSelector(state => state.lists['job-importances'].options);
  const defaultSelectNormalPriority = (importanceList || []).find(e => e.label === 'normal');

  const timeUnits = useSelector(selectUnitsByType('time'));
  const filteredUnits = timeUnits
    .filter(el => periodicityUnits.includes(el.label))
    .map(el => ({ ...el, id: el.notation }));

  useEffect(() => {
    if (fields?.importance_id?.value === null && defaultSelectNormalPriority) {
      selectField('importance_id')(defaultSelectNormalPriority?.id);
    }
  }, [fields?.importance_id?.value, defaultSelectNormalPriority?.id]);

  return (
    <Row className="d-flex align-items-center job-header pms-setup-header">
      <Col xs={'auto'}>
        <ImportanceSelector
          filter={{ value: fields.importance_id.value }}
          onChange={d => selectField('importance_id')(d.value)}
          hideLabel
          hideImportanceLabel
          isClearable={false}
          invisible={true}
          autoFocus={false}
          isJob={true}
          dynamicWidth={true}
          extraPortalStyle={{ width: 140 }}
          {...fields.importance_id}
        />
      </Col>

      <Col xs={'auto'} className="px-0">
        <div className="small-separator mx-1 py-1 bg-platinum"></div>
      </Col>

      <Col xs={'auto'}>
        <DepartmentSelector
          dynamicWidth={true}
          boxPlaceholder={true}
          value={fields.main_department_id.value}
          onChange={d => selectField('main_department_id')(d)}
          onlyCrewDepartments={true}
          autoFocus={false}
          invisible
          isMulti={false}
          className="job-input job-input--departments"
          extraPortalStyle={{ width: 140 }}
        />
      </Col>

      <Col xs={'auto'} className="px-0">
        <div className="small-separator mx-1 py-1 bg-platinum"></div>
      </Col>

      <Col xs={'auto'} className={'max-width-165'}>
        <DepartmentSelector
          dynamicWidth={true}
          boxPlaceholder={true}
          value={fields.departments.value}
          onChange={d => selectField('departments')(d)}
          showMore={true}
          isMulti={true}
          withCrewDepartments={false}
          autoFocus={false}
          invisible
          className="job-input job-input--departments"
          hideSelectedOptions={false}
          components={{ MultiValueRemove: () => null }}
        />
      </Col>

      <Col xs={'auto'} className="px-0">
        <div className="small-separator mx-1 py-1 bg-platinum"></div>
      </Col>

      <Col xs={'auto'} className="align-items-center d-flex">
        <div className="fs-12 text-primary cme-6 cpe-2 ps-1">Type: </div>

        <ListSelect
          placeholder="Select type"
          onChange={opt => selectField('job_type_id')(opt)}
          getOptionLabel={option => option.name}
          list="maintenance-job-types"
          isClearable
          invisible={true}
          isAsync={false}
          className={'width-168'}
          menuPortalTarget={document.body}
          extraPortalStyle={{ width: 220 }}
          {...fields.job_type_id}
        />
      </Col>

      <Col xs={'auto'} className="px-0">
        <div className="small-separator mx-1 py-1 bg-platinum"></div>
      </Col>

      <Col
        xs={'auto'}
        className={`align-items-center d-flex ${
          fields.interval_unit?.value === periodicityEnums.as_needed ? 'pe-0' : ''
        }`}
      >
        <div
          className={`fs-12 text-primary ${
            fields.interval_unit?.value === periodicityEnums.as_needed ? '' : 'cme-6'
          } cpe-2`}
        >
          Periodicity:{' '}
        </div>

        {fields.interval_unit?.value === periodicityEnums.as_needed ? null : (
          <NumberInput
            className="max-width-80 mb-0 number-input"
            innerClassName="fs-12 fw-medium"
            placeholder="Add value"
            name="title"
            invisible={true}
            onChange={changeField('interval')}
            {...fields.interval}
          />
        )}
      </Col>

      {fields.interval_unit?.value === periodicityEnums.as_needed ? null : (
        <Col xs={'auto'} className="px-0">
          <div className="small-separator mx-1 py-1 bg-platinum"></div>
        </Col>
      )}

      <Col xs={1}>
        <Select
          placeholder="Select periodicity"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          onChange={opt => {
            selectField('interval')(
              opt === periodicityEnums.as_needed
                ? INITIAL_VALUE
                : fields.interval_unit?.value === periodicityEnums.as_needed
                ? ''
                : fields?.interval?.value
            );

            selectField('interval_unit')(opt);
          }}
          options={[
            { id: periodicityEnums.system_running_hours, name: 'Running Hours' },
            { id: periodicityEnums.as_needed, name: 'As Needed' },
            ...filteredUnits
          ]}
          invisible={true}
          isClearable={true}
          {...fields.interval_unit}
        />
      </Col>

      <Col xs="auto" className="fs-12 text-primary d-flex align-items-center px-2">
        {fields.interval_unit?.value === periodicityEnums.system_running_hours ? 'or' : null}
      </Col>

      {fields.interval_unit?.value === periodicityEnums.system_running_hours ? (
        <Col xs="auto" className="d-flex align-items-center">
          <NumberInput
            className="mb-0 number-input"
            innerClassName="fs-12 fw-medium max-width-80"
            placeholder="Add value"
            name="title"
            invisible={true}
            onChange={changeField('second_interval')}
            {...fields.second_interval}
          />
          <Select
            placeholder="Select periodicity"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            onChange={opt => selectField('second_interval_unit')(opt)}
            options={filteredUnits}
            invisible={true}
            className="pms-setup-select mb-0 pe-1 fs-12 min-width-120"
            {...fields.second_interval_unit}
          />
        </Col>
      ) : null}

      <Col xs="auto" className="fs-12 text-primary d-flex align-items-center text-nowrap">
        {fields.interval_unit?.value === periodicityEnums.system_running_hours
          ? 'Whatever comes first'
          : null}
      </Col>
    </Row>
  );
};

export default Header;
