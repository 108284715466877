import CircledButton from 'common/components/buttons/CircledButton';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Size } from './_types';
import { File } from 'common/entities/files/FileTypes';
import { useCallback, useState } from 'react';

const iconSize = {
  lg: 18,
  sm: 12,
  xs: 10,
  full: 18
};

const svgSize = {
  lg: 8,
  sm: 4,
  xs: 4,
  full: 8
};

const FileRemoveButton = ({ size, onClick, file }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  return (
    <Container className="file-remove-icon" size={size} data-testid="files-remove-button">
      <CircledButton
        type="remove"
        svgStyle={{ width: svgSize[size], height: svgSize[size] }}
        style={{ width: iconSize[size], height: iconSize[size] }}
        onClick={openModal}
      />

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onClick}
        closeModal={closeModal}
        isOpen={isModalOpen}
        actionText={'DELETE'}
        header={'Delete File'}
        body={`Are you sure you want to delete the file "${file?.filename}"?`}
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  right: ${({ size }) => `-${iconSize?.[size] / 2 - 2}px`};
  top: -2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
`;

FileRemoveButton.propTypes = {
  file: File.isRequired,
  size: Size.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FileRemoveButton;
