import { useEffect, useState } from 'react';
import {
  selectIsSummaryViewActive,
  selectActiveRequisitionID
} from 'common/components/purchasing/requisition/store/selectors';
import {
  selectActiveEntityId,
  selectReviewProcessSteps,
  selectReviewProcessFormDrawerIsOpen
} from 'common/components/review-process/store/selectors';
import {
  setActiveReviewProcessEntityId,
  setReviewProcessFormDrawerIsOpen,
  createReviewProcessSubstepFormSubmission
} from 'common/components/review-process/store/actions';
import { getFormSubmission, updateFormSubmission } from 'store/forms/actions';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import ReviewProcessSteps from 'common/components/review-process/steps';
import History from 'common/components/review-process/components/history';
import FormsDrawer from 'common/components/forms/drawer';
import { getEntityReviewProcess } from 'common/components/review-process/store/actions';
import entityActions from 'common/components/review-process/entity-actions';
import Notes from 'common/components/review-process/components/notes';
import paths from 'routing/routes/_paths';
import { checkIfEligibleForPendingReview } from '@/common/components/purchasing/requisition/store/actions';

const ReviewProcess = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsSummaryViewActive);
  const [isLoading, setIsLoading] = useState(false);

  const activeRequisitionID = useAppSelector(selectActiveRequisitionID);
  const activeEntityId = useAppSelector(selectActiveEntityId);
  const reviewProcessSteps = useAppSelector(
    state => selectReviewProcessSteps(state, activeEntityId) || []
  );

  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedSubStepID, setSelectedSubStepID] = useState(null);
  const [selectedStepFormID, setSelectedStepFormID] = useState(null);
  const [selectedFormSubmissionID, setSelectedFormSubmissionID] = useState(null);
  const [drawerType, setDrawerType] = useState('view');
  const isFormDrawerOpen = useAppSelector(selectReviewProcessFormDrawerIsOpen);

  const fetchReviewProcess = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        getEntityReviewProcess({
          entity_id: activeEntityId,
          review_process_action: entityActions.purchasingRequisitionApproval.label
        })
      );
      await dispatch(checkIfEligibleForPendingReview({ id: activeRequisitionID }));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFormSelect = (form, formSubmission, step_form_id, sub_step_id) => {
    if (formSubmission) {
      window.open(`${paths.forms}/${formSubmission?.id}`, '_blank');
    } else {
      setSelectedForm(form);
      setSelectedStepFormID(step_form_id);
      setSelectedSubStepID(sub_step_id);
      setDrawerType(!formSubmission ? 'edit' : 'view');
      dispatch(setReviewProcessFormDrawerIsOpen(true));
    }
  };

  const onFormClose = () => {
    setSelectedForm(null);
    setSelectedStepFormID(null);
    setSelectedSubStepID(null);
    setSelectedFormSubmissionID(null);

    setDrawerType('view');
    dispatch(setReviewProcessFormDrawerIsOpen(false));

    fetchReviewProcess();
  };

  const onFormSubmit = async params => {
    if (selectedFormSubmissionID) {
      await dispatch(updateFormSubmission({ ...params, id: selectedFormSubmissionID }));
      await dispatch(getFormSubmission({ id: selectedFormSubmissionID }));
    } else {
      await dispatch(
        createReviewProcessSubstepFormSubmission({
          ...params,
          substep_id: selectedSubStepID,
          substep_form_id: selectedStepFormID
        })
      );
    }
  };

  useEffect(() => {
    if (isOpen) dispatch(setActiveReviewProcessEntityId(activeRequisitionID));
  }, [isOpen, dispatch, activeRequisitionID]);

  if (!isOpen) return null;

  return (
    <div>
      <div className="pe-1 py-2">
        <ReviewProcessSteps
          steps={reviewProcessSteps}
          activeEntityId={activeEntityId}
          loading={isLoading}
          onFormSelect={onFormSelect}
          fetchReviewProcess={fetchReviewProcess}
        />
      </div>
      <Notes activeEntityId={activeEntityId} />
      <div className="mt-6">
        <History reviewProcessSteps={reviewProcessSteps} activeEntityId={activeEntityId} />
      </div>
      <FormsDrawer
        drawerType={drawerType}
        drawer={{ isOpen: isFormDrawerOpen && activeEntityId, open: () => null }}
        onDrawerClose={onFormClose}
        initialForm={selectedForm}
        initialFormSubmissionID={selectedFormSubmissionID}
        onSaveFormSubmission={onFormSubmit}
        hiddenFields={{ vessel: true, type: true }}
      />
    </div>
  );
};

export default ReviewProcess;
