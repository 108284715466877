import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import Drawer, { FormDrawer, FormBody, DrawerHeader, FormFooter } from 'common/components/drawer';
import { Button, Col, Row } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { hiddenField, optionField, stringField } from 'common/utils/form/fieldTypes';
import InputField from 'common/components/form/inputs/Input';
import CircledButton from 'common/components/buttons/CircledButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSystemAttributesGroup,
  updateSystemAttributesGroup
} from 'common/components/pms/setup/store/actions';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { VesselAttributesGroupType } from 'common/entities/pms/SetupTypes';
import { FormState } from 'common/entities/form/FormTypes';
import { selectListOptionsFromStore } from 'store/lists/selectors';

const config = {
  id: hiddenField(),
  name: stringField({ required: true }),
  attributes: {
    id: hiddenField(),
    name: stringField({ required: true }),
    type: optionField({ required: true })
  }
};

const Attribute = ({ formState, index, onRemove }) => {
  const { fields, selectField, changeField } = useFormState(formState);

  return (
    <>
      {index === 0 && <strong className="text-primary fs-10 cmb-6">ATTRIBUTES</strong>}
      <Row className="align-items-center mb-1">
        <Col xs={7}>
          <InputField
            className="mb-0"
            placeholder="Add name"
            onChange={changeField('name')}
            {...fields.name}
          />
        </Col>
        <Col xs={4}>
          <AsyncSelector
            className="mb-0"
            placeholder="Select type"
            loadOptions={search =>
              getAsyncOptions(search, 'vessel-system-attribute-types', null, false)
            }
            defaultOptions={() =>
              getInitialAsyncValues('vessel-system-attribute-types', null, null)
            }
            isClearable={false}
            getOptionValue={opt => opt.value}
            getOptionLabel={opt => opt.name}
            onChange={type => selectField('type')(type)}
            {...fields.type}
          />
        </Col>
        <Col xs="auto">
          <CircledButton
            style={{ height: 15, width: 15 }}
            svgStyle={{ width: 6, height: 1 }}
            type="remove"
            onClick={onRemove}
          />
        </Col>
      </Row>
    </>
  );
};

Attribute.propTypes = {
  formState: FormState.isRequired,
  onRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

const Form = ({ drawer, active, setActive, refetchData }) => {
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const { formState, resetForm, collectValues, loadValues } = useForm(config);
  const { fields, changeField, subStates, removeSubform, addSubform } = useFormState(formState);

  const vesselAttributeTypes = useSelector(state =>
    selectListOptionsFromStore(state, 'vessel-system-attribute-types')
  );

  const attributes = subStates('attributes');

  const close = () => {
    drawer.close();
    setActive(null);
    resetForm();
  };

  const onSave = async () => {
    try {
      const values = collectValues();

      if (!values) return;

      const { name, attributes, id } = values;

      const params = {
        id: id || undefined,
        name,
        attributes: attributes.map(({ name, type, id }) => ({
          name,
          type: type.value,
          id: id || undefined
        }))
      };

      if (active) {
        await dispatch(updateSystemAttributesGroup(params)).unwrap();
      } else {
        await dispatch(createSystemAttributesGroup(params)).unwrap();
      }

      close();
      refetchData();
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (drawer.isOpen && active) {
      loadValues({
        ...active,
        attributes: active.attributes.map(attr => ({
          ...attr,
          type: vesselAttributeTypes.find(type => type.value === attr.type)
        }))
      });
    }
  }, [drawer.isOpen, active, loadValues, vesselAttributeTypes]);

  return (
    <Drawer {...drawer} close={close}>
      <FormDrawer>
        <DrawerHeader>
          {!active ? (
            'Create new System Attributes Group'
          ) : (
            <>
              Edit <strong>{active.name}</strong>
            </>
          )}
        </DrawerHeader>

        <FormBody>
          <div className="border-bottom pb-3 mb-3">
            <InputField
              className="mb-0"
              label="NAME"
              placeholder="Add name"
              onChange={changeField('name')}
              {...fields.name}
            />
          </div>

          {attributes.map((attributeFormState, index) => (
            <Attribute
              key={`attribute-${index}`}
              formState={attributeFormState}
              index={index}
              onRemove={() => removeSubform('attributes', index)}
            />
          ))}

          <CircledButton
            type="add"
            label="Add attribute"
            style={{ height: 15, width: 15 }}
            svgStyle={{ width: 6, height: 6 }}
            onClick={() => addSubform('attributes')}
          />
        </FormBody>

        <FormFooter>
          <Button color="cancel" className="px-0 py-1 me-4" onClick={close}>
            CANCEL
          </Button>
          <Button color="primary" className="px-4" disabled={isSaving} onClick={onSave}>
            SAVE
          </Button>
        </FormFooter>
      </FormDrawer>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState.isRequired,
  refetchData: PropTypes.func.isRequired,
  active: VesselAttributesGroupType,
  setActive: PropTypes.func.isRequired
};

export default Form;
