import {
  selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTimeStamp,
  selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTotalRunningHours
} from '@/common/components/pms/jobs/store/selectors-ts.ts';
import { useAppSelector } from '@/store/hooks';
import TextWithTooltip from '@/ts-common/components/general/TextWithTooltip';
import { displayDate } from '@/ts-common/utils/dates';
import { numberToStr } from '@/ts-common/utils/numbers';

const LastRhReportInfo = () => {
  const lastRunningHoursTimestamp = useAppSelector(
    selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTimeStamp
  );
  const lastRunningHoursTotals = useAppSelector(
    selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTotalRunningHours
  );

  if (!lastRunningHoursTimestamp) return null;

  return (
    <div className="d-flex align-items-end fw-bold text-primary mt-2 ms-2 overflow-hidden w-100p">
      <div className="d-flex flex-column w-100p">
        <div className="cme-6 form-label">LAST R/H REPORT</div>

        <TextWithTooltip className="d-flex align-items-center h-26 fs-12 fw-normal">
          {displayDate(lastRunningHoursTimestamp, { time: true })}
          <span className="cmx-4">|</span>
          {numberToStr(lastRunningHoursTotals)} rh
        </TextWithTooltip>
      </div>
    </div>
  );
};

export default LastRhReportInfo;
