import CircledButton from 'common/components/buttons/CircledButton';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
// import { selectSystemHasUntriggeredJobs } from 'common/components/pms/setup/store/selectors';

const AssignementItem = ({ item, onNumberClick, selectedId, type }) => {
  // const assignmentHasUntriggeredJobs = useSelector(state =>
  //   type === 'system'
  //     ? selectSystemHasUntriggeredJobs(state, item?.id)
  //     : item?.untriggered_maintenance_jobs_count
  // );

  // ${assignmentHasUntriggeredJobs ? 'untriggered' : ''}
  return (
    <div
      data-testId={'pms-system-assignment-number'}
      key={item?.id?.toString()}
      onClick={() => onNumberClick(item)}
      className={`assignment-number cme-4 d-flex align-items-center cursor-pointer justify-content-center fs-10 fw-bold  ${
        item?.id === selectedId ? 'assignment-number__selected' : ''
      } `}
    >
      {item.index}
    </div>
  );
};

AssignementItem.propTypes = {
  type: PropTypes.oneOf(['system', 'subSystem']),
  selectedId: PropTypes.number,
  onNumberClick: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number
    // untriggered_maintenance_jobs_count: PropTypes.number
  })
};

const AssignmentNumber = ({
  title = 'Systems',
  data = [],
  selectedId,
  loading,
  hideAddButton = false,
  onNumberClick,
  onAddAction,
  showPreventionModal = false,
  type
}) => {
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);

  return (
    <>
      <div className="fs-10 lh-1 text-violet mt-1 mb-1">{title}</div>
      <div
        className={`d-flex align-items-center mb-2 ${
          loading ? 'pointer-events-none opacity-5' : ''
        }`}
      >
        {data?.map(item => (
          <AssignementItem
            key={item.id}
            item={item}
            onNumberClick={onNumberClick}
            selectedId={selectedId}
            type={type}
          />
        ))}
        {hideAddButton ? null : (
          <CircledButton
            style={{ width: 16, height: 16 }}
            onClick={showPreventionModal ? () => setShowAssignmentModal(true) : onAddAction}
            type={'add'}
            className={'cms-4'}
            label=""
            svgStyle={{ height: 7, width: 7 }}
          />
        )}
      </div>

      <DangerousActionModal
        transparent
        action={'addition'}
        onAccept={onAddAction}
        actionHoverColor="primary"
        closeModal={() => setShowAssignmentModal(false)}
        isOpen={showAssignmentModal}
        actionText={'ADD'}
        header={'Add Extra Assignment'}
        body={`Are you sure you want to add an extra assignment of this system to the vessel?`}
      />
    </>
  );
};

AssignmentNumber.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  selectedId: PropTypes.number,
  hideAddButton: PropTypes.bool,
  onNumberClick: PropTypes.func,
  onAddAction: PropTypes.func,
  showPreventionModal: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ),
  type: PropTypes.oneOf(['system', 'subSystem'])
};

export default AssignmentNumber;
