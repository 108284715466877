import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import SvgRender from 'common/components/general/SvgRender';
import FileContainer from './FileContainer';
import ProgressLoader from './ProgressLoader';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

import { Size } from './_types';

import add from 'common/assets/svg/actions/add.svg';
import { useCallback } from 'react';

const UploadedButton = ({
  size,
  onUpload,
  isLoading,
  uploadError,
  uploadText,
  singleUpload,
  color,
  accept
}) => {
  const { tooltipID, avoidRender } = useTooltipID('upload-button');
  const buttonSize = size === 'full' ? 'sm' : size;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => onUpload(acceptedFiles)
  });

  const getSelectedFiles = useCallback(e => {
    if (e.target.files) return Array.from(e.target.files);

    return [];
  }, []);

  return (
    <div
      className={`d-flex flex-column ${buttonSize === 'lg' && !singleUpload ? 'mb-1' : ''} text-${
        uploadError ? 'red' : color
      }`}
      data-testid="files-upload-button"
    >
      <div className="d-flex align-items-center">
        <FileContainer size={buttonSize} id={tooltipID}>
          <Button
            size={buttonSize}
            active={isDragActive}
            className="d-flex align-items-center justify-content-center"
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              onChange={e => onUpload(getSelectedFiles(e))}
              disabled={isLoading}
              accept={accept ? accept : undefined}
              multiple={singleUpload ? false : true}
            />
            <Icon className="d-flex align-items-center justify-content-center">
              <SvgRender
                className="justify-content-center pointer-events-none"
                src={add}
                style={{ width: '100%', height: '100%' }}
              />
            </Icon>
            {isLoading && <ProgressLoader size={buttonSize} />}
          </Button>
        </FileContainer>
        {(buttonSize === 'lg' || uploadText) && (
          <div className="ms-1 text-primary fs-12 fw-medium">
            {uploadText || `Upload file${!singleUpload ? '(s)' : ''}`}
          </div>
        )}
      </div>

      {uploadError && buttonSize === 'lg' && (
        <FileContainer size={buttonSize} height="initial">
          <div className="text-truncate text-nowrap fs-10 fw-normal cmt-2 pointer">
            {uploadError}
          </div>
        </FileContainer>
      )}

      {!avoidRender && uploadError ? (
        <Tooltip fade={false} target={tooltipID} innerClassName="fs-12">
          {uploadError}
        </Tooltip>
      ) : null}
    </div>
  );
};

const spacing = { lg: '26%' };

const Button = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-width: 1px;
  border-color: currentColor;
  border-style: ${({ active }) => (active ? 'solid' : 'dashed')};
  border-radius: inherit;
  padding: ${({ size }) => spacing?.[size] || '10%'};
  background-color: ${({ active }) => (active ? 'rgba(53,64,105,0.1)' : 'rgba(53, 64, 105, 0.05)')};

  &:hover {
    border-style: solid;
  }
`;

const Icon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: white;
  padding: 25%;
`;

UploadedButton.propTypes = {
  size: Size.isRequired,
  onUpload: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  singleUpload: PropTypes.bool,
  color: PropTypes.string,
  accept: PropTypes.string,
  uploadError: PropTypes.string,
  uploadText: PropTypes.string
};

export default UploadedButton;
