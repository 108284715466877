import { dateField, hiddenField, optionField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  name: stringField({ required: true }),
  category: optionField(),
  subcategory: optionField(),
  tags: optionField(),
  attachments: optionField({ initialValue: [] }),
  department_id: optionField(),
  importance_id: optionField(),
  notes: stringField(),
  // the below fields are not being using on vessel
  resolved_at: dateField(),
  jobs: optionField(),
  form_submissions: optionField(),
  purchasing_requisitions: optionField(),
  vessel_systems: optionField(),
  origin_audit: hiddenField(),
  due_date: dateField()
};

export default config;
