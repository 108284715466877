import { ReportStatus } from '@/common/types/captain-reports';
import { FC } from 'react';
import { reportStatusAlias, reportStatusIcon } from '@/captain-reports/utils/helpers';

import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

type ReportStatusProps = {
  target: string;
  status: ReportStatus;
  className?: string;
  showTooltip?: boolean;
};

const ReportStatusIcon: FC<ReportStatusProps> = ({
  status,
  className = '',
  target,
  showTooltip = true
}) => {
  const { avoidRender, tooltipID } = useTooltipID(`${target}`);

  return (
    <>
      <SvgRender
        id={tooltipID}
        className={`report-status status-${status} ${className} cursor-pointer`}
        src={reportStatusIcon[status]}
        style={{ width: 16, height: 16 }}
      />
      {showTooltip && tooltipID && !avoidRender && (
        <Tooltip target={tooltipID}>{reportStatusAlias[status]}</Tooltip>
      )}
    </>
  );
};

export default ReportStatusIcon;
