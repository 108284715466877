import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import FieldValue from '@/common/components/pms/setup/views/system/components/FieldValue';
import _get from 'lodash/get';

import { FC } from 'react';
import { VesselSystemGroup } from '@/common/types/vessel-systems';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignVesselAssignmentToGroup } from '@/store/settings/selectors';

type GroupFieldProps = {
  value: VesselSystemGroup | null;
  isSubSystem?: boolean;
};

const GroupField: FC<GroupFieldProps> = ({ value, isSubSystem }) => {
  const isGroupVisible = useAppSelector(selectPmsAssignVesselAssignmentToGroup);

  if (isSubSystem) return null;

  return (
    isGroupVisible && (
      <div className="mt-2 cpt-2">
        <FieldLabel>GROUP</FieldLabel>
        <FieldValue>{`${_get(value, 'code', '')} ${_get(value, 'name', '-')}`}</FieldValue>
      </div>
    )
  );
};

export default GroupField;
