import { FC, useCallback, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import styled from '@emotion/styled';

import { VesselSystemAssignmentChildData } from '@/common/types/vessel-systems';
import { nestedColumns } from './tableConfing';
import { getSubSystemIcon } from '@/common/components/pms/setup/helpers.jsx';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setSelectedSystem, setSelectedTab } from '@/common/components/pms/setup/store/actions';
import { selectSelectedSystem } from '@/common/components/pms/setup/store/selectors';
import { SortingType } from '@/ts-common/types/table-query';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';

import Table from '@/common/components/table';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';

type CollapsabledSubsystemsProps = {
  subsystems: VesselSystemAssignmentChildData[];
};

const CollapsabledSubsystems: FC<CollapsabledSubsystemsProps> = ({ subsystems }) => {
  const [sorting, setSorting] = useState<SortingType>({});
  const dispatch = useAppDispatch();
  const selectedSystem = useAppSelector(selectSelectedSystem);

  const onSelectSystem = useCallback(
    (data: VesselSystemAssignmentChildData) => {
      dispatch(setSelectedTab(''));
      dispatch(setSelectedSystem(data.vessel_system.id));
    },
    [dispatch]
  );

  return (
    <Container className="cpx-12 cpb-12 pt-2 bg-white">
      <Table
        hideTopPagination
        hideBottomPagination
        hideTableFilters
        hideTableSearch
        handleTableSort={sorting => setSorting(sorting.sorting)}
        columns={nestedColumns}
        state={{
          data: _orderBy(subsystems, [Object.keys(sorting)[0]], [sorting[Object.keys(sorting)[0]]]),
          sorting
        }}
        rows={{
          'vessel_system.description': (data: VesselSystemAssignmentChildData) => (
            <div className="d-flex flex-nowrap overflow-hidden align-items-center">
              {getSubSystemIcon(data.vessel_system, true)}
              <div
                className={`overflow-hidden ps-1 pointer ${
                  selectedSystem === data.vessel_system.id ? 'fw-medium' : ''
                }`}
                onClick={() => onSelectSystem(data)}
              >
                <TextWithTooltip>{data.vessel_system.description}</TextWithTooltip>
              </div>
            </div>
          )
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  .app-table {
    .app-table--head {
      .cell {
        color: ${variables.violet};
      }
    }

    .app-table--body {
      .cell {
        background: ${variables.seasalt} !important;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
`;

export default CollapsabledSubsystems;
