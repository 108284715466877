import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectCategory } from 'common/components/purchasing/requisition/store/selectors';
import BudgetedCategory from './BudgetedCategory';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { toggleCategoryDrawer } from '@/common/components/purchasing/requisition/store/actions.ts';
import {
  selectCategoryDrawerIsOpen,
  selectVesselRequisitionIsLockedForOffice,
  selectOnBoardStatus,
  selectIsTemplateRequisition
} from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

export const CategoryDivElementType = 'requisition-category';

const Category = ({ categoryID, inline = true }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const category = useSelector(state => selectCategory(state, categoryID));
  const isDrawerOpen = useAppSelector(selectCategoryDrawerIsOpen);
  const lockedForOffice = useAppSelector(selectVesselRequisitionIsLockedForOffice);
  const onboardStatus = useAppSelector(selectOnBoardStatus);
  const canEditCategory =
    !isOnBoard && inline && !lockedForOffice && onboardStatus !== 'for_correction';
  const isTemplate = useAppSelector(selectIsTemplateRequisition);

  const dispatch = useAppDispatch();

  const openDrawer = (isOpen, categoryID) => {
    dispatch(toggleCategoryDrawer({ categoryID, isOpen }));
  };

  return (
    <div className="d-flex align-items-center cpb-6">
      {canEditCategory && (
        <AuthCheck
          permissions={
            isTemplate
              ? []
              : [permissions.office.purchasing.requisitions.change_requisition_category]
          }
        >
          <CircledButton
            size={14}
            type="edit"
            onClick={() => openDrawer(true, categoryID)}
            className="cme-12"
            svgStyle={{ width: 8, height: 8 }}
            backgroundColor="primary"
            disabled={isDrawerOpen}
            svgColor="white"
          />
        </AuthCheck>
      )}

      <Row className="w-100p g-0">
        <Col
          xs="auto"
          className={clsx(
            'bg-primary',
            'text-white',
            'rounded',
            'd-inline-flex',
            'align-items-center',
            'fs-12',
            'lh-1',
            'px-1',
            'h-20',
            { 'mt-5': isOnBoard }
          )}
          data-type={CategoryDivElementType}
        >
          Category:&nbsp;<strong>{category?.name}</strong>
        </Col>
        {!isOnBoard ? (
          <Col className="ps-1" xs={inline ? '' : 12}>
            <BudgetedCategory categoryID={categoryID} inline={inline} />
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

Category.propTypes = {
  categoryID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inline: PropTypes.bool
};

export default Category;
