import { getChartOfAccountsGroups } from '@/api/account-groups/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestParamsType } from '@webthatmatters/orca-table';

export const getChartOfAccountsGroupsAction = createAsyncThunk(
  'GET_CHART_OF_ACCOUNTS',
  async (params: RequestParamsType, { rejectWithValue }) => {
    try {
      const res = await getChartOfAccountsGroups(params);

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
