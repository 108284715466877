import { IntegrationsExternalIdType } from '@/common/components/form/helpers/FormIntegrationFields';
import { AccountingCoaGroupEntended } from '@/common/types/accounting';

import { deleteRequest, get, post, put } from '@/utils/api';
import { RequestParamsType } from '@webthatmatters/orca-table';

export const getChartOfAccountsGroups = async (params: RequestParamsType) => {
  return (await get<AccountingCoaGroupEntended[]>('/account-groups', params)).data;
};

export type CreateChartOfAccountGroupParams = {
  name: string;
  code: string;
  currency_ids: number[];
  normal_balance: string | null;
  external_ids: IntegrationsExternalIdType[];
  party_company_subtypes: number[];
  party_company_types: number[];
  party_company_account_type_id: number | null;
};

export const createChartOfAccountGroup = async (
  params: CreateChartOfAccountGroupParams & { parent_id: number | null }
) => {
  return (await post<AccountingCoaGroupEntended>('/account-groups', params)).data;
};

export const updateChartOfAccountGroup = async (
  params: CreateChartOfAccountGroupParams & { id: number }
) => {
  const { id, ...rest } = params;
  return (await put<AccountingCoaGroupEntended>(`/account-groups/${id}`, rest)).data;
};

export const deleteChartOfAccountGroup = async (params: { id: number }) => {
  return (await deleteRequest<AccountingCoaGroupEntended>(`/account-groups/${params.id}`, params))
    .data;
};
