import { FC, useMemo, useState } from 'react';
import { css } from '@emotion/react';

import { DrawerStateType } from '@/ts-common/types/drawer';
import { useGetMaintenanceJobPrototypesVesselAssignmentActivationsQuery } from '@/api/pms/queries';
import { columns } from 'views/pms/library/job-prototypes/activation-drawer/config';
import { useUpdateMaintenanceJobPrototypesVesselAssignmentActivationsMutation } from '@/api/pms/mutations';

import Drawer, { DrawerBody, DrawerHeader } from '@/ts-common/components/drawer/Drawer';
import ColoredTable from '@/common/components/colored-table';
import ActivationCirlce from 'common/components/general/ActivationCirlce';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import ActivationModal from '@/common/components/pms/setup/views/system/vessels/jobs/job/components/ActivationModal';

type JobTriggerPrototype = {
  title: string;
  id: number;
};

type TableVesselAssignmentActivation = {
  active: boolean;
  vessel_name: string;
  assignment_identifier: string | number;
  vessel_system_assignment_id: number;
  job_trigger_prototype_id: number;
};

type ActivationDrawerProps = {
  drawer: DrawerStateType;
  onClose: () => void;
  jobTriggerPrototype?: JobTriggerPrototype;
  onUpdateVesselAssignmentActivation: () => void;
};

const ActivationDrawer: FC<ActivationDrawerProps> = ({
  drawer,
  onClose,
  jobTriggerPrototype,
  onUpdateVesselAssignmentActivation
}) => {
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [editedVesselAssignmentActivation, setEditedVesselAssignmentActivation] =
    useState<TableVesselAssignmentActivation>();

  const { data: maintenanceJobPrototypesVesselAssignmentActivationsData } =
    useGetMaintenanceJobPrototypesVesselAssignmentActivationsQuery(jobTriggerPrototype?.id);

  const { mutate: updateMaintenanceJobPrototypesVesselAssignmentActivations } =
    useUpdateMaintenanceJobPrototypesVesselAssignmentActivationsMutation(
      onUpdateVesselAssignmentActivation,
      jobTriggerPrototype?.id
    );

  const tableData = useMemo(() => {
    return maintenanceJobPrototypesVesselAssignmentActivationsData
      ? maintenanceJobPrototypesVesselAssignmentActivationsData.map(assignmentActivation => ({
          assignment_identifier:
            assignmentActivation.assignment_identifier || assignmentActivation.parent.index,
          vessel_name: assignmentActivation.vessel_name,
          active: assignmentActivation.maintenance_job_classes[0].active,
          vessel_system_assignment_id: assignmentActivation.id,
          job_trigger_prototype_id: jobTriggerPrototype?.id
        }))
      : [];
  }, [jobTriggerPrototype?.id, maintenanceJobPrototypesVesselAssignmentActivationsData]);

  return (
    <Drawer {...drawer} close={onClose}>
      <DrawerHeader>Activate job for vessel assignment</DrawerHeader>
      <DrawerBody className="px-4 py-3">
        <div>
          <TextWithTooltip className="fs-12 text-primary lh-15 fw-medium">
            {jobTriggerPrototype?.title}
          </TextWithTooltip>
        </div>
        <div className="mt-4 cpt-4">
          <ColoredTable
            css={css`
              .app-table--head-inner {
                .cell {
                  font-size: 10px !important;
                  font-weight: 700 !important;
                }
              }

              .app-table--body--row {
                border-radius: 9px !important;

                &-wrap:hover {
                  box-shadow: unset !important;
                }
              }
            `}
            columns={columns}
            rows={{
              activation: (data: TableVesselAssignmentActivation) => {
                return (
                  <div className="max-width-fit">
                    <ActivationCirlce
                      isActive={data.active}
                      onClick={() => {
                        setEditedVesselAssignmentActivation(data);
                        setIsActivationModalOpen(true);
                      }}
                      info={`${
                        data.active ? 'Activated' : 'Deactivated'
                      } job for this vessel assignment`}
                      tooltipInnerClassName="lh-15 cpx-6 py-1 max-width-none"
                    />
                  </div>
                );
              }
            }}
            state={{ data: tableData }}
            color="lighter-gray"
            getRowClassName={(_: any, i: number) => `${i > 0 ? 'cmt-4' : 'cmt-12'} rounded-9`}
            hideTableSearch
          />
        </div>
      </DrawerBody>
      <ActivationModal
        isOpen={isActivationModalOpen}
        isActive={!!editedVesselAssignmentActivation?.active}
        onAccept={() => {
          if (!editedVesselAssignmentActivation) return;

          const params = {
            vessel_system_assignment_id:
              editedVesselAssignmentActivation.vessel_system_assignment_id,
            job_trigger_prototype_id: editedVesselAssignmentActivation.job_trigger_prototype_id,
            active: !editedVesselAssignmentActivation.active
          };

          updateMaintenanceJobPrototypesVesselAssignmentActivations(params);
        }}
        onCancel={() => setIsActivationModalOpen(false)}
        jobTitle={jobTriggerPrototype?.title}
      />
    </Drawer>
  );
};

export default ActivationDrawer;
