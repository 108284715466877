import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addReviewProcessStep,
  approveReviewProcess,
  bulkApproveReviewProcessSteps,
  deleteReviewProcessStep,
  editReviewProcessStep,
  rejectReviewProcess,
  restartReviewProcess
} from './api';
import { useAppDispatch } from '@/store/hooks';
import { successHandler } from '@/common/utils/notifications';
import { SYSTEM_TASKS } from '../system-tasks/query-keys';
import { SYSTEM_TASK_TYPE, SystemTask } from '@/types/system-tasks';

export const useApproveReviewProcessMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: approveReviewProcess,
    onSuccess: () =>
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Approved successfully`
        })
      )
  });
};

export const useRejectReviewProcessMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: rejectReviewProcess,
    onSuccess: () =>
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Rejected successfully`
        })
      )
  });
};

export const useRestartReviewProcessMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: restartReviewProcess,
    onSuccess: () =>
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Restarted successfully`
        })
      )
  });
};

export const useBulkApproveReviewProcessSteps = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: bulkApproveReviewProcessSteps,
    retry: false,
    onSuccess: data => {
      const triggeredSystemTask: SystemTask = {
        ...data,
        type: SYSTEM_TASK_TYPE.BULK_APPROVE_REVIEW_PROCESS_STEPS
      };

      if (data.execution_type === 'sync') triggeredSystemTask.event = 'async_action.completed';

      queryClient.setQueryData([SYSTEM_TASKS], (oldTaks: SystemTask[]) => [
        ...oldTaks,
        triggeredSystemTask
      ]);
    }
  });
};

export const useAddReviewProcessStepMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: addReviewProcessStep,
    onSuccess: () =>
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Created successfully`
        })
      )
  });
};

export const useEditReviewProcessStepMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: editReviewProcessStep,
    onSuccess: () =>
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Updated successfully`
        })
      )
  });
};

export const useDeleteReviewProcessStepMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: deleteReviewProcessStep,
    onSuccess: () =>
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Deleted successfully`
        })
      )
  });
};
