import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import Form from './Form';

import { useForm, useActions } from 'utils/hooks';
import config from './config';
import _get from 'lodash/get';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import { useSelector } from 'react-redux';
import {
  selectSelectedSystem,
  selectCurrentSystem
} from 'common/components/pms/setup/store/selectors';
import Spinner from 'common/components/general/Spinner';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';

const SparePartsForm = ({ drawer, onFinishSave, selectedPartForEdit, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchingPart, setFetchingPart] = useState(false);
  const { formState, collectValues, loadValues, hasErrors, handleSubmitError } = useForm(config);
  const [createSparePart, getSparePart, updateSparePart] = useActions([
    pmsActions.createSparePart,
    pmsActions.getSparePart,
    pmsActions.updateSparePart
  ]);
  const selectedSystem = useSelector(selectSelectedSystem);
  const currentSystem = useSelector(selectCurrentSystem);
  const groupsIsVisible = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const onSubmit = async () => {
    const values = collectValues();
    if (!values || !selectedSystem) return;

    const { vessel_system_group_id, drawing_file, ...rest } = values;

    const params = {
      ...rest,
      drawing_file_id: _get(drawing_file, 'id'),
      attachments: _get(values, 'attachments', []).map(el => el.id),
      submaker_id: _get(values, 'submaker_id.id', null)
    };

    if (groupsIsVisible)
      params.vessel_system_group_id = _get(values, 'vessel_system_group_id.id', null);

    try {
      setIsSubmitting(true);
      if (selectedPartForEdit) {
        params.id = selectedPartForEdit;
        await updateSparePart(params);
      } else {
        params.system_id = selectedSystem;
        await createSparePart(params);
      }

      setIsSubmitting(false);
      onFinishSave();
      drawer.close();
    } catch (error) {
      handleSubmitError(error);
    }
  };

  const loadPart = async () => {
    try {
      setFetchingPart(true);
      const part = await getSparePart({ id: selectedPartForEdit });

      loadValues({
        ...part,
        drawing_file_id: part.drawing_file,
        vessel_system_group_id: _get(part, 'group', null),
        submaker_id: part?.submaker
      });

      setFetchingPart(false);
    } catch (e) {
      setFetchingPart(false);
    }
  };

  useEffect(() => {
    if (!drawer.isOpen && onClose) {
      onClose();
      loadValues({});
    }
  }, [drawer.isOpen]);

  useEffect(() => {
    if (selectedPartForEdit) {
      loadPart();
    }
  }, [selectedPartForEdit]);

  return (
    <Drawer {...drawer} className="spare-parts-drawer">
      <DrawerHeader>{selectedPartForEdit ? 'Edit' : 'Add'} Spare Part</DrawerHeader>

      <FormDrawer>
        <FormBody>
          {fetchingPart ? <Spinner /> : <Form formState={formState} system={currentSystem} />}
        </FormBody>
      </FormDrawer>
      <FormFooter>
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={drawer.close}
          disabled={isSubmitting}
        >
          CANCEL
        </Button>
        <Button
          onClick={onSubmit}
          disabled={isSubmitting || hasErrors}
          color="primary"
          className="px-4"
        >
          SAVE
        </Button>
      </FormFooter>
    </Drawer>
  );
};

export default SparePartsForm;
