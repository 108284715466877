import PropTypes from 'prop-types';
import { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import SvgRender from 'common/components/general/SvgRender';
import _get from 'lodash/get';

import { Button } from 'reactstrap';
import { saveDownloadedFile } from 'common/utils/downloads';
import useGallery from 'common/components/gallery/useGallery';
import { icon } from '@/ts-common/utils/files.ts';
import { isFileSynced } from '@/api/files/api';

import x from 'common/assets/svg/common/x.svg';
import emptyFile from 'common/assets/svg/files/bordered/file-empty.svg';
import add from 'common/assets/svg/actions/add.svg';
import download from 'common/assets/svg/actions/download.svg';
import Spinner from 'common/components/general/Spinner';
import AttachmentsMenu from 'common/components/attachments/AttachmentsMenu';
import AttachmentFile from 'common/components/attachments/AttachmentFile';

import _isEqual from 'lodash/isEqual';

const config = {
  close: {
    className: 'border border-primary close',
    icon: {
      src: x,
      style: { width: 8, height: 8 }
    }
  },
  download: {
    enableDownload: true,
    icon: {
      src: download,
      className: 'text-primary',
      style: { width: 12, height: 12 }
    }
  },
  attachments: {
    className: '',
    icon: {
      src: emptyFile,
      className: 'text-moody-blue',
      style: { width: 10, height: 10 }
    }
  },
  add: {
    className: 'is-empty',
    icon: {
      src: add,
      style: { width: 14, height: 14 }
    }
  }
};

const DownloadAttachment = ({
  hasAttachments = false,
  downloadFiles,
  attachFiles = () => {},
  data = {},
  isDisabled = false,
  isHidden = false,
  downloadFileName = null,
  hiddenSuffix = false,
  canAddAttachments = true,
  popupPlacement = 'left',
  requestAttachments,
  size = 'sm'
}) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const [isAttachmentMenuOpen, setIsAttachmentMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [attachments, setAttachments] = useState(_get(data, 'attachments', []) || []);
  const [canDownloadAllAttachments, setCanDownloadAllAttachments] = useState(false);

  const attachmentsButtonRef = useRef(null);

  const { setFiles, setIsOpen, setCurrentFile } = useGallery();

  useUpdateEffect(() => {
    setIsAttachmentMenuOpen(false);
    if (!_isEqual(data?.attachments, attachments)) {
      setAttachments(data?.attachments || []);
    }
  }, [data?.id, data?.attachments]);

  const getConfig = useCallback(() => {
    if (hasAttachments && isAttachmentMenuOpen) {
      return config.close;
    } else if (
      !attachments?.length &&
      !data?.attachments_count &&
      hasAttachments &&
      canAddAttachments
    ) {
      return config.download;
    } else if (hasAttachments) {
      return config.attachments;
    } else if (canAddAttachments) {
      return config.add;
    } else {
      return config.attachments;
    }
  }, [hasAttachments, isAttachmentMenuOpen, data?.attachments_count]);

  const previewAttachmentHandler = (event, file) => {
    // if (isOnBoard && file.extension === 'pdf' && !file.url_inline) return;
    event.stopPropagation();
    const reversedAttachments = [...attachments].reverse();

    setFiles(popupPlacement === 'right' ? reversedAttachments : attachments);
    setIsOpen(true);

    setCurrentFile(file);
  };

  const onClick = async () => {
    if (hasAttachments) {
      if (!isAttachmentMenuOpen) {
        let menuAttachments = [];

        if (requestAttachments) {
          setIsLoading(true);

          try {
            const res = await requestAttachments();

            menuAttachments = res?.data || res || [];
          } catch (err) {
            console.error(err);
          }

          setIsLoading(false);
        } else {
          menuAttachments = _get(data, 'attachments', []) || [];
        }

        setAttachments(menuAttachments);
        setCanDownloadAllAttachments(
          menuAttachments.every(file => isFileSynced(file, isOnBoard).is_synced)
        );
      }

      setIsAttachmentMenuOpen(!isAttachmentMenuOpen);
    } else {
      attachFiles();
    }
  };
  const downloadHandler = async event => {
    event.stopPropagation();

    if (hasAttachments) {
      if (!canDownloadAllAttachments && isOnBoard) return;

      setIsDownloading(true);

      if (downloadFiles) {
        const response = await downloadFiles({ id: data.id });

        if (response && response.data) {
          saveDownloadedFile(
            response,
            `${downloadFileName ? downloadFileName : ''}${hiddenSuffix ? '' : '_attachments'}`
          );
        }
      }

      setIsDownloading(false);
    } else {
      attachFiles();
    }
  };

  const selectedConfig = getConfig();
  const attachmentsCount = attachments?.length || data?.attachments_count;
  const minimumVisibleAttachments = requestAttachments ? 0 : 1;

  return !isHidden ? (
    <div className="d-inline-flex align-items-center justify-content-center position-relative download-attachment-wrapper">
      {!isLoading ? (
        attachmentsCount > minimumVisibleAttachments ? (
          <div ref={attachmentsButtonRef}>
            <Button
              type="button"
              color="white"
              className={`download-attachment-btn p-0 d-inline-flex align-items-center justify-content-center ${
                selectedConfig.className
              } ${attachmentsCount > 0 || canAddAttachments ? '' : 'shadow-none disabled'}`}
              onClick={selectedConfig.enableDownload ? downloadHandler : onClick}
              size={size}
            >
              {(!canAddAttachments || attachmentsCount) && !isAttachmentMenuOpen ? (
                <div
                  className={`${
                    size === 'xl' || size === 'xxl' ? 'fs-14' : 'fs-10'
                  } text-moody-blue fw-bold ${
                    attachmentsCount >= 10 && size === 'sm' ? '' : 'cme-2'
                  } ${attachmentsCount > 0 || canAddAttachments ? '' : 'disabled'}`}
                >
                  {attachmentsCount || 0}
                </div>
              ) : null}

              {attachmentsCount >= 10 &&
              size === 'sm' &&
              !selectedConfig.className.includes('close') ? null : (
                <SvgRender
                  {...selectedConfig.icon}
                  style={
                    size !== 'xl' && size !== 'xxl'
                      ? selectedConfig.icon.style
                      : {
                          width: selectedConfig.icon.style.width + 5,
                          height: selectedConfig.icon.style.height + 5
                        }
                  }
                />
              )}
            </Button>
          </div>
        ) : (
          <>
            {attachments.map(file => (
              <AttachmentFile
                key={file.id}
                file={file}
                onClick={previewAttachmentHandler}
                size={size}
                icon={icon(file.mime_type, file.extension)}
              />
            ))}
          </>
        )
      ) : (
        <div className="cpt-2">
          <Spinner size={size === 'lg' ? 28 : 16} />
        </div>
      )}
      {attachments?.length ? (
        <AttachmentsMenu
          isAttachmentMenuOpen={isAttachmentMenuOpen}
          downloadHandler={downloadHandler}
          isDisabled={isDisabled || isDownloading}
          attachments={attachments}
          config={config}
          previewAttachmentHandler={previewAttachmentHandler}
          popupPlacement={popupPlacement}
          canDownloadAllAttachments={canDownloadAllAttachments}
          size={size}
          attachmentsButtonRef={attachmentsButtonRef}
        />
      ) : null}
    </div>
  ) : null;
};

DownloadAttachment.propTypes = {
  popupPlacement: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['sm', 'lg', 'xl', 'xxl']),
  hasAttachments: PropTypes.bool || null,
  downloadFiles: PropTypes.func || undefined,
  attachFiles: PropTypes.func,
  data: PropTypes.object,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  downloadFileName: PropTypes.string,
  hiddenSuffix: PropTypes.bool,
  canAddAttachments: PropTypes.bool,
  requestAttachments: PropTypes.func || null
};

export default DownloadAttachment;
