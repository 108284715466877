import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import Select from 'captain-reports/templates/inputs/Select';
import useValue from 'captain-reports/utils/useValue';
import useError from 'captain-reports/utils/useError';
import _isString from 'lodash/isString';

const SelectWithValidation = ({
  field,
  sectionLabel,
  subGroup,
  subGroupIndex,
  sectionActiveTab,
  className,
  customAllowedOptions
}) => {
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  return (
    <div className={`${className || ''}`}>
      <ValidationWrapper
        field={{ ...field, label: null }}
        value={value}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
        staticPosition={false}
        customAllowedOptions={customAllowedOptions}
      >
        <Select
          field={{
            ...field,
            selectRest: { ...field.selectRest, error: value ? '' : _isString(error) ? error : '' }
          }}
          sectionLabel={sectionLabel}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
          sectionActiveTab={sectionActiveTab}
          value={value}
        />
      </ValidationWrapper>
    </div>
  );
};

export default SelectWithValidation;
