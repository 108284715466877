import { FC } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import eyeIcon from 'common/assets/svg/common/eye.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';

const ViewButton: FC<ButtonProps> = ({ className = '', ...props }) => {
  return (
    <Button
      type="button"
      color="white"
      className={`w-32 min-h-24 max-h-24 d-flex align-items-center justify-content-center fs-12 border-radius-7 p-0 ${className}`}
      {...props}
    >
      <SvgRender className="text-dark" src={eyeIcon} style={{ width: 16, height: 16 }} />
    </Button>
  );
};

export default ViewButton;
