import TYPES from './types.ts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createReportSetup,
  CreateReportSetupParams,
  editReportSetup,
  EditReportSetupParams,
  getReportSetup,
  GroupTypeWithAccountIdsType
} from '@/api/budgeting-reports/api.ts';
import {
  selectGroupData,
  selectGroupSubItems,
  selectGroupRootIds
} from '@/store/report-setup/selectors';
import { RootState } from '../index.ts';
import { gatherGroupItems } from './helpers.ts';

type GetReportSetupParams = {
  id: string;
};

export const getReportSetupAction = createAsyncThunk(
  TYPES.GET_REPORT_SETUP,
  async (params: GetReportSetupParams, { rejectWithValue, fulfillWithValue }) => {
    const { id } = params;

    try {
      const res = await getReportSetup({ id });

      return fulfillWithValue(res);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createReportSetupAction = createAsyncThunk(
  TYPES.CREATE_REPORT_SETUP,
  async (
    params: Omit<CreateReportSetupParams, 'groups'>,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const rootItems = selectGroupRootIds(state);
    const subItems = selectGroupSubItems(state);
    const data = selectGroupData(state);

    const result: GroupTypeWithAccountIdsType[] = [];
    rootItems.forEach(rootId => gatherGroupItems(rootId, subItems, data, result));

    const parsedParams = {
      ...params,
      groups: result
    };

    try {
      const res = await createReportSetup(parsedParams);

      return fulfillWithValue(res);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editReportSetupAction = createAsyncThunk(
  TYPES.EDIT_REPORT_SETUP,
  async (
    params: Omit<EditReportSetupParams, 'groups'>,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const rootItems = selectGroupRootIds(state);
    const subItems = selectGroupSubItems(state);
    const data = selectGroupData(state);

    const result: GroupTypeWithAccountIdsType[] = [];
    rootItems.forEach(rootId => gatherGroupItems(rootId, subItems, data, result));

    const parsedParams = {
      ...params,
      groups: result
    };

    try {
      const res = await editReportSetup(parsedParams);

      return fulfillWithValue(res);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
