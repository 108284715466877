import { useEffect, useState } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import useDrawer from 'common/components/drawer/useDrawer';

import { useActions } from 'utils/hooks';

import CollapseForm from 'common/components/collapse/CollapseForm';

import * as pmsActions from 'common/components/pms/setup/store/actions';
import {
  selectSparePartsLoading,
  selectSelectedSystem
} from 'common/components/pms/setup/store/selectors';

import PmsModal from 'common/components/pms/setup/views/components/modals/PmsModal';

import SparePartsForm from './spare-parts-form';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';
import List from './List';
import { useAppSelector } from '@/store/hooks';

const SpareParts = ({ systemFormType }) => {
  const drawer = useDrawer(false);
  const sparePartsLoading = useAppSelector(selectSparePartsLoading);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedPartForEdit, setSelectedPartForEdit] = useState(null);
  const [selectedPartForDelete, setSelectedPartForDelete] = useState(null);

  const selectedSystem = useAppSelector(selectSelectedSystem);

  const [getSpareParts, deleteSparePart] = useActions([
    pmsActions.getSpareParts,
    pmsActions.deleteSparePart
  ]);

  useEffect(() => {
    if (selectedSystem) {
      getSpareParts({ system_id: selectedSystem });
    }
  }, [selectedSystem]);

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedPartForDelete(null);
  };

  const onAcceptDelete = async () => {
    try {
      if (selectedSystem) {
        await deleteSparePart({ system_id: selectedSystem, id: selectedPartForDelete.id });
        setSelectedPartForDelete(null);
        getSpareParts({ system_id: selectedSystem });
      }
    } catch (e) {}
  };

  const onFinishSave = () => {
    if (selectedPartForEdit) {
      setSelectedPartForEdit(null);
    }
    getSpareParts({ system_id: selectedSystem });
  };

  const onClose = () => setSelectedPartForEdit(null);

  return (
    <CollapseForm
      circledButtonProps={{ style: { width: 20, height: 20 }, svgStyle: { width: 11, height: 11 } }}
      hideExpandButton={true}
      className="main-collapse"
      hideActions={true}
      reversedExpandBtn={true}
      header={<div className="fs-12 lh-1 text-violet">Spare Parts</div>}
    >
      <div className="system-view spare-parts text-primary cursor-pointer position-relative mt-1">
        {systemFormType ? null : (
          <List
            setSelectedPartForDelete={setSelectedPartForDelete}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setSelectedPartForEdit={setSelectedPartForEdit}
            drawer={drawer}
          />
        )}
        {!sparePartsLoading ? (
          <AuthCheck permissions={[permissions.office.pms.library.systems.edit_spare_parts]}>
            <CircledButton
              onClick={() => drawer.open()}
              type={'add'}
              disabled={systemFormType}
              className="small_circled-btn"
              label="Add Spare Part"
              svgStyle={{ height: 7, width: 7 }}
              style={{ width: 16, height: 16 }}
            />
          </AuthCheck>
        ) : null}
        <SparePartsForm
          onClose={onClose}
          drawer={drawer}
          onFinishSave={onFinishSave}
          selectedPartForEdit={selectedPartForEdit}
        />
        <PmsModal
          isOpen={isDeleteModalOpen}
          header="Delete Part"
          closeModal={closeDeleteModal}
          description={
            selectedPartForDelete ? (
              <div>
                Are you sure you want to delete the part{' '}
                <strong>{selectedPartForDelete.part_no}</strong>? <br /> This cannot be undone.
              </div>
            ) : (
              ''
            )
          }
          onAccept={onAcceptDelete}
        />
      </div>
    </CollapseForm>
  );
};

export default SpareParts;
