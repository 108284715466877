import { createSelector } from 'reselect';
import { RootState } from '..';
import { ReduxTableLabelKeyType } from '@/store/tables/lists/keys';
import { selectTableList, selectTableReducer } from '@/common/components/table/store/selector-ts';
import { TableListReportDataKeys } from './reducer';

export const selectTableFooterValues = createSelector(
  selectTableList,
  table => table?.footer_values || {}
);

export const selectTableFooterColumns = createSelector(
  selectTableList,
  table => table?.footer_columns || []
);

export const selectTableVessel = createSelector(selectTableList, table => table?.vessel || []);

export const selectTableListReportData = (state: RootState, label: ReduxTableLabelKeyType) =>
  selectTableList(state, label)?.report_data;

export const selectTableListReportDataKey = (
  state: RootState,
  label: ReduxTableLabelKeyType,
  key: TableListReportDataKeys
) => selectTableListReportData(state, label)?.[key];

export const selectTableSorting = createSelector(selectTableList, table => table?.sorting || {});

export const selectTablePaging = createSelector(selectTableList, table => table?.paging || {});

export const selectTableDefaultRequestParams = createSelector(
  selectTableList,
  table => table?.defaultTableParams
);

export const selectSearch = createSelector([selectTableReducer], table => table.search);

export const selectSearchSaving = (state: RootState) => selectTableReducer(state).searchSaving;

export const selectSearchLoading = (state: RootState) => selectTableReducer(state).searchLoading;

export const selectSearchShares = createSelector([selectSearch], search =>
  search && search.shares ? search.shares : []
);
