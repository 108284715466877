import { useState, useCallback } from 'react';
import CollapseForm from 'common/components/collapse/CollapseForm';
import { Row, Col } from 'reactstrap';
import CircledButton from 'common/components/buttons/CircledButton';
import check from 'common/assets/svg/common/check-circle.svg';
import error from 'common/assets/svg/common/x-circle.svg';
import SvgRender from 'common/components/general/SvgRender';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import { useActions } from 'utils/hooks';
import _get from 'lodash/get';
import Spinner from 'common/components/general/Spinner';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { numberToStr } from 'common/utils/numbers';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { capitalizeFirst } from 'common/utils/texts';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';
import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import FieldValue from '@/common/components/pms/setup/views/system/components/FieldValue';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';

const SparePart = ({ part, onEdit, onDelete }) => {
  const [fullPart, setFullPart] = useState(null);
  const [fetchingPart, setFetchingPart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [getSparePart] = useActions([pmsActions.getSparePart]);
  const groupsIsVisible = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const renderHeader = () => (
    <Row className="text-primary fs-12 fw-bold w-100p cpy-6" onClick={onOpenCollapseHandler}>
      <Col xs={3}>
        {part?.drawing_no ? (
          <TextWithTooltip className="h-auto">{part.drawing_no}</TextWithTooltip>
        ) : (
          '-'
        )}
      </Col>
      <Col xs={2}>
        {part?.part_no ? <TextWithTooltip className="h-auto">{part.part_no}</TextWithTooltip> : '-'}
      </Col>
      <Col xs={4}>
        {part?.description ? (
          <TextWithTooltip className="h-auto">{part.description}</TextWithTooltip>
        ) : (
          '-'
        )}
      </Col>
      <Col xs={3}>
        <div className="w-100p spare-parts-actions d-flex align-items-center justify-content-end">
          <AuthCheck permissions={[permissions.office.pms.library.systems.delete_spare_parts]}>
            <CircledButton
              type="remove"
              style={{ width: 16, height: 16 }}
              onClick={e => onDelete(e, part)}
              svgStyle={{ width: 6, height: 6 }}
              className="cme-6"
            />
          </AuthCheck>
          <AuthCheck permissions={[permissions.office.pms.library.systems.edit_spare_parts]}>
            <CircledButton
              style={{ width: 16, height: 16 }}
              type="edit"
              onClick={e => onEdit(e, part.id)}
              svgStyle={{ width: 9, height: 9 }}
            />
          </AuthCheck>
          <div className="mini-divider ms-2 me-2" />

          <CircledButton
            type="arrow"
            style={{ width: 16, height: 16 }}
            reversed={true}
            svgStyle={{
              transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
              width: 8,
              height: 8
            }}
            label={
              <strong className="fw-medium text-primary fs-12">
                {isOpen ? 'Shrink' : 'Expand'}
              </strong>
            }
          />
        </div>
      </Col>
    </Row>
  );

  const renderValue = useCallback(
    (data, key, type) =>
      type === 'number' ? numberToStr(_get(data, key)) : _get(data, key) || '-',
    []
  );

  const onOpenCollapseHandler = () => {
    setIsOpen(!isOpen);
  };

  const onOpen = useCallback(async () => {
    try {
      setFetchingPart(true);
      const res = await getSparePart({ id: part.id });

      setFullPart(res);

      setFetchingPart(false);
    } catch (e) {
      setIsOpen(false);
      setFetchingPart(false);
    }
  }, [getSparePart, part.id]);

  return (
    <CollapseForm
      circledButtonProps={{ style: { width: 16, height: 16 }, svgStyle: { width: 7, height: 7 } }}
      onOpen={onOpen}
      renderHeader={renderHeader}
      hideActions={true}
    >
      <div className="spare-part-details mb-2">
        {fetchingPart ? (
          <Spinner />
        ) : fullPart ? (
          <Row>
            <Col xs={12} className="mb-2 d-flex align-items-center">
              <SvgRender
                src={fullPart.ihm_related ? check : error}
                style={{ width: 16, height: 16 }}
                className={`cme-12 ${fullPart.ihm_related ? 'text-turquoise' : 'text-violet'}`}
              />
              <div className=" fs-12">IHM Related</div>
            </Col>
            <Col xs={12} className="mb-2">
              {groupsIsVisible && (
                <div className="mb-2">
                  <FieldLabel>GROUP</FieldLabel>
                  <FieldValue>{`${_get(fullPart, 'group.code', '')} ${renderValue(
                    fullPart,
                    'group.name'
                  )}`}</FieldValue>
                </div>
              )}
              <Row>
                <Col xs={3}>
                  <FieldLabel>DRAWING NO.</FieldLabel>
                  <FieldValue>{renderValue(fullPart, 'drawing_no')}</FieldValue>
                </Col>
                <Col xs={3} className="pe-2">
                  <FieldLabel>DRAWING FILE</FieldLabel>
                  {fullPart.drawing_file_id ? (
                    <UploadFiles
                      className={`mb-0`}
                      files={fullPart.drawing_file ? [fullPart.drawing_file] : []}
                      viewOnly={true}
                    />
                  ) : (
                    '-'
                  )}
                </Col>
                <Col xs={3}>
                  <FieldLabel>POSITION NO.</FieldLabel>
                  <FieldValue>{fullPart?.part_position_no || '-'}</FieldValue>
                </Col>
                <Col xs={3}>
                  <FieldLabel>UNIT</FieldLabel>
                  <FieldValue>{capitalizeFirst(fullPart?.part_unit || '-')}</FieldValue>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <FieldLabel>SUB-MAKER</FieldLabel>
                  <FieldValue>{renderValue(fullPart, 'submaker.full_name')}</FieldValue>
                </Col>
                <Col xs={3}>
                  <FieldLabel>SUB-MAKER PART NO.</FieldLabel>
                  <FieldValue>{renderValue(fullPart, 'submaker_part_no')}</FieldValue>
                </Col>
                <Col xs={3} className="pe-2">
                  <FieldLabel>NOx TECHNICAL FILE IMO NO.</FieldLabel>
                  <FieldValue>{renderValue(fullPart, 'nox_technical_file_imo_no')}</FieldValue>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-2">
              <Row>
                <Col xs={6}>
                  <FieldLabel>MINIMUM REQUIRED NO. OF SPARES ONBOARD</FieldLabel>
                  <div className="d-flex">
                    <FieldValue>
                      {renderValue(fullPart, 'minimum_required_rob', 'number')}
                    </FieldValue>
                    <FieldValue>
                      {fullPart.is_rob_requirement_official ? (
                        <div className="text-violet ms-1">
                          |<span className="ps-1">Official</span>
                        </div>
                      ) : (
                        <div className="text-orange ms-1">
                          |<span className="ps-1 text-orange">Company Requirement</span>
                        </div>
                      )}
                    </FieldValue>
                  </div>
                </Col>

                <Col xs={6}>
                  <FieldLabel>OPTIMAL NO. OF SPARES ONBOARD</FieldLabel>
                  <FieldValue>{renderValue(fullPart, 'optimal_rob', 'number')}</FieldValue>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="mb-1 mt-3">
              <Row>
                <Col xs={6}>
                  <FieldLabel>COMMENTS</FieldLabel>
                  <FieldValue>{renderValue(fullPart, 'comments')}</FieldValue>
                </Col>
                <Col xs={6}>
                  <FieldLabel>PHOTOS & OTHER INFO</FieldLabel>
                  {fullPart.attachments?.length ? (
                    <DownloadAttachment
                      downloadFileName="spare_part_attachments"
                      data={{
                        attachments: fullPart.attachments,
                        attachments_count: fullPart.attachments?.length,
                        id: fullPart?.id
                      }}
                      downloadFiles={() =>
                        downloadAllFilesOfEntity({ id: fullPart?.id, type: 'spare_part' })
                      }
                      hasAttachments={fullPart.attachments?.length}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </div>
    </CollapseForm>
  );
};

export default SparePart;
