import _get from 'lodash/get';
import { Row, Col } from 'reactstrap';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import TreeTitleActions from 'common/components/tree-layout/main/TreeTitleActions';
import VesselSystemInfo from 'common/components/pms/jobs/components/VesselSystemInfo';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import SvgRender from 'common/components/general/SvgRender';
import VesselSystemGroupSelector from 'common/components/selectors/VesselSystemGroupSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import xIcon from 'common/assets/svg/common/x-circle.svg';
import cloneIcon from 'common/assets/svg/actions/clone-filled.svg';
import burger from 'common/assets/svg/common/chapter-colored.svg';
import emptyRibbon from 'common/assets/svg/common/ribbon-line-colored.svg';
import checkedIcon from 'common/assets/svg/common/check-circle-solid.svg';
import deleteSvg from 'common/assets/svg/actions/delete.svg';
import permissions from '@/common/utils/permissions/constants';

export const VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEMS = 'vessels_assigned';

export const columns = [
  {
    header: 'System',
    key: 'description',
    type: 'string',
    sort: true,
    canFilter: true,
    minWidth: 405,
    maxWidth: 405
  },
  {
    header: 'Vessels Assigned',
    key: 'vessels',
    sort: false,
    canFilter: false,
    minWidth: 250,
    maxWidth: 250
  },
  {
    header: 'System Group',
    key: 'group',
    sort: true,
    canFilter: false,
    minWidth: 280,
    maxWidth: 280
  },
  {
    header: 'System Group',
    key: 'group_id',
    type: 'collection',
    component: VesselSystemGroupSelector,
    componentRest: {
      isMulti: true
    },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Maker',
    key: 'maker',
    sort: true,
    canFilter: false,
    minWidth: 270,
    maxWidth: 270
  },
  {
    header: 'Maker',
    key: 'maker_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company', role: 'maker' } },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Licensee',
    key: 'licensee',
    sort: true,
    canFilter: false,
    minWidth: 290,
    maxWidth: 290
  },
  {
    header: 'Licensee',
    key: 'licensee_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company', role: 'maker' } },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Type',
    key: 'type',
    type: 'string',
    sort: true,
    minWidth: 260,
    maxWidth: 260,
    canFilter: true
  },
  {
    header: 'Running Hours to be Reported',
    key: 'report_running_hours',
    type: 'boolean',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
    minWidth: 120,
    maxWidth: 120,
    sort: false,
    canFilter: true
  },
  {
    header: 'Drawing no.',
    key: 'drawing_no',
    bodyClassName: 'pe-0',
    type: 'string',
    minWidth: 200,
    maxWidth: 200,
    sort: true,
    canFilter: true
  },
  {
    header: 'Office Department',
    key: 'office_department_id',
    component: DepartmentSelector,
    type: 'collection',
    componentRest: { isMulti: true },
    sort: false,
    hidden: true,
    canFilter: true
  },
  {
    header: 'Vessel Department',
    key: 'vessel_department_id',
    component: DepartmentSelector,
    type: 'collection',
    componentRest: { isMulti: true, onlyCrewDepartments: true },
    sort: false,
    hidden: true,
    canFilter: true
  },
  {
    header: 'Is Critical',
    key: 'is_critical',
    type: 'boolean',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
    sort: false,
    hidden: true,
    canFilter: true
  },
  {
    header: 'Is Navigational',
    key: 'is_navigational',
    type: 'boolean',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
    sort: false,
    hidden: true,
    canFilter: true
  },
  {
    header: 'Is Environmental',
    key: 'is_environmental_critical',
    type: 'boolean',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
    sort: false,
    hidden: true,
    canFilter: true
  }
];

export const getRows = setSelectedItem => ({
  description: data => (
    <div className={data?.parent_id ? 'ps-2' : ''}>
      <VesselSystemInfo
        key={`vessel-system-${data.id}`}
        vesselSystem={{
          description: _get(data, 'description')
        }}
        attributes={{
          multiplicity: _get(data, 'multiplicity'),
          is_critical: _get(data, 'is_critical'),
          is_environmental_critical: _get(data, 'is_environmental_critical'),
          is_navigational: _get(data, 'is_navigational')
        }}
        showSystemIcon={false}
        showGroup={false}
      />
    </div>
  ),
  vessels: data => (
    <div className={data?.parent_id ? 'ps-2' : ''}>
      <NamesInTooltip names={(_get(data, 'vessels') || []).map(v => v?.name)} />
    </div>
  ),
  group: data => (
    <div className={`w-100p d-flex align-items-center ${data?.parent_id ? 'ps-2' : ''}`}>
      <SvgRender
        src={data?.parent_id ? emptyRibbon : burger}
        style={{ width: 14, height: 14 }}
        className="text-primary me-1"
      />
      <TextWithTooltip className="h-auto">
        {_get(data, 'group.code') ? `${_get(data, 'group.code')}. ` : ''}
        {_get(data, 'group.name') || '-'}
      </TextWithTooltip>
    </div>
  ),
  maker: data => (
    <div className={data?.parent_id ? 'ps-2' : ''}>
      <TextWithTooltip className={`h-auto `}>{_get(data, 'maker.full_name')}</TextWithTooltip>
    </div>
  ),
  licensee: data => (
    <div className={data?.parent_id ? 'ps-2' : ''}>
      <TextWithTooltip className={`h-auto `}>{_get(data, 'licensee.full_name')}</TextWithTooltip>
    </div>
  ),
  type: data => (
    <div className={data?.parent_id ? 'ps-2' : ''}>
      <TextWithTooltip className={`h-auto `}>{_get(data, 'type')}</TextWithTooltip>
    </div>
  ),
  drawing_no: data => (
    <Row className="align-items-center w-100p">
      <Col className={data?.parent_id ? 'ps-2' : ''}>
        <TextWithTooltip className={`h-auto`}>{_get(data, 'drawing_no')}</TextWithTooltip>
      </Col>

      <Col
        xs="auto"
        className={`d-flex align-items-center justify-content-end  ${
          data?.parent_id ? 'pe-1' : 'pe-0'
        }`}
      >
        <TreeTitleActions
          actions={[
            {
              name: 'Edit',
              onClick: () => {
                setSelectedItem(data, 'edit');
              },
              permissions: [permissions.office.pms.library.systems.edit]
            },
            ...(data?.parent_id
              ? []
              : [
                  {
                    name: 'Add sub-system',
                    onClick: () => {
                      setSelectedItem(data, 'add_sub_system');
                    },
                    permissions: [permissions.office.pms.library.systems.create]
                  }
                ]),
            {
              name: 'Clone system',
              onClick: () => {
                setSelectedItem(data, 'clone_system');
              },
              permissions: [permissions.office.pms.library.systems.create]
            },
            {
              name: 'Delete',
              onClick: () => {
                setSelectedItem(data, 'delete');
              },
              permissions: [permissions.office.pms.library.systems.delete]
            }
          ]}
        />
      </Col>
    </Row>
  ),
  report_running_hours: data => (
    <div className={`d-flex align-items-center justify-content-center cpe-12`}>
      <SvgRender
        src={data?.report_running_hours ? checkedIcon : xIcon}
        className={data?.report_running_hours ? 'text-green' : 'text-violet'}
        style={{ width: 16, height: 16 }}
      />
    </div>
  )
});

export const drawerTypes = ['create', 'edit', 'add_sub_system'];

export const constructDrawerTitle = (type, data) => {
  switch (type) {
    case 'edit':
      return (
        <div className="d-flex flex-column">
          <div>Edit System</div>
          <div className="cmt-6 fw-bold fs-12">{data?.description}</div>
        </div>
      );
    case 'create':
      return <div>Create new System</div>;
    case 'add_sub_system':
      return (
        <div className="d-flex flex-column">
          <div>Add Sub-Group</div>
          <div className="cmt-6 fw-bold fs-12">{data?.description}</div>
        </div>
      );

    default:
      return null;
  }
};

export const constructDrawerButtonTitle = (type, data) => {
  switch (type) {
    case 'edit':
      return 'SAVE';
    case 'create':
      return 'CREATE';
    case 'add_sub_system':
      return 'ADD';

    default:
      return null;
  }
};

export const modalDetails = {
  delete: {
    actionIcon: (
      <SvgRender src={deleteSvg} style={{ width: 84, height: 84 }} className="text-red" />
    ),
    actionText: 'DELETE',
    header: 'Delete'
  },
  clone_system: {
    actionIcon: <SvgRender src={cloneIcon} style={{ width: 84, height: 84 }} />,
    actionText: 'CLONE',
    header: 'Clone',
    color: 'primary'
  }
};
