import { FC } from 'react';
import { EntityLog } from '@/common/types/logs';

import TimelinePoint, { TimelinePointProps } from './TimelinePoint';

type TimelineProps = {
  entityLogs?: EntityLog[];
  pointComponent?: React.ComponentType<TimelinePointProps>;
};

const Timeline: FC<TimelineProps> = ({ entityLogs, pointComponent }) => {
  const Tag = pointComponent || TimelinePoint;

  return (
    <div>
      {entityLogs?.map((entityLog, index) => (
        <Tag
          key={index}
          className={`${index > 0 ? 'cmt-6' : ''}`}
          index={index}
          pointCount={entityLogs.length}
          entityLog={entityLog}
        />
      ))}
    </div>
  );
};

export default Timeline;
