import { useEffect, useState } from 'react';

import CkEditor from 'common/components/form/inputs/ckeditor';
import Label from './Label';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIfEditMode,
  selectIsJobLocked,
  selectJobField
} from 'common/components/jobs/_base/store/selectors';
import { setJobField, updateJob } from 'common/components/jobs/_base/store/actions';
import InputSaveActions from '../InputSaveActions';

import { selectJobId } from 'common/components/jobs/_base/store/selectors';

const Comments = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [tempComments, setTempComments] = useState('');

  const dispatch = useDispatch();

  const comments = useSelector(state => selectJobField(state, 'vessel_comments'));
  const isEditMode = useSelector(checkIfEditMode);

  const isJobLocked = useSelector(state => selectIsJobLocked(state, true, true));
  const jobId = useSelector(selectJobId);

  useEffect(() => {
    if (comments) {
      setTempComments(prev => (!prev ? comments : prev));
    }
  }, [comments]);

  useEffect(() => {
    const updateJobVesselComments = async jobId => {
      try {
        if (jobId) {
          await dispatch(updateJob({ vessel_comments: tempComments }));
          return;
        }
        await dispatch(setJobField('vessel_comments', tempComments));
      } catch (error) {
        console.error(error);
      }
    };

    if (!isFocused && tempComments && comments !== tempComments) {
      updateJobVesselComments(jobId);
    }
  }, [comments, dispatch, isFocused, tempComments, jobId]);

  const onSave = () => {
    try {
      dispatch(updateJob({ vessel_comments: tempComments }));
      dispatch(setJobField('vessel_comments', tempComments));
      setTimeout(() => setIsFocused(false), 600);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-light-gray rounded-lg p-2 h-100p">
      <Label title="VESSEL COMMENTS" />

      <div>
        <CkEditor
          className={`${isFocused ? '' : 'job-description-editor--hide'} mb-1`}
          isFocused={isFocused}
          onFocus={focus =>
            focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100)
          }
          placeholder="Add some text…"
          id="job-vessel-comments"
          minHeight="32px"
          onChange={v => setTempComments(v)}
          value={tempComments}
          disabled={isJobLocked}
        />

        {isEditMode && isFocused && !isJobLocked ? (
          <InputSaveActions
            onCancel={() => {
              dispatch(setJobField('vessel_comments', comments));
              setTempComments(comments);
              setTimeout(() => setIsFocused(false), 600);
            }}
            onSave={onSave}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Comments;
