import { Row, Col } from 'reactstrap';
import FormField from 'common/components/form/helpers/FormField';

const Form = ({ config, keys, fields, changeField, selectField, isSubSystem }) => {
  return (
    <div className="system-form position-relative">
      <Row className="w-100p">
        {keys.map(key => (
          <Col
            key={key}
            xs={config[key]?.form?.edit?.width || 'auto'}
            className={`${config[key]?.form?.edit?.className || ''}`}
          >
            {config[key]?.form?.edit?.customComponent ? (
              config[key]?.form?.edit?.customComponent(
                fields[key],
                { selectField, changeField },
                { isSubSystem }
              )
            ) : (
              <FormField
                fieldConfig={config[key]}
                fieldKey={key}
                changeField={changeField}
                selectField={selectField}
                fields={fields}
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Form;
