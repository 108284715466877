import SystemGroups from '@/common/components/pms/setup/views/systems-groups';
import CollapsableSystemContrainer from './system/components/CollapsableSystemContrainer';
import { Row, Col } from 'reactstrap';

const Pms = () => {
  return (
    <div className="pms">
      <Row className="h-100p pms-container setup-view flex-nowrap g-0">
        <Col className="flex-1 overflow-hidden pe-3 base-transition">
          <SystemGroups />
        </Col>
        <Col xs="auto">
          <CollapsableSystemContrainer />
        </Col>
      </Row>
    </div>
  );
};

export default Pms;
