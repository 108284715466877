export const weatherOptions = [
  // { label: 'Pressure', value: 'pressure', enabled: true },
  { label: 'Current', value: 'currents' },
  { label: 'Wind', value: 'winds', enabled: true, isPointLayer: true },
  { label: 'Primary Swell', value: 'swell', enabled: true, isPointLayer: true },
  { label: 'Visibility', value: 'visibility' },
  { label: 'Sea Temperature', value: 'sea-temperature' },
  { label: 'Significant Wave', value: 'waves', enabled: true }
  // { label: 'Air Temperature', value: 'air-temperature', enabled: true },
  // { label: 'Animated Wind', value: 'animated-winds', enabled: true }
];

export const layerOptions = [
  { label: 'ECA', value: 'eca', width: 7 },
  { label: 'Load Lines', value: 'load-lines', isWeather: true, width: 5 },
  { label: 'HRA', value: 'hra', width: 7 },
  { label: 'Time Zones', value: 'timezones', isWeather: true, width: 5 },
  { label: 'VRA', value: 'vra', width: 7 },
  { label: 'Custom Areas', value: 'custom-areas', width: 5 }
  //{ label: 'Ports', value: 'ports' }
];
