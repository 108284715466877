import { download, get } from '@/api';
import { Attachment, Stream } from '@/ts-common/types/files';
import { getFileSyncState } from '@/ts-common/utils/files';

export const getFileStream = async (fileId?: string | number) => {
  if (!fileId) return null;

  try {
    const response = await get<Stream>(`/files/${fileId}`);

    if (!response) return null;

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getFileMetadata = async (fileId?: string | number) => {
  if (!fileId) return null;

  try {
    const response = await get<Attachment>(`/files-metadata/${fileId}`);

    if (!response) return null;

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getFilesMetadata = async (fileIds: (number | string)[] = []) => {
  if (!fileIds.length) return [];

  try {
    const response = await get<Attachment[]>(`/files-metadata`, { ...fileIds });

    if (!response) return [];

    return response.data;
  } catch (error) {
    return [];
  }
};

export const getFileBaseUrl = () => '';

export const renewFilesMetadata = async (files: Attachment[]) => {
  if (!files?.length) return null;

  try {
    const response = await get<Attachment[]>(`/files-metadata`);

    if (!response) return files;

    return response.data;
  } catch (error) {
    return files;
  }
};

export const isFileSynced = (file: Attachment) => getFileSyncState(file, false);

type downloadAllFilesOfEntityType =
  | 'bunker_survey'
  | 'finding'
  | 'note'
  | 'job'
  | 'job_maintenance_details'
  | 'job_flag_extension'
  | 'spare_part'
  | 'email'
  | 'purchasing_supplier_evaluation'
  | 'purchasing_requisition_supplier'
  | 'purchasing_forwarding_case'
  | 'purchasing'
  | 'vessel_document'
  | 'event_lub_oil_analysis_assignment'
  | 'event_office_attachments'
  | 'event_vessel_attachments'
  | 'vetting'
  | 'invoices'
  | 'crew_contract'
  | 'port_document_action_document'
  | 'account_contract';

export const downloadAllFilesOfEntity = async ({
  id,
  type,
  file_type
}: {
  id: number | string;
  type: downloadAllFilesOfEntityType;
  file_type?: string;
}) => {
  try {
    return await download<Attachment[]>(`/files/entity`, { id, type, file_type });
  } catch (error) {
    console.error(error);
  }
};
