import {
  Currency,
  KeyLabelListingOptionType,
  CrewProfileDefaultViewType
} from '@/common/types/enums';

export enum ExternalEmailType {
  Wingu = 'wingu_email',
  Danaos = 'danaos_email',
  Infogate = 'infogate_email',
  ThinkCompass = 'thinkcompass_email'
}

export enum BunkerSurveyDifferenceApplicableType {
  None = 'none',
  Departure = 'departure_report'
}

export interface OrganizationSettings {
  accounting_master: boolean | null;
  system_currency: Currency | null;
  external_email_status: string | null;
  purchasing_email_status: string | null;
  purchasing_requisitions_highlight_cheapest: boolean | null;
  purchasing_requisitions_highlight_most_expensive: boolean | null;
  evaluations_range_from: string | number;
  evaluations_range_to: string | number;
  evaluations_step: string | number;
  external_email_type:
    | ExternalEmailType.Wingu
    | ExternalEmailType.Danaos
    | ExternalEmailType.Infogate
    | ExternalEmailType.ThinkCompass
    | null;
  pms_jobs_require_total_system_running_hours_when_reported_in_the_system: boolean | null;
  pms_assign_system_and_subsystem_or_assignment_to_group: 'system' | 'system_assignment' | null;
  itinerary_edit_scheduled_ports_onboard: boolean | null;
  crew_rotation_multiple_excel_export: boolean;
  crew_ticketing_cases_show_pdf_export: boolean;
  crew_member_external_id_keys: KeyLabelListingOptionType[];
  crew_profile_default_view: CrewProfileDefaultViewType;
  apply_the_bunker_survey_difference_as_correction_to:
    | BunkerSurveyDifferenceApplicableType.Departure
    | BunkerSurveyDifferenceApplicableType.None;
}

export type OrganizationSettingsKey = keyof OrganizationSettings;
