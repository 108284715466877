import { createSelector } from 'reselect';
import _sortBy from 'lodash/sortBy';

const selectVesselsReducer = state => state.vessels;

export const selectVesselProfile = createSelector(selectVesselsReducer, reducer => reducer.profile);

const getKey = (_, key) => key;

export const selectVesselTitle = state =>
  state.vessels.profile && state.vessels.profile.name ? state.vessels.profile.name : '';

export const selectVesselId = createSelector(selectVesselProfile, profile =>
  profile.id ? profile.id : null
);

export const selectVesselName = createSelector(selectVesselProfile, vessel => vessel.name);

export const selectVesselImo = createSelector(selectVesselProfile, profile => profile?.imo_no);

export const selectVesselConsumptions = createSelector(
  selectVesselProfile,
  profile => profile?.consumptions || []
);

/* Pool */

export const selectVesselPool = createSelector(selectVesselsReducer, reducer => reducer.pool);

export const selectPoolFetching = createSelector(selectVesselPool, pool => pool.fetching);

export const selectActivePool = createSelector(selectVesselPool, pool => pool.active);

export const selectPoolDrawerIsOpen = createSelector(selectVesselPool, pool => pool.drawer.isOpen);

export const selectPoolDrawerType = createSelector(selectVesselPool, pool => pool.drawer.type);

export const selectPool = createSelector(selectVesselPool, pool => pool.poolData);

export const selectHasPoolData = createSelector(selectPool, poolData => poolData?.length);

/* Documents Selectors */
const selectVesselDocuments = createSelector(selectVesselsReducer, reducer => reducer.documents);
const selectVesselDocumentsCategories = createSelector(
  selectVesselsReducer,
  reducer => reducer.documents.categories
);

export const selectDocumentDrawerIsOpen = createSelector(
  selectVesselDocuments,
  documents => documents.drawerOpen
);

export const selectDocumentDrawerType = createSelector(
  selectVesselDocuments,
  documents => documents.drawerType
);

export const selectDocumentDrawerActiveItem = createSelector(
  selectVesselDocuments,
  documents => documents.drawerActiveItem
);

export const selectDocumentsLoading = createSelector(
  selectVesselDocuments,
  documents => documents.isDataLoading
);

export const selectDocumentsTypes = createSelector(
  selectVesselDocuments,
  documents => documents.types
);

export const selectDocumentTypeCategories = createSelector(
  selectVesselDocumentsCategories,
  getKey,
  (documentsCategories, key) => documentsCategories[key]
);

export const exludedDocumentCategories = createSelector(
  selectVesselDocuments,
  documents => documents.exludedCategories
);

export const areAllDocumentTypePanelsExpanded = createSelector(
  selectVesselDocuments,
  documents => documents.expandedAllPanels
);

export const selectDocumentSearch = createSelector(
  selectVesselDocuments,
  documents => documents.search
);
/* -- */

/* Bunkers Selectors */

const selectVesselBunkers = createSelector(selectVesselsReducer, reducer => reducer.bunkers);

export const selectBunkersFetching = createSelector(
  selectVesselBunkers,
  bunkers => bunkers.fetching
);

export const selectBunkersStartRob = createSelector(
  selectVesselBunkers,
  bunkers => bunkers.startRob || []
);

export const selectBunkersEndRob = createSelector(
  selectVesselBunkers,
  bunkers => bunkers.endRob || []
);

export const selectBunkersActions = createSelector(
  selectVesselBunkers,
  bunkers => bunkers.actions || []
);

export const selectBunkersTotals = createSelector(selectVesselBunkers, bunkers => bunkers.totals);

export const selectBunkersDrawerStatus = createSelector(
  selectVesselBunkers,
  bunkers => bunkers.drawerOpen
);

export const selectBunkersModalOpen = createSelector(
  selectVesselBunkers,
  bunkers => bunkers.modalOpen
);

export const selectRobReseted = createSelector(selectVesselBunkers, bunkers => bunkers.robReseted);

export const selectRobCalculation = createSelector(selectVesselProfile, profile =>
  profile.rob_calculation ? profile.rob_calculation : 'fifo'
);

/* Data Integration Selectors */

/* Default Sources Tab */
const selectDefaultSources = createSelector(
  selectVesselsReducer,
  reducer => reducer.defaultSources
);

export const selectDefaultSourcesData = createSelector(
  selectDefaultSources,
  defaultSource => defaultSource.data
);

export const selectDefaultSourcesFetching = createSelector(
  selectDefaultSources,
  defaultSource => defaultSource.fetching
);

/* Label Parameters Tab */
const selectLabelParameters = createSelector(
  selectVesselsReducer,
  reducer => reducer.labelParameters
);

export const selectLabelParametersData = createSelector(
  selectLabelParameters,
  labelParameter => labelParameter.data
);

export const selectLabelParametersPaging = createSelector(
  selectLabelParameters,
  labelParameter => labelParameter.paging
);

export const selectLabelParametersFetching = createSelector(
  selectLabelParameters,
  labelParameter => labelParameter.fetching
);

/* Integration Sources */
const selectIntegrationSources = createSelector(
  selectVesselsReducer,
  reducer => reducer.integrationSources
);

export const selectIntegrationSourcesData = createSelector(
  selectIntegrationSources,
  integrationSource => integrationSource.data
);

export const selectTceReportData = state => selectVesselsReducer(state)?.tceResultsData;
export const selectTceReportIsLoading = state => selectVesselsReducer(state)?.loading;

/* -- */

/* Benchmarks */
const selectBenchmarks = createSelector(selectVesselsReducer, reducer => reducer.benchmarks);

export const selectBenchmarksInputHoverIndex = createSelector(
  selectBenchmarks,
  benchmarks => benchmarks.inputHoverIndex
);

export const selectBenchmarksDataIsLoading = createSelector(
  selectBenchmarks,
  benchmarks => benchmarks.isDataLoading
);

export const selectBenchmarksData = createSelector(selectBenchmarks, benchmarks => benchmarks.data);
export const selectBenchmarkTypes = createSelector(
  selectBenchmarks,
  benchmarks => benchmarks.types
);
export const selectBenchmarkConditions = createSelector(
  selectBenchmarks,
  benchmarks => benchmarks.conditions
);
export const selectBenchmarkIsLoadingConditionsOrTypes = createSelector(
  selectBenchmarks,
  benchmarks => benchmarks.isLoadingConditionsOrTypes
);

export const selectEdittingBenchmarkId = createSelector(
  selectBenchmarks,
  benchmarks => benchmarks.editingBenchmarkId
);

export const selectIsBenchmarksLogsDrawerOpen = state => selectBenchmarks(state).isLogsDrawerOpen;

export const selectBenchmarksActiveLogsFormula = state => selectBenchmarks(state).activeLogsFormula;

/* -- */

export const selectCiiReferenceYearly = createSelector(
  selectVesselsReducer,
  vessel => vessel?.ciiReferenceYearly
);

/* Market Indexes */

export const selectVesselMarketIndexes = createSelector(selectVesselProfile, profile =>
  _sortBy(profile?.market_indexes || [], 'name')
);

/* Veseel Profile External Ids */

export const selectVesselProfileExternalIds = state => selectVesselProfile(state).external_ids;
