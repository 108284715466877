import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';

const PmsModal = ({
  isOpen,
  closeModal,
  onAccept,
  header = 'Delete Group',
  description = '',
  ...rest
}) => {
  return (
    <DangerousActionModal
      header={header}
      isOpen={isOpen}
      onCancel={closeModal}
      onAccept={onAccept}
      {...rest}
    >
      {description}
    </DangerousActionModal>
  );
};

export default PmsModal;
