import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import SvgRender from '../general/SvgRender';
import Tooltip from '@/ts-common/components/general/Tooltip';

type SizeType = 'sm' | 'md' | 'lg';

type TabType = {
  label?: string;
  icon?: string;
  tooltip?: string;
  warning?: string;
  to?: string;
  isActive?: boolean;
  className?: string;
  iconStyle?: Record<string, unknown>;
  onClick?: () => void;
};

const Tab: FC<TabType & { size: SizeType; index: number }> = ({
  label,
  icon,
  tooltip,
  warning,
  to,
  isActive,
  className = '',
  size = 'lg',
  iconStyle,
  index,
  ...rest
}) => {
  const Tag = to ? NavLink : Button;

  const props = to
    ? {
        className: `btn-group-button btn-${size} ${className}`,
        exact: true,
        to,
        isActive
      }
    : {
        color: 'group-button',
        size,
        to: to ?? '',
        className: `${isActive ? 'active' : ''} ${className}`
      };

  return (
    <>
      <Tag id={`nav-tab-${index}`} {...props} {...rest}>
        {icon ? <SvgRender src={icon} style={iconStyle || { width: 16, height: 16 }} /> : label}
      </Tag>

      {!!tooltip && (
        <Tooltip fade={false} target={`nav-tab-${index}`} placement="bottom">
          {tooltip}
        </Tooltip>
      )}

      {!!warning && (
        <>
          <div
            id={`nav-tab-${index}`}
            className="btn-group-button--warning position-absolute bg-red"
          />
          <Tooltip placement="right" innerClassName="min-w-fit" target={`nav-tab-${index}`}>
            {warning}
          </Tooltip>
        </>
      )}
    </>
  );
};

export type NavigationGroupTabsProps = {
  tabs: TabType[];
  className?: string;
  size?: SizeType;
};

const NavigationGroupTabs: FC<NavigationGroupTabsProps> = ({
  tabs = [],
  className = '',
  size = 'lg'
}) => {
  return (
    <ButtonGroup className={`d-inline-flex g-0 group-button ${className}`}>
      {tabs.map((tab, i) => (
        <Tab size={size} key={i} index={i} {...tab} />
      ))}
    </ButtonGroup>
  );
};

export default NavigationGroupTabs;
