import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import SvgRender from 'common/components/general/SvgRender';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import ReviewProcessDrawer from 'common/components/review-process';
import { useActions } from 'utils/hooks';

import send from 'common/assets/svg/actions/send.svg';
import reviewProcess from 'common/assets/svg/common/review-process.svg';
import check from 'common/assets/svg/common/check-circle.svg';
import rejected from 'common/assets/svg/common/x-circle.svg';

import {
  isReviewProcessCompleted,
  selectReviewProcessStepsLength,
  selectReviewProcessStepsFilledLength
} from 'common/components/review-process/store/selectors';

import * as reviewProcessActions from 'common/components/review-process/store/actions';
import InfoModal from 'common/components/modals/InfoModal';

const getDefaultButtonProps = (sendForApproval: any) => ({
  buttonLabel: sendForApproval ? 'Send for Approval' : 'Under Approval',
  buttonIcon: send,
  completedLabel: 'Approved',
  showStepsWhenCompleted: false,
  hideButtonLabelPrefix: false
});

const getDefaultApprovalModalProps = (entityAction: any) => ({
  modalLabel: 'Send For Approval',
  modalBody: `Are you sure you want to send this ${entityAction?.name} for Approval? This cannot be undone.`,
  modalAction: 'send',
  modalActionText: 'SEND',
  modalCancelText: 'NO'
});

const ReviewProcessToggleButton: FC<any> = ({
  entityAction,
  sendForApproval,
  entityId,
  disabled,
  withDrawer = true,
  className,
  approvalButtonProps = {},
  approvalModalProps = {},
  preventionModalProps,
  hasExtraPrevention = false,
  isRejected = false,
  rejectedButtonProps = { buttonLabel: 'Rejected' },
  onDrawerCloseCallback = () => null
}) => {
  const [actionModalIsOpen, setActionModalIsOpen] = useState(false);
  const [actionPreventionModalIsOpen, setActionPreventionModalIsOpen] = useState(false);

  const [setReviewProcessDrawerIsOpen] = useActions([
    reviewProcessActions.setReviewProcessDrawerIsOpen
  ]);

  const stepsLength = useSelector(state => selectReviewProcessStepsLength(state, entityId));
  const stepsFilledLength = useSelector(state =>
    selectReviewProcessStepsFilledLength(state, entityId)
  );
  const isCompleted = useSelector(state => isReviewProcessCompleted(state, entityId));

  const buttonProps = { ...getDefaultButtonProps(sendForApproval), ...approvalButtonProps };
  const modalProps = { ...getDefaultApprovalModalProps(entityAction), ...approvalModalProps };

  const {
    buttonLabel,
    buttonIcon,
    completedLabel,
    showStepsWhenCompleted,
    hideButtonLabelPrefix,
    onClick
  } = buttonProps;

  return sendForApproval ? (
    <>
      <BoxShadowButton
        className={`text-primary ms-1 ${className || ''}`}
        size="sm"
        disabled={disabled}
        color="primary"
        onClick={() => {
          if (hasExtraPrevention) {
            setActionPreventionModalIsOpen(true);
          } else {
            setActionModalIsOpen(true);
          }
        }}
      >
        <SvgRender src={buttonIcon} style={{ width: 20, height: 20 }} className="cme-12" />
        <span>
          {hideButtonLabelPrefix ? '' : 'Trigger'} {buttonLabel}
        </span>
      </BoxShadowButton>

      <DangerousActionModal
        transparent
        onAccept={sendForApproval}
        closeModal={() => setActionModalIsOpen(false)}
        isOpen={actionModalIsOpen}
        action={modalProps.modalAction}
        cancelText={modalProps.modalCancelText}
        actionHoverColor="primary"
        actionText={modalProps.modalActionText}
        header={modalProps.modalLabel}
        body={modalProps.modalBody}
      />

      {hasExtraPrevention ? (
        <InfoModal
          transparent
          closeModal={() => setActionPreventionModalIsOpen(false)}
          isOpen={actionPreventionModalIsOpen}
          actionIcon={preventionModalProps?.icon}
          actionText={'OK'}
          header={preventionModalProps?.header}
          body={preventionModalProps?.body}
          size={preventionModalProps?.size}
        />
      ) : null}
    </>
  ) : (
    <>
      <BoxShadowButton
        className={`text-${isRejected ? 'red' : 'turquoise'} border-1 ${className || ''}`}
        size="sm"
        disabled={disabled}
        color={isRejected ? 'red' : 'turquoise'}
        onClick={() =>
          onClick ? onClick(true, entityId) : setReviewProcessDrawerIsOpen(true, entityId)
        }
      >
        <SvgRender
          src={isRejected ? rejected : isCompleted ? check : reviewProcess}
          style={isRejected ? { width: 16, height: 16 } : { width: 20, height: 20 }}
          className="cme-12"
        />
        <span>
          {isRejected
            ? rejectedButtonProps.buttonLabel
            : isCompleted
              ? completedLabel
              : buttonLabel}
        </span>
        {!isCompleted || (isCompleted && showStepsWhenCompleted) ? (
          <>
            <span className="cms-4">|</span>
            <strong className="cms-4">
              {stepsFilledLength || 0} / {stepsLength || 0}
            </strong>
          </>
        ) : null}
      </BoxShadowButton>

      {withDrawer ? (
        <ReviewProcessDrawer
          entityAction={entityAction}
          onDrawerCloseCallback={onDrawerCloseCallback}
        />
      ) : null}
    </>
  );
};

export default ReviewProcessToggleButton;
