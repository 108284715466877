import JobPrioritySelector from 'common/components/selectors/jobs/JobPrioritySelector';
import MaintenanceJobTypeSelector from 'common/components/selectors/jobs/MaintenanceJobTypeSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import Departments from 'common/components/general/Departments';
import VesselSystemSelector from 'common/components/selectors/VesselSystemSelector';
import VesselSystemInfo from 'common/components/pms/jobs/components/VesselSystemInfo';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import Priority from 'common/components/general/Priority';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';
import checkedIcon from 'common/assets/svg/common/check-circle-solid.svg';
import x from 'common/assets/svg/common/x-circle.svg';
import formIcon from 'common/assets/svg/common/forms.svg';
import safetyEnabled from '@/common/assets/svg/jobs/guards.svg';
import drydockEnabled from '@/common/assets/svg/jobs/drydock_enabled.svg';
import { Button } from 'reactstrap';
import DeactivatedVessels from '@/common/components/pms/setup/views/system/specifications/jobs/job/DeactivatedVessels';

export const VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES = 'vessel_id';

export const columns = [
  {
    header: 'System',
    key: 'vessel_system',
    type: 'string',
    canFilter: false,
    sort: true,
    minWidth: 368,
    maxWidth: 368
  },
  {
    header: 'Vessel System',
    key: 'vessel_system_id',
    canFilter: true,
    hidden: true,
    type: 'collection',
    component: VesselSystemSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Vessels Assigned',
    key: 'vessels_assigned',
    type: 'string',
    minWidth: 204,
    maxWidth: 204,
    canFilter: false
  },
  {
    header: 'I',
    key: 'importance',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 72,
    maxWidth: 72
  },
  {
    header: 'Title',
    key: 'title',
    type: 'string',
    sort: true,
    minWidth: 348,
    maxWidth: 348
  },
  {
    header: 'Importance',
    key: 'importance_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: JobPrioritySelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    hidden: true
  },
  {
    header: 'Type',
    key: 'job_type',
    data_key: 'maintenance_details.job_type.name',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 200,
    maxWidth: 200,
    truncate: true
  },
  {
    header: 'Job Type',
    key: 'maintenance_job_type_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: MaintenanceJobTypeSelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    hidden: true
  },
  {
    header: 'Periodicity',
    key: 'periodicity',
    data_key: 'periodicity_description',
    sort: true,
    canFilter: false,
    minWidth: 140,
    maxWidth: 140
  },
  {
    header: 'Crew Dept',
    key: 'crew_department',
    sort: true,
    canFilter: false,
    type: 'string',
    minWidth: 140,
    maxWidth: 140
  },
  {
    header: 'Vessel Department',
    key: 'crew_department_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: DepartmentSelector,
    componentRest: {
      onlyCrewDepartments: true
    }
  },
  {
    header: 'Office Depts',
    key: 'department',
    sort: false,
    canFilter: false,
    type: 'string',
    minWidth: 154,
    maxWidth: 154
  },
  {
    header: <div className="fs-10 lh-16 fw-bold">Linked Forms</div>,
    key: 'forms',
    columns: [
      {
        header: 'Form',
        key: 'form',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        sort: false,
        minWidth: 364,
        maxWidth: 364,
        className: 'pe-0'
      }
    ]
  },
  {
    header: 'SPARE PARTS SET',
    key: 'spare_parts_count',
    sort: false,
    canFilter: false,
    minWidth: 124,
    maxWidth: 124,
    className: 'text-center'
  },
  {
    key: 'maintenance_attachments_count',
    sort: false,
    canFilter: false,
    minWidth: 48,
    maxWidth: 48
  },
  {
    header: 'Class',
    key: 'is_class',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Critical',
    key: 'is_critical',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Drydock',
    key: 'is_drydock',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Environmental',
    key: 'is_environmental_critical',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Office Department',
    key: 'department_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: DepartmentSelector
  },
  {
    header: 'Navigational',
    key: 'is_navigational',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Safety',
    key: 'is_safety',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Periodicity Interval',
    key: 'periodicity_interval',
    type: 'periodicity_interval',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Periodicity Running Hours',
    key: 'periodicity_running_hours',
    type: 'number',
    canFilter: true,
    hidden: true
  },
  {
    header: '',
    key: 'activation',
    minWidth: 128,
    maxWidth: 128
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const rows = {
  vessel_system: data => {
    const vesselSystem = data?.maintenance_details?.vessel_system;

    if (!vesselSystem) return null;

    return (
      <VesselSystemInfo
        vesselSystem={{ ...vesselSystem, description: data?.vessel_system_description }}
        attributes={{
          is_critical: vesselSystem?.is_critical,
          is_environmental_critical: vesselSystem?.is_environmental_critical,
          is_navigational: vesselSystem?.is_navigational
        }}
        showGroup={false}
      />
    );
  },
  vessels_assigned: data => {
    return (
      <div className="d-flex align-items-center">
        <NamesInTooltip
          names={(data?.maintenance_details?.vessel_system?.vessels || []).map(v => v?.name)}
        />
        <DeactivatedVessels job={{ inactive_vessels: data.inactive_vessels }} />
      </div>
    );
  },
  importance: data => <Priority value={data.importance_id} />,
  crew_department: data => {
    return data?.crew_department?.id ? (
      <Departments values={[{ ...data?.crew_department, is_crew_department: true }]} />
    ) : null;
  },
  department: data => <Departments values={data?.departments} maxLimit={1} />,
  form: data => {
    return (
      <div className="overflow-hidden">
        {data.forms?.map((f, index) => (
          <div
            key={index}
            className={`d-flex flex-nowrap overflow-hidden ${index !== 0 ? 'cmt-2' : ''}`}
          >
            <Button color="purple" className="pointer-events-none h-20 w-20 p-0">
              <SvgRender
                src={formIcon}
                className={`text-white`}
                style={{ height: 11, width: 11 }}
              />
            </Button>
            <div className="flex-1 overflow-hidden px-1">
              <TextWithTooltip>{f.form?.name}</TextWithTooltip>
            </div>
          </div>
        ))}
      </div>
    );
  },
  spare_parts_count: data => {
    const isChecked = data?.maintenance_spare_parts?.required?.length;

    return (
      <SvgRender
        style={{ width: 16, height: 16 }}
        src={isChecked ? checkedIcon : x}
        className={`text-${isChecked ? 'green' : 'violet'}`}
      />
    );
  },
  title: data => {
    return (
      <div className="d-flex align-items-center flex-nowrap w-100p">
        <div className="flex-1 overflow-hidden cpe-4">
          <TextWithTooltip>{data?.title}</TextWithTooltip>
        </div>
        {data?.is_safety ? (
          <div className="d-inline-flex align-items-center cpb-2 cme-4">
            <SvgRender className="cme-4" src={safetyEnabled} style={{ width: 14, height: 14 }} />
          </div>
        ) : null}
        {data?.is_drydock ? (
          <div className="d-inline-flex align-items-center cpb-2 cme-4 ">
            <SvgRender className="cme-4" src={drydockEnabled} style={{ width: 14, height: 14 }} />
          </div>
        ) : null}
      </div>
    );
  }
};
