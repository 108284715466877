import {
  numberField,
  optionField,
  stringField,
  singleDateField,
  booleanField,
  hiddenField
} from 'common/utils/form/fieldTypes';
import { Supplier } from '@/common/types/purchasing.ts';
import { Attachment } from '@/ts-common/types/files';
import { Party } from '@/common/types/parties';
import { Country, Currency, Department } from '@/common/types/enums';
import { PortDaCaseAgent, PortDaCaseAgentItem } from '@/common/types/port-da-cases';
import {
  AddSubForm,
  ChangeFieldFunc,
  FieldsStateType,
  RemoveSubForm,
  SelectFieldFunc,
  SubStates
} from '@/common/types/form';
import { DrawerStateType } from '@/ts-common/types/drawer';
import {
  AccountingCompany,
  InstallmentPeriodicityType,
  InvoiceDocumentType,
  InvoiceLinkedType,
  InvoiceMatchedVendorType
} from '@/common/types/invoices';
import { CompanyParty, OrcaCompany } from '@/common/types/companies';
import { PermissionType } from '@/common/types/permissions';
import permissions from '@/common/utils/permissions/constants';
import {
  InvoiceEventAccount,
  InvoiceEventCrewMedical,
  InvoicePurchasingRequisition,
  InvoiceTicketOffer,
  InvoiceAccountContractPayment
} from '@/common/types/invoices.ts';
import { DateInputType } from '@webthatmatters/orca-dateinput';

export type ConfigType = {
  department_ids: number[];
  vat_no: string | null;
  address: string | null;
  country: Country | null;
  country_id: number | null;
  remarks: string;
  attachments: Attachment[];
  invoice_type: InvoiceDocumentType | null;
  amount: string;
  custom_rate: string;
  invoice_no: string;
  date: DateInputType;
  due_date: DateInputType;
  company: OrcaCompany | null;
  company_id: number | null;
  currency: Currency | null;
  currency_id: number | null;
  vendor: CompanyParty | null;
  vendor_id: number | null;
  vat_percentage: number | null;
  is_paid: boolean;
  pay_party: Supplier | null;
  pay_party_id: number | null;
  is_domestic: boolean;
  is_different_paying_party: boolean;
  files: Attachment[];
  file_id: number | null;
  requisition_suppliers: InvoicePurchasingRequisition[];
  tickets: InvoiceTicketOffer[];
  da_case_items: PortDaCaseAgentItem[];
  da_case_agents: PortDaCaseAgent[];
  has_installments: boolean;
  number_of_installments: number | null;
  installments_periodicity_value: number | null;
  installments_periodicity_type: InstallmentPeriodicityType | null;
  has_downpayments: boolean;
  downpayments_amount: number | null;
  vendor_accounting_companies: AccountingCompany[];
  accounting_companies: AccountingCompany[];
  should_send: boolean;
  invoice_type_id: number;
  invoice_link_to_entity_type_id: InvoiceLinkedType | null;
  invoice_relation_ids: number[];
  base_currency_equivalent_rate: string | null;
  amount_base_currency_equivalent: string | null;
  event_accounts: InvoiceEventAccount[];
  event_crew_medical_cases_expenses: InvoiceEventCrewMedical[];
  purchasing_total_delivered_sum: number | null;
  purchasing_total_ordered_sum: number | null;
  linked_case_has_same_currency: boolean;
  account_contract_payments: InvoiceAccountContractPayment[];
};

export type ConfigKey = keyof ConfigType;

type PermittedFormFieldType = { [key: PermissionType]: ConfigKey[] };

export const permittedFormFields: PermittedFormFieldType = {
  [permissions.office.accounting.invoices.edit_main_info]: [
    'company',
    'company_id',
    'department_ids',
    'vendor',
    'vendor_id',
    'vat_no',
    'address',
    'country',
    'country_id',
    'currency',
    'currency_id',
    'is_different_paying_party',
    'pay_party',
    'pay_party_id',
    'invoice_no',
    'date',
    'due_date',
    'amount',
    'custom_rate',
    'vat_percentage',
    'has_installments',
    'number_of_installments',
    'installments_periodicity_value',
    'installments_periodicity_type',
    'has_downpayments',
    'downpayments_amount',
    'remarks',
    'attachments',
    'is_domestic',
    'file_id'
  ],
  [permissions.office.accounting.invoices.edit_is_paid]: ['is_paid'],
  [permissions.office.accounting.invoices.edit_accounting_fields]: [
    'should_send',
    'invoice_type',
    'invoice_type_id',
    'accounting_companies',
    'vendor_accounting_companies'
  ],
  [permissions.office.accounting.invoices.link_with_cases]: [
    'invoice_link_to_entity_type_id',
    'invoice_relation_ids'
  ]
};

export type FormFieldsConfigType = FieldsStateType<ConfigType>;

export type InvoiceTabType = {
  hasFile: boolean;
  changeField: ChangeFieldFunc;
  selectField: SelectFieldFunc;
  fields: FormFieldsConfigType;
  drawer: DrawerStateType;
  changeVendorFields: () => void;
  matchedVendors: InvoiceMatchedVendorType[];
  onCompanyDrawerOpen: () => void;
  isViewOnly: boolean;
  linkedCasesDifference?: { percentage: number; amount: number };
  baseCurrencyEquivalentRate: number | null;
  customRate: number | null;
  amountBaseCurrencyEquivalent: number | null;
  previousAutoEquivalentRate: number | null;
  activeID: number | null;
  departments: Department[];
  subStates: SubStates[];
  addSubform: AddSubForm;
  removeSubform: RemoveSubForm;
  setSubStates: (i: string, items: any) => void;
  setShowLinkCases: (v: boolean) => void;
  purchasingTotals: { ordered: string; delivered: string };
  shouldDisableEditWhenIsGoodForPayment: boolean;
  shouldDisableEditWhenHasSentToAccounting: boolean;
  initialCurrencyLabel: string | undefined;
  invoiceLogs?: { updated_at: string; updated_by?: Party; created_at: string; created_by?: Party };
};

const config = {
  company: optionField(),
  department_ids: optionField(),
  vendor: optionField({ required: true }),
  vat_no: stringField(),
  address: stringField({ required: true }),
  country: optionField({ required: true }),
  invoice_no: stringField({ required: true }),
  date: singleDateField({ required: true }),
  due_date: singleDateField(),
  currency: optionField({ required: true }),
  remarks: stringField(),
  amount: numberField({ required: true }),
  amount_base_currency_equivalent: numberField(),
  base_currency_equivalent_rate: numberField({ decimalScale: 5 }),
  attachments: optionField({ initialValue: [] }),
  files: optionField({ initialValue: [] }),
  invoice_type_id: numberField(),
  invoice_relation_ids: optionField({ initialValue: [] }),
  tickets: optionField({ initialValue: [] }),
  da_case_agents: optionField({ initialValue: [] }),
  da_case_items: optionField({ initialValue: [] }),
  requisition_suppliers: optionField({ initialValue: [] }),
  event_accounts: optionField({ initialValue: [] }),
  event_crew_medical_cases_expenses: optionField({ initialValue: [] }),
  account_contract_payments: optionField({ initialValue: [] }),
  invoice_link_to_entity_type_id: optionField(),
  is_domestic: booleanField({ initialValue: false }),
  is_different_paying_party: booleanField({ initialValue: false }),
  pay_party: optionField(),
  vat_percentage: numberField(),
  custom_rate: numberField({
    decimalScale: 4
  }),
  invoice_type: optionField(),
  vendor_accounting_companies: {
    accounting_company: optionField({ required: true }),
    account: optionField(),
    local_amount: numberField(),
    debit_or_credit: stringField()
  },
  accounting_companies: {
    accounting_company: optionField({ required: true }),
    account: optionField(),
    local_amount: numberField(),
    debit_or_credit: stringField()
  },
  is_paid: booleanField({ initialValue: false }),
  has_installments: booleanField({ initialValue: false }),
  number_of_installments: numberField(),
  installments_periodicity_value: numberField(),
  installments_periodicity_type: stringField(),
  has_downpayments: booleanField({ initialValue: false }),
  downpayments_amount: numberField(),
  purchasing_total_delivered_sum: hiddenField(),
  purchasing_total_ordered_sum: hiddenField(),
  linked_case_has_same_currency: hiddenField(),
  account_contract_payments_count: stringField()
};

export type ConfigKeys = keyof typeof config;

export default config;
