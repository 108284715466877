import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FC, useState } from 'react';
import { Button } from 'reactstrap';
import { selectReviewProcessId } from '@/common/components/review-process/store/selectors-ts.ts';
import { replaceReviewProcessAction } from '@/common/components/review-process/store/actions-ts';

type ResetReviewProcessActionButtonProps = {
  entityId: number | string;
  fetchReviewProcess: () => void;
};

const ResetReviewProcessActionButton: FC<ResetReviewProcessActionButtonProps> = ({
  entityId,
  fetchReviewProcess
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, entityId));

  const onReset = async () => {
    if (!reviewProcessId) return;

    try {
      setIsLoading(true);

      await dispatch(replaceReviewProcessAction({ id: reviewProcessId })).unwrap();
      await fetchReviewProcess();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between mb-2 flex-1">
      <div className="fs-12 text-violet fw-medium flex-1 pe-1">
        * The terms of the process have changed.
      </div>
      <Button
        size="sm"
        color="primary"
        className="text-white fs-10"
        disabled={isLoading}
        onClick={onReset}
      >
        Reset the Approval Process
      </Button>
    </div>
  );
};

export default ResetReviewProcessActionButton;
