import Item from './Item';
import Header from './Header';

const Fifo = ({ subState = [], accountId, removeSubform, startedAt }) => {
  const onRemove = index => {
    removeSubform('items', index);
  };

  return (
    <div>
      <Header />

      {subState.map((state, i) => (
        <Item
          key={`account-balance-fifo-${i}`}
          formState={state}
          accountId={accountId}
          onRemove={() => onRemove(i)}
          startedAt={startedAt}
        />
      ))}
    </div>
  );
};

export default Fifo;
