import { useEffect, useMemo, useState } from 'react';
import Input from 'common/components/form/inputs/Input';
import Select from 'common/components/form/inputs/Select';
import SvgRender from 'common/components/general/SvgRender';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import { getFormFieldParams } from 'common/components/forms/digital/state-utils';
import { fetchCrewMembers } from 'common/components/forms/digital/lists-helpers';
import binIcon from 'common/assets/svg/actions/delete.svg';
import Spinner from 'common/components/general/Spinner';
import CircledButton from '@/ts-common/components/buttons/CircledButton';

const CrewSelect = ({
  value,
  error,
  onChange,
  defaultOptions,
  selectedCrewParticipants,
  loadOptions
}) => {
  return (
    <Select
      value={value}
      error={error}
      isAsync
      onChange={onChange}
      getOptionValue={option => option.id}
      getOptionLabel={option => getPartyDisplayName(option)}
      className="mb-0"
      placeholder="Select"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      filterOption={option => !selectedCrewParticipants.includes(option.value)}
    />
  );
};

const CrewParticipants = ({
  disabled,
  value,
  onChange,
  error,
  headerFormState,
  formField,
  isFormForVessel
}) => {
  const [crewOptions, setCrewOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const filteredFormValues = useMemo(
    () => (value || []).filter(participant => participant?.crew_rank_id),
    [value]
  );
  const hasSavedFormValues = !!filteredFormValues.length;
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);

  const { notes_label } = getFormFieldParams(formField);
  const { submissionDate, selectedVesselId } = headerFormState;
  const selectedDate = submissionDate ? submissionDate.format('YYYY-MM-DD') : null;

  const crewParticipants = value && value.length ? value : [{}];

  const onAdd = () => {
    onChange([...crewParticipants, { crew_member: null, crew_rank: null, notes: null }]);
  };

  const onDelete = index => () => {
    onChange(crewParticipants.filter((_, i) => i !== index));
  };

  const onFieldChange = (i, fieldName) => opt => {
    const updatedValue = [...crewParticipants];
    updatedValue[i][fieldName] = opt;

    if (fieldName === 'crew_member') {
      updatedValue[i].crew_rank = opt ? opt.contract_rank || opt.rank : null;
    }

    onChange(updatedValue);
  };

  useEffect(() => {
    const shouldFetchCrew =
      (isOnBoard && !selectedVesselId) || (selectedVesselId && isFormForVessel) || !isFormForVessel;

    if (!shouldFetchCrew) {
      onChange([{}]);
      return;
    }

    const initParticipants = async () => {
      setIsLoading(true);

      const crew = await fetchCrewMembers({
        isOnBoard,
        selectedDate,
        selectedVesselId,
        isFormForVessel,
        dispatch,
        search: ''
      });

      const validCrew = crew.filter(c => c?.type === 'crew' || c?.rank_id);
      setCrewOptions(validCrew?.length ? validCrew : []);

      if (validCrew?.length && isFormForVessel && !hasSavedFormValues) {
        onChange(
          validCrew?.map(c => {
            return {
              ...c,
              notes: null,
              crew_member: c,
              crew_rank: { id: c?.contract_rank?.id, name: c?.contract_rank?.name }
            };
          })
        );
      }

      setIsLoading(false);
    };

    initParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFormForVessel, isOnBoard, selectedDate, selectedVesselId, hasSavedFormValues]);

  return (
    <Row noGutters>
      {isLoading ? (
        <Spinner />
      ) : (
        <Col xs={12}>
          <Row noGutters className="fs-12 fw-bold text-violet mb-1">
            <Col xs={1}>NO.</Col>
            <Col xs={3}>NAME</Col>
            <Col xs={3} className="ms-3">
              RANK
            </Col>
            <Col xs={3} className="ms-3">
              {notes_label || 'NOTES'}
            </Col>
          </Row>
          {(crewParticipants || []).map((el, i) => {
            return (
              <Row key={i.toString()} noGutters className="fs-12 fw-bold text-violet mb-1">
                <Col xs={1} className="d-flex align-items-center">
                  {i + 1}
                </Col>
                <Col xs={3}>
                  <CrewSelect
                    value={el?.crew_member}
                    error={error}
                    onChange={onFieldChange(i, 'crew_member')}
                    defaultOptions={crewOptions}
                    selectedCrewParticipants={crewParticipants.map(
                      participant => participant.crew_member?.id
                    )}
                    loadOptions={search =>
                      fetchCrewMembers({
                        search,
                        isOnBoard,
                        selectedDate,
                        dispatch,
                        selectedVesselId
                      })
                    }
                  />
                </Col>
                <Col xs={3} className="ms-3">
                  <Select
                    value={el?.crew_rank}
                    isAsync
                    onChange={onFieldChange(i, 'crew_rank')}
                    getOptionValue={option => option.id}
                    defaultOptions={() => getInitialAsyncValues('crew-ranks')}
                    loadOptions={search => getAsyncOptions(search, 'crew-ranks')}
                    getOptionLabel={option => option.name}
                    className="mb-0"
                    placeholder="Select"
                    disabled
                  />
                </Col>
                <Col className="ms-3">
                  <Input
                    onChange={e => onFieldChange(i, 'notes')(e.target.value)}
                    value={disabled ? null : el?.notes}
                    className="mb-0"
                    placeholder="Add notes"
                  />
                </Col>
                <Col xs="auto" className="d-flex align-items-center ms-3">
                  <SvgRender
                    onClick={onDelete(i)}
                    src={binIcon}
                    className={`text-red cursor-pointer ${
                      i === 0 && !el?.crew_member && !el?.notes ? 'opacity-0' : ''
                    }`}
                    style={{ width: 16, height: 16 }}
                  />
                </Col>
              </Row>
            );
          })}
          <CircledButton type="add" disabled={disabled} onClick={onAdd} label="Add more" />
        </Col>
      )}
    </Row>
  );
};

export default CrewParticipants;
