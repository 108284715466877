import { get } from '@/api';
import {
  EntityLogsRequestParams,
  EntityLog,
  EntityLogsPaginatedResponse,
  EntityLogsPaginatedRequestParams
} from '@/common/types/logs';

export const getEntityLogs = async (params: EntityLogsRequestParams) => {
  const response = await get<EntityLog[]>('/entity-logs', params);

  return response.data;
};

export const getPaginatedEntityLogs = async (params: EntityLogsPaginatedRequestParams) => {
  const response = await get<EntityLogsPaginatedResponse>('/entity-logs', params);

  return response.data;
};
