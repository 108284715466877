import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NestedTableWrapper } from 'common/components/colored-table/styles/WithNestedTable';

import ColoredTable from 'common/components/colored-table';
import { TableTypes } from 'common/entities/tables/TableTypes';
import { setActiveSystemGroupState } from 'common/components/pms/setup/store/actions';

import { getRows } from './helpers';

const DEPTH_MULTIPLIER = 10;

const CollapsedTable = ({ data = [], prevDepth = 0, table }) => {
  const currentDepth = 1 + prevDepth;
  const dispatch = useDispatch();

  const setSelectedItem = useCallback(
    data => {
      dispatch(setActiveSystemGroupState(data));
    },
    [dispatch]
  );

  const rows = useMemo(() => getRows(setSelectedItem), [setSelectedItem]);

  return (
    <NestedTableWrapper>
      <ColoredTable
        hideTopPagination
        hideTableFilters
        hideTableSearch
        hideTableReorder
        hideTableHead
        collapsableRows={data =>
          data.system_groups?.length ? (
            <div style={{ paddingLeft: `${currentDepth * DEPTH_MULTIPLIER}px` }}>
              <CollapsedTable data={data.system_groups} prevDepth={currentDepth} table={table} />
            </div>
          ) : null
        }
        rows={rows}
        {...table}
        state={{ data }}
      />
    </NestedTableWrapper>
  );
};

export default CollapsedTable;

CollapsedTable.propTypes = {
  prevDepth: PropTypes.number,
  data: PropTypes.array,
  table: TableTypes
};
