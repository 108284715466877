import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import pms from 'common/assets/svg/common/setup.svg';

const EmptyState = () => {
  return (
    <div className="empty-state h-100p d-flex align-items-center justify-content-center text-violet fs-16 fw-medium ">
      <div className="inner-container d-flex align-items-center justify-content-center position-relative">
        <div className="empty-state-icon">
          <SvgRender src={pms} style={{ width: 240, height: 240 }} />
        </div>
        <div className="empty-text">No system selected</div>
      </div>
    </div>
  );
};

export default EmptyState;
