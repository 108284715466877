import PropTypes from 'prop-types';
import LinkedForms from './LinkedForms';
import FormDrawer from './FormDrawer';

import add from 'common/assets/svg/actions/add.svg';
import { VesselType } from 'common/entities/vessels/VesselTypes';
import { useCallback, useState } from 'react';
import { useDrawer } from 'common/components/drawer';
import LinkedTabModuleActions from '../_components/LinkedTabModuleActions';
import OvalButton from 'common/components/buttons/OvalButton';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import link from '@/common/assets/svg/jobs/link.svg';

const LinkedFormEntities = ({
  linkedForms,
  vessel,
  addLinkedForm,
  removeLinkedForm,
  cancelTabAction,
  tabLabel
}) => {
  const [formPreviewState, setFormPreviewState] = useState(null);
  const [type, setType] = useState('view');
  const [selectedForm, setSelectedForm] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(!linkedForms?.length);
  const formDrawer = useDrawer(false);

  const toggleFormDrawer = useCallback(
    (type, form = null) => {
      setFormPreviewState(form);
      setSelectedForm(form);
      setType(type);
      formDrawer.open();
    },
    [formDrawer, setSelectedForm]
  );

  return (
    <>
      <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <div className="d-flex align-items-center height-20">
          <div className="flex-1 form-label mb-0">LINKED FORMS</div>
          {tabLabel ? (
            <LinkedTabModuleActions
              showDeleteButton={!showCancelButton && isHovering}
              showCancelButton={showCancelButton}
              onCancel={() => cancelTabAction(tabLabel)}
              onDelete={() => removeLinkedForm()}
            />
          ) : null}
          <OvalButton
            onClick={() => toggleFormDrawer('add')}
            type="edit"
            icon={add}
            text="Fill in Form"
            size="sm"
            className="ms-1"
          />
        </div>

        <LinkedForms
          formSubmissions={linkedForms}
          removeLinkedForm={index => {
            removeLinkedForm(index);
            setShowCancelButton(false);
          }}
          previewLinkedForm={form_submission => toggleFormDrawer('view', form_submission)}
        />
      </div>

      <CircledButton
        className="mt-1"
        icon={link}
        label="Link to existing Form"
        onClick={() => toggleFormDrawer('link_form')}
        svgColor="white"
        svgStyle={{ width: 10, height: 10 }}
        size={20}
      />

      <FormDrawer
        drawer={formDrawer}
        initialVesselID={vessel?.id}
        formPreviewState={formPreviewState}
        selectedForm={selectedForm}
        type={type}
        isVesselLocked={vessel?.id}
        setSelectedForm={setSelectedForm}
        formSubmissionsSelectorRestParams={{
          defaultOptionsTriggerChange: formDrawer.isOpen,
          listParams: { has_findings: false, vessel_id: vessel?.id }
        }}
        addLinkedForm={form => {
          addLinkedForm(form);
          setShowCancelButton(false);
        }}
      />
    </>
  );
};

LinkedFormEntities.propTypes = {
  linkedForms: PropTypes.array.isRequired,
  addLinkedForm: PropTypes.func,
  removeLinkedForm: PropTypes.func,
  vessel: VesselType,
  tabLabel: PropTypes.string,
  cancelTabAction: PropTypes.func
};

export default LinkedFormEntities;
