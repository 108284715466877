import { Col, Row } from 'reactstrap';
import Attachments from './Attachments';
import Comments from './Comments';

const VesselFields = () => {
  return (
    <Row className="vessel-fields">
      <Col xs={8}>
        <Comments />
      </Col>

      <Col xs={4}>
        <Attachments />
      </Col>
    </Row>
  );
};

export default VesselFields;
