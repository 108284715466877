import { useQuery } from '@tanstack/react-query';
import { getSystemTask, getSystemTaskResult, getSystemTasks, GetSystemTasksParams } from './api';
import { SYSTEM_TASK_RESULTS, SYSTEM_TASKS } from './query-keys';
import _orderBy from 'lodash/orderBy';

export const useGetSystemTasks = ({ type }: GetSystemTasksParams) => {
  return useQuery({
    queryKey: [SYSTEM_TASKS],
    enabled: !!type,
    retry: false,
    staleTime: Infinity, // No automatic refetching - data are updated real-time with Sockets
    queryFn: () => getSystemTasks(),
    select: data => data.filter(d => d.type === type && d.result_type !== 'file')
  });
};

export const useGetFileSystemTasks = () => {
  return useQuery({
    queryKey: [SYSTEM_TASKS],
    enabled: false,
    retry: false,
    staleTime: Infinity, // No automatic refetching - data are updated real-time with Sockets
    queryFn: () => getSystemTasks(),
    select: data =>
      _orderBy(
        data.filter(d => d.result_type === 'file'),
        'created_at',
        'desc'
      )
  });
};

export const useGetSystemTask = ({
  id,
  enabled,
  refetchInterval,
  refetchIntervalInBackground
}: {
  enabled: boolean;
  id?: number | null;
  refetchInterval: number;
  refetchIntervalInBackground: boolean;
}) => {
  return useQuery({
    queryKey: [SYSTEM_TASKS, id],
    enabled,
    refetchInterval,
    refetchIntervalInBackground,
    retry: false,
    staleTime: Infinity, // Data will always be fresh, no automatic refetching
    queryFn: () => (id ? getSystemTask({ id }) : null)
  });
};

export const useGetSystemTaskResult = ({
  id,
  enabled
}: {
  enabled: boolean;
  id?: number | null;
}) => {
  return useQuery({
    queryKey: [SYSTEM_TASK_RESULTS, id],
    enabled,
    retry: false,
    staleTime: Infinity, // Data will always be fresh, no automatic refetching
    queryFn: () => (id ? getSystemTaskResult({ id }) : null)
  });
};
