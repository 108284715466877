import SingleSystem from '@/common/components/pms/setup/views/system';
import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import ViewButton from '@/common/components/buttons/ViewButton';
import HideButton from '@/common/components/buttons/HideButton';
import { FC } from 'react';
import { styles } from './tableConfing';

type SystemSpeficationsProps = {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
};

const SystemSpefications: FC<SystemSpeficationsProps> = ({ isCollapsed, setIsCollapsed }) => {
  return (
    <SideView isCollapsed={isCollapsed} className="position-relative ms-auto">
      <CollapsedContainer
        isCollapsed={isCollapsed}
        className="d-flex flex-column position-absolute top-0 h-100p"
      >
        <ViewButton className="mb-1" onClick={() => setIsCollapsed(false)} />
        <div className="d-flex flex-column align-items-center cpt-12 w-32 h-100p border-radius-7 flex-1 bg-white">
          <RotatedText className="text-dark fs-12">SPECIFICATIONS</RotatedText>
        </div>
      </CollapsedContainer>

      <UncollapsedContainer
        isCollapsed={isCollapsed}
        className="d-inline-flex align-items-center position-absolute top-0"
      >
        <HideButton onClick={() => setIsCollapsed(true)} />
      </UncollapsedContainer>

      <AnimatedSingleSystemContainer isCollapsed={isCollapsed} className="h-100p">
        <SingleSystem />
      </AnimatedSingleSystemContainer>
    </SideView>
  );
};

const SideView = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  top: 0;
  right: 0;
  min-height: 50vh;
  margin-top: -${variables.size8};
  width: ${isCollapsed ? `${styles.collapsedWidth}px` : `${styles.expandedWidth}px`};

  .single-system {
    padding-top: 0 !important;

    &.single-system--empty {
      padding-top: 2rem !important;
      padding-bottom: 4rem;
    }

    .pms__container.right-side {
      max-height: initial;
      height: initial;
    }
  }
  `
);

const CollapsedContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  width: 32px;
  max-height: calc(100% - 3rem);
  transition: all 0.3s ease-in-out;
  opacity: ${isCollapsed ? '1' : '0'};
  z-index: ${!isCollapsed ? '-1' : '1'}
`
);

const UncollapsedContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  transition: all 0.3s ease-in-out;
  opacity: ${isCollapsed ? '0' : '1'};
  z-index: ${isCollapsed ? '-1' : '2'};
  right: 0;
  `
);

const AnimatedSingleSystemContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  width: ${styles.expandedWidth}px;
  opacity: ${isCollapsed ? '0' : '1'};
  z-index: ${isCollapsed ? '-1' : '1'}
  `
);

const RotatedText = styled.strong`
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
`;

export default SystemSpefications;
