const paths = {
  landing: '/',
  login: '/login',
  logout: '/logout',
  not_found: '/404',
  unauthorized: '/unauthorized',

  dashboard: '/dashboard',

  persons: '/persons',
  companies: '/companies',

  crew: '/crew',
  crew_planning: '/crew-planning',
  crew_evaluation_templates: '/crew-evaluation-templates',
  crew_evaluation_reasons: '/crew-evaluation-reasons',
  crew_document_types: '/crew-document-types',
  crew_matrices: '/crew-matrices',
  crew_evaluation_obligation_templates: '/crew-evaluation-obligation-templates',
  crew_evaluation_obligations: '/crew-evaluations',
  crew_evaluation_criteria: '/crew-evaluation-criteria',
  crew_document_manager: '/crew-document-manager',
  crew_seamen_contracts: '/crew-seamen-contracts',
  crew_wages: '/crew-wages',
  crew_skill_types: '/crew-skills',
  crew_ticketing_types: '/ticketing-types',
  crew_trainings: '/crew-trainings',
  crew_permissions: '/permissions/crew',
  crew_ranks: '/crew-ranks',
  crew_rank_policies: '/crew-ranks/policies',

  crew_list: '/crew-list',
  crew_watchkeeping: '/crew-watchkeeping',
  crew_holidays: '/holidays',
  crew_termination_reasons: '/crew-termination-reasons',

  drills: '/drills',

  hsqe_trainings: '/hsqe-trainings',

  reports: '/reports',
  reports_forms: '/reports/forms',
  reports_crew: '/reports/crew',
  reports_voyage: '/reports/voyage',
  reports_purchasing: '/reports/purchasing',
  reports_purchasing_items_ordered_on_board: '/reports/purchasing/items-ordered',
  reports_purchasing_requested_but_not_delivered: '/reports/purchasing/requested-but-not-delivered',
  reports_purchasing_budgets: '/reports/purchasing/budget',
  reports_past_promotions: '/reports/crew/promotions/past',
  reports_promotions: '/reports/crew/promotions',
  reports_crew_medical_statistics: '/reports/crew/crew-medical-statistics',
  reports_lubricants_supply_monitoring: '/reports/voyage/lubricants-supply-monitoring',
  reports_change_logs: '/reports/crew/crew-change-logs',
  reports_evaluations: '/reports/crew/evaluations',
  reports_training_needs: '/reports/crew/training-needs',
  reports_trainings: '/reports/crew/trainings',
  reports_retention_rate: '/reports/crew/retention-rate',
  reports_crew_wages: '/reports/crew/wages',
  reports_onboard_nationalities: '/reports/crew/onboard-nationalities',
  reports_on_signer_vessel_agent_statistics: '/reports/crew/on-signer-vessel-agent-statistics',
  reports_on_signer_evaluation_statistics: '/reports/crew/vessel-agent-evaluation-statistics',
  reports_vessel_agent_evaluation_statistics: '/reports/crew/vessel-agent-evaluation-statistics',
  reports_crew_promotions_contracts: '/reports/crew/crew-promotions-contracts',
  reports_overlapping_days: '/reports/crew/overlapping-days',
  reports_vaccinations: '/reports/crew/vaccination-report',
  reports_not_for_reemployment: '/reports/crew/not-for-reemployment',
  reports_newly_hired: '/reports/crew/newly-hired',
  reports_lubricants_remaining_days_estimator:
    '/reports/voyage/lubricants-remaining-days-estimator',
  reports_fresh_water_generator_performance: '/reports/voyage/fresh-water-generator-performance',
  reports_fresh_water_generator_performance_fleet:
    '/reports/voyage/fresh-water-generator-performance-fleet',
  reports_stern_tube_report: '/reports/voyage/stern-tube-report',
  reports_cii_reference_lines: '/reports/voyage/cii-reference-lines',
  reports_cii_fleet_running_cps: '/reports/voyage/cii-fleet-running-cps',
  reports_cii_fleet_for_one_year: '/reports/voyage/cii-fleet-year',
  reports_running_cps_redelivery_dates: '/reports/voyage/running-cps-redelivery-dates',
  reports_vessel_tce_results: '/reports/voyage/vessel-tce-results',
  reports_cii_fleet_running_legs: '/reports/voyage/cii-fleet-running-legs',
  reports_port_statement_bunkering_and_bunker_survey:
    '/reports/voyage/port-statement-bunkering-and-bunker-survey',
  reports_captain_reports_bunkers: '/reports/voyage/captain-reports-bunkers',
  reports_mrv: '/reports/voyage/mrv',
  reports_imo_dcs: '/reports/voyage/imo-dcs',
  reports_fixtures: '/reports/voyage/fixtures',
  reports_pms: '/reports/pms',
  reports_tanks_condition_summary: '/reports/pms/tanks-condition-summary',
  reports_critical_equipment_performance_test_and_data:
    '/reports/pms/critical-equipment-performance-test-and-data',
  reports_overdue_jobs: '/reports/pms/overdue-jobs',
  reports_job_statistics: '/reports/pms/job-statistics',
  reports_megger_and_vibration_report_for_motors:
    '/reports/pms/megger-and-vibration-report-for-motors',
  reports_main_engine_piston_and_cylinder_liner_wear_down:
    '/reports/pms/main-engine-piston-and-cylinder-liner-wear-down',
  reports_event: '/reports/events',
  reports_performance: '/reports/performance',
  reports_environmental: '/reports/environmental',
  reports_lubricants_fleet_consumption: '/reports/voyage/lubricants-fleet-consumption',
  reports_root_cause_analysis_statistics: 'reports/events/root-cause-analysis-statistics',
  reports_audits_and_inspections_statistics: '/reports/events/audits-and-inspections-statistics',
  reports_vetting_statistics: '/reports/events/vetting-statistics',
  reports_risk_assessment_statistics: '/reports/events/risk-assessment-statistics',
  reports_stoppages: '/reports/voyage/stoppages',
  reports_accounting: '/reports/accounting',
  reports_accounting_budgeting: '/reports/accounting/budgeting-report',
  reports_crew_debriefing: '/reports/crew/crew-debriefing',
  reports_crew_fleet_crew_list: '/reports/crew/fleet-crew-lists',
  reports_off_hire_totals: '/reports/events/off-hire',
  reports_ports_and_cargoes_statistics: '/reports/voyage/ports-and-cargoes-statistics',

  vessels: '/vessels',
  vessels_document_manager_per_document: '/vessels-document-manager/per-document',
  vessels_document_manager: '/vessels-document-manager/list',
  vessel_groups: '/vessel-groups',
  vessel_document_categories: '/vessels-documents-categories',
  vessel_document_types: '/vessels-documents-types',
  vessel_document_reminders: '/reminders/vessels-documents',

  voyages: '/voyages',
  port_da_items: '/port-da-items',
  port_da_cases: '/port-da-cases',
  freight_hire_collection_items: '/freight-hire-collection-items',
  freight_hire: '/freight-hire',

  position_list: '/position-list',
  fleet_crew: '/fleet-crew',

  estimations: '/estimations',
  market_indexes: '/market-indexes',
  bunker_prices: '/bunker-prices',

  captain_reports: '/captain-reports',
  captain_report_templates: '/captain-report-templates',

  comparison: '/comparison',

  profile: '/profile',

  jobs: '/jobs',
  regular_jobs: '/jobs/regular',
  flag_extensions_dispensations_jobs: '/jobs/flag-extension-dispensation',
  orca_jobs: '/jobs/orca-jobs',

  jobs_templates: '/jobs-and-findings/templates',
  jobs_triggers: '/jobs-and-findings/triggers',
  jobs_status: '/jobs-and-findings/status',
  jobs_restricted: '/jobs-and-findings/fields',
  jobs_findings: '/jobs-and-findings/findings',

  projects: '/projects',

  pms_library: '/pms-library',
  pms_configuration: '/pms-configuration',

  pms_jobs_label: '/pms-jobs',
  pms_jobs: '/pms-jobs/list',
  pms_jobs_per_system: '/pms-jobs/per-system',
  pms_running_hours: '/pms-running-hours',
  pms_inventory: '/pms-inventory',

  departments: '/departments',
  department_polices: '/department/policies',
  department_roles: '/department-roles',
  department_role_polices: '/department-roles/policies',

  persons_permissions: '/permissions/persons',
  persons_permission_policies: '/permissions/persons/policies',

  case_tags: '/case-tags',
  company_types: '/company-types',
  ports: '/ports',
  manuals: '/manuals',
  filesystem: '/filesystem',

  beacon: '/beacon',
  events: '/events',
  events_calendar: '/events-calendar',
  events_calendar_overview: '/events-calendar/overview',

  forms: '/forms',
  form_settings: '/form-settings',
  form_fields: '/form-settings/form-fields',
  form_planning: '/form-planning',
  form_obligations: '/form-obligations',
  form_table_reports: '/form-table-reports',

  event_settings: '/event-settings',
  event_templates: '/event-settings/templates',
  event_underwater_services_types: '/event-settings/underwater-services-types',
  event_off_hire: '/event-settings/event-off-hire',
  event_lub_oil_analysis: '/event-settings/lub-oil-analysis',
  event_medical_cases: '/event-settings/medical-cases',

  risk_assessment: '/risk-assessment',

  indexes: '/indexes',

  onboard_syncs: '/onboard-syncs',

  news_announcements: '/news-announcements',

  ticketing: '/ticketing',
  purchasing: '/purchasing',
  purchasing_library_setup: '/purchasing/library-setup',
  purchasing_categories: '/purchasing/categories',
  purchasing_packing: '/purchasing/packing',
  purchasing_unit_types: '/purchasing/types',
  purchasing_requisitions: '/purchasing/requisitions',
  purchasing_requisitions_ev: '/purchasing/requisitions/ev',
  purchasing_requisitions_approval: '/purchasing/requisitions/approval-view',
  purchasing_requisition_templates: '/purchasing/requisitions-templates',
  purchasing_forwarding: '/purchasing-forwarding',
  purchasing_forwarding_items: '/purchasing-forwarding/items',
  purchasing_forwarding_orders: '/purchasing-forwarding/orders',
  purchasing_forwarding_cases: '/purchasing-forwarding/cases',
  purchasing_inventory: '/purchasing-inventory',
  purchasing_pricelist: '/purchasing-pricelist',
  purchasing_budgeted_quantity: '/purchasing-budgeted-quantity',

  mga_coa_settings: '/mga-settings/coa',
  mga_action_types_settings: '/mga-settings/action-types',
  mga_items_settings: '/mga-settings/items',
  mga: '/mga',

  accounting_invoices: '/invoices',
  accounting_contracts: '/contracts',
  accounting_contracts_payments: '/account-contracts/payments',
  accounting_coa_groups_settings: '/accounting-settings/coa-groups',
  accounting_ledger_cards_settings: '/accounting-settings/ledger-cards',
  accounting_companies_settings: '/accounting-settings/companies',
  accounting_contract_types_settings: '/accounting-settings/contract-types',
  accounting_medical_expenses_settings: '/accounting-settings/medical-expenses',
  accounting_budget_settings: '/accounting-settings/budget',
  accounting_budget_scenarios_settings: '/accounting-settings/budget-scenarios',
  budgeting_report_setup: '/accounting-settings/budgeting-reports',

  root_cause_analysis: '/root-cause-analysis',
  it_dashboard: '/it-dashboard',
  it_integration_logs: '/it-integration-logs',

  performance_setups: '/performance/setups',

  inventory: '/inventory',

  vetting_setup: '/vetting-setup',

  findings: '/findings',

  policies: '/policies',
  notification_preferences_administration: '/notification-preferences-administration',
  review_process: '/review-process',
  setup_onboard_filters: '/setup/onboard/filters',
  tags: '/tags'
};

export default paths;
