import TYPES from './types';
import { put, post, deleteRequest, get } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

export const getPurchasingCategories = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_CATEGORIES.START, payload: { params } });

  return get(`/purchasing-categories`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_CATEGORIES.SUCCESS,
        payload: { data: response.data, params }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_CATEGORIES.ERROR, payload: error });

      throw error;
    });
};

export const createPurchasingCategory = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PURCHASING_CATEGORY.START, payload: { params } });

  return post(`/purchasing-categories`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_PURCHASING_CATEGORY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
      dispatch(getPurchasingCategories({ search: params.search }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PURCHASING_CATEGORY.ERROR, payload: error });

      throw error;
    });
};

export const updatePurchasingCategory = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_PURCHASING_CATEGORY.START, payload: { params } });

  return put(`/purchasing-categories/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_PURCHASING_CATEGORY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Edited successfully' }));
      dispatch(getPurchasingCategories({ search: params.search }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_PURCHASING_CATEGORY.ERROR, payload: error });

      throw error;
    });
};

export const deletePurchasingCategory = params => dispatch => {
  dispatch({ type: TYPES.DELETE_PURCHASING_CATEGORY.START, payload: { params } });

  return deleteRequest(`/purchasing-categories/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_PURCHASING_CATEGORY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));
      dispatch(getPurchasingCategories());

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_CATEGORY.ERROR, payload: error });

      throw error;
    });
};

export const setActiveCategoriesGroup = group => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_CATEGORIES_GROUP, payload: group });
};

export const toggleCategoryAssignmentDrawer = status => dispatch => {
  dispatch({ type: TYPES.TOGGLE_CATEGORY_ASSIGNMENT_DRAWER, payload: status });
};
