import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import PMS_LIBRARY_TYPES from '@/common/components/pms/setup/store/types.ts';
import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { get, put, post, deleteRequest, download } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { strToNumber } from 'common/utils/numbers';
import { refetchDeafultListOptions } from 'store/lists/actions';
import { handleStatusSubmissionValidations } from './helpers';
import _findKey from 'lodash/findKey';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import {
  setMessages,
  postMessage,
  setUnreadMessages,
  setMarkAsRead,
  setFetching
} from 'common/components/chatbox/store/actions';
import { selectActiveCategoryID, selectPMSItems } from './selectors';
import { AppDispatch, RootState } from '@/store';
import { ItemConfigurationKey } from '@/common/components/purchasing/requisition/categories/items/config';
import { ThunkActionType } from '@/store/hooks';
import {
  ItemType,
  selectIsTemplateRequisition,
  selectItemsTableComparisonViewEnabled,
  selectRequisitionVessel
} from './selectors-ts';
import {
  requestExternalSupplierToken,
  bulkPurchasingRequisitions,
  BulkPurchasingRequisitionsParams,
  bulkUpdatePurchasingRequisitionCategoryItems,
  BulkUpdatePurchasingRequisitionCategoryItemsArgs,
  RequestExternalSupplierTokenParams,
  applyToAllItemsInTheCategory,
  getPurchasingRequisitions
} from '@/api/purchasing/api.ts';
import {
  recalculatePurchasingRequisitionItemsSupplierTotals,
  recalculatePurchasingRequisitionSupplierTotals,
  recomparePurchasingRequisitionItems
} from '@/api/purchasing/queries';
import {
  Supplier,
  ForwardingCaseBoxWithItems,
  BaseForwardingCaseBox,
  RequisitionItemParam,
  PurchasingExternalSupplierTokens,
  ApplyToAllItemsInTheCategoryParams,
  Requisition,
  PurchasingRequisitionOnBoardStatus,
  RequisitionItem,
  StoreItem
} from '@/common/types/purchasing.ts';
import { ItineraryPort } from '@/common/types/itineraries';
import { Port } from '@/common/types/ports';
import { Vessel } from '@/common/types/vessel';
import { TableRequestPaging } from '@/common/types/front-entities/table';
import { getVesselSystems } from '@/api/vessel-systems/api';
import { selectSystemsSearch } from '@/common/components/pms/setup/store/selectors';
import { SparePartBase } from '@/common/types/pms';
import { RequestParamsType } from '@webthatmatters/orca-table';
import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const selectIsTemplateLocation = (state: RootState): boolean => {
  return state.purchasing.requisitions.isTemplate;
};

export const getRequisitionRequestPrefix =
  (): ThunkActionType =>
  (_, getState): string => {
    return selectIsTemplateLocation(getState())
      ? '/purchasing-requisition-templates'
      : '/purchasing-requisitions';
  };

/* Basic Flow - items & Categories */
export const getPurchasingRequisitionsAction =
  (params: RequestParamsType & { table: string }, isApprovalView?: boolean) =>
  (dispatch: AppDispatch) => {
    const { table, ...rest } = params;
    dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS.START, payload: { params } });
    dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

    const requestParams = {
      ...rest,
      filters: [...(rest.filters || [])]
    };

    if (isApprovalView) {
      if (
        !requestParams.filters.find(f => f.name === 'pending_approval') &&
        !requestParams.filters.find(f => f.name === 'current_user_approval')
      ) {
        requestParams.filters.push({ name: 'pending_approval', operation: '=', value: true });
      }
    }

    return getPurchasingRequisitions(requestParams, {
      with_suppliers: true,
      with_approval_checks: isApprovalView ? true : undefined
    })
      .then(response => {
        dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS.SUCCESS, payload: response });
        dispatch({
          type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
          payload: { data: response, table }
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS.ERROR, payload: error });
        dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
        throw error;
      });
  };

export const checkIfEligibleForPendingReview = params => dispatch => {
  /* Returns the user ability to edit/update the review process (eligible For Pending Review Process) 
    depending on his 'department_roles', 'departments' and steps departments , department_roles */

  const { id, ...rest } = params;
  dispatch({ type: TYPES.IS_ELIGIBLE_FOR_PENDING_REVIEW.START, payload: { params } });

  return get(`/purchasing-requisitions/${id}/eligible-for-pending-review`, rest)
    .then(response => {
      dispatch({ type: TYPES.IS_ELIGIBLE_FOR_PENDING_REVIEW.SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: TYPES.IS_ELIGIBLE_FOR_PENDING_REVIEW.ERROR, payload: error });
    });
};

export const getPurchasingRequisitionsTotals = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_TOTALS.START });

  return get('/purchasing-requisitions/totals', params)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_TOTALS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_TOTALS.ERROR, payload: error });
      throw error;
    });
};

export const createPurchasingRequisition = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PURCHASING_REQUISITION.START, payload: { params } });

  return post('/purchasing-requisitions', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_PURCHASING_REQUISITION.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PURCHASING_REQUISITION.ERROR, payload: error });
      throw error;
    });
};

export const bulkCreatePurchasingRequisitions = createAsyncThunk(
  'BULK_PURCHASING_REQUISITIONS',
  async (params: BulkPurchasingRequisitionsParams, { rejectWithValue, dispatch }) => {
    try {
      await bulkPurchasingRequisitions(params);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updatePurchasingRequisition = params => dispatch => {
  const { isAutoSaving, ...rest } = params;

  dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION.START, payload: { params } });

  return put(`${dispatch(getRequisitionRequestPrefix())}/${params.id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION.SUCCESS, payload: response.data });

      if (!isAutoSaving) {
        dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      }

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION.ERROR, payload: error });
      throw error;
    });
};

export const mergePurchasingRequisitions = params => dispatch => {
  dispatch({ type: TYPES.MERGE_PURCHASING_REQUISITIONS.START, payload: { params } });

  return post('/purchasing-requisitions/merge', params)
    .then(response => {
      dispatch({
        type: TYPES.MERGE_PURCHASING_REQUISITIONS.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Merged successfully' }));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.MERGE_PURCHASING_REQUISITIONS.ERROR, payload: error });
      throw error;
    });
};

export const generatePurchasingRequisitionCode = params => dispatch => {
  dispatch({ type: TYPES.GENERATE_PURCHASING_REQUISITION_CODE.START, payload: { params } });

  return post('/purchasing-requisitions/generate-code', params)
    .then(response => {
      dispatch({
        type: TYPES.GENERATE_PURCHASING_REQUISITION_CODE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GENERATE_PURCHASING_REQUISITION_CODE.ERROR, payload: error });
      throw error;
    });
};

export const submitPurchasingRequisition = params => dispatch => {
  dispatch({ type: TYPES.SUBMIT_PURCHASING_REQUISITION.START, payload: { params } });

  return put(`/purchasing-requisitions/${params.id}/submit`, params)
    .then(response => {
      dispatch({ type: TYPES.SUBMIT_PURCHASING_REQUISITION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.SUBMIT_PURCHASING_REQUISITION.ERROR, payload: error });
      throw error;
    });
};

export const approvePurchasingRequisition = params => dispatch => {
  dispatch({ type: TYPES.APPROVE_PURCHASING_REQUISITION.START, payload: { params } });

  return put(`/purchasing-requisitions/${params.id}/approve`, params)
    .then(response => {
      dispatch({
        type: TYPES.APPROVE_PURCHASING_REQUISITION.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.APPROVE_PURCHASING_REQUISITION.ERROR, payload: error });
      throw error;
    });
};

export const getPurchasingRequisition = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION.START, payload: { params } });

  const { id, load_from_requisition, categoryID, load_from_requisition_template, ...rest } = params;

  return get(
    `${
      !load_from_requisition_template
        ? dispatch(getRequisitionRequestPrefix())
        : '/purchasing-requisition-templates'
    }/${id}`,
    rest
  )
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITION.SUCCESS,
        payload: {
          ...response.data,
          load_from_requisition,
          params
        }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITION.ERROR, payload: error });
      throw error;
    });
};

export const deletePurchasingRequisition = params => dispatch => {
  dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION.START });

  return deleteRequest(`/purchasing-requisitions/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION.SUCCESS });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION.ERROR, payload: error });
      throw error;
    });
};

export const getPurchasingRequisitionItems = params => (dispatch, getState) => {
  const { load_from_requisition_template, id, requisition_supplier_id } = params;
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_ITEMS.START, payload: { params } });

  const requestParams = {
    requisition_supplier_id
  };

  return get(
    `${
      !load_from_requisition_template
        ? dispatch(getRequisitionRequestPrefix())
        : '/purchasing-requisition-templates'
    }/${id}/items`,
    requestParams
  )
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITION_ITEMS.SUCCESS,
        payload: { data: response.data, params: { ...params, isOnBoard: getState().isOnBoard } }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_ITEMS.ERROR, payload: error });
      throw error;
    });
};

export const getPurchasingRequisitionsLastDeliveredItems = params => (dispatch, getState) => {
  if (selectIsTemplateLocation(getState())) return;

  dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_LAST_DELIVERED_ITEMS.START, payload: params });
  const { items } = getState().purchasing.requisitions;

  const requestParams = {
    requisition_id: params?.id || null,
    items: _reduce(
      items,
      (result, value) => {
        if (!value.is_out_of_the_list)
          result.push({ item_id: value.id, item_type: value?.item_type || value?.entity_type });

        return result;
      },
      []
    )
  };

  if (!requestParams.items.length) return;

  return post('/purchasing-requisitions/items/last-delivered', requestParams)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_LAST_DELIVERED_ITEMS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_LAST_DELIVERED_ITEMS.ERROR,
        payload: error
      });
      throw error;
    });
};

export const updatePurchasingRequisitionItem =
  (params: {
    id: string | number;
    supplierRequisitionID: string | number;
    included_in_qtn?: boolean;
  }): ThunkActionType =>
  (dispatch, getState) => {
    dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION_ITEM.START, payload: { params } });
    const comparisonViewEnabled = selectItemsTableComparisonViewEnabled(getState());

    const { id, supplierRequisitionID, ...rest } = params;

    return put(
      `${dispatch(getRequisitionRequestPrefix())}/items/${id}${
        supplierRequisitionID ? `/suppliers/${supplierRequisitionID}` : ''
      }`,
      rest
    )
      .then(response => {
        dispatch({
          type: TYPES.UPDATE_PURCHASING_REQUISITION_ITEM.SUCCESS,
          payload: response.data
        });

        if (supplierRequisitionID) {
          // Update totals and auto-calculated values
          const items = getState().purchasing.requisitions.items;
          const itemID = _findKey(
            items,
            i => i.requisition_item_id === response.data.requisition_item_id
          );
          const {
            unit_price_base_currency_equivalent,
            total_original_price,
            total_original_price_base_currency_equivalent,
            total_discounted_price,
            total_discounted_price_base_currency_equivalent,
            total_proposed_price,
            total_proposed_price_base_currency_equivalent,
            total_approved_price,
            total_approved_price_base_currency_equivalent,
            total_delivered_price,
            total_delivered_price_base_currency_equivalent,
            requisition_item
          } = response.data;

          // Set supplier_details fields
          dispatch(
            setItemField(
              { itemID, supplierRequisitionID },
              {
                unit_price_base_currency_equivalent,
                total_original_price,
                total_original_price_base_currency_equivalent,
                total_discounted_price,
                total_discounted_price_base_currency_equivalent,
                total_proposed_price,
                total_proposed_price_base_currency_equivalent,
                total_approved_price,
                total_approved_price_base_currency_equivalent,
                total_delivered_price,
                total_delivered_price_base_currency_equivalent
              }
            )
          );

          // Set basic item fields
          dispatch(
            setItemField(
              { itemID },
              {
                sum_proposed_quantity: _get(requisition_item, 'sum_proposed_quantity', 0),
                sum_approved_quantity: _get(requisition_item, 'sum_approved_quantity', 0),
                sum_approved_price: _get(requisition_item, 'sum_approved_price', 0),
                sum_proposed_price: _get(requisition_item, 'sum_proposed_price', 0),
                sum_delivered_quantity: _get(requisition_item, 'sum_delivered_quantity', 0),
                sum_delivered_price: _get(requisition_item, 'sum_delivered_price', 0)
              }
            )
          );
        }

        if (comparisonViewEnabled) {
          const requisition = getState().purchasing.requisitions.active as unknown as Requisition;
          const requisitionItem = response.data?.requisition_item as unknown as RequisitionItem;

          recomparePurchasingRequisitionItems(requisition.id, requisitionItem.category_id);
        }

        recalculatePurchasingRequisitionSupplierTotals();
        recalculatePurchasingRequisitionItemsSupplierTotals();

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION_ITEM.ERROR, payload: error });
        dispatch(
          getPurchasingRequisitionItems({ id: getState().purchasing.requisitions.active?.id })
        ); // Refetch items on error

        throw error;
      });
  };

export const bulkUpdatePurchasingRequisitionCategoryItemsAction = createAsyncThunk<
  ItemType[],
  BulkUpdatePurchasingRequisitionCategoryItemsArgs
>(TYPES.BULK_UPDATE_PURCHASING_REQUISITION_ITEMS, async (params, { rejectWithValue, getState }) => {
  try {
    const res = await bulkUpdatePurchasingRequisitionCategoryItems(params);

    const state = getState() as RootState;
    const comparisonViewEnabled = selectItemsTableComparisonViewEnabled(state);

    if (comparisonViewEnabled) {
      const requisition = state.purchasing.requisitions.active as unknown as Requisition;

      recomparePurchasingRequisitionItems(requisition.id, params.categoryID);
    }

    recalculatePurchasingRequisitionItemsSupplierTotals();
    recalculatePurchasingRequisitionSupplierTotals();

    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deletePurchasingRequisitionItem = createAsyncThunk(
  TYPES.DELETE_PURCHASING_REQUISITION_ITEM,
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await deleteRequest(
        `${dispatch(getRequisitionRequestPrefix())}/items/${params.id}`
      );

      dispatch(
        getPurchasingRequisitionItems({ id: getState().purchasing.requisitions.active?.id })
      );

      return res.data;
    } catch (error) {
      dispatch(
        getPurchasingRequisitionItems({ id: getState().purchasing.requisitions.active?.id })
      );

      return rejectWithValue(error);
    }
  }
);

export const getPurchasingRequisitionParams = (formState, fields) => (dispatch, getState) => {
  const {
    vessel,
    tags,
    company,
    include_in_budget,
    is_for_vessel,
    remarks,
    attachments,
    vessel_remarks,
    vessel_attachments,
    past_requisition,
    ...restParams
  } = formState;
  const { categories, categoryItems, items, unlistedItems } = getState().purchasing.requisitions;
  const activeID = getState().purchasing.requisitions.active?.id;
  const isRequisitionTemplate = selectIsTemplateRequisition(getState());

  const { isOnBoard, account } = getState() as RootState;

  const params = {
    ...restParams,
    items: [],
    is_for_vessel,
    tags: isOnBoard ? undefined : tags || [],
    company_id: isOnBoard ? undefined : !is_for_vessel ? company?.id || null : null,
    vessel_remarks: isOnBoard ? vessel_remarks : undefined,
    vessel_attachment_ids: isOnBoard ? (vessel_attachments || []).map(v => v?.id) : undefined,
    past_requisition: past_requisition
  };

  if (isAuthorized(account, [permissions.office.purchasing.requisitions.include_in_budget])) {
    params.include_in_budget = include_in_budget;
  }

  if (!isOnBoard) {
    if (is_for_vessel && !activeID) {
      if (vessel?.length === 1) {
        params.vessel_id = vessel[0]?.id || null;
      } else if ((past_requisition?.id || isRequisitionTemplate) && vessel?.id) {
        params.vessel_id = vessel.id;
      } else {
        params.vessel_ids = vessel?.map(v => v?.id) || null;
      }
    } else if (is_for_vessel && activeID) {
      params.vessel_id = vessel?.id || null;
    }
  }

  params.items = categories.reduce((acc, key, index) => {
    const allCategoryItems =
      isOnBoard && unlistedItems.length && index === 0
        ? [...(categoryItems[key] || []), ...unlistedItems]
        : categoryItems[key];

    const parsedItems = allCategoryItems.map(id => {
      const {
        unlisted_description,
        is_out_of_the_list,
        file,
        requisition_item_id,
        item_type,
        entity_type,
        comments,
        ...rest
      } = {
        ...items[id]
      };

      const item = {
        id: requisition_item_id,
        category_id: key,
        file_id: file?.id || null,
        comments: isOnBoard || !requisition_item_id ? comments : undefined
      };

      if (is_out_of_the_list) {
        item.unlisted_description = items[id].unlisted_description;
      } else {
        item.item_id = items[id].id;
        item.item_type = item_type || entity_type;
      }

      (fields?.items || [])?.forEach(field => {
        if (rest[field.key] !== undefined) {
          if (field.type === 'number') {
            item[field.key] = strToNumber(rest[field.key]);
          } else {
            item[field.key] = rest[field.key];
          }
        }
      });

      return item;
    });

    return [...acc, ...parsedItems];
  }, []);

  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_PARAMS });

  return params;
};

export const setCategory = (data, index) => dispatch => {
  dispatch({ type: TYPES.SET_CATEGORY, payload: { data, index } });
};

export const addCategory = data => dispatch => {
  dispatch({ type: TYPES.ADD_CATEGORY, payload: data });
};

export const removeCategory = index => dispatch => {
  dispatch({ type: TYPES.REMOVE_CATEGORY, payload: index });
};

export const addCategoryItem =
  ({
    item,
    categoryID,
    requested_packaging_id,
    requested_quantity = undefined,
    revised_quantity = undefined,
    showSuccessMessage = false,
    hideSelectedItems = true,
    type
  }: {
    item: SparePartBase | StoreItem;
    categoryID: number;
    requested_packaging_id?: number | null;
    requested_quantity?: number;
    revised_quantity?: number;
    showSuccessMessage?: boolean;
    type: 'spare_part' | 'store';
    hideSelectedItems: boolean;
  }) =>
  async (dispatch, getState) => {
    const requisition = getState().purchasing.requisitions.active as Requisition | null;
    const onBoardStatus = getState().purchasing.requisitions
      .onBoardStatus as PurchasingRequisitionOnBoardStatus;

    const requisitionId = requisition?.id;
    const requisitionIsSubmitted = onBoardStatus === 'submitted';

    if (requisitionIsSubmitted && requisitionId) {
      dispatch({
        type: TYPES.ADD_ITEM_TO_PURCHASING_REQUISITION.START,
        payload: { item, categoryID, hideSelectedItems }
      });

      const res = await post(`${dispatch(getRequisitionRequestPrefix())}/${requisitionId}/items`, {
        item_id: item.id,
        item_type: type,
        category_id: categoryID,
        requested_packaging_id,
        requested_quantity,
        revised_quantity
      })
        .then(response => {
          dispatch({
            type: TYPES.ADD_ITEM_TO_PURCHASING_REQUISITION.SUCCESS,
            payload: { item: response.data, categoryID }
          });

          dispatch(getPurchasingRequisitionItems({ id: requisitionId }));

          dispatch(
            successHandler({
              title: 'Success!',
              message: 'The item was added successfully to the list.'
            })
          );
        })
        .catch(error => {
          dispatch({
            type: TYPES.ADD_ITEM_TO_PURCHASING_REQUISITION.ERROR,
            payload: { error, item }
          });

          throw error;
        });

      dispatch(getPurchasingRequisition({ id: requisitionId, categoryID }));

      return res;
    } else {
      dispatch({
        type: TYPES.ADD_CATEGORY_ITEM,
        payload: { item: { ...item, entity_type: type }, categoryID }
      });

      if (showSuccessMessage) {
        dispatch(
          successHandler({
            title: 'Success!',
            message: 'The item was added successfully to the list.'
          })
        );
      }
    }
  };

export const removeCategoryItem = (itemID, categoryID) => (dispatch, getState) => {
  const onBoardStatus = getState().purchasing.requisitions
    .onBoardStatus as PurchasingRequisitionOnBoardStatus;

  const requisitionIsSubmitted = onBoardStatus === 'submitted';
  const itemRequisitionId = getState().purchasing.requisitions.items?.[itemID]?.requisition_item_id;

  if (requisitionIsSubmitted && itemRequisitionId) {
    dispatch({
      type: TYPES.DELETE_ITEM_FROM_PURCHASING_REQUISITION.START,
      payload: { itemID, categoryID }
    });

    return deleteRequest(`${dispatch(getRequisitionRequestPrefix())}/items/${itemRequisitionId}`)
      .then(response => {
        dispatch({
          type: TYPES.DELETE_ITEM_FROM_PURCHASING_REQUISITION.SUCCESS,
          payload: { item: response.data, categoryID }
        });

        dispatch({ type: TYPES.REMOVE_CATEGORY_ITEM, payload: { itemID, categoryID } });
      })
      .catch(error => {
        dispatch({
          type: TYPES.DELETE_ITEM_FROM_PURCHASING_REQUISITION.ERROR,
          payload: error
        });

        throw error;
      });
  } else {
    dispatch({ type: TYPES.REMOVE_CATEGORY_ITEM, payload: { itemID, categoryID } });
  }
};

export const setItemField =
  (
    { itemID, supplierRequisitionID }: { itemID: string; supplierRequisitionID?: string },
    field: { [key: string]: unknown }
  ): ThunkActionType =>
  dispatch => {
    dispatch({ type: TYPES.SET_ITEM_FIELD, payload: { itemID, supplierRequisitionID, field } });
  };

export const setItemFieldError =
  ({ itemID, supplierRequisitionID }, field) =>
  (dispatch, getState) => {
    const key = Object.keys(field)[0];
    const value = field[key];
    const item = getState()?.purchasing?.requisitions?.items[itemID];

    if (value) {
      dispatch(setItemField({ itemID, supplierRequisitionID }, field));
      return;
    }

    if (supplierRequisitionID) {
      if (item.supplier_details[supplierRequisitionID][key])
        dispatch(setItemField({ itemID, supplierRequisitionID }, field));
    } else {
      if (item[key]) dispatch(setItemField({ itemID, supplierRequisitionID }, field));
    }
  };

export const applyDiscountToAllItems = params => dispatch => {
  dispatch({ type: TYPES.APPLY_DISCOUNT_TO_ALL_ITEMS.START, payload: { params } });

  const { id, supplierID, ...rest } = params;

  return put(
    `${dispatch(getRequisitionRequestPrefix())}/suppliers/${supplierID}/discount-all-items`,
    {
      ...rest
    }
  )
    .then(response => {
      dispatch({
        type: TYPES.APPLY_DISCOUNT_TO_ALL_ITEMS.SUCCESS,
        payload: response.data
      });

      dispatch(getPurchasingRequisitionItems({ id }));
      recalculatePurchasingRequisitionSupplierTotals();

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.APPLY_DISCOUNT_TO_ALL_ITEMS.ERROR, payload: error });
      throw error;
    });
};

export const applyToAllItemsInTheCategoryAction = createAsyncThunk(
  'APPLY_TO_ALL_ITEMS_IN_CATEGORY',
  async (params: ApplyToAllItemsInTheCategoryParams, { rejectWithValue, dispatch }) => {
    const { id } = params;

    try {
      await applyToAllItemsInTheCategory(params);

      dispatch(getPurchasingRequisitionItems({ id }));
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fillQuantityFromExistings = params => dispatch => {
  dispatch({ type: TYPES.FILL_QUANTITY_FROM_EXISTINGS.START, payload: { params } });

  const { id, supplierID, field, ...rest } = params;

  return put(
    `${dispatch(getRequisitionRequestPrefix())}/suppliers/${supplierID}/update-items-quantity`,
    {
      quantity_field: field,
      ...rest
    }
  )
    .then(response => {
      dispatch({
        type: TYPES.FILL_QUANTITY_FROM_EXISTINGS.SUCCESS,
        payload: response.data
      });

      dispatch(getPurchasingRequisitionItems({ id }));
      recalculatePurchasingRequisitionItemsSupplierTotals();
      recalculatePurchasingRequisitionSupplierTotals();

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.FILL_QUANTITY_FROM_EXISTINGS.ERROR, payload: error });
      throw error;
    });
};

export const fillRevQuantityFromReQ = params => dispatch => {
  dispatch({ type: TYPES.FILL_REV_QUANTITY_FROM_REQ.START, payload: { params } });

  const { id, field, ...rest } = params;

  return put(`${dispatch(getRequisitionRequestPrefix())}/${id}/items/update-items-quantity`, {
    quantity_field: field,
    ...rest
  })
    .then(response => {
      dispatch({
        type: TYPES.FILL_REV_QUANTITY_FROM_REQ.SUCCESS,
        payload: response.data
      });

      dispatch(getPurchasingRequisitionItems({ id }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.FILL_REV_QUANTITY_FROM_REQ, payload: error });
      throw error;
    });
};

export const resetState = () => dispatch => {
  dispatch({ type: TYPES.RESET_STATE, payload: null });
};

export const toggleLastDeliveredTooltip = (isOpen, itemID) => dispatch => {
  dispatch({ type: TYPES.TOGGLE_LAST_DELIVERED_TOOLTIP, payload: { itemID, isOpen } });
};

export const removePmsItems = createAsyncThunk(
  TYPES.REMOVE_PMS_ITEMS,
  async (params, { rejectWithValue, getState }) => {
    try {
      if (params?.vessel_id) {
        const selectedPmsitems = selectPMSItems(getState());

        const requestParams = {
          list: 'spare-parts',
          ids: selectedPmsitems.map(key => key.replace('spare_part_', '')),
          ...params
        };

        // Make a request to check if the already selected spare parts belong to the newly selected vessel
        const res = await get('/lists', requestParams);

        return res.data;
      }

      return [];
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

// selection item drawers

export const togglePurchasingItemsSelectionDrawer = payload => dispatch => {
  dispatch({
    type: TYPES.TOGGLE_PURCHASING_ITEMS_SELECTION_DRAWER,
    payload: payload
  });
};

export const togglePmsItemsSelectionDrawer = payload => dispatch => {
  dispatch({
    type: TYPES.TOGGLE_PMS_ITEMS_SELECTION_DRAWER,
    payload: payload
  });
};

export const togglePurchasingCommunicationsDrawer = isOpen => dispatch => {
  dispatch({ type: TYPES.TOGGLE_PURCHASING_COMMUNICATION_DRAWER, payload: isOpen });
};

export const setSelectedItemToBeReplaced = id => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_ITEM_TO_BE_REPLACED, payload: id });
};

export const setActiveCategoryId = id => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_CATEGORY_ID, payload: id });
};

export const toggleCategoryDrawer =
  ({ isOpen, categoryID }: { isOpen: boolean; categoryID: number | null }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: TYPES.CATEGORY_DRAWER_OPEN, payload: { isOpen, categoryID } });
  };

/* Suppliers */

export const setIsAddingSupplier = payload => dispatch => {
  dispatch({ type: TYPES.SET_IS_ADDING_SUPPLIER, payload });
};

export const setPurchasingRequisitionSuppliers = payload => dispatch => {
  dispatch({ type: TYPES.SET_PURCHASING_REQUISITION_SUPPLIERS, payload });
};

export const getPurchasingRequisitionSuppliers = params => (dispatch, getState) => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_SUPPLIERS.START, payload: { params } });
  const { id } = params;

  return get(`${dispatch(getRequisitionRequestPrefix())}/${id}/suppliers`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITION_SUPPLIERS.SUCCESS,
        payload: response.data.map((e, index) => ({
          ...e,
          supplier_number: index + 1,
          system_currency_label: getState()?.settings?.system_currency?.label
        }))
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_SUPPLIERS.ERROR, payload: error });
      throw error;
    });
};

export const addPurchasingRequisitionSupplier = params => dispatch => {
  dispatch({ type: TYPES.ADD_PURCHASING_REQUISITION_SUPPLIER.START, payload: { params } });
  const { id, supplier, supplier_representative_for_id } = params;

  return post(`${dispatch(getRequisitionRequestPrefix())}/${id}/suppliers`, {
    supplier_id: supplier.id,
    supplier_representative_for_id
  })
    .then(response => {
      dispatch({ type: TYPES.ADD_PURCHASING_REQUISITION_SUPPLIER.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Added successfully' }));
      dispatch(getPurchasingRequisitionSuppliers({ id }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.ADD_PURCHASING_REQUISITION_SUPPLIER.ERROR, payload: error });
      throw error;
    });
};

export const getPurchasingRequisitionSupplier = params => (dispatch, getState) => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_SUPPLIER.START, payload: { params } });
  const { id } = params;

  return get(`${dispatch(getRequisitionRequestPrefix())}/suppliers/${id}`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITION_SUPPLIER.SUCCESS,
        payload: {
          ...response.data,
          system_currency_label: getState()?.settings?.system_currency?.label
        }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_SUPPLIER.ERROR, payload: error });
      throw error;
    });
};

export const updatePurchasingRequisitionSupplier = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`${dispatch(getRequisitionRequestPrefix())}/suppliers/${id}`, params)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.SUCCESS,
        payload: {
          ...rest,
          ...response.data
        }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.ERROR, payload: error });
      throw error;
    });
};

export const uploadPurchasingRequisitionSupplierFile = params => dispatch => {
  dispatch({ type: TYPES.UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE.START, payload: { params } });
  const { id, file_id, ...rest } = params;

  return post(`${dispatch(getRequisitionRequestPrefix())}/suppliers/${id}/import/excel`, {
    file_id: file_id
  })
    .then(response => {
      dispatch({
        type: TYPES.UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE.SUCCESS,
        payload: {
          ...rest,
          ...response.data
        }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE.ERROR, payload: error });
      throw error;
    });
};

export const evaluatePurchasingRequisitionSupplier = params => dispatch => {
  dispatch({ type: TYPES.EVALUATE_PURCHASING_REQUISITION_SUPPLIER.START, payload: { params } });
  const { id, ...rest } = params;

  return post(`/purchasing-requisitions/suppliers/${id}/evaluate`, params)
    .then(response => {
      dispatch({
        type: TYPES.EVALUATE_PURCHASING_REQUISITION_SUPPLIER.SUCCESS,
        payload: { ...rest, ...response.data }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EVALUATE_PURCHASING_REQUISITION_SUPPLIER.ERROR, payload: error });
      throw error;
    });
};

export const updatePurchasingRequisitionSupplierStatus = params => (dispatch, getState) => {
  dispatch({
    type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER_STATUS.START,
    payload: { params }
  });
  const { id, type, ...rest } = params;

  const hasErros = handleStatusSubmissionValidations(
    { type, supplierRequisitionID: id },
    { getState, dispatch }
  );

  if (hasErros) return;

  return put(`${dispatch(getRequisitionRequestPrefix())}/suppliers/${id}/${type}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER_STATUS.SUCCESS,
        payload: {
          ...response.data,
          system_currency_label: getState()?.settings?.system_currency?.label
        }
      });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(getPurchasingRequisitionSupplier({ id }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER_STATUS.ERROR, payload: error });
      throw error;
    });
};

export const deletePurchasingRequisitionSupplier = params => dispatch => {
  dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_SUPPLIER.START, payload: { params } });
  const { id } = params;

  return deleteRequest(`${dispatch(getRequisitionRequestPrefix())}/suppliers/${id}`)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_PURCHASING_REQUISITION_SUPPLIER.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_SUPPLIER.ERROR, payload: error });
      throw error;
    });
};

export const downloadSupplierInvoiceAttachments = params => dispatch => {
  dispatch({ type: TYPES.DOWNLOAD_SUPPLIER_INVOICE_ATTACHMENTS.START, payload: { id } });

  const { id } = params;

  return download(`/purchasing-requisitions/suppliers/${id}/invoice-attachments`)
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_SUPPLIER_INVOICE_ATTACHMENTS.SUCCESS,
        payload: { data: response.data, id }
      });
      return response;
    })
    .catch(error =>
      dispatch({
        type: TYPES.DOWNLOAD_SUPPLIER_INVOICE_ATTACHMENTS.ERROR,
        payload: { error, id }
      })
    );
};

export const sendPurchasingRequisitionSupplierDeliveryReport = params => dispatch => {
  dispatch({
    type: TYPES.SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT.START,
    payload: { params }
  });
  const { id, ...rest } = params;

  return put(`/purchasing-requisitions/suppliers/${id}/delivery`, rest)
    .then(response => {
      dispatch({
        type: TYPES.SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT.SUCCESS,
        payload: response.data
      });
      dispatch(
        successHandler({ title: 'Success!', message: 'Delivery report was sent successfully' })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT.ERROR,
        payload: error
      });
      throw error;
    });
};

export const toggleSupplierRejection =
  (params: { id: number; type: 'reject' | 'reactivate' }) => (dispatch: AppDispatch) => {
    const { id, type } = params;

    dispatch({ type: TYPES.TOGGLE_SUPPLIER_REJECTION.START, payload: id });

    return put(`purchasing-requisitions/suppliers/${id}/${type}`)
      .then(response => {
        dispatch({
          type: TYPES.TOGGLE_SUPPLIER_REJECTION.SUCCESS,
          payload: response.data
        });

        dispatch({
          type: TYPES.UPDATE_PURCHASING_REQUISITION_SUPPLIER.SUCCESS,
          payload: response.data
        });

        return response.data;
      })
      .catch(error => {
        dispatch({
          type: TYPES.TOGGLE_SUPPLIER_REJECTION.ERROR,
          payload: error
        });

        throw error;
      });
  };

export const setVisibleSuppliers = suppliers => dispatch => {
  dispatch({ type: TYPES.SET_SUPPLIERS_VISIBILITY, payload: suppliers });
};

export const toggleAllSuppliersVisibility = areVisible => dispatch => {
  dispatch({ type: TYPES.TOGGLE_ALL_SUPPLIERS_VISIBILITY, payload: areVisible });
};

export const toggleSupplierVisibility = (id, visibility) => dispatch => {
  dispatch({ type: TYPES.TOGGLE_SUPPLIER_VISIBILITY, payload: { id, visibility } });
};
// Chat actions

export const getPurchasingRequisitionChatMessages = params => (dispatch, getState) => {
  if (selectIsTemplateLocation(getState())) return;

  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_CHAT_MESSAGES.START, payload: { params } });
  dispatch(setFetching(true));

  return get(`/purchasing-requisitions/${params.purchasing_requisition_ids}/messages`)
    .then(response => {
      dispatch(setMessages(response.data));
      dispatch(setFetching(false));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_CHAT_MESSAGES.ERROR, payload: error });
      dispatch(setFetching(false));
      throw error;
    });
};

export const postPurchasingRequisitionChatMessage = params => dispatch => {
  const { purchasing_requisition_ids, ...rest } = params;

  dispatch({ type: TYPES.POST_PURCHASING_REQUISITION_CHAT_MESSAGE.START, payload: { params } });

  return post(`/purchasing-requisitions/${purchasing_requisition_ids}/messages`, rest)
    .then(response => {
      dispatch(postMessage(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.POST_PURCHASING_REQUISITION_CHAT_MESSAGE.ERROR, payload: error });
      throw error;
    });
};

export const getUnreadPurchasingRequisitionChatMessages = params => (dispatch, getState) => {
  if (selectIsTemplateLocation(getState())) return;

  dispatch({
    type: TYPES.GET_UNREAD_PURCHASING_REQUISITION_CHAT_MESSAGES.START,
    payload: { params }
  });

  return get(`/purchasing-requisitions/${params.id}/messages/get-unread`)
    .then(response => {
      dispatch(setUnreadMessages({ count: response.data.count }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_UNREAD_PURCHASING_REQUISITION_CHAT_MESSAGES.ERROR,
        payload: error
      });
      throw error;
    });
};

export const purchasingRequisitionChatMessageMarkAsRead = params => dispatch => {
  dispatch({
    type: TYPES.PURCHASING_REQUISITION_CHAT_MESSAGE_MARK_AS_READ.START,
    payload: { params }
  });

  return put(`/purchasing-requisitions/${params.purchasing_requisition_ids}/messages/mark-as-read`)
    .then(response => {
      dispatch(setMarkAsRead(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.PURCHASING_REQUISITION_CHAT_MESSAGE_MARK_AS_READ.ERROR,
        payload: error
      });
      throw error;
    });
};

/* Table Configuration Actions */

export const changePurchasingTableConfiguration = params => dispatch => {
  const { id, shouldUpdate } = params;

  if (id === 'add') {
    return dispatch({
      type: TYPES.GET_PURCHASING_TABLE_CONFIGURATION.SUCCESS,
      payload: { id, settings: [], name: 'Creating Configuration' }
    });
  }

  dispatch({ type: TYPES.GET_PURCHASING_TABLE_CONFIGURATION.START, payload: { id } });

  if (id === 'default' && !shouldUpdate) {
    return null;
  }

  return get('/purchasing-requisitions/settings', { id: id === 'default' ? null : id })
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_TABLE_CONFIGURATION.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_TABLE_CONFIGURATION.ERROR, payload: error });
      throw error;
    });
};

export const createPurchasingTableConfiguration = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PURCHASING_TABLE_CONFIGURATION.START, payload: { params } });

  return post('/purchasing-requisitions/settings', params)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_PURCHASING_TABLE_CONFIGURATION.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
      dispatch(changePurchasingTableConfiguration({ id: response.data.id, shouldUpdate: true }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PURCHASING_TABLE_CONFIGURATION.ERROR, payload: error });
      throw error;
    });
};

export const editPurchasingTableConfiguration = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.EDIT_PURCHASING_TABLE_CONFIGURATION.START, payload: { params } });

  return put(`/purchasing-requisitions/settings/${id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.EDIT_PURCHASING_TABLE_CONFIGURATION.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Edited successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_PURCHASING_TABLE_CONFIGURATION.ERROR, payload: error });
      throw error;
    });
};

export const deletePurchasingTableConfiguration = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.DELETE_PURCHASING_TABLE_CONFIGURATION.START, payload: { id } });

  return deleteRequest(`/purchasing-requisitions/settings/${id}`)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_PURCHASING_TABLE_CONFIGURATION.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_TABLE_CONFIGURATION.ERROR, payload: error });
      throw error;
    });
};

export const setRequsitionVessel =
  (selected: Vessel | Vessel[] | null) => (dispatch: AppDispatch) => {
    dispatch({ type: TYPES.SET_REQUISITION_VESSEL, payload: selected });
  };

export const setPreventLocationChange = prevent => dispatch => {
  dispatch({ type: TYPES.SET_PREVENT_LOCATION_CHANGE, payload: prevent });
};

export const setVisibleItemFields =
  ({ listedFields, unlistedFields }) =>
  dispatch => {
    dispatch({
      type: TYPES.SET_VISIBLE_ITEM_FIELDS,
      payload: { listed: listedFields, unlisted: unlistedFields }
    });
  };

/* Purchasing templates */
export const getPurchasingRequisitionTemplates = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_TEMPLATES.START, payload: { params } });
  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table: params.table } });

  return get('/purchasing-requisition-templates', params)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITION_TEMPLATES.SUCCESS,
        payload: response.data
      });
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table: params.table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITION_TEMPLATES.ERROR, payload: error });
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table: params.table } });
      throw error;
    });
};

export const deletePurchasingRequisitionTemplate = params => dispatch => {
  dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_TEMPLATE.START });

  return deleteRequest(`/purchasing-requisition-templates/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_TEMPLATE.SUCCESS });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_TEMPLATE.ERROR, payload: error });
      throw error;
    });
};

export const createPurchasingRequisitionTemplate = params => dispatch => {
  dispatch({ type: TYPES.CREATE_PURCHASING_REQUISITION_TEMPLATE.START, payload: { params } });

  return post('/purchasing-requisition-templates', params)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_PURCHASING_REQUISITION_TEMPLATE.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_PURCHASING_REQUISITION_TEMPLATE.ERROR, payload: error });
      throw error;
    });
};

export const loadPurchasingRequisitionSuppliersFromTemplate = params => dispatch => {
  dispatch({
    type: TYPES.LOAD_PURCHASING_REQUISITION_SUPPLIERS_FROM_TEMPLATE.START,
    payload: { params }
  });

  return put(`/purchasing-requisition-templates/${params.id}/load-template-suppliers`, params)
    .then(response => {
      dispatch({
        type: TYPES.LOAD_PURCHASING_REQUISITION_SUPPLIERS_FROM_TEMPLATE.SUCCESS,
        payload: response.data
      });
      dispatch(getPurchasingRequisitionSuppliers({ id: params.id }));
      dispatch(getPurchasingRequisitionItems({ id: params.id }));
      dispatch(successHandler({ title: 'Success!', message: 'Loaded successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.LOAD_PURCHASING_REQUISITION_SUPPLIERS_FROM_TEMPLATE.ERROR,
        payload: error
      });
      throw error;
    });
};

export const resetTemplateState = () => dispatch => {
  dispatch({ type: TYPES.RESET_TEMPLATE_STATE, payload: null });
};
/* -- */

/* Forwarding Cases */

interface ForwardingCaseBoxParams extends BaseForwardingCaseBox {
  id?: number;
  requisition_items: RequisitionItemParam[];
}

export type BaseForwardingCaseParams = {
  itinerary_port_id: number | undefined;
  vessel_id: string | undefined;
  port_id: number | undefined;
  company_id: string | undefined;
  forwarder_id: number | null;
  requisition_supplier_ids: number[];
  forwarder_cost: number | null;
  agent_cost: number | null;
  boxes: ForwardingCaseBoxParams[];
  awb_attachment_ids: number[];
};

export interface ForwardingCase {
  id: number;
  boxes: ForwardingCaseBoxWithItems[];
  requisition_suppliers: Supplier[];
  itinerary_port_id: number | null;
  port: Port;
  port_id: number;
  vessel: [{ id: number }];
  company: [{ id: number; name: string }];
  itinerary_port: ItineraryPort;
}

export const createForwardingCase = createAsyncThunk(
  'CREATE_FORWARDING_CASE',
  async (params: BaseForwardingCaseParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await post<ForwardingCase>('/forwarding-cases', params);
      dispatch(
        successHandler({ title: 'Success!', message: 'Forwarding case created successfully' })
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

type UpdateForwardingCaseParams = {
  id: number | string;
} & BaseForwardingCaseParams;

export const updateForwardingCase = createAsyncThunk(
  'UPDATE_FORWARDING_CASE',
  async (params: UpdateForwardingCaseParams, { rejectWithValue, dispatch }) => {
    const { id, ...rest } = params;
    try {
      const response = await put<ForwardingCase>(`/forwarding-cases/${id}`, rest);
      dispatch(
        successHandler({ title: 'Success!', message: 'Forwarding case updated successfully' })
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

type GetForwardingCaseParams = {
  id: number | string;
};

export const getForwardingCase = createAsyncThunk(
  'GET_FORWARDING_CASE',
  async (params: GetForwardingCaseParams, { rejectWithValue }) => {
    const { id, ...rest } = params;
    try {
      const response = await get<ForwardingCase>(`/forwarding-cases/${id}`, rest);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getForwardingItemsCount = createAsyncThunk(
  'GET_FORWARDING_ITEMS_COUNT',
  async (params, { rejectWithValue }) => {
    const { view, ...rest } = params;
    try {
      const response = await get(`/forwarding-cases/${view}/count`, rest);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* -- */

/* Purchasing emails */
export const sendPurchasingRequisitionEmails = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.SEND_PURCHASING_REQUISITIONS_EMAILS.START, payload: { params } });

  return post(`/purchasing-requisitions/${id}/emails`, rest)
    .then(response => {
      dispatch({ type: TYPES.SEND_PURCHASING_REQUISITIONS_EMAILS.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Send successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.SEND_PURCHASING_REQUISITIONS_EMAILS.ERROR, payload: error });
      throw error;
    });
};

export const getPurchasingRequisitionEmails = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_EMAILS.START, payload: { params } });

  return get('/purchasing-requisitions/emails', params)
    .then(response => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_EMAILS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_EMAILS.ERROR, payload: error });
      throw error;
    });
};

export const shouldIncludeDefaultEmailAttachments = (value: boolean) => dispatch => {
  dispatch({
    type: TYPES.INCLUDE_DEFAULT_EMAIL_ATTACHMENTS,
    payload: value
  });
};

export const getPurchasingRequisitionEmailAttachments = params => dispatch => {
  dispatch({
    type: TYPES.GET_PURCHASING_REQUISITIONS_EMAIL_ATTACHMENTS.START,
    payload: { params }
  });

  const { email_id } = params;

  return get(`/emails/${email_id}/attachments`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_EMAIL_ATTACHMENTS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_EMAIL_ATTACHMENTS.ERROR, payload: error });
      throw error;
    });
};

export const createPurchasingRequisitionEmailTemplates = params => dispatch => {
  dispatch({
    type: TYPES.CREATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES.START,
    payload: { params }
  });

  return post(`/email-templates`, params)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Template created successfully' }));
      dispatch(
        refetchDeafultListOptions('email-templates', '', { type: 'purchasing_requisition' })
      );
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES.ERROR,
        payload: error
      });
      throw error;
    });
};

export const updatePurchasingRequisitionEmailTemplates = params => dispatch => {
  dispatch({
    type: TYPES.UPDATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES.START,
    payload: { params }
  });

  const { email_template_id } = params;

  return put(`/email-templates/${email_template_id}`, params)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES.SUCCESS,
        payload: response.data
      });
      dispatch(
        refetchDeafultListOptions('email-templates', '', { type: 'purchasing_requisition' })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES.ERROR,
        payload: error
      });
      throw error;
    });
};

export const linkPurchasingRequisitionEmail = params => dispatch => {
  dispatch({ type: TYPES.LINK_PURCHASING_REQUISITIONS_EMAIL.START, payload: { params } });

  const { requisitionId, ...rest } = params;

  return post(`/purchasing-requisitions/${requisitionId}/linked-emails`, rest)
    .then(response => {
      dispatch({ type: TYPES.LINK_PURCHASING_REQUISITIONS_EMAIL.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Email linked successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.LINK_PURCHASING_REQUISITIONS_EMAIL.ERROR, payload: error });
      throw error;
    });
};

export const getEmailSearchLink = createAsyncThunk(
  TYPES.GET_PURCHASING_REQUISITION_EMAIL_LINK,
  async (params, { rejectWithValue }) => {
    try {
      const { requisitionId, ...rest } = params;

      const res = await get(`/purchasing-requisitions/${requisitionId}/email-search-link`, rest);
      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unlinkFinding = createAsyncThunk(
  'UNLINK_PURCHASING_FINDING',
  async (params, { rejectWithValue }) => {
    try {
      const { requisitionId, id, ...rest } = params;

      await deleteRequest(`/purchasing-requisitions/${requisitionId}/findings/${id}`, rest);
      return params;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setCollapsedFindings = value => dispatch => {
  dispatch({ type: TYPES.SET_COLLAPSED_FINDINGS, payload: value });
};

export const getPurchasingRequisitionLinkedEmailTempUrl = params => dispatch => {
  const { email_id } = params;

  dispatch({
    type: TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS_TEMP_URL.START,
    payload: { params }
  });

  return get(`/purchasing-requisitions/linked-emails/${email_id}/temp-url`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS_TEMP_URL.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS_TEMP_URL.ERROR,
        payload: error
      });
      throw error;
    });
};

export const getPurchasingRequisitionLinkedEmails = params => dispatch => {
  dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS.START, payload: { params } });

  const { requisitionId } = params;

  return get(`/purchasing-requisitions/${requisitionId}/linked-emails`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PURCHASING_REQUISITIONS_LINKED_EMAILS.ERROR, payload: error });
      throw error;
    });
};

export const deletePurchasingRequisitionEmailLink = params => dispatch => {
  dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_EMAIL_LINK.START });

  return deleteRequest(`/purchasing-requisitions/linked-emails/${params.id}`)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_PURCHASING_REQUISITION_EMAIL_LINK.SUCCESS,
        payload: { id: params.id }
      });
      dispatch(successHandler({ title: 'Success!', message: 'Linked email removed successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_PURCHASING_REQUISITION_EMAIL_LINK.ERROR, payload: error });
      throw error;
    });
};

export const requestExternalSupplierTokenAction = createAsyncThunk<
  PurchasingExternalSupplierTokens,
  RequestExternalSupplierTokenParams
>('REQUEST_EXTERNAL_SUPPLIER_TOKEN', async (params, { rejectWithValue }) => {
  try {
    const response = await requestExternalSupplierToken(params);

    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const enableSupplierForwarding = createAsyncThunk(
  'ENABLE_SUPPLIER_FORWARDING',
  async (params, { rejectWithValue, dispatch }) => {
    const { id } = params;

    try {
      const response = await post(`/purchasing-requisitions/suppliers/${id}/forwarding`);

      dispatch(
        successHandler({ title: 'Success!', message: 'Forwarding case created successfully' })
      );

      dispatch(getPurchasingRequisitionSupplier({ id: id }));

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const disableSupplierForwarding = createAsyncThunk(
  'DISABLE_SUPPLIER_FORWARDING',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const response = await deleteRequest(`/purchasing-requisitions/suppliers/${id}/forwarding`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setIsLastRequisitionLoaded = value => dispatch => {
  dispatch({ type: TYPES.SET_IS_LAST_REQUISITION_LOADED, payload: value });
};

/* Vessel Systems */
type GetPurchasingRequisitionVesselSystemsAction = {
  paging: TableRequestPaging;
  with_counts: boolean;
  search?: string;
  purchasing_category_id?: number;
  vessel_ids?: number[];
  keepOpened?: boolean;
  hideLoading?: boolean;
};

export const getPurchasingRequisitionVesselSystemsAction = createAsyncThunk(
  PMS_LIBRARY_TYPES.GET_PURCHASING_REQUISITION_VESSEL_SYSTEMS,
  async (
    params: GetPurchasingRequisitionVesselSystemsAction,
    { rejectWithValue, getState, fulfillWithValue }
  ) => {
    try {
      const { keepOpened, hideLoading, ...rest } = params;

      const state = getState() as RootState;
      const systemsSearch = selectSystemsSearch(state);

      const activePurchasingCategoryID = selectActiveCategoryID(state);
      const requisitionVessel = selectRequisitionVessel(state);

      const isOnBoard = state.isOnBoard;

      let vesselIds = undefined;

      if (!isOnBoard && requisitionVessel) {
        if (Array.isArray(requisitionVessel)) {
          vesselIds = requisitionVessel.map(v => v.id);
        } else {
          vesselIds = [requisitionVessel.id];
        }
      }

      const requestParams = {
        purchasing_category_id: activePurchasingCategoryID,
        vessel_ids: vesselIds,
        search: systemsSearch || undefined,
        ...rest // Override any of the above
      };

      const response = await getVesselSystems(requestParams);

      return fulfillWithValue({
        response,
        keepOpened,
        hideLoading,
        hasSearch: requestParams.search && requestParams.search.length > 0,
        searchText: requestParams.search
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
/* */

/* Remarks and attachments top section */
export const toggleRemarksAndAttachments = show => dispatch => {
  dispatch({ type: TYPES.TOGGLE_REMARKS_AND_ATTACHMENTS, payload: show });
};

export const toggleSummaryView = isActive => dispatch => {
  dispatch({ type: TYPES.TOGGLE_SUMMARY_VIEW, payload: isActive });
};

export const setPurchasingRequisitionCommentsDrawerOpen =
  (isOpen, params = {}) =>
  dispatch => {
    dispatch({
      type: TYPES.SET_ITEM_COMMENTS_DRAWER_OPEN,
      payload: { isOpen, ...params }
    });
  };

export const setPurchasingRequisitionPoDetailsDrawerOpen =
  (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: TYPES.SET_SUPPLIER_PO_DETAILS_DRAWER_OPEN,
      payload: isOpen
    });
  };

export const setItemsTableConfiguration =
  (labels: ItemConfigurationKey[]) => (dispatch: AppDispatch) =>
    dispatch({ type: TYPES.SET_ITEMS_TABLE_CONFIGURATION, payload: labels });

export const setItemsTableComparisonViewEnabled = (isEnabled: boolean) => (dispatch: AppDispatch) =>
  dispatch({ type: TYPES.SET_ITEMS_TABLE_COMPARISON_VIEW_ENABLED, payload: isEnabled });

export const setItemsTableComparisonBenchmarkThresh = (value: number) => (dispatch: AppDispatch) =>
  dispatch({
    type: TYPES.SET_ITEMS_TABLE_COMPARISON_BENCHMARK_THRESH,
    payload: { value }
  });

export const setItemsTableComparisonCommentsVisibility =
  (isVisible: boolean) => (dispatch: AppDispatch) =>
    dispatch({
      type: TYPES.SET_ITEMS_TABLE_COMPARISON_COMMENTS_VISIBILITY,
      payload: isVisible
    });

export const setItemsTableComparisonLocalCurrenciesVisibility =
  (isVisible: boolean) => (dispatch: AppDispatch) =>
    dispatch({
      type: TYPES.SET_ITEMS_TABLE_COMPARISON_LOCAL_CURRENCIES_VISIBILITY,
      payload: isVisible
    });
