import moment from 'moment-timezone';
import _findKey from 'lodash/findKey';
import _isString from 'lodash/isString';
import { strToNumber } from '@/ts-common/utils/numbers';

moment.updateLocale(moment.locale(), { invalidDate: '' });
const defs = {
  year: 'YYYY',
  month: 'MM',
  day: 'DD'
};

export const getMinutesHoursOrDays = date => {
  const days = moment().diff(moment(date), 'days');
  const hours = moment().diff(moment(date), 'hours');
  const minutes = moment().diff(moment(date), 'minutes');

  let time = 0;

  if (minutes < 60) {
    time = minutes !== 1 ? minutes + ' minutes ' : ' 1 minute';
  } else if (hours < 24) {
    time = hours !== 1 ? hours + ' hours ' : ' 1 hour';
  } else if (days > 0) {
    time = days !== 1 ? days + ' days ' : ' 1 day';
  }

  return time;
};

export const getDefaultFormat = hide => {
  // hide: oneOf(['year', 'month', 'day', 'date', 'time'])

  const dateFormat = 'DD/MM/YYYY';
  const timeFormat = 'HH:mm';
  let format = '';

  if (hide) {
    let formated_date = '';

    if (!hide.date) {
      // Checks for YYYY, MM, DD
      let parts = dateFormat.split('/');
      parts = parts.filter(part => !hide[_findKey(defs, def => def === part)]);

      if (parts.length) {
        formated_date = parts.join('/');
      }
    }

    if (!hide.time) {
      format = `${formated_date !== '' ? `${formated_date} ` : ''}${timeFormat}`;
    } else {
      format = `${formated_date}`;
    }
  } else {
    format = `${dateFormat}, ${timeFormat}`;
  }

  return format;
};

export const todayInUtc = format => {
  const utc = moment().utc();
  const date = moment(utc.format('YYYY-MM-DD HH:mm'));

  if (format) return date.format(format);
  else return date;
};

export const dateToUtc = (date, day, format) => {
  // day: oneOf(['startOf', 'endOf'])
  if (!date) return null;

  const utc = moment(
    `${date}${day === 'endOf' ? ' 23:59' : day === 'startOf' ? ' 00:00' : ''}`
  ).utc();

  if (format) return utc.format(format);
  else return utc;
};

export const dateToLocal = (date, day, format) => {
  // day: oneOf(['startOf', 'endOf'])
  if (!date) return null;

  let newDate = null;

  if (date instanceof moment) {
    if (day === 'endOf' || day === 'startOf') newDate = date.format('YYYY-MM-DD');
    else newDate = date.format('YYYY-MM-DD HH:mm');
  } else {
    newDate = date;
  }

  const local = moment
    .utc(`${newDate}${day === 'endOf' ? ' 23:59' : day === 'startOf' ? ' 00:00' : ''}`)
    .local();

  if (format) return local.format(format);
  else return local;
};

export const formatDate = (date, hide) => {
  if (!date) return '';

  return dateToLocal(date).format(getDefaultFormat(hide));
};

export const displayDate = (date, hide) => {
  if (!date) return '';

  if (date && date instanceof moment) return date?.format(getDefaultFormat(hide));

  return moment.utc(date, getDefaultFormat(hide), true).isValid()
    ? date
    : moment.utc(date).format(getDefaultFormat(hide));
};

export const getCustomFormat = (date, format, fallback) => {
  if (!date) {
    return fallback || '';
  }

  return moment(date).format(format);
};

export const minutesToFormat = (_minutes, format) => {
  if (!_minutes && _minutes !== 0) {
    return null;
  }

  const getLeadingZero = value => {
    return value > 9 ? value : `0${value}`;
  };

  try {
    const hours = Math.floor(_minutes / 60);
    const minutes = _minutes % 60;

    switch (format) {
      case 'hh:mm':
        return `${getLeadingZero(hours)}:${getLeadingZero(minutes)}`;

      default:
        return `${hours}h ${minutes}m`;
    }
  } catch {
    return null;
  }
};

export const formatDaysDuration = (numberOfDays, format) => {
  let startDate = moment();

  // numberOfDays can be passed as number or can be passed like { months : '' ,days : ''}

  let endEnd = moment().add(numberOfDays, 'days');

  let years = endEnd.diff(startDate, 'year');
  startDate.add(years, 'years');

  let months = endEnd.diff(startDate, 'months');
  startDate.add(months, 'months');

  let days = endEnd.diff(startDate, 'days');

  const daysDisplay = days > 0 ? (days !== 1 ? ' days' : ' day') : '';
  const monthsDisplay = months > 0 ? (months !== 1 ? ' months ' : ' month ') : '';
  const yearsDisplay = years > 0 ? (years !== 1 ? ' years ' : ' year ') : '';

  if (format === 'days') {
    return {
      years: { value: `${years > 0 ? years : ''}`, variant: yearsDisplay },
      months: { value: `${months > 0 ? months : ''}`, variant: monthsDisplay },
      days: { value: `${days > 0 ? days : ''}`, variant: daysDisplay }
    };
  } else if (format === 'months') {
    const yearsToMonths = years > 0 ? years * 12 : null;
    const totalMonths = months + yearsToMonths;
    const monthsDisplay = totalMonths > 0 ? (totalMonths !== 1 ? ' months ' : ' month ') : '';

    return {
      months: { value: `${totalMonths > 0 ? totalMonths : ''}`, variant: monthsDisplay },
      days: { value: `${days > 0 ? days : ''}`, variant: daysDisplay }
    };
  } else {
    const hasNotDuration = years === 0 && months === 0;
    const yearsValue = hasNotDuration ? '-' : years;
    const yearsVariant = hasNotDuration ? null : '.';
    const monthsValue = hasNotDuration ? null : months;
    const monthsVariant = hasNotDuration ? null : ' years';

    return {
      years: { value: yearsValue, variant: yearsVariant },
      months: { value: monthsValue, variant: monthsVariant }
    };
  }
};

export const calculateTimeRanges = (step = 30, customRange) => {
  // customRange = { start: 00:00:00, end: 00:00:00 }
  let dayMinutes = 1440;
  let start =
    moment().format('YYYY-MM-DD') +
    (customRange && customRange.start ? ` ${customRange.start}` : ` 00:00:00`); //YYYY-MM-DD HH:mm:ss
  let end =
    customRange && customRange.end
      ? `${moment().format('YYYY-MM-DD')} ${customRange.end}`
      : `${moment().format('YYYY-MM-DD')} 23:59:00`; //YYYY-MM-DD HH:mm:ss

  let date = moment(start).format('YYYY-MM-DD HH:mm:ss');
  let ranges = [];
  ranges.push(date);

  dayMinutes = moment(end).diff(moment(start), 'minutes');

  while (dayMinutes > 0) {
    dayMinutes = dayMinutes - parseInt(step);
    date = moment(date).add(step, 'minutes').format('YYYY-MM-DD HH:mm:ss');

    if (moment(date).format('YYYY-MM-DD HH:mm') <= moment(end).format('YYYY-MM-DD HH:mm'))
      ranges.push(date);
  }

  return ranges.map(r => {
    const t = moment(r).format('HH:mm');

    return { label: t, value: t };
  });
};

export const calculateHoursAndDays = (total_time, asObject) => {
  if (!total_time) {
    return null;
  }

  const days = total_time < 24 ? 0 : Math.floor(total_time / 24);
  const hours = Math.trunc(total_time % 24);

  /* 
    Example:
    total_time = 38 (can be either float or integer)
    
    Expected results: 
    days = 1
    hours = 14
  */

  return asObject
    ? {
        days,
        hours
      }
    : !days && !hours
      ? '-'
      : `${days ? `${days}d ` : ''}${hours ? `${hours}h` : ''}`;
};

export const convertMinutesToHoursAndMinutes = minutes => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes - hours * 60;
  return { hours, minutes: minutesLeft };
};

export const compareDates = (date, compareWithDate, condition) => {
  // Condition = [isAfter, isBefore, isSame]

  if (date && compareWithDate) {
    const formatedDate =
      date instanceof moment && date?._isUTC
        ? moment.utc(date).format('YYYY-MM-DD HH:mm')
        : moment(date).format('YYYY-MM-DD HH:mm');
    const formatedCompareDate =
      compareWithDate instanceof moment && compareWithDate?._isUTC
        ? moment.utc(compareWithDate).format('YYYY-MM-DD HH:mm')
        : moment(compareWithDate).format('YYYY-MM-DD HH:mm');

    switch (condition) {
      case 'isAfter':
        return moment(formatedDate).isAfter(formatedCompareDate);
      case 'isBefore':
        return moment(formatedDate).isBefore(formatedCompareDate);
      case 'isSame':
        return moment(formatedDate).isSame(formatedCompareDate);

      default:
        return false;
    }
  }

  return false;
};

const getImezoneOffset = tmz => {
  const regex = /[^\w\s]/g; // Any character that is not word or whitespace
  const indexOfSymbol = tmz.search(regex); // The index of - or +

  if (indexOfSymbol === -1) {
    // Case no symbol
    return 0;
  } else if (!Number(parseFloat(tmz[indexOfSymbol + 1]))) {
    // Case next to symbol is character instead of number
    return 0;
  } else {
    let tzConcat = tmz[indexOfSymbol].concat(tmz[indexOfSymbol + 1]);

    if (tmz[indexOfSymbol + 2]) {
      // In case the timezone is .5 instead of single integer
      tzConcat = tzConcat.concat(tmz[indexOfSymbol + 2].concat(tmz[indexOfSymbol + 3]));
    }

    const offset = parseFloat(tzConcat) * 60;

    return offset;
  }
};

// Converts a UTC timestamp to a local port timestamp (adds the port timezone)
export const portDateUtcToLocal = (date, port, timezone) => {
  /* Date is expected to be in UTC
  and the port.timeZone needs to be in format like: 5 or -5 */

  const selectedTmz = timezone ? timezone : port ? port?.timezone : null;
  const strTmz = `${+selectedTmz > 0 ? '+' : '-'}${Math.abs(+selectedTmz)}`;

  const tmz = `UTC${strTmz}`;

  if (!date) return null;

  if (!selectedTmz) {
    return moment(date);
  }

  const offset = getImezoneOffset(tmz);

  if (offset || offset === 0) {
    return moment.utc(date).utcOffset(offset);
  } else {
    if (port && port.timezone) {
      return moment.utc(date);
    }
  }

  return moment(date);
};

// Converts a timestamp with a specific port timezone to UTC timestamp (removes the port timezone)
export const portDateLocalToUtc = (date, port, timezone) => {
  /* The date is expected to be exactly the one that the user enters in the input. 
     If there is a port with an Offset, subtract that offset from the date to get the date in UTC.
     IMPORTANT!!! This function is already being used in the dates transformation while the forms are submitting.
     Check src\views\voyages\profile\operations\edit\port\config.js for more info
  */

  const selectedTmz = timezone ? timezone : port?.timezone;
  const strTmz = `${+selectedTmz > 0 ? '+' : '-'}${Math.abs(+selectedTmz)}`;

  const tmz = `UTC${strTmz}`;

  if (!selectedTmz) {
    return moment(date);
  }

  const offset = getImezoneOffset(tmz);

  if (offset) {
    return moment(date).add(-offset, 'minutes');
  } else {
    if (port && port.timezone) {
      return moment(date);
    }
  }

  return moment(date);
};

const textFormatted = (value, text) => {
  return (
    <>
      <span className="fs-12">{value}</span>{' '}
      <span className="fs-10">{`${text}${value > 1 ? 's' : ''}`}</span>{' '}
    </>
  );
};

export const formatDuration = (start, end, showTime = true) => {
  const startDate = moment(start);
  const endDate = moment(end);
  let parts = [];
  const duration = moment.duration(endDate.diff(startDate));

  // return nothing when the duration is falsy, not correctly parsed (P0D) or duration minutes are negative
  if (!duration || duration.toISOString() === 'P0D' || duration.asMinutes() <= 0)
    return <span className="fs-10 fw-bold">-</span>;

  if (duration.years() >= 1) {
    const years = Math.floor(duration.years());
    parts.push(textFormatted(years, 'year'));
  }

  if (duration.months() >= 1) {
    const months = Math.floor(duration.months());
    parts.push(textFormatted(months, 'month'));
  }

  if (duration.days() >= 1) {
    const days = Math.floor(duration.days());
    parts.push(textFormatted(days, 'day'));
  }

  if (duration.hours() >= 1 && showTime) {
    const hours = Math.floor(duration.hours());
    parts.push(textFormatted(hours, 'hour'));
  }

  if (duration.minutes() >= 1 && showTime) {
    const minutes = duration.minutes();
    parts.push(textFormatted(minutes, 'minute'));
  }

  return parts;
};

export const getFormattedTime = time => {
  if (_isString(time) && time.includes(':')) {
    return time;
  } else {
    return null;
  }
};

export const getDatesDifferenceAsDays = (firstDate, secondDate) => {
  if (firstDate && secondDate) {
    const first = moment(firstDate, 'YYYY-MM-DD').startOf('day');
    const second = moment(secondDate, 'YYYY-MM-DD').startOf('day');

    const days = Math.round(second.diff(first, 'days', true));
    return days || 0;
  } else return;
};

export const getTimezoneHoursDiff = diff => {
  if ((!diff && diff !== 0) || isNaN(diff)) return;

  const parts = diff.toString().split('.');

  const hours = Math.abs(parts[0]);
  const mins = parts[1] ? parseInt((parts[1] * 60) / 100) : 0;

  return `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `${mins}0` : mins}h`;
};

export const convertMinutesToDaysHoursAndMinutes = minutes => {
  if (isNaN(parseInt(minutes))) return null;

  const mins = strToNumber(minutes);

  if (mins < 0) return null;

  const HOURS_IN_A_DAY = 24;
  const MINUTES_IN_AN_HOUR = 60;

  const days = Math.floor(mins / (HOURS_IN_A_DAY * MINUTES_IN_AN_HOUR));
  const hours = Math.floor((mins % (HOURS_IN_A_DAY * MINUTES_IN_AN_HOUR)) / MINUTES_IN_AN_HOUR);
  const minutesLeft = mins % MINUTES_IN_AN_HOUR;

  return { days, hours, minutesLeft };
};
