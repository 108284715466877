import Header from 'common/components/tree-layout/main/Header';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectRootSystems,
  selectAllSystemsExpanded,
  selectSystemsLoading,
  selectHasCollapsedSubSystemsContent,
  selectSystemsPagingTotal,
  selectSystemsSearch,
  selectPmsLibraryIsUsedInSetup,
  selectSystemsPagingCurrentPage
} from 'common/components/pms/setup/store/selectors';
import {
  getVesselSystemsAction,
  toggleAllSystems
} from '@/common/components/pms/setup/store/actions';
import Loader from 'common/components/tree-layout/components/Loader';
import System from './system';
import useRouter from 'use-react-router';

const SystemContainer = () => {
  const dispatch = useAppDispatch();

  const systemsTree = useRef<HTMLDivElement>(null);

  const { match } = useRouter<{ vessel_id: string; group_id: string }>();

  const rootSystems = useAppSelector(selectRootSystems);
  const allSystemsExpanded = useAppSelector(selectAllSystemsExpanded);
  const systemsLoading = useAppSelector(selectSystemsLoading);
  const hasCollapsedContent = useAppSelector(selectHasCollapsedSubSystemsContent);
  const totalPages = useAppSelector(selectSystemsPagingTotal);
  const currentPage = useAppSelector(selectSystemsPagingCurrentPage);
  const search = useAppSelector(selectSystemsSearch);
  const isUsedInSetup = useAppSelector(selectPmsLibraryIsUsedInSetup);

  const onSearchChange = (s: string) => {
    return dispatch(
      getVesselSystemsAction({ search: s || undefined, keepOpened: true, firstPage: true })
    );
  };

  const onSearchClear = () => {
    if (search) {
      return dispatch(getVesselSystemsAction({ search: undefined, firstPage: true }));
    }
  };

  const loadMore = useCallback(() => {
    const { clientHeight, scrollTop, scrollHeight } = systemsTree.current;

    if (scrollHeight - (scrollTop + clientHeight) <= 20) {
      dispatch(getVesselSystemsAction({ loadMore: true }));
    }
  }, [dispatch]);

  useEffect(() => {
    const tree = systemsTree.current;

    if (totalPages > 1) {
      tree.addEventListener('scroll', loadMore, true);
    }

    return () => (totalPages > 1 ? tree.removeEventListener('scroll', loadMore, true) : null);
  }, [loadMore, totalPages]);

  useEffect(() => {
    if (!isUsedInSetup) return;

    dispatch(
      getVesselSystemsAction({
        firstPage: true,
        vessel_system_group_id: match.params.group_id ? +match.params.group_id : undefined,
        vessel_ids: match.params.vessel_id ? [+match.params.vessel_id] : undefined
      })
    );
  }, [dispatch, match.params.group_id, match.params.vessel_id, isUsedInSetup]);

  return (
    <div className="groups-container systems-container w-100p cmt-12 d-flex flex-column overflow-hidden">
      <Header
        placeholder="Search systems"
        allExpanded={allSystemsExpanded}
        search={search}
        hasCollapsedContent={hasCollapsedContent}
        toggleCollapse={(open: boolean) => dispatch(toggleAllSystems(open))}
        onSearchChange={onSearchChange}
        onSearchClear={onSearchClear}
      />

      <div className="chapters-tree flex-1" ref={systemsTree}>
        {rootSystems?.length
          ? systemsLoading && currentPage === 1
            ? null
            : rootSystems.map((el: number) => {
                return <System key={el.toString()} id={el} />;
              })
          : null}

        {systemsLoading ? <Loader /> : null}
      </div>
    </div>
  );
};

export default SystemContainer;
