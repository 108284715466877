import { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import {
  createMaintenanceJobType,
  editMaintenanceJobType
} from 'common/components/pms/setup/store/actions';
import Input from 'common/components/form/inputs/Input';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { useForm, useFormState } from 'utils/hooks';
import { stringField } from 'common/utils/form/fieldTypes';

const config = { name: stringField({ required: true }) };

const Form = ({ drawer, active, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, collectValues, loadValues, hasErrors, resetForm } = useForm(config);
  const { fields, changeField } = useFormState(formState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (drawer.isOpen) {
      resetForm();

      if (active?.id) {
        loadValues(active);
      }
    }
  }, [active, drawer.isOpen, loadValues, resetForm]);

  const onClose = () => {
    resetForm();
    drawer.close();
  };

  const onSubmit = async () => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSubmitting(true);

      active?.id
        ? await dispatch(editMaintenanceJobType({ id: active?.id, ...values })).unwrap()
        : await dispatch(createMaintenanceJobType(values)).unwrap();

      setIsSubmitting(false);
      refetchData();
      onClose();
    } catch (err) {
      setIsSubmitting(false);

      console.error(err);
    }
  };

  return (
    <Drawer {...drawer} className="spare-parts-drawer">
      <DrawerHeader>{active?.id ? 'Edit' : 'Create new'} Job Type</DrawerHeader>

      <FormDrawer>
        <FormBody>
          <Input
            className={`mb-0 w-100p`}
            placeholder="Add type"
            label="Type"
            onChange={changeField('name')}
            {...fields.name}
          />
        </FormBody>
      </FormDrawer>
      <FormFooter>
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={onClose}
          disabled={isSubmitting || hasErrors}
        >
          CANCEL
        </Button>
        <Button onClick={onSubmit} disabled={isSubmitting} color="primary" className="px-4">
          {active?.id ? 'SAVE' : 'CREATE'}
        </Button>
      </FormFooter>
    </Drawer>
  );
};

export default Form;

Form.propTypes = {
  drawer: DrawerState,
  active: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  refetchData: PropTypes.func.isRequired
};
