import { sections } from '@/captain-reports/templates/config/_initialState';

import arrival from '@/captain-reports/assets/svg/arrival.svg';
import ballast from '@/captain-reports/assets/svg/ballast.svg';
import delivery from '@/captain-reports/assets/svg/delivery.svg';
import redelivery from '@/captain-reports/assets/svg/redelivery.svg';
import port_noon from '@/captain-reports/assets/svg/port-noon.svg';
import noon from '@/captain-reports/assets/svg/noon.svg';
import departure from '@/captain-reports/assets/svg/departure.svg';
import stoppage from '@/captain-reports/assets/svg/stoppage.svg';
import port_statement from '@/captain-reports/assets/svg/port-statement.svg';
import instructed_speed from '@/captain-reports/assets/svg/instructed-speed.svg';
import system_running_hours from '@/captain-reports/assets/svg/running-hours.svg';
import port_document_overview from '@/captain-reports/assets/svg/port-documents-overview.svg';
import passage_plan from '@/captain-reports/assets/svg/passage-plan.svg';
import approved from 'captain-reports/assets/svg/report-status/approved.svg';
import draft from 'common/assets/svg/common/draft.svg';
import pending from 'captain-reports/assets/svg/report-status/pending.svg';
import reopened from 'captain-reports/assets/svg/report-status/reopened.svg';

import paths from 'routing/routes/_paths';
import {
  CaptainReportPerType,
  CaptainReportTemplateType,
  ReportStatus
} from '@/common/types/captain-reports';
import { displayUTCDate } from '@/ts-common/utils/dates';

export const leftSide = sections.filter(s => s.side === 'left');

export const rightSide = sections.filter(s => s.side === 'right');

export const constructUrlPath = (
  reportType: CaptainReportTemplateType,
  isPmsRunningHours?: boolean
) => {
  switch (reportType) {
    case 'instructed_speed':
      return `${paths.captain_reports}/instructed-speed`;
    case 'system_running_hours':
      return !isPmsRunningHours
        ? `${paths.captain_reports}/system-running-hours`
        : `${paths.pms_running_hours}`;
    case 'port_document_overview':
      return `${paths.captain_reports}/port-document-overview`;
    case 'passage_plan':
      return `${paths.captain_reports}/passage-plan`;
    default:
      return `${paths.captain_reports}`;
  }
};

export const captainReportIcon = {
  arrival,
  ballast,
  delivery,
  redelivery,
  noon,
  departure,
  port_noon,
  stoppage,
  port_statement,
  instructed_speed,
  system_running_hours,
  port_document_overview,
  passage_plan
} as const;

export const reportStatusAlias: Record<ReportStatus, string> = {
  approved: 'Approved',
  pending: 'Pending',
  draft: 'Draft',
  rebuild: 'For Correction'
} as const;

export const reportStatusIcon: Record<ReportStatus, string> = {
  approved,
  pending,
  draft,
  rebuild: reopened
} as const;

export const displayCaptainReportPerType = (data: CaptainReportPerType) => {
  const type = data.template.type.split('_').join(' ');

  return `${data.current_port ? `${data.current_port.name} - ` : ''}${displayUTCDate(data.timestamp)}, ${type.charAt(0).toUpperCase() + type.slice(1)}`;
};
