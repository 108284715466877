import { Row, Col } from 'reactstrap';
import Input from 'common/components/form/inputs/Input';

import StyledSelect from 'common/components/form/styled-inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import Checkbox from 'common/components/form/inputs/Checkbox';
import UploadFiles from 'common/components/form/inputs/upload-files';
import {
  Option,
  MultiValueLabel,
  SingleValue
} from 'common/components/selectors/departments/DepartmentSelector';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Textarea from 'common/components/form/inputs/Textarea';

import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import {
  selectSystemFormType,
  selectSystemFormParentId,
  selectSystems
} from 'common/components/pms/setup/store/selectors';
import {
  CrewSingleValue,
  CrewOption
} from 'common/components/selectors/departments/CrewDepartment';
import Information from 'common/components/general/Information';

import SystemGroupSelect from '@/common/components/pms/setup/views/system/specifications/system-details/_components/SystemGroupSelect.tsx';
import CustomMakerOption from 'common/components/pms/setup/views/system/components/CustomMakerOption';
import VesselSystemAttributes from '../_components/VesselSystemAttributes';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';

export const getMakerDefaultOptions = async () => {
  const listOptions = await getInitialAsyncValues('parties', null, null, {
    type: 'company',
    role: 'maker'
  });

  const modifiedListOptions = listOptions?.length
    ? listOptions
    : [{ id: 'create', title: 'create' }];

  return modifiedListOptions;
};

export const loadModifiedOptions = async search => {
  const listOptions = await getAsyncOptions(search, 'parties', {
    type: 'company',
    role: 'maker'
  });

  const modifiedListOptions = listOptions?.length
    ? listOptions
    : [{ id: 'create', title: 'create' }];

  return modifiedListOptions;
};

const SystemForm = ({
  changeField,
  fields,
  selectField,
  setFieldValue,
  system,
  type = 'system',
  formState
}) => {
  const systems = useSelector(selectSystems);
  const formType = useSelector(selectSystemFormType);
  const formParentId = useSelector(selectSystemFormParentId);
  const groupsIsVisible = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const parentSystem = system ? systems[system.parent_id] : systems[formParentId];
  const parentGroupId = _get(parentSystem, 'vessel_system_group_id')
    ? parentSystem.vessel_system_group_id
    : undefined;

  const actualType = formType ? formType : type;

  return (
    <div className="system-form position-relative">
      <Row className="g-0">
        {actualType === 'system' ? null : (
          <Col xs={12} className="d-flex align-items-center mb-2">
            <NumberInput
              placeholder="Add no."
              className="multiplicity-field"
              invisible
              onChange={changeField('multiplicity')}
              label="Multiplicity"
              value={fields.multiplicity.value || ''}
              error={fields.multiplicity.error}
            />
            <div className="fs-12 text-primary cmt-6">Same sub-system(s) on system</div>
          </Col>
        )}
        <Col xs={12}>
          <Input
            placeholder="Add description"
            invisible
            className="mb-2"
            onChange={changeField('description')}
            label="Description"
            {...fields.description}
          />
        </Col>

        {groupsIsVisible && (
          <Col xs={12}>
            <SystemGroupSelect
              onChange={value => selectField('vessel_system_group_id')(value)}
              parentGroupId={parentGroupId}
              {...fields.vessel_system_group_id}
            />
          </Col>
        )}

        <Col xs={4} className="pe-3">
          <StyledSelect
            showLabel
            label={
              <div className="d-flex align-items-center">
                <div className="lh-14">Maker</div>
                <Information
                  svgStyle={{ width: 12, height: 12 }}
                  className="cms-12 text-primary"
                  tooltipClassname="text-start max-width-344"
                  message='Available options come from Menu > Contacts > Companies page, where Company`s Type is "Maker”.'
                />
              </div>
            }
            placeholder="Select maker"
            onChange={selected =>
              selected?.id === 'create'
                ? window.open('/companies/create')
                : setFieldValue('maker_id', selected)
            }
            defaultOptions={getMakerDefaultOptions}
            loadOptions={loadModifiedOptions}
            isAsync
            isClearable
            getOptionValue={option => option.id}
            getOptionLabel={option => option.full_name}
            components={{ Option: CustomMakerOption }}
            {...fields.maker_id}
          />
        </Col>
        <Col xs={4} className="pe-3">
          <Input
            placeholder="Add type"
            invisible
            className="mb-2"
            onChange={changeField('type')}
            label="Type"
            {...fields.type}
          />
        </Col>
        <Col xs={4}>
          <StyledSelect
            showLabel
            label={
              <div className="d-flex align-items-center">
                <div className="lh-14">Licensee</div>
                <Information
                  svgStyle={{ width: 12, height: 12 }}
                  className="cms-12 text-primary"
                  tooltipClassname="text-start max-width-344"
                  message='Available options come from Menu > Contacts > Companies page, where Company`s Type is "Maker”.'
                />
              </div>
            }
            placeholder="Select licensee"
            onChange={selected =>
              selected?.id === 'create'
                ? window.open('/companies/create')
                : setFieldValue('licensee_id', selected)
            }
            defaultOptions={getMakerDefaultOptions}
            loadOptions={loadModifiedOptions}
            isAsync
            isClearable
            getOptionValue={option => option.id}
            getOptionLabel={option => option.full_name}
            className="mb-2"
            components={{ Option: CustomMakerOption }}
            {...fields.licensee_id}
          />
        </Col>

        <Col xs={12}>
          <Row className="g-0">
            <Col xs={'auto'} className="pe-4">
              <Checkbox
                label="Critical"
                value={fields.is_critical.value}
                onChange={e => selectField('is_critical')(e.target.checked)}
                className="mb-2 text-primary"
              />
            </Col>
            <Col xs={'auto'} className="pe-4">
              <Checkbox
                label="Environmental"
                value={fields.is_environmental_critical.value}
                onChange={e => selectField('is_environmental_critical')(e.target.checked)}
                className="mb-2 text-primary"
              />
            </Col>
            <Col xs={'auto'} className="pe-4">
              <Checkbox
                label="Navigational"
                value={fields.is_navigational.value}
                onChange={e => selectField('is_navigational')(e.target.checked)}
                className="mb-2 text-primary"
              />
            </Col>
            {actualType === 'system' ? (
              <Col xs={'auto'}>
                <Checkbox
                  label="Running Hours to be reported"
                  value={fields.report_running_hours.value}
                  onChange={e => selectField('report_running_hours')(e.target.checked)}
                  className="mb-2 text-primary"
                />
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col xs={12} className="d-flex border-top border-white pt-3 mt-1">
          <div className="border-end border-white pe-2 w-13p">
            <div className="text-primary fs-10 cmb-4">MANUAL FILE</div>
            <UploadFiles
              group="vessel-systems"
              files={fields.manual_file_id.value ? [fields.manual_file_id.value] : []}
              onChange={files => {
                selectField('manual_file_id')(files && files.length ? files[0] : null);
              }}
              showFileName={false}
              showFileNameOnHover={false}
              singleUpload={true}
            />
          </div>
          <div className="cps-12 w-15p pe-0">
            <div className="text-primary fs-10 cmb-4">DRAWING FILE</div>
            <UploadFiles
              group="vessel-systems"
              files={fields.drawing_file_id.value ? [fields.drawing_file_id.value] : []}
              onChange={files => {
                selectField('drawing_file_id')(files && files.length ? files[0] : null);
              }}
              showFileName={false}
              showFileNameOnHover={false}
              singleUpload={true}
            />
          </div>
          <div className="px-0 border-end border-white w-12p">
            <div className="text-primary fs-10 cmb-4">DRAWING NO.</div>
            <Input
              placeholder="Add no"
              invisible
              className="mb-2 "
              onChange={changeField('drawing_no')}
              {...fields.drawing_no}
            />
          </div>
          <div className="cps-12 w-18p border-end border-white">
            <div className="text-primary fs-10 cmb-4">BUDGETARY CODE</div>
            <Input
              placeholder="Add no"
              invisible
              className="mb-2 "
              onChange={changeField('budgetary_code')}
              {...fields.budgetary_code}
            />
          </div>
          <div className="w-20p cps-12">
            <StyledSelect
              showLabel
              label="Vessel Crew Dept"
              placeholder="Select department"
              onChange={selectField('crew_department_id')}
              defaultOptions={() => getInitialAsyncValues('crew-departments')}
              loadOptions={search => getAsyncOptions(search, 'crew-departments')}
              isAsync
              isClearable
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              components={{
                Option: CrewOption,
                SingleValue: CrewSingleValue
              }}
              {...fields.crew_department_id}
            />
          </div>
          <div className="w-20p ps-2">
            <StyledSelect
              showLabel
              label="Office Department"
              placeholder="Select department"
              onChange={selectField('department_id')}
              defaultOptions={() => getInitialAsyncValues('departments')}
              loadOptions={search => getAsyncOptions(search, 'departments')}
              isAsync
              isClearable
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              components={{
                Option,
                MultiValueLabel,
                SingleValue
              }}
              {...fields.department_id}
            />
          </div>
        </Col>

        <Col xs={12} className="mt-1">
          <Textarea
            autoResize
            className="mb-0"
            label={'Notes'}
            invisible
            rows={1}
            name="Notes"
            placeholder="Add some notes"
            onChange={changeField('notes')}
            value={fields.notes.value}
          />
        </Col>

        <Col xs={12} className="border-top border-white pt-3 mt-2">
          <VesselSystemAttributes isViewMode={false} formState={formState} />
        </Col>
      </Row>
    </div>
  );
};

export default SystemForm;
