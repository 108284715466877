import _isString from 'lodash/isString';

export const NOT_ONLY_WHITESPACE_REGEX = /[\s\S]*\S[\s\S]*/;

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailError = field => {
  return EMAIL_REGEX.test(field.value.toLowerCase()) ? null : 'Invalid email address';
};

export const isEmail = email => {
  return EMAIL_REGEX.test(email);
};

export const REQUIRED_FIELD_MESSAGE = 'Required';

export const INVALID_FIELD_MESSAGE = 'Invalid';

export const hasRequiredValidation = error =>
  error && _isString(error)
    ? error.toLowerCase().includes(REQUIRED_FIELD_MESSAGE.toLowerCase())
    : error;
