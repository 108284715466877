import { useState, useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';
import TreeTitle from 'common/components/tree-layout/main/TreeTitle';
import {
  selectGroup,
  selectActiveGroup,
  selectGroupSubGroups,
  selectPmsLibraryIsUsedInCategories,
  selectIsGroupOpen
} from 'common/components/pms/setup/store/selectors';
import { selectAccount } from '@/store/account/selectors';
import { useActions } from 'utils/hooks';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import GroupFormModal from 'common/components/pms/setup/views/components/modals/GroupFormModal';
import PmsModal from 'common/components/pms/setup/views/components/modals/PmsModal';
import SubGroup from './SubGroup';
import useRouter from 'use-react-router';
import CategoryAssignment from 'common/components/purchasing/library-setup/views/components/CategoryAssignment';

import permissions from '@/common/utils/permissions/constants';
import { isAuthorized } from '@/utils/permissions/authorize';
import { getVesselRouteForLibrarySetup } from '@/views/pms/library/_components/tabsConfig';

const Group = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selected, setSelected] = useState(null);
  const account = useSelector(selectAccount);

  const { history, match } = useRouter();

  //delete modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const group = useSelector(state => selectGroup(state, id));
  const isOpen = useSelector(state => selectIsGroupOpen(state, id));
  const subGroups = useSelector(state => selectGroupSubGroups(state, id));

  const isPmsLibraryUsedInCategories = useSelector(selectPmsLibraryIsUsedInCategories);

  const activeGroup = useSelector(selectActiveGroup);

  const [
    setActiveGroup,
    toggleGroup,
    getSystemGroups,
    createSystemGroup,
    updateSystemGroup,
    deleteSystemGroup,
    setSelectedSystem,
    setSelectedTab
  ] = useActions([
    pmsActions.setActiveGroup,
    pmsActions.toggleGroup,
    pmsActions.getSystemGroups,
    pmsActions.createSystemGroup,
    pmsActions.updateSystemGroup,
    pmsActions.deleteSystemGroup,
    pmsActions.setSelectedSystem,
    pmsActions.setSelectedTab
  ]);

  const onClick = () => {
    if (!isPmsLibraryUsedInCategories) {
      setSelectedSystem(null);
      setSelectedTab(null);
      setActiveGroup(id);

      history.push(getVesselRouteForLibrarySetup(`groups/${group.id}`, match.params.vessel_id));
    } else {
      setActiveGroup(id);
    }
  };

  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);

  const onExpand = useCallback(() => toggleGroup(id), [id]);

  const onModalSave = async params => {
    try {
      setModalLoading(true);
      if (modalType === 'add') {
        await createSystemGroup({ ...params, parent_id: selected.id });
      } else if (modalType === 'edit') {
        await updateSystemGroup({ ...params, id: selected.id });
      } else if (modalType === 'delete') {
        await deleteSystemGroup({ id: selected.id });
      }

      setIsModalOpen(false);
      setModalLoading(false);
      getSystemGroups({ keepOpened: true });
    } catch (e) {
      setModalLoading(false);
    }
  };

  const actions = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: () => {
          setModalType('edit');
          setSelected(group);
          setIsModalOpen(true);
        }
      },
      {
        name: 'Add sub-group',
        onClick: () => {
          setModalType('add');
          setSelected(group);
          setIsModalOpen(true);
        }
      },
      {
        name: 'Delete',
        onClick: () => {
          setModalType('delete');
          setSelected(group);
          setIsDeleteModalOpen(true);
        }
      }
    ];
  }, [group?.id]);

  if (!group) return null;

  return (
    <div>
      <TreeTitle
        isActive={id === activeGroup}
        hasChildren={!!subGroups}
        parent_id={group.parent_id}
        preffix={group?.code ? group.code : ''}
        title={group.name}
        type={group?.type}
        onClick={onClick}
        onExpand={onExpand}
        isOpen={isOpen}
        actions={!isPmsLibraryUsedInCategories ? actions : null}
        rightComponent={
          isPmsLibraryUsedInCategories ? <CategoryAssignment group={group} origin="pms" /> : null
        }
        disabledActions={!isAuthorized(account, [permissions.office.pms.library.edit_groups])}
      />
      {subGroups ? <SubGroup id={id} subGroups={subGroups} /> : null}
      <GroupFormModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSave={onModalSave}
        disabled={modalLoading}
        title={modalType === 'add' ? 'Add Sub-Group' : 'Edit'}
        initialCode={modalType === 'edit' ? selected?.code : ''}
        initialName={modalType === 'edit' ? selected?.name : ''}
        initialType={modalType === 'edit' ? selected?.type : null}
        modalType={modalType}
      />
      <PmsModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        header={`Delete ${selected?.parent_id ? 'Sub-Group' : 'Group'}`}
        description={
          selected ? (
            <div>
              Are you sure you want to delete <strong>{selected.name}</strong>?
              <br /> This cannot be undone.
            </div>
          ) : (
            ''
          )
        }
        onAccept={onModalSave}
      />
    </div>
  );
};

export default Group;
