import Drawer, { DrawerHeader } from 'common/components/drawer';
import Checkbox from 'common/components/form/inputs/Checkbox';
import { Button } from 'reactstrap';
import { FormDrawer } from 'common/components/drawer';
import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import { useForm, useFormState } from 'utils/hooks';
import { useEffect, useState } from 'react';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import { useActions } from 'utils/hooks';
import Input from 'common/components/form/inputs/Input';
import Priority from 'common/components/general/Priority';
import {
  selectSelectedSubAssignment,
  selectVesselId,
  selectVesselSubSystemId,
  selectVesselSystemId
} from 'common/components/pms/setup/store/selectors';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import config from './config';
import Textarea from '@/ts-common/components/form/inputs/Textarea';

const JobsDrawer = ({ drawer, selectedJobForEdit }) => {
  const currentAssignmentsVesselId = useSelector(selectVesselId);
  const currentAssignmentsVesselSystemId = useSelector(selectVesselSystemId);
  const currentSubAssignmentsVesselSystemId = useSelector(selectVesselSubSystemId);
  const selectedSubAssignment = useSelector(selectSelectedSubAssignment);

  const [updateVesselAssignmentPrototype, getVesselAssignments, getVesselSubAssignments] =
    useActions([
      pmsActions.updateVesselAssignmentPrototype,
      pmsActions.getVesselAssignments,
      pmsActions.getVesselSubAssignments
    ]);

  const [isSaving, setIsSaving] = useState(false);
  const { formState, loadValues, collectValues, handleSubmitError } = useForm(config);
  const { fields, selectField, changeField } = useFormState(formState);

  useEffect(() => {
    if (drawer.isOpen) {
      loadValues({
        is_class: selectedJobForEdit?.pivot.is_class,
        cms_reference_code: selectedJobForEdit?.pivot.cms_reference_code,
        comments: selectedJobForEdit?.pivot.comments
      });
    }
  }, [drawer.isOpen]);

  const onSave = async () => {
    const { is_class, cms_reference_code, comments } = collectValues();
    try {
      setIsSaving(true);
      await updateVesselAssignmentPrototype({
        vessel_system_assignment_id: selectedJobForEdit.pivot.vessel_system_assignment_id,
        job_trigger_prototype_id: selectedJobForEdit.metadata.job_trigger_prototype_id,
        is_class: is_class,
        cms_reference_code: !is_class ? '' : cms_reference_code,
        comments
      });

      if (selectedSubAssignment) {
        await getVesselSubAssignments({
          vessel_id: currentAssignmentsVesselId,
          parent_id: currentSubAssignmentsVesselSystemId,
          leaveSelectedAssignment: true
        });
      } else {
        await getVesselAssignments({
          vessel_id: currentAssignmentsVesselId,
          system_id: currentAssignmentsVesselSystemId,
          leaveSelectedAssignment: true
        });
      }

      drawer.close();
      setIsSaving(false);
    } catch (error) {
      handleSubmitError(error);
      setIsSaving(false);
    }
  };

  return (
    <Drawer {...drawer}>
      <DrawerHeader>Edit Job</DrawerHeader>

      <FormDrawer>
        <FormBody>
          <div>
            {selectedJobForEdit ? (
              <div className="d-flex align-items-start border-bottom pb-3 cmt-12">
                <div className="d-flex align-items-center cme-12 cpt-0">
                  <div className="cme-6 cpt-2">
                    <Priority size="small" value={selectedJobForEdit.importance_id} />
                  </div>
                  <div className="text-primary fs-10">{selectedJobForEdit.id}</div>
                </div>
                <div className="d-flex-column cpt-2">
                  <div className="fs-12 text-primary fw-medium">{selectedJobForEdit.title}</div>
                  <div className="fs-12 text-violet fw-medium">
                    <div> {selectedJobForEdit?.maintenance_details?.job_type?.name}</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="text-primary fw-bold fs-12 mb-2 mt-3">
            <Checkbox
              label="Class"
              value={fields.is_class.value}
              onChange={e => selectField('is_class')(!fields.is_class.value)}
              className="mb-3 text-primary"
            />
            {fields.is_class.value === true ? (
              <Row>
                <Col xs={5}>
                  <Input
                    placeholder="Add no."
                    size="sm"
                    value={fields.cms_reference_code.value}
                    label="CMS REFERENCE CODE"
                    onChange={e => selectField('cms_reference_code')(e.target.value)}
                    {...fields.cms_reference_code}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
          <div className="text-primary fw-bold fs-12 mb-2 mt-3">
            <Row>
              <Col xs={12}>
                <Textarea
                  placeholder="Add some comments"
                  label="COMMENTS"
                  onChange={changeField('comments')}
                  rows={7}
                  {...fields.comments}
                />
              </Col>
            </Row>
          </div>
        </FormBody>
        <FormFooter>
          <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
            CANCEL
          </Button>
          <Button color="primary" disabled={isSaving} onClick={onSave} className="px-4">
            SAVE
          </Button>
        </FormFooter>
      </FormDrawer>
    </Drawer>
  );
};

export default JobsDrawer;
