import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import AssignmentTab from '../../components/assignment-tab';

import { runningHoursConfig, systemDetailsConfig } from '../config';

import * as pmsActions from 'common/components/pms/setup/store/actions';

import Jobs from 'common/components/pms/setup/views/system/vessels/jobs';
import { selectAccount } from '@/store/account/selectors';

import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const Vessels = ({
  isSubSystem,
  selectedSystem,
  currentAssignment,
  currentAssignments,
  assignment
}) => {
  const account = useSelector(selectAccount);

  const [getVesselAssignments, deleteVesselAssignment, getVesselSubAssignments, setSelectedTab] =
    useActions([
      pmsActions.getVesselAssignments,
      pmsActions.deleteVesselAssignment,
      pmsActions.getVesselSubAssignments,
      pmsActions.setSelectedTab
    ]);

  const isSelectedAssignmentLast =
    currentAssignments?.length && currentAssignment?.index
      ? currentAssignments.length === currentAssignment.index
      : false;

  const afterSave = () => {
    if (currentAssignment && currentAssignment.id && !currentAssignment.parent_id) {
      getVesselAssignments({
        vessel_id: currentAssignment.vessel_id,
        system_id: currentAssignment.vessel_system_id,
        leaveSelectedAssignment: true
      });
    } else {
      getVesselSubAssignments({
        vessel_id: currentAssignment.vessel_id,
        parent_id: selectedSystem,
        leaveSelectedAssignment: true
      });
    }
  };

  const deleteAction = async () => {
    await deleteVesselAssignment({ id: currentAssignment.id });
    await getVesselAssignments({
      vessel_id: currentAssignment.vessel_id,
      system_id: currentAssignment.vessel_system_id,
      leaveSelectedAssignment: false
    });

    await setSelectedTab(null);
  };

  return (
    <>
      <AssignmentTab
        title={`${isSubSystem ? 'Sub-system' : 'System'} Details`}
        isSubSystem={isSubSystem}
        currentAssignment={currentAssignment}
        config={systemDetailsConfig}
        hideDeleteAction={
          !isSelectedAssignmentLast ||
          !isAuthorized(account, [permissions.office.pms.library.system_assignments.delete])
        }
        deleteAction={deleteAction}
        afterSave={afterSave}
      />
      <AssignmentTab
        title="Running Hours"
        headerLink
        config={runningHoursConfig}
        currentAssignment={currentAssignment}
        afterSave={afterSave}
      />
      <Jobs assignment={assignment} />
    </>
  );
};

export default Vessels;
