import React, { FC, ReactNode, useEffect } from 'react';
import Drawer, { DrawerBody, DrawerHeader } from 'common/components/drawer';
import Timeline from './Timeline';
import TimelineLoader from './TimelineLoader';

import { usePaginatedEntityLogs } from '@/api/logs/queries';
import { useInvalidatePaginatedEntityLogs } from '@/api/logs/mutations';
import { EntityLogsRequestParams, EntityType } from '@/common/types/logs';
import { DrawerStateType } from '@/ts-common/types/drawer';
import { TimelinePointProps } from './TimelinePoint';

type LogsTimelineDrawerType = {
  drawer: DrawerStateType;
  header?: string;
  subheader?: ReactNode;
  pointComponent?: React.ComponentType<TimelinePointProps>;
  entityId: number | string | null | undefined;
  entityType: EntityType | null;
  entityRequestParams?: EntityLogsRequestParams;
  canBeEnabledWithoutEntity?: boolean;
  excludedEntityActions?: string[];
};

const LogsTimelineDrawer: FC<LogsTimelineDrawerType> = ({
  drawer,
  header = 'Logs',
  subheader,
  pointComponent,
  entityId,
  entityType,
  entityRequestParams = {
    filters: [
      {
        name: 'entity_id',
        operation: 'oneOf',
        value: [entityId]
      },
      {
        name: 'entity_type',
        operation: '=',
        value: entityType
      }
    ]
  },
  canBeEnabledWithoutEntity = false,
  excludedEntityActions = []
}) => {
  const { data, isFetching, hasNextPage, isFetchingNextPage, fetchNextPage } =
    usePaginatedEntityLogs(entityRequestParams);
  const { mutate: invalidatePaginatedEntityLogs } = useInvalidatePaginatedEntityLogs();

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (hasNextPage && !isFetchingNextPage && scrollHeight - scrollTop <= clientHeight + 60) {
      fetchNextPage({ cancelRefetch: !drawer.isOpen });
    }
  };

  useEffect(() => {
    if (drawer.isOpen && ((!!entityId && !!entityType) || canBeEnabledWithoutEntity)) {
      fetchNextPage();
    }
  }, [canBeEnabledWithoutEntity, drawer.isOpen, entityId, entityType, fetchNextPage]);

  const logs = data?.pages;

  return (
    <Drawer
      {...drawer}
      close={() => {
        drawer.close();
        invalidatePaginatedEntityLogs(entityRequestParams);
      }}
    >
      <DrawerHeader>
        <div>{header}</div>
        {subheader ? <div className="fw-bold fs-12 cmt-4">{subheader}</div> : null}
      </DrawerHeader>
      <DrawerBody className="py-3" onScroll={onScroll}>
        <Timeline
          entityLogs={
            excludedEntityActions.length
              ? logs?.filter(log => !excludedEntityActions.includes(log.service_action))
              : logs
          }
          pointComponent={pointComponent}
        />
        {isFetchingNextPage || isFetching ? <TimelineLoader /> : null}
      </DrawerBody>
    </Drawer>
  );
};

export default LogsTimelineDrawer;
