import { useQuery } from '@tanstack/react-query';
import { MAINTENANCE_JOB_PROTOTYPES_VESSEL_ASSIGNMENT_ACTIVATIONS } from './query-keys';
import { getMaintenanceJobPrototypesVesselAssignmentActivations } from './api';

export const useGetMaintenanceJobPrototypesVesselAssignmentActivationsQuery = (
  jobTriggerPrototypeId: number | undefined
) => {
  return useQuery({
    enabled: !!jobTriggerPrototypeId,
    queryKey: [MAINTENANCE_JOB_PROTOTYPES_VESSEL_ASSIGNMENT_ACTIVATIONS, jobTriggerPrototypeId],
    queryFn: () => getMaintenanceJobPrototypesVesselAssignmentActivations(jobTriggerPrototypeId)
  });
};
