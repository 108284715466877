import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure
} from '@/ts-common/utils/tree-structure.ts';
import { FieldTypesType, GroupType } from '@/common/types/report-bulder.ts';

import {
  flattenGroupsWithAccounts,
  ReportGroupStateData,
  ReportGroupId
} from '@/store/report-setup/helpers.ts';
import { getBudgetingReportAction } from './actions.ts';

type SubItemsState = Record<ReportGroupId, ReportGroupId[] | undefined>;

type DataState = Record<
  ReportGroupId,
  ReportGroupStateData &
    Pick<GroupType, 'remaining_usd' | 'budgeted_usd' | 'remaining_percent' | 'actual_usd'>
>;

type GroupsType = {
  rootItems: ReportGroupId[];
  subItems: SubItemsState;
  data: DataState;
};

export interface ReportState {
  columns: FieldTypesType[];
  groups: GroupsType;
  isFetching: boolean;
}

type GetReportSetupActionParams = {
  columns: FieldTypesType[];
  data: GroupType[];
};

const INITIAL_STATE: ReportState = {
  columns: [],
  isFetching: false,
  groups: {
    rootItems: [],
    subItems: {},
    data: {}
  }
};

const slice = createSlice({
  name: 'budgeting-report',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBudgetingReportAction.pending, state => {
        state.isFetching = true;

        return state;
      })
      .addCase(getBudgetingReportAction.rejected, state => {
        state.isFetching = false;

        return state;
      })
      .addCase(
        getBudgetingReportAction.fulfilled,
        (state, { payload }: PayloadAction<GetReportSetupActionParams>) => {
          const flattenGroups = flattenGroupsWithAccounts(payload.data);

          state.groups.rootItems = getRootItemsIDs(flattenGroups) as ReportGroupId[];
          state.groups.data = normalizeTreeStructure(flattenGroups) as DataState;
          state.groups.subItems = getSubItems(flattenGroups) as SubItemsState;
          state.columns = payload.columns;
          state.isFetching = false;

          return state;
        }
      )
      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      });
  }
});

export default slice.reducer;
