import React, { useState, useEffect, useCallback } from 'react';
import { config } from 'common/components/layouts/profile-notes/config';
import { useForm, useFormState } from 'utils/hooks';
import Editor from 'common/components/form/inputs/Editor';
import {
  getReviewProcessNotes,
  updateReviewProcessNote,
  createReviewProcessNotes
} from 'common/components/review-process/store/actions';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { NoteState } from 'common/entities/notes/NoteTypes';
import ShadowBox from 'common/components/general/ShadowBox';
import FormSubmitAction from 'common/components/form/FormSubmitAction';
import Tag from '@/common/components/general/Tag.tsx';
import { useAppSelector } from '@/store/hooks';

const Form = ({ closeForm, setActiveNote, activeNote, reviewProcessId }) => {
  const { formState, collectValues, loadValues } = useForm(config, {});
  const { fields, selectField } = useFormState(formState);

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const onCancelForm = () => {
    setActiveNote(null);
    closeForm();
  };

  const handleSubmit = async () => {
    const values = collectValues();
    if (!values) return;

    const { tags, ...rest } = values;

    try {
      setIsSubmitting(true);

      if (activeNote?.id) {
        await dispatch(
          updateReviewProcessNote({
            id: reviewProcessId,
            note_id: activeNote?.id,
            ...values
          })
        );
      } else {
        await dispatch(
          createReviewProcessNotes({ id: reviewProcessId, tags: tags || [], ...rest })
        );
      }

      await dispatch(getReviewProcessNotes({ id: reviewProcessId })).unwrap();

      setIsSubmitting(false);

      onCancelForm();
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const load = useCallback(
    active => {
      loadValues({
        ...active,
        tags: active.tags?.map(t => t.name)
      });
    },
    [loadValues]
  );

  useEffect(() => {
    load(activeNote ?? {});
  }, [activeNote, load]);

  return (
    <ShadowBox className="py-2 px-2 mb-2" color="light-2" flat>
      <div>
        <div className="mb-1">
          <Editor
            className="mb-0"
            onChange={html => selectField('comments')(html)}
            value={fields.comments.value}
            placeholder={'Add some notes'}
            minHeight={'120px'}
            maxHeight={'120px'}
          />
        </div>
        {isOnBoard ? null : (
          <div className="flex-1">
            <Tag
              className="mb-0"
              tags={fields.tags?.value || []}
              type={'note'}
              onChange={value => selectField('tags')(value)}
            />
          </div>
        )}
        <FormSubmitAction
          onCancel={closeForm}
          onSave={handleSubmit}
          canSubmit={fields?.comments?.value && !isSubmitting}
        />
      </div>
    </ShadowBox>
  );
};

Form.propTypes = {
  note: NoteState,
  activeNote: NoteState,
  closeForm: PropTypes.func,
  setActiveNote: PropTypes.func,
  setNotes: PropTypes.func,
  reviewProcessId: PropTypes.number.isRequired
};
export default Form;
