import React, { useState } from 'react';
import { FormState } from 'common/entities/form/FormTypes';
import { useFormState } from 'utils/hooks';
import Editor from 'common/components/form/inputs/Editor';
import CkEditor from 'common/components/form/inputs/ckeditor';
import PropTypes from 'prop-types';
import Tag from '@/common/components/general/Tag.tsx';
import { useAppSelector } from '@/store/hooks';

const NoteEditorWithTags = ({ formState }) => {
  const { fields, selectField } = useFormState(formState);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  return (
    <>
      <Editor
        placeholder="Add some text"
        onChange={selectField('content')}
        minHeight={'200px'}
        maxHeight={'200px'}
        className={`${fields?.content?.error ? 'mb-2' : 'mb-1'}`}
        {...fields.content}
      />
      {isOnBoard ? null : (
        <Tag
          className="mb-0"
          tags={fields.tags?.value || []}
          type={'event_office_note'}
          onChange={value => selectField('tags')(value)}
        />
      )}
    </>
  );
};

const NoteEditorFull = ({ formState }) => {
  const { fields, selectField } = useFormState(formState);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="event-notes__full-editor">
      <CkEditor
        isFocused={isFocused}
        onFocus={focus => (focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100))}
        placeholder="Add some text…"
        minHeight={'703'}
        maxHeight={'703'}
        onChange={selectField('content')}
        {...fields.content}
      />
    </div>
  );
};

const NoteEditor = ({ formState, fullEditor }) =>
  fullEditor ? (
    <NoteEditorFull formState={formState} />
  ) : (
    <NoteEditorWithTags formState={formState} />
  );

NoteEditor.propTypes = {
  formState: FormState,
  fullEditor: PropTypes.bool
};

export default NoteEditor;
