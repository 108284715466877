import { useMemo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import formsIcon from 'common/assets/svg/common/forms.svg';
import checklistIcon from 'common/assets/svg/jobs/checklist.svg';

import * as pmsActions from 'common/components/pms/setup/store/actions';
import { selectCurrentPmsJobActiveTabs } from 'common/components/pms/setup/store/selectors';
import TabsModule from 'common/components/general/TabsModule';

const Tabs = ({ selectField, fields, isOpen }) => {
  const activeTabs = useSelector(selectCurrentPmsJobActiveTabs);

  const [editMaintenanceJobActiveTabs, resetMaintenanceJobActiveTabs] = useActions([
    pmsActions.editMaintenanceJobActiveTabs,
    pmsActions.resetMaintenanceJobActiveTabs
  ]);

  const setActiveJobTab = (tab, isActive) => {
    editMaintenanceJobActiveTabs(tab, isActive);
  };

  useEffect(() => {
    resetMaintenanceJobActiveTabs();
  }, [isOpen]);

  const tabs = useMemo(
    () => [
      // {
      //   label: 'checklist',
      //   icon: checklist,
      //   text: 'Checklist',
      //   error: jobChecklistError
      // },
      { label: 'checklist_options', icon: checklistIcon, name: 'Checklist' },
      { label: 'forms', icon: formsIcon, name: 'Forms' }
    ],
    []
  );

  return (
    <Row className="align-items-center mb-4" noGutters>
      <Col className="pe-1">
        <TabsModule
          tabs={tabs.map(tab => ({
            ...tab,
            active: activeTabs[tab.label],
            disabled: fields[tab.label]?.value?.length
          }))}
          onTabClick={label => setActiveJobTab(label, !activeTabs[label])}
        />
      </Col>
    </Row>
  );
};

export default Tabs;
