import { FC } from 'react';

import { VesselSystemMaintenanceJob } from '@/common/types/vessel-systems';
import SvgRender from '@/ts-common/components/general/SvgRender';
import deactivate from 'common/assets/svg/actions/deactivate.svg';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type DeactivatedVesselsProps = {
  className?: string;
  job: VesselSystemMaintenanceJob;
};

const DeactivatedVessels: FC<DeactivatedVesselsProps> = ({ className = '', job }) => {
  const vesselsLength = job.inactive_vessels?.length || 0;

  const { tooltipID, avoidRender } = useTooltipID('inactive-vessels-maintenance-job');

  if (vesselsLength === 0 || avoidRender) return null;

  return (
    <>
      <div
        id={tooltipID}
        className={`d-flex align-items-center justify-content-center bg-violet border-radius-3 fs-12 cps-4 cpe-6 cpy-2 text-white cursor pointer lh-15 ${className}`}
      >
        <SvgRender src={deactivate} style={{ width: 12, height: 12 }} />
        <span className="cms-6 fw-medium">{vesselsLength}</span>
      </div>

      <Tooltip target={tooltipID} innerClassName="d-flex flex-column align-items-start">
        Deactivated for:
        <br />
        <strong className="text-white">
          {(job.inactive_vessels || []).map(
            (vessel, index) => vessel.name + (index !== vesselsLength - 1 ? ', ' : '')
          )}
        </strong>
      </Tooltip>
    </>
  );
};

export default DeactivatedVessels;
