import { FC } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import hideSvg from 'common/assets/svg/common/hide.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';

const HideButton: FC<ButtonProps> = ({ className = '', ...props }) => {
  return (
    <Button
      type="button"
      color="white"
      className={`h-24 bg-white d-flex align-items-center justify-content-center fs-12 border-radius-5 p-0 min-w-72 max-w-72 ${className}`}
      {...props}
    >
      <SvgRender className="text-dark" src={hideSvg} style={{ width: 15, height: 15 }} />
      <span className="text-dark ms-1">Hide</span>
    </Button>
  );
};

export default HideButton;
