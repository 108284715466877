import { FC, useEffect, useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import { useGetFileSystemTasks } from '@/api/system-tasks/queries';
import SvgRender from '@/ts-common/components/general/SvgRender';
import AsyncFileTaskProgress from './AsyncFileTaskProgress';
import styled from '@emotion/styled';
import arrow from '@/ts-common/assets/svg/arrows/arrow.svg';
import close from '@/ts-common/assets/svg/actions/close.svg';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';

const AsyncDownloadFilesPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const fileSystemTaksQuery = useGetFileSystemTasks();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (fileSystemTaksQuery.data?.length) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [fileSystemTaksQuery.data?.length]);

  if (!fileSystemTaksQuery.data?.length) return null;

  const filesCount = fileSystemTaksQuery.data.length;

  return (
    <DownloadPopup className={`text-primary bg-white fs-12 ${isHidden ? 'd-none' : ''}`}>
      <div className="min-h-48 px-2 d-flex align-items-center">
        <div className="flex-1">
          Downloading&nbsp;<strong>{filesCount}</strong>&nbsp;file{`${filesCount > 1 ? 's' : ''}`}
        </div>

        <Button
          color="link"
          className="p-0 text-primary me-1 d-flex align-items-center"
          onClick={() => setIsOpen(prev => !prev)}
        >
          <SvgRender
            src={arrow}
            style={{ width: 12, height: 12, transform: isOpen ? '' : `rotate(180deg)` }}
          />
        </Button>

        <Button
          color="link"
          className="p-0 text-primary d-flex align-items-center"
          onClick={() => setIsHidden(true)}
        >
          <SvgRender src={close} style={{ width: 10, height: 9 }} />
        </Button>
      </div>

      <Collapse isOpen={isOpen}>
        {fileSystemTaksQuery.data.map(systemTask => (
          <AsyncFileTaskProgress key={systemTask.id} currentTask={systemTask} />
        ))}
      </Collapse>
    </DownloadPopup>
  );
};

const DownloadPopup = styled.div`
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  z-index: ${+variables.modalZIndex - 1};
  width: 268px;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
`;

export default AsyncDownloadFilesPopup;
