import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectReviewProcessId } from '../store/selectors-ts';
import { canUserAddMoreDynamicSteps } from '../store/selectors';
import { getStepIsDynamic, getStepIsMultipleDynamic } from '../entity-actions';
import { useForm, useFormState } from 'utils/hooks';
import {
  booleanField,
  hiddenField,
  optionField,
  stringField
} from '@/common/utils/form/fieldTypes';
import { Step, SubStep } from '@/common/types/review-process';
import { FormState } from '@/common/types/form';
import { Form, FormSubmission } from '@/common/types/form-submissions';
import {
  useAddReviewProcessStepMutation,
  useDeleteReviewProcessStepMutation,
  useEditReviewProcessStepMutation
} from '@/api/review-processes/mutations';
import { SetupReviewProcessStepParams } from '@/api/review-processes/api';
import { DepartmentRole } from '@/common/types/enums';
import { errorHandler } from '@/common/utils/notifications';
import { getErrorResponseMessage } from '@/ts-common/utils/errors';
import { AxiosError, AxiosResponse } from 'axios';

import SubStepForm from './SubStep';
import StepWrapper from '../components/StepWrapper';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';
import _sortBy from 'lodash/sortBy';

const config = {
  id: hiddenField(),
  is_required: booleanField({ initialValue: false }),
  substeps: {
    department_ids: optionField({ initialValue: [] }),
    department_roles: optionField({ initialValue: [] }),
    remarks: stringField(),
    id: hiddenField()
  }
};

type FormConfig = {
  id: number | null;
  is_required: boolean;
  substeps: {
    department_ids: number[];
    department_roles: DepartmentRole[];
    remarks: string;
    id: number | null;
  }[];
};

type ReviewProcessStepProps = {
  step: Omit<Step, 'id'> & { id: number | null; is_configuring_initial_value?: boolean };
  steps: (Omit<Step, 'id'> & { id: number | null; is_configuring_initial_value?: boolean })[];
  index: number;
  entityId: number | string;
  loading: boolean;
  disabled?: boolean;
  onFormSelect: (
    form: Form,
    formSubmission: FormSubmission,
    step_form_id: number,
    sub_step_id: number
  ) => void;
  refetchDrawer: () => void;
  setIsAddingStep: Dispatch<SetStateAction<boolean>>;
};

const ReviewProcessStep: FC<ReviewProcessStepProps> = ({
  step,
  steps,
  index,
  entityId,
  loading,
  disabled = false,
  onFormSelect,
  refetchDrawer,
  setIsAddingStep
}) => {
  const { id, type, substeps, is_required, is_configuring_initial_value } = step;

  const [isConfiguringStep, setIsConfiguringStep] = useState<boolean>(
    is_configuring_initial_value || false
  );
  const [deleteStepPreventionModalIsOpen, setDeleteStepPreventionModalIsOpen] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { mutateAsync: addReviewProcessStepMutation, isPending: isCreating } =
    useAddReviewProcessStepMutation();
  const { mutateAsync: editReviewProcessStepMutation, isPending: isUpdating } =
    useEditReviewProcessStepMutation();
  const { mutateAsync: deleteReviewProcessStepMutation } = useDeleteReviewProcessStepMutation();

  const stepIsDynamic = useMemo(() => getStepIsDynamic(type), [type]);
  const stepIsMultipleDynamic = useMemo(() => getStepIsMultipleDynamic(type), [type]);
  const canAddMoreDynamicSubsteps = useAppSelector(state =>
    canUserAddMoreDynamicSteps(state, entityId)
  );
  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, entityId));

  const { formState, loadValues, collectValues, resetForm } = useForm(config);
  const { subStates, addSubform, removeSubform, selectField, fields } = useFormState(formState);
  const substepsState = subStates('substeps');

  const dynamicStepCanBeConfigured =
    (stepIsDynamic || stepIsMultipleDynamic) && steps[index]?.substeps[0]?.can_edit_reviewer;
  const dynamicStepIsAlreadyConfigured =
    (stepIsDynamic || stepIsMultipleDynamic) &&
    (steps[index]?.substeps[0].departments.length > 0 ||
      steps[index]?.substeps[0].department_roles.length > 0);

  const initFormValues = useCallback(() => {
    loadValues({
      id,
      is_required: is_required,
      substeps: _sortBy(substeps, s => s.id).map(sub => ({
        id: !id ? undefined : sub.id,
        department_ids: sub.departments.map(d => d.id),
        department_roles: sub.department_roles
      }))
    });
  }, [id, is_required, loadValues, substeps]);

  useEffect(() => {
    initFormValues();
  }, [initFormValues]);

  const onSaveStep = async () => {
    const { id, substeps, is_required } = collectValues() as FormConfig;

    const params: SetupReviewProcessStepParams = {
      is_required,
      substeps: substeps.map(s => ({
        department_ids: s.department_ids,
        department_role_ids: s.department_roles.map(r => r.id),
        id: id && s.id ? s.id : undefined
      }))
    };

    try {
      if (id) {
        await editReviewProcessStepMutation({ ...params, step_id: id });
      } else if (reviewProcessId) {
        await addReviewProcessStepMutation({ ...params, id: reviewProcessId });
        setIsAddingStep(false);
      }

      resetForm();
      setIsConfiguringStep(false);
      refetchDrawer();
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(
        errorHandler({ message: getErrorResponseMessage(axiosError.response as AxiosResponse) })
      );
    }
  };

  const onDeleteStep = async () => {
    if (!id) return;

    await deleteReviewProcessStepMutation({ step_id: id });

    resetForm();
    setIsConfiguringStep(false);
    refetchDrawer();
  };

  return (
    <StepWrapper
      index={index}
      id={id}
      substeps={substeps}
      steps={steps}
      collapsable={dynamicStepCanBeConfigured && !dynamicStepIsAlreadyConfigured ? false : true}
      dynamicStepCanBeConfigured={dynamicStepCanBeConfigured}
      dynamicStepIsAlreadyConfigured={dynamicStepIsAlreadyConfigured}
      isDisabled={disabled || loading || isCreating || isUpdating}
      isMultipleDynamic={stepIsMultipleDynamic}
      isConfiguringStep={isConfiguringStep}
      isRequired={fields.is_required.value}
      setIsConfiguringStep={(value: boolean) => {
        if (value === false) initFormValues();
        if (is_configuring_initial_value) setIsAddingStep(false);

        setIsConfiguringStep(value);
      }}
      setIsRequired={() => selectField('is_required')(!fields.is_required.value)}
      onSaveStep={onSaveStep}
      onRemoveStep={() => setDeleteStepPreventionModalIsOpen(true)}
    >
      {substepsState.map((subStepState: FormState<SubStep>, i: number) => (
        <SubStepForm
          key={subStepState.index}
          index={i}
          entityId={entityId}
          subStepState={subStepState}
          subStep={substeps.find(s => s.id === subStepState.state.id.value) || {}}
          isDisabled={disabled || loading || isCreating || isUpdating}
          stepId={id}
          onFormSelect={onFormSelect}
          isDynamic={stepIsDynamic}
          isMultipleDynamic={stepIsMultipleDynamic}
          dynamicStepCanBeConfigured={dynamicStepCanBeConfigured}
          dynamicStepIsAlreadyConfigured={dynamicStepIsAlreadyConfigured}
          isConfiguringStep={isConfiguringStep} // If !can_edit_reviewer, this flag will always be false
          refetchDrawer={refetchDrawer}
          stepIndex={index}
          steps={steps}
          onRemoveSubStep={() => (i !== 0 ? removeSubform('substeps', i) : null)}
        />
      ))}

      <div className="d-flex flex-column align-items-start">
        {((stepIsDynamic && !canAddMoreDynamicSubsteps) || stepIsMultipleDynamic) &&
        isConfiguringStep ? (
          <CircledButton
            type="add"
            label="Add group"
            disabled={loading || disabled || isCreating || isUpdating}
            onClick={() => addSubform('substeps', { department_roles: [], department_ids: [] })}
            svgStyle={{ width: 8, height: 8 }}
            size={15}
            className="fs-12 cmt-4 mb-1 fw-bold text-primary"
          />
        ) : null}
      </div>

      <DangerousActionModal
        type="delete"
        isOpen={deleteStepPreventionModalIsOpen}
        onAccept={onDeleteStep}
        onCancel={() => setDeleteStepPreventionModalIsOpen(false)}
      >
        Are you sure you want to delete this step?
      </DangerousActionModal>
    </StepWrapper>
  );
};

export default ReviewProcessStep;
