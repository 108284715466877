import { RootState } from '@/store';
import { FieldTypesType } from '@/common/types/report-bulder.ts';

type IdType = string | number;

const selectReportSetupReducer = (state: RootState) => state.reportSetup;

export const selectIsFetching = (state: RootState) => selectReportSetupReducer(state).isFetching;

export const selectReportFields = (state: RootState): FieldTypesType[] =>
  selectReportSetupReducer(state).fields;

export const selectReportName = (state: RootState) => selectReportSetupReducer(state).name;

export const selectReportId = (state: RootState) => selectReportSetupReducer(state).id;

export const setIsSubmitting = (state: RootState) => selectReportSetupReducer(state).isSubmitting;

export const selectGroups = (state: RootState) => selectReportSetupReducer(state).groups;

export const selectGroupRootIds = (state: RootState) => selectGroups(state).rootItems;

export const selectGroupSubItems = (state: RootState) => selectGroups(state).subItems;

export const selectGroupSubItemsById = (state: RootState, id: IdType) =>
  selectGroups(state).subItems[id];

export const selectGroupData = (state: RootState) => selectGroups(state).data;

export const selectGroupDataById = (state: RootState, id: IdType) => selectGroups(state).data[id];
