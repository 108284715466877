import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import { selectManualTitle } from 'manuals/store/selectors';

const ManualsList = React.lazy(() => retry(() => import('@/views/manuals/list/index.jsx')));
const ManualInner = React.lazy(() =>
  retry(() => import('@/views/manuals/list/manual-inner/index.jsx'))
);
const ManualsAiSearch = React.lazy(() =>
  retry(() => import('@/views/manuals/list/manual-ai/index.tsx'))
);
const Versioning = React.lazy(() => retry(() => import('@/views/manuals/versioning/index.jsx')));
const VersionsIndex = React.lazy(() =>
  retry(() => import('@/views/manuals/list/versions-index/index.jsx'))
);

const routes = [
  {
    path: `${paths.manuals}`,
    component: ManualsList,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.list.view],
    documentTitle: 'Manuals'
  },
  {
    path: `${paths.manuals}/ai-search`,
    component: ManualsAiSearch,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.ai_search],
    documentTitle: 'Manuals AI Search'
  },
  {
    path: `${paths.manuals}/versions-index`,
    component: VersionsIndex,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.list.view],
    documentTitle: 'Manuals Versions Index'
  },
  {
    path: `${paths.manuals}/versioning`,
    component: Versioning,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.list.view, permissions.office.manuals.versioning.view],
    documentTitle: 'Manuals Versioning'
  },
  {
    path: `${paths.manuals}/:id/versioning`,
    component: Versioning,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.view, permissions.office.manuals.versioning.view],
    documentTitle: 'Manuals Versioning'
  },
  {
    path: `${paths.manuals}/:id/versioning/:version_id`,
    component: Versioning,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.view, permissions.office.manuals.versioning.view],
    documentTitle: 'Manuals Versioning'
  },
  {
    path: `${paths.manuals}/:id/chapter/:chapter_uid`,
    component: ManualInner,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.view],
    documentTitle: selectManualTitle
  },
  {
    path: `${paths.manuals}/:id`,
    component: ManualInner,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.manuals.view],
    documentTitle: selectManualTitle
  }
];

export default routes;
