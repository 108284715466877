import PropTypes from 'prop-types';
import Drawer, { DrawerHeader, DrawerBody } from 'common/components/drawer';
import { Row, Col } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useEffect, useState } from 'react';
import {
  selectBeaconDrawerIsOpen,
  selectBeaconDrawerHasSearch
} from 'store/beacon/sidebar/selectors';
import { toggleBeaconDrawerAction } from 'store/beacon/sidebar/slice';

import * as beaconActions from 'common/components/beacon/store/actions';
import * as sidebarActions from 'store/beacon/sidebar/actions';
import PostContent from './PostContent';
import PostBreadcrumbs from './PostBreadcrumbs';
import Tree from './Tree';
import HeaderSearch from './HeaderSearch';
import SearchResults from './SearchResults';
import { useSelector } from 'react-redux';

const Beacon = ({ computedMatch }) => {
  const [getBeaconMenus, findActiveBeaconPost, toggleBeaconDrawer] = useActions([
    beaconActions.getBeaconMenus,
    sidebarActions.findActiveBeaconPost,
    toggleBeaconDrawerAction
  ]);
  const isOpen = useSelector(selectBeaconDrawerIsOpen);
  const hasSearch = useSelector(selectBeaconDrawerHasSearch);

  useEffect(() => {
    getBeaconMenus();
  }, [getBeaconMenus]);

  useEffect(() => {
    if (isOpen) {
      findActiveBeaconPost({ path: computedMatch?.path, url: computedMatch?.url });
    }
  }, [isOpen, computedMatch?.path, computedMatch?.url, findActiveBeaconPost]);

  if (!document.getElementById('drawer-container')) return null;

  return (
    <Drawer isOpen={isOpen} size="lg" close={() => toggleBeaconDrawer(false)}>
      <DrawerHeader className="py-1">
        <Row className="align-items-center me-n1 g-0">
          <Col xs={3}>
            <PostBreadcrumbs />
          </Col>
          <Col xs={9} className="ms-n1">
            <HeaderSearch />
          </Col>
        </Row>
      </DrawerHeader>

      <DrawerBody className="ps-3 pe-3 overflow-hidden">
        <Row className="cpt-12 h-100p g-0">
          <Col xs={3}>
            <Tree />
          </Col>
          <Col xs={9} className="ps-3">
            {hasSearch ? <SearchResults /> : <PostContent />}
          </Col>
        </Row>
      </DrawerBody>
    </Drawer>
  );
};

Beacon.propTypes = {
  computedMatch: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

const Container = props => {
  const [loading, setLoading] = useState(true);

  // Simulate a loading state for 2 seconds so drawer-container is added to dom
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) return null;

  return <Beacon {...props} />;
};

export default Container;
