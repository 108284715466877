import _get from 'lodash/get';

import isEmpty from 'lodash/isEmpty';
import { getTemplateLabelsParams } from 'common/components/jobs/_base/modules/template/_helpers';
import { dateToUtc, dateToLocal } from 'common/utils/dates';
import { jobEnums } from 'common/utils/fixed';
import { selectIsPms } from 'common/components/pms/jobs/store/selectors';
import store from 'store';
import { selectRestrictedFields } from 'store/jobs-fields/selectors';

export const checkIfFieldVisible = (arr, field) => {
  const found = arr.find(el => el.field === field);
  if (found) {
    return found.visible;
  } else return true;
};

export const parseJobProfile = data => {
  const {
    id,
    title,
    description,
    remarks,
    departments,
    main_department,
    responsible_onboard_department,
    status_id,
    importance_id,
    due_date,
    approvers,
    assignee,
    assignee_crew_rank,
    project,
    is_archived,
    created_at,
    created_by,
    last_due_date_change,
    has_many_due_date_changes,
    attachments,
    due_date_show_time,
    template,
    has_checklist_options,
    has_linked_jobs,
    has_notes,
    notes,
    has_forms,
    tags,
    confidential,
    viewers,
    viewers_count,
    is_watcher,
    port,
    is_for_vessel,
    vessels,
    vessel,
    prototype_id,
    maintenance_job_class,
    due_date_changed_by,
    due_date_changed_at,
    due_date_remarks,
    due_date_previous,
    periodicity,
    periodicity_description,
    type,
    orca_id,
    itinerary_port,
    flag_extension,
    is_drydock,
    is_safety,
    vessel_comments,
    vessel_attachments,
    findings,
    time_spent,
    events,
    resubmitted
  } = data;

  const assignedTo = assignee_crew_rank || assignee;

  if (is_for_vessel && assignedTo) {
    const type = assignee_crew_rank ? 'crew_rank' : assignee?.type || '';

    assignedTo.entity_type = type;
    assignedTo.entity_uid = `${type}-${assignedTo.id}`;
  }

  const job = {
    id,
    title,
    description,
    remarks,
    vessels,
    status_id,
    importance_id,
    assignee: assignedTo,
    project,
    is_archived,
    last_due_date_change,
    has_many_due_date_changes,
    due_date_show_time,
    created_at,
    created_by,
    template,
    has_checklist_options,
    has_linked_jobs,
    has_notes,
    has_forms,
    notes: notes ? notes : [],
    confidential,
    viewers: viewers ? viewers : [],
    viewers_count,
    is_watcher,
    attachments: attachments ? attachments : [],
    approvers: !approvers || (approvers && !approvers.length) ? [{}] : approvers,
    due_date: due_date ? dateToLocal(due_date) : null,
    departments: departments ? departments.map(d => d.id) : [],
    main_department: main_department ? main_department.id : null,
    responsible_onboard_department: responsible_onboard_department
      ? responsible_onboard_department.id
      : null,
    tags: tags ? tags.map(d => d.name) : [],
    port,
    is_for_vessel,
    prototype_id,
    maintenance_job_class,
    due_date_changed_by,
    due_date_changed_at,
    due_date_remarks,
    due_date_previous,
    periodicity,
    periodicity_description,
    type,
    orca_id,
    document_title: title,
    itinerary_port,
    flag_extension,
    is_drydock,
    is_safety,
    vessel_comments,
    vessel_attachments,
    findings,
    time_spent,
    events,
    resubmitted
  };

  if (is_for_vessel && vessel) {
    job.vessels = [vessel];
  } else {
    job.vessels = vessels;
  }

  return job;
};

export const parseJobBeforeSave = params => {
  const {
    vessels,
    importance_id,
    main_department,
    responsible_onboard_department,
    approvers,
    assignee,
    project,
    attachments,
    template,
    due_date,
    maintenance,
    type,
    vessel_attachments,
    ...rest
  } = params;

  const formatted = { type, ...rest };
  const isUnscheduled = type === jobEnums.unscheduled;

  if (vessels !== undefined) {
    formatted.vessels = vessels && Array.isArray(vessels) ? vessels.map(el => el.id) : [];
  }

  if (formatted.is_for_vessel && formatted.vessels) {
    formatted.vessels = vessels.map(vessel => vessel.id);
  }

  if (importance_id !== undefined) {
    formatted.importance_id = importance_id;
  }

  if (due_date !== undefined) {
    formatted.due_date = due_date
      ? dateToUtc(due_date.format('YYYY-MM-DD HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss')
      : null;
  }

  if (approvers !== undefined) {
    formatted.approvers = approvers.filter(a => a.id).map(a => a.id);
  }

  if (assignee !== undefined) {
    if (assignee?.entity_type === 'crew_rank') {
      formatted.assignee_crew_rank_id = assignee?.id;
    } else {
      formatted.assignee_id = assignee?.id;
    }
  }

  if (project !== undefined) {
    formatted.project_id = project && project.id ? project.id : null;
  }

  if (isUnscheduled) {
    formatted.vessel_id = formatted.vessels?.length ? formatted.vessels[0] : null;
    formatted.vessels = undefined;
    formatted.is_for_vessel = true;
  }

  if (attachments !== undefined) {
    formatted.attachments = attachments.map(a => a.id);
  }

  if (vessel_attachments !== undefined) {
    formatted.vessel_attachments = vessel_attachments.map(a => a.id);
  }

  if (template !== undefined) {
    formatted.template_id = template && template.id ? template.id : null;
  }

  if (main_department !== undefined) {
    formatted.main_department_id = main_department ? main_department : null;
  }

  if (responsible_onboard_department !== undefined) {
    formatted.responsible_onboard_department_id = responsible_onboard_department
      ? responsible_onboard_department
      : null;
  }

  if (maintenance !== undefined) {
    const maintenance_attachments = _get(maintenance, 'maintenance_details.attached_manuals');

    formatted.maintenance = {
      attachments: maintenance_attachments ? maintenance_attachments?.map(e => e.id) : undefined,
      vessel_system_assignment_id: _get(
        maintenance,
        'maintenance_details.vessel_system_assignment.id',
        null
      ),
      vessel_system_id: _get(
        maintenance,
        'maintenance_details.vessel_system_assignment.vessel_system_id',
        null
      ),
      job_type_id: _get(maintenance, 'maintenance_details.job_type_id', null),
      carried_out_at: _get(maintenance, 'maintenance_details.carried_out_at', null),
      total_system_running_hours: _get(
        maintenance,
        'maintenance_details.total_system_running_hours',
        null
      ),
      due_on_rh: _get(maintenance, 'maintenance_details.due_on_rh', null)
    };

    if (isUnscheduled) {
      /* 
        When unscheduled, we can edit the required spare parts from Office. 
        The consumed spare parts can only get editted from Vessels so we don't send them as request params at all. 
      */
      formatted.maintenance.spare_parts = _get(maintenance, 'maintenance_spare_parts.required', [])
        ?.filter(part => part?.spare_part)
        ?.map(part => ({
          spare_part_id: _get(part, 'spare_part.id'),
          quantity: _get(part, 'quantity', null)
        }));
    }
  }

  return formatted;
};

export const getNewJobFields = jobState => {
  const isPms = selectIsPms(store.getState());

  const restrictedFields = isPms
    ? selectRestrictedFields(store.getState()).map(f => ({ ...f, visible: true }))
    : selectRestrictedFields(store.getState());

  const {
    title,
    description,
    remarks,
    departments,
    vessels,
    status_id,
    importance_id,
    main_department,
    responsible_onboard_department,
    due_date,
    approvers,
    assignee,
    project,
    attachments,
    linked,
    notes,
    checklist,
    due_date_show_time,
    template,
    templateData,
    tags,
    confidential,
    viewers,
    port,
    is_for_vessel,
    maintenance,
    type,
    jobForms,
    flag_extension,
    itinerary_port,
    is_drydock,
    vessel_comments,
    vessel_attachments,
    time_spent
  } = jobState;

  const restrictedMapping = {
    approvers,
    assignee_id: assignee,
    description,
    remarks,
    project_id: project,
    due_date,
    importance_id,
    status_id,
    tags,
    vessels,
    title
  };

  const isUnscheduled = type === jobEnums.unscheduled;

  //Check which job fields are visible based on Jobs & Findings > Field Setup
  const templateVisibleField = restrictedFields.find(
    el => el.field === 'template_id' && el.visible === true
  );
  const templateLabelsParams = templateVisibleField
    ? getTemplateLabelsParams(template, templateData.labels, templateData.fleetTemplate)
    : {};

  let initialState = {
    departments,
    due_date_show_time,
    main_department,
    responsible_onboard_department,
    attachments,
    project,
    remarks,
    importance_id,
    due_date,
    title,
    status_id,
    confidential,
    is_for_vessel,
    maintenance,
    type,
    is_drydock,
    vessel_comments,
    vessel_attachments,
    viewers: confidential ? viewers.map(v => v.id) : [],
    ...templateLabelsParams,
    linked_jobs: linked.data
      .filter(el => el.job.id)
      .map(el => ({
        id: el.job.id,
        type: el.type
      })),
    form_uids: (jobForms?.forms || []).filter(form => form.uid).map(form => form.uid),
    flag_extension: {
      completed_at: flag_extension?.completed_at,
      informed_at: flag_extension?.informed_at,
      requested_on: flag_extension?.requested_on,
      type_id: flag_extension?.type?.id,
      requested_for: flag_extension?.requested_for,
      country_id: flag_extension?.country?.id || null,
      attachment_ids: (flag_extension?.attachments || [])?.map(e => e.id) || []
    },
    itinerary_port_id: itinerary_port?.id,
    port_id: port?.id,
    notes: (notes || []).map(note => {
      return { comments: note.comments, tags: note.tags?.map(t => t?.name) };
    }),
    time_spent
  };

  const checklistOptionsVisibleField = restrictedFields.find(
    el => el.field === 'checklist_options' && el.visible === true
  );
  if (checklistOptionsVisibleField) {
    initialState.checklist_options = checklist.options
      .filter(ch => ch.title)
      .map(v => ({
        ...v,
        title: v.title,
        id: v.id || undefined
      }));
  }

  //Add the rest visible fields to initialState
  restrictedFields.forEach(f => {
    if (f.visible && restrictedMapping[f.field] && f.field !== 'assignee_id') {
      initialState[f.field] = restrictedMapping[f.field];
    } else if (f.visible && f.field === 'assignee_id') {
      initialState['assignee'] = restrictedMapping[f.field];
    }
  });

  if (isPms && isUnscheduled) {
    initialState.vessels = vessels;
    initialState.type = type;
  }

  const params = parseJobBeforeSave(initialState);

  return params;
};

export const retrieveTypeOfUser = (data, accountId) => {
  let types = {
    approvers: { name: 'approvers', value: false },
    assignee: { name: 'assignee', value: false },
    creator: { name: 'creator', value: false }
  };
  const isApprover = data.approvers?.some(approver => approver.id === accountId);
  const isAssignee = isEmpty(data.assignee) ? false : data.assignee.id === accountId;
  const isCreator =
    data.created_by && data.created_by.id ? data.created_by.id === accountId : false;

  if (isApprover) types.approvers.value = true;
  if (isAssignee) types.assignee.value = true;
  if (isCreator) types.creator.value = true;

  return types;
};

export const getAutoSavedFieldValue = (field, value) => {
  switch (field) {
    case 'country':
      return value?.id || null;
    case 'itinerary_port':
      return value?.id || null;
    case 'port':
      return value?.id || null;
    default:
      return value;
  }
};

export const getAutoSavedFieldKey = field => {
  switch (field) {
    case 'country':
      return 'country_id';
    case 'itinerary_port':
      return 'itinerary_port_id';
    case 'port':
      return 'port_id';
    default:
      return field;
  }
};
