import CkEditor from 'common/components/form/inputs/ckeditor';
import { minimalToolbarConfig } from '@/common/components/form/inputs/ckeditor/config';
import { useState } from 'react';

const TextEditorInput = ({ onChange, value, disabled, error }) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <CkEditor
        placeholder="Add some text"
        onChange={html => onChange(html)}
        customToolbar={minimalToolbarConfig}
        value={value}
        isFocused={isFocused}
        onFocus={focus => (focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100))}
        minHeight={'206px'}
        disabled={disabled}
        error={error}
      />
    </>
  );
};

export default TextEditorInput;
