import React, { useCallback, useState } from 'react';

import NumberInput from '@/ts-common/components/form/inputs/NumberInput.tsx';
import Information from 'common/components/general/Information';

import { selectJobFieldError } from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceJobHasSystemRunningHours,
  selectPreviousMaintenanceDetailsField,
  selectMaintenanceDetaisField
} from 'common/components/pms/jobs/store/selectors';
import { selectJobPeriodicityHasRunningHours } from 'common/components/pms/jobs/store/selectors';
import { Button } from 'reactstrap';
import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';

import edit from 'common/assets/svg/actions/edit.svg';
import SvgRender from 'common/components/general/SvgRender';
import { useActions } from 'utils/hooks';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import RunningHoursDueInputModal from './RunningHoursDueInputModal';
import RunningHoursDueInputTooltip from './RunningHoursDueInputTooltip';
import RunningHoursDueInputPopover from './RunningHoursDueInputPopover';
import { selectPmsJobsRequireTotalSystemRunningHoursWhenReportedInTheSystem } from '@/store/settings/selectors.ts';
import { useAppSelector } from '@/store/hooks';

const RunningHoursDueInput = ({
  isPrevious,
  isDueTypeField,
  isLocked,
  hoursFieldKey,
  hoursTitleField,
  hasTooltip,
  color
}) => {
  const [remarksModalOpen, setRemarksModalOpen] = useState(false);

  const hasSystemRunningHoursFlag = useAppSelector(selectMaintenanceJobHasSystemRunningHours);
  const hasRunningHoursPeriodicity = useAppSelector(selectJobPeriodicityHasRunningHours);
  const shouldShowSystemRunningHoursInput = useAppSelector(
    selectPmsJobsRequireTotalSystemRunningHoursWhenReportedInTheSystem
  );

  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const hoursFieldValue = useAppSelector(state =>
    isPrevious
      ? selectPreviousMaintenanceDetailsField(state, hoursFieldKey)
      : selectMaintenanceDetaisField(state, hoursFieldKey)
  );

  const hoursFieldError = useAppSelector(state => selectJobFieldError(state, hoursFieldKey));

  const [setMaintenanceField] = useActions([maintenanceActions.setMaintenanceField]);

  const updateField = useCallback(
    ({ field, value, autoSave, updateTable }) =>
      setMaintenanceField(field, value, autoSave, updateTable),
    [setMaintenanceField]
  );

  const { avoidRender, tooltipID } = useTooltipID('due-on-rh-id');

  if (
    !hasRunningHoursPeriodicity &&
    !shouldShowSystemRunningHoursInput &&
    hoursFieldKey === 'total_system_running_hours'
  )
    return <div className="height-40 mt-2"></div>;

  return (hasSystemRunningHoursFlag && hoursFieldKey !== 'due_on_rh') ||
    (hasRunningHoursPeriodicity && hoursFieldKey === 'due_on_rh') ? (
    <>
      <div className="d-flex align-items-center fs-10 fw-bold text-primary cmb-4 mt-2">
        <div className={`cme-6 text-${color}`}>{hoursTitleField}</div>
        {hasTooltip ? (
          <Information
            target={'total_system_running_hours'}
            message="Total system running hours when job was carried out"
            svgClassname={`height-12 text-${color}`}
          />
        ) : null}
      </div>
      <div className="d-flex align-items-center align-items-center">
        <div id={tooltipID}>
          <NumberInput
            placeholder="Add value"
            disabled={isOnBoard || (!isOnBoard && !isDueTypeField) ? isLocked : true}
            className="mb-0 min-w-160"
            onAutoSave={value =>
              updateField({
                field: hoursFieldKey,
                autoSave: true,
                updateTable: true,
                value
              })
            }
            value={hoursFieldValue}
            error={hoursFieldError}
            data-testid="pms-running-hours-input"
            isAllowed={({ floatValue }) =>
              floatValue <= 99999999 || !floatValue || floatValue === 0
            }
          />
        </div>

        {isDueTypeField && !isOnBoard ? (
          <>
            <Button
              className="text-primary ms-1 cursor-pointer lh-12 p-0"
              onClick={() => setRemarksModalOpen(true)}
              color="link"
              disabled={isLocked}
              data-testid="pms-running-hours-edit"
            >
              <SvgRender style={{ height: 14, width: 14 }} src={edit} />
            </Button>

            <RunningHoursDueInputPopover />
            <RunningHoursDueInputTooltip avoidRender={avoidRender} tooltipID={tooltipID} />
          </>
        ) : null}
      </div>

      <RunningHoursDueInputModal
        remarksModalOpen={remarksModalOpen}
        setRemarksModalOpen={setRemarksModalOpen}
      />
    </>
  ) : null;
};

export default RunningHoursDueInput;
