import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { useSelector } from 'react-redux';

import NumberInput from 'common/components/form/inputs/NumberInput';
import Input from 'common/components/form/inputs/Input';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';
import DateInput from 'common/components/form/inputs/date';
import Textarea from 'common/components/form/inputs/Textarea';
import Checkbox from 'common/components/form/inputs/Checkbox';
import Tag from '@/common/components/general/Tag.tsx';

// This is DEPRECATED component due to be removed in the future - do not use it.
const FormField = ({
  fieldConfig,
  fieldKey,
  changeField,
  selectField,
  fields,
  setFieldError,
  setFieldValue
}) => {
  const { type, inputProps, list, listParams, onChange, label } = fieldConfig;
  const listOptions = useSelector(state => selectListOptionsFromStore(state, list));

  switch (type) {
    case 'number':
      return (
        // pms pass invisible: true
        <NumberInput
          onChange={changeField(fieldKey)}
          label={label}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'string':
      return (
        <Input
          label={label}
          onChange={changeField(fieldKey)}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'select':
      const SelectComponent = inputProps?.styled ? StyledSelect : Select;
      const listProps = inputProps?.isAsync
        ? {
            loadOptions: search => getAsyncOptions(search, list, { search, ...listParams }),
            defaultOptionsTriggerChange: listParams?.category,
            defaultOptions: () => getInitialAsyncValues(list, null, null, { ...listParams })
          }
        : {
            options: listOptions
          };
      // pms pass styled: true

      return (
        <SelectComponent
          label={label}
          onChange={selectField(fieldKey)}
          getOptionValue={option => option.label}
          getOptionLabel={option => option.name}
          {...listProps}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'date':
      return (
        <DateInput
          label={label}
          onChange={
            onChange ? e => onChange(e, { setFieldError, setFieldValue }) : selectField(fieldKey)
          }
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'date-input':
      // pms change date to date-input
      return (
        <DateInput
          label={label}
          onChange={date => selectField(fieldKey)(date)}
          showTime={inputProps.hasTime}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'tags':
      return (
        <Tag
          tags={fields.issue_auths.value}
          onChange={selectField(fieldKey)}
          type={'form_field'}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'textarea':
      return (
        <Textarea
          label={label}
          onChange={changeField(fieldKey)}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    case 'checkbox':
      return (
        <Checkbox
          label={label}
          onChange={e => selectField(fieldKey)(e.target.checked)}
          {...inputProps}
          {...fields[fieldKey]}
        />
      );

    default:
      return null;
  }
};

export default FormField;
