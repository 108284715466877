import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import moment from 'moment';
import { dateToLocal } from 'common/utils/dates';

const DueDateChange = props => {
  const { properties, isInPms } = props;

  const newDueDate = properties.attributes?.due_date || properties?.new;
  const oldDueDate = properties.old?.due_date || properties?.old;

  return (
    <div className={`d-flex align-items-center ${isInPms ? 'cpt-6' : ''}`}>
      <div
        className={`${isInPms ? 'text-violet' : ''} fs-12 text-violet fw-light`}
        data-testid="job-activity-date-before"
      >
        {oldDueDate ? dateToLocal(moment(oldDueDate), null, 'DD/MM/YYYY') : null}
      </div>
      <SvgRender
        className={`${isInPms ? 'text-violet' : 'text-primary'}`}
        src={arrow}
        style={{ width: 32, height: 17 }}
      />
      <div
        className={`${isInPms ? 'text-violet' : ''} fs-12 text-violet fw-light`}
        data-testid="job-activity-date-after"
      >
        {newDueDate ? dateToLocal(moment(newDueDate), null, 'DD/MM/YYYY') : null}
      </div>
    </div>
  );
};

export default DueDateChange;
