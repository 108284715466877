import { FC, useState } from 'react';

import { Row, Col } from 'reactstrap';

// import UntriggeredIndicator from 'common/components/pms/setup/views/components/UntriggeredIndicator';
import classIcon from '@/common/assets/svg/common/class-color.svg';
import ActivationCirlce from '@/common/components/general/ActivationCirlce';
import { VesselSystemMaintenanceJob } from '@/common/types/vessel-systems';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import SvgRender from '@/ts-common/components/general/SvgRender';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import { useToggleVesselAssignmentJobMutation } from '@/api/vessel-systems/mutations';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import ActivationModal from './components/ActivationModal';
import CommentsPopover from 'common/components/popovers/CommentsPopover';

type JobProps = {
  job: VesselSystemMaintenanceJob;
  onEdit: (job: VesselSystemMaintenanceJob) => void;
  isUntriggered?: boolean;
  vesselId: number;
  vesselAssignmentId: number;
};

const Job: FC<JobProps> = ({ job, onEdit, vesselId, vesselAssignmentId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { avoidRender, tooltipID } = useTooltipID();

  const isActive = job.pivot.active;

  const toggleVesselAssignmentJobMutation = useToggleVesselAssignmentJobMutation({
    isActive,
    systemId: job.maintenance_details.vessel_system_id,
    vesselId: vesselId
  });

  const handleOnAccept = () => {
    toggleVesselAssignmentJobMutation.mutate({
      assignmentId: vesselAssignmentId,
      isActivate: !isActive,
      jobId: job.id
    });

    setIsModalOpen(false);
  };

  if (avoidRender) return null;

  return (
    <>
      <Row className="cmb-4">
        <Col>
          <Row className="pms-job-row align-items-center  mx-1 justify-content-between">
            <Col className="d-flex align-items-center max-width-4 cme-4">
              {job.pivot.is_class ? (
                <SvgRender src={classIcon} style={{ width: '15px', height: '15px' }} />
              ) : null}
            </Col>
            <Col xs={7} className="d-flex ps-0">
              <TextWithTooltip
                className="text-primary fw-medium fs-12 cursor-pointer max-width-344"
                onClick={() => onEdit(job)}
              >
                {job.title}
              </TextWithTooltip>
            </Col>
            <Col
              xs={4}
              className="d-flex justify-content-start align-items-center text-truncate fs-10 text-violet"
            >
              <div className="d-flex align-items-center">
                <div className="w-32 justify-content-center">
                  <CommentsPopover
                    className="w-24"
                    popoverTitle="Comments"
                    comments={job.pivot.comments || ''}
                    addBtn={false}
                  />
                </div>
                <span className="cme-4">CMS Reference Code:</span>
              </div>
              <TextWithTooltip>
                {/* {isUntriggered ? <UntriggeredIndicator className="me-1" /> : null} */}
                <strong id={tooltipID}>
                  {job.pivot?.cms_reference_code ? job.pivot?.cms_reference_code : '-'}
                </strong>
              </TextWithTooltip>

              <ActivationCirlce
                isActive={isActive}
                onClick={() => setIsModalOpen(true)}
                info={`${isActive ? 'Activated' : 'Deactivated'} job for this vessel assignment`}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={'auto'} className="d-flex align-items-center ps-0">
          <div className="d-flex align-items-center justify-content-end">
            <CircledButton
              size={16}
              type="edit"
              onClick={() => onEdit(job)}
              svgStyle={{ width: 9, height: 9 }}
            />
          </div>
        </Col>
      </Row>
      <ActivationModal
        isOpen={isModalOpen}
        isActive={isActive}
        onAccept={handleOnAccept}
        onCancel={() => setIsModalOpen(false)}
        jobTitle={job.title}
      />
    </>
  );
};

export default Job;
