import { SystemTask } from '@/types/system-tasks';
import { FC, useEffect, useMemo, useState } from 'react';
import { Progress } from 'reactstrap';
import { useGetFileSystemTasks, useGetSystemTask } from '@/api/system-tasks/queries';
import {
  calculateAsyncSystemTaskProgressPercentage,
  getAsyncSystemTaskHasFailed,
  getAsyncSystemTaskIsCompleted,
  getSystemTaskIsCompleted
} from '@/api/system-tasks/helpers';
import { useAppDispatch } from '@/store/hooks';
import { errorHandler } from '@/common/utils/notifications';
import { useUpdateAsyncSystemTasks } from '@/api/system-tasks/mutations';
import { handleFileDownload } from '@/ts-common/utils/download';
import SvgRender from '@/ts-common/components/general/SvgRender';
import styled from '@emotion/styled';
import check from '@/common/assets/svg/common/check-circle.svg';

type AsyncFileTaskProgressProps = {
  currentTask: SystemTask;
};

const AsyncFileTaskProgress: FC<AsyncFileTaskProgressProps> = ({ currentTask }) => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const dispatch = useAppDispatch();

  const isCompleted = useMemo(() => getAsyncSystemTaskIsCompleted(currentTask), [currentTask]);

  const progress = calculateAsyncSystemTaskProgressPercentage(
    currentTask.event,
    currentTask.progress
  );

  const fallbackSystemTaskQuery = useGetSystemTask({
    id: currentTask.id,
    enabled: !isCompleted && !isDownloaded,
    refetchInterval: 60000,
    refetchIntervalInBackground: true
  });
  const { mutate } = useUpdateAsyncSystemTasks();
  const { refetch } = useGetFileSystemTasks();

  const hasFailed = useMemo(
    () =>
      getAsyncSystemTaskHasFailed(currentTask) ||
      getAsyncSystemTaskHasFailed(fallbackSystemTaskQuery.data),
    [currentTask, fallbackSystemTaskQuery.data]
  );

  useEffect(() => {
    if (
      fallbackSystemTaskQuery.isSuccess &&
      fallbackSystemTaskQuery.data &&
      getSystemTaskIsCompleted(fallbackSystemTaskQuery.data) &&
      !isCompleted
    ) {
      mutate({
        event: 'async_action.completed',
        data: { action_id: fallbackSystemTaskQuery.data.id }
      });
    }
  }, [isCompleted, fallbackSystemTaskQuery.data, fallbackSystemTaskQuery.isSuccess, mutate]);

  useEffect(() => {
    if (isCompleted && !isDownloaded) {
      handleFileDownload({ url: `/system-tasks/${currentTask.id}/result` });
      setIsDownloaded(true);
      mutate({ event: 'async_action.completed', data: { action_id: currentTask.id } });

      setTimeout(() => refetch(), 2000);
    }
  }, [currentTask.id, isCompleted, isDownloaded, mutate, refetch]);

  useEffect(() => {
    if (hasFailed) {
      dispatch(
        errorHandler({
          message: currentTask.error ?? 'Download unsuccessful. Something went wrong.'
        })
      );
      refetch();
    }
  }, [currentTask.error, hasFailed, dispatch, refetch]);

  return (
    <File className="px-2 min-h-48 d-flex align-items-center">
      <div className="flex-1 fw-black d-flex flex-column">
        <div className="cmb-6 text-capitalize">{currentTask.type.replace(/[.-]/g, ' ')}</div>
        <div style={{ width: 114 }}>
          <Progress
            color={isCompleted ? 'green' : 'purple'}
            value={isCompleted ? 100 : progress === 0 ? 1 : progress}
            min={0}
            max={100}
            animated
          />
        </div>
      </div>
      {isCompleted ? (
        <SvgRender src={check} style={{ width: 16, height: 16 }} className="text-green" />
      ) : (
        <div className="text-violet fs-10 fw-normal">{progress}%</div>
      )}
    </File>
  );
};

const File = styled.div`
  border-top: 1px solid rgba(239, 239, 239, 1);

  .progress {
    height: 3px;
  }
`;

export default AsyncFileTaskProgress;
