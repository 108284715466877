import { createSelector } from 'reselect';
import { RootState } from '@/store';
import { ReviewProcess } from '@/common/types/review-process';
import { stepTypes } from '../entity-actions';

type Key = string | number;

export type EntityReviewProcess = ReviewProcess & { isEnabled: boolean };

type ReviewProcessStateType = {
  entities: Record<number | string, EntityReviewProcess> | undefined;
  activeEntityId: number | string;
  isDrawerOpen: boolean;
  isFormDrawerOpen: boolean;
  // notes: []
};

const selectReviewProcess = (state: RootState) => state.reviewProcess as ReviewProcessStateType;

export const selectReviewProcessEntities = (state: RootState) =>
  selectReviewProcess(state).entities;

export const selectReviewProcessEntity = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntities(state)?.[entityId];

export const selectReviewProcessTemplateMatchedWithEntity = (state: RootState, id: Key) =>
  selectReviewProcessEntity(state, id)?.template_matches_with_entity;

export const selectReviewProcessIsRejected = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.is_rejected;

export const selectReviewProcessId = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.id;

export const selectReviewProcessSteps = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.steps || [];

export const selectReviewProcessName = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.name;

export const selectReviewProcessCreatedBy = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.created_by?.full_name;

export const selectReviewProcessCreatedAt = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.created_at;

export const selectReviewProcessRestartedBy = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.restarted_by?.full_name;

export const selectReviewProcessRestartedAt = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.restarted_at;

export const selectReviewProcessCanBeRestarted = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.can_restart;

export const selectReviewProcessMultipleDynamicStep = (
  state: RootState,
  entityId: number | string
) => selectReviewProcessEntity(state, entityId)?.multiple_dynamic_step;

export const selectReviewProcessHasConfiguredMultipleDynamicSteps = createSelector(
  selectReviewProcessSteps,
  steps => steps.some(step => step.type === stepTypes.multiple_dynamic)
);

export const selectReviewProcessStepsLength = (state: RootState, entityId: number | string) =>
  selectReviewProcessSteps(state, entityId).length;

export const selectReviewProcessStepsFilledLength = createSelector(
  selectReviewProcessSteps,
  steps =>
    steps
      .map(step => step.substeps.every(subStep => subStep.completed_by))
      .filter(substep => substep).length
);

export const shouldCreateReviewProcess = (state: RootState, entityId: number | string) =>
  selectReviewProcessEntity(state, entityId)?.isEnabled;

export const isReviewProcessCompleted = createSelector(
  selectReviewProcessId,
  selectReviewProcessStepsLength,
  selectReviewProcessStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, steps, stepsCompleted, canCreate) =>
    !!(
      (!reviewProcessId && !canCreate) ||
      (reviewProcessId && steps === stepsCompleted) ||
      (reviewProcessId && !steps)
    )
);

export const reviewProcessHasSomeCompletedSteps = createSelector(
  selectReviewProcessId,
  selectReviewProcessStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, stepsCompleted) => (reviewProcessId && stepsCompleted ? true : false)
);

export const selectReviewProcessMandatorySteps = createSelector(selectReviewProcessSteps, steps =>
  steps.filter(step => step.is_required)
);

export const selectReviewProcessMandatoryStepsLength = createSelector(
  selectReviewProcessSteps,
  steps => steps.filter(step => step.is_required).length
);

export const selectReviewProcessMandatoryStepsFilledLength = createSelector(
  selectReviewProcessMandatorySteps,
  steps =>
    steps
      .map(step => step.substeps.every(subStep => subStep.completed_by))
      .filter(substep => substep)?.length
);

export const isMandatoryStepsCompleted = createSelector(
  selectReviewProcessId,
  selectReviewProcessMandatoryStepsLength,
  selectReviewProcessMandatoryStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, mandatorySteps, mandatoryStepsCompleted, canCreate) =>
    (!reviewProcessId && !canCreate) ||
    (reviewProcessId && mandatorySteps === mandatoryStepsCompleted) ||
    (reviewProcessId && !mandatorySteps)
);

export const selectActiveEntityId = (state: RootState) => selectReviewProcess(state).activeEntityId;

export const selectReviewProcessDrawerIsOpen = (state: RootState) =>
  selectReviewProcess(state).isDrawerOpen;

export const selectReviewProcessFormDrawerIsOpen = (state: RootState) =>
  selectReviewProcess(state).isFormDrawerOpen;
