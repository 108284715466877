import PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';
import { useFormState } from 'utils/hooks';
import Input from 'common/components/form/styled-inputs/Input';
import FormNumberInput from 'common/components/form/inputs/NumberInput';
import UploadFiles from 'common/components/form/inputs/upload-files';
import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import { FormState } from 'common/entities/form/FormTypes';

const TextInput = ({ formState, isLast }) => {
  const { changeField, fields } = useFormState(formState);

  return (
    <Col xs={4} className={!isLast ? 'mb-2' : ''}>
      <Input
        label={fields.name.value}
        placeholder="Add some text"
        onChange={changeField('value')}
        {...fields.value}
      />
    </Col>
  );
};

TextInput.propTypes = {
  formState: FormState.isRequired,
  isLast: PropTypes.bool.isRequired
};

const NumberInput = ({ formState, isLast }) => {
  const { changeField, fields } = useFormState(formState);

  return (
    <Col xs={4} className={!isLast ? 'mb-2' : ''}>
      <FormNumberInput
        invisible
        label={fields.name.value}
        placeholder="Add no."
        onChange={changeField('value')}
        {...fields.value}
      />
    </Col>
  );
};

NumberInput.propTypes = {
  formState: FormState.isRequired,
  isLast: PropTypes.bool.isRequired
};

const AttachmentInput = ({ formState, isLast }) => {
  const { selectField, fields } = useFormState(formState);

  return (
    <Col xs={4} className={`pt-0 ${!isLast ? 'mb-2' : ''}`}>
      <FieldLabel>{fields.name.value?.toUpperCase()}</FieldLabel>
      <UploadFiles
        group="vessel-systems"
        files={fields.file.value ? [fields.file.value] : []}
        onChange={files => {
          selectField('file')(files && files.length ? files[0] : null);
        }}
        showFileName={false}
        showFileNameOnHover={false}
        singleUpload={true}
      />
    </Col>
  );
};

AttachmentInput.propTypes = {
  formState: FormState.isRequired,
  isLast: PropTypes.bool.isRequired
};

const AttributeInputs = ({ attributeStates }) => {
  return (
    <Row>
      {attributeStates.map((formState, index) => {
        const type = formState.state.type?.value;
        const isLast = attributeStates.length - 1 === index;

        if (type === 'text')
          return <TextInput key={`input-${index}`} isLast={isLast} formState={formState} />;
        if (type === 'image')
          return <AttachmentInput key={`input-${index}`} isLast={isLast} formState={formState} />;
        if (type === 'number')
          return <NumberInput key={`input-${index}`} isLast={isLast} formState={formState} />;
        if (type === 'attachment')
          return <AttachmentInput key={`input-${index}`} isLast={isLast} formState={formState} />;

        return null;
      })}
    </Row>
  );
};

AttributeInputs.propTypes = {
  attributeStates: PropTypes.arrayOf(FormState)
};

export default AttributeInputs;
