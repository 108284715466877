import PersonSelector from '@/common/components/selectors/PersonSelector';
import variables from '@/common/assets/scss/abstracts/_exports.module.scss';
import VesselSystemGroupSelector from '@/common/components/selectors/VesselSystemGroupSelector';

export const VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS = 'vessel_id';

export const styles = {
  expandedWidth: +variables.pmsSingleSystemWidth,
  collapsedWidth: 32,
  gutter: 24
};

export const columns = [
  {
    header: 'Group',
    key: 'vessel_system_group_id',
    sortingKey: 'group',
    type: 'collection',
    component: VesselSystemGroupSelector,
    componentRest: {
      isMulti: true
    },
    sort: true,
    canFilter: true,
    truncate: true,
    minWidth: 285,
    maxWidth: 285
  },
  {
    header: 'Assignment Description',
    key: 'description',
    type: 'string',
    sort: true,
    canFilter: true,
    minWidth: 380,
    maxWidth: 380
  },
  {
    header: 'Maker',
    key: 'maker_id',
    sortingKey: 'maker',
    type: 'collection',
    data_key: 'vessel_system.maker.full_name',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company', role: 'maker' } },
    sort: true,
    canFilter: true,
    truncate: true,
    minWidth: 288,
    maxWidth: 288
  },
  {
    header: 'Type',
    key: 'type',
    type: 'string',
    data_key: 'vessel_system.type',
    sort: true,
    canFilter: true,
    truncate: true,
    minWidth: 288,
    maxWidth: 288
  },
  {
    header: 'Licensee',
    key: 'licensee_id',
    sortingKey: 'licensee',
    type: 'collection',
    data_key: 'vessel_system.licensee.full_name',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company', role: 'maker' } },
    sort: true,
    canFilter: true,
    truncate: true,
    minWidth: 288,
    maxWidth: 288
  },
  {
    header: 'Serial No.',
    key: 'serial_no',
    type: 'string',
    data_key: 'serial_no',
    sort: true,
    canFilter: true,
    truncate: true,
    minWidth: 230,
    maxWidth: 230
  }
];

export const nestedColumns = [
  {
    header: 'Description',
    key: 'vessel_system.description',
    className: 'ps-4',
    truncate: true,
    width: 4,
    sort: true
  },
  {
    header: 'Drawing No.',
    key: 'vessel_system.drawing_no',
    truncate: true,
    width: 2,
    sort: true
  },
  // {
  //   header: 'Multiplicity',
  //   key: 'multiplicity',
  //   data_key: 'vessel_system.multiplicity',
  //   truncate: true,
  //   width: 2
  // },
  {
    header: 'Maker',
    key: 'vessel_system.maker.full_name',
    data_key: 'vessel_system.maker.full_name',
    truncate: true,
    width: 3,
    sort: true
  },
  {
    header: 'Type',
    key: 'vessel_system.type',
    data_key: 'vessel_system.type',
    truncate: true,
    width: 3,
    sort: true
  }
];
