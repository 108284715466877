import { useQuery, useInfiniteQuery, queryOptions } from '@tanstack/react-query';
import { getEntityLogs, getPaginatedEntityLogs } from './api';
import { ENTITY_LOGS, ENTITY_LOGS_PAGINATED } from './query-keys';
import { EntityLogsPaginatedResponse, EntityLogsRequestParams } from '@/common/types/logs';
import _flatten from 'lodash/flatten';

export const useEntityLogs = (
  options: { enabled: boolean } = { enabled: true },
  params: EntityLogsRequestParams
) => {
  return useQuery(
    queryOptions({
      queryKey: [ENTITY_LOGS, params],
      queryFn: () => getEntityLogs(params),
      retry: false,
      refetchOnMount: true,
      ...options
    })
  );
};

export const usePaginatedEntityLogs = (params: EntityLogsRequestParams) => {
  return useInfiniteQuery({
    queryKey: [ENTITY_LOGS_PAGINATED, params],
    initialPageParam: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: false,
    queryFn: ({ pageParam }) =>
      getPaginatedEntityLogs({ ...params, paging: { current_page: pageParam, per_page: 50 } }),
    getNextPageParam: response => {
      const data = response as EntityLogsPaginatedResponse | undefined;

      return !data
        ? 1
        : data.current_page + 1 <= data.last_page
          ? data.current_page + 1
          : undefined;
    },
    select: data => {
      return {
        pages: _flatten(data.pages.map(p => p.data)),
        pageParams: data.pages
      };
    }
  });
};
