import {
  BunkerSurveyDifferenceApplicableType,
  OrganizationSettings
} from '@/types/organization-settings';
import { getSettings } from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE: OrganizationSettings = {
  accounting_master: null,
  system_currency: null,
  external_email_status: null,
  purchasing_email_status: null,
  purchasing_requisitions_highlight_cheapest: null,
  purchasing_requisitions_highlight_most_expensive: null,
  pms_jobs_require_total_system_running_hours_when_reported_in_the_system: true,
  pms_assign_system_and_subsystem_or_assignment_to_group: null,
  evaluations_range_from: 1,
  evaluations_range_to: 5,
  evaluations_step: 0.5,
  itinerary_edit_scheduled_ports_onboard: false,
  crew_rotation_multiple_excel_export: false,
  crew_ticketing_cases_show_pdf_export: false,
  crew_member_external_id_keys: [],
  external_email_type: null,
  crew_profile_default_view: 'personal_main_info',
  apply_the_bunker_survey_difference_as_correction_to:
    BunkerSurveyDifferenceApplicableType.Departure
};

type GetSettingsPayload = Partial<OrganizationSettings>;

const slice = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSettings.fulfilled, (state, action: PayloadAction<GetSettingsPayload>) => {
      return { ...state, ...action.payload };
    });
  }
});

export default slice.reducer;
