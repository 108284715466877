import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import BasicModal from 'common/components/modals/BasicModal';
import { Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { selectCurrentSystemVessels } from 'common/components/pms/setup/store/selectors';

const AssignToVessel = ({
  isOpen,
  setIsOpen,
  onSave,
  disabled,
  initialCount,
  selectedVesselToEdit,
  setSelectedVesselToEdit,
  title = 'Assign to Vessel'
}) => {
  const [vessel, setVessel] = useState('');
  const [count, setCount] = useState(1);
  const currentSystemVessels = useSelector(selectCurrentSystemVessels);

  useEffect(() => {
    if (isOpen) {
      setVessel(selectedVesselToEdit ? selectedVesselToEdit : '');
      setCount(initialCount ? initialCount : 1);
    }
  }, [isOpen]);

  const onCreate = () => {
    if (vessel && count) {
      onSave({ vessel_id: vessel.id, count });
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setSelectedVesselToEdit(null);
  };

  return (
    <BasicModal
      isOpen={isOpen}
      toggle={toggle}
      header={
        <div>
          <div>{title}</div>
        </div>
      }
      body={
        <Row>
          <Col xs={12}>
            <Select
              disabled={selectedVesselToEdit}
              label="VESSEL"
              placeholder="Select vessel"
              value={vessel}
              onChange={setVessel}
              loadOptions={search => getAsyncOptions(search, 'vessels')}
              defaultOptions={() => getInitialAsyncValues('vessels')}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              noOptionsMessage={({ inputValue }) =>
                !inputValue.length ? `Search for vessels` : `No vessels found`
              }
              filterOption={option => !currentSystemVessels.find(v => v.id === option.value)}
              isAsync
            />
          </Col>
          <Col xs={12} className="mt-1">
            <Row className="d-flex align-items-center">
              <Col xs={3}>
                <NumberInput
                  onChange={e => setCount(e.target.value)}
                  className="mb-0"
                  placeholder="Vessel count"
                  value={count}
                />
              </Col>
              <Col className="fw-bold fs-12 text-primary" xs={6}>
                Same system(s) on vessel
              </Col>
            </Row>
          </Col>
        </Row>
      }
      footer={
        <div className="mt-0 d-flex align-items-center justify-content-between w-100p">
          <div>
            <Button color="cancel" onClick={toggle}>
              CANCEL
            </Button>
            <Button color="primary" disabled={disabled || !vessel || !count} onClick={onCreate}>
              ASSIGN
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default AssignToVessel;
