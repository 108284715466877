import { useQuery } from '@tanstack/react-query';
import { getTagsSettings } from './api';

export const useGetTagSettings = () => {
  return useQuery({
    queryKey: ['/tags/settings'],
    enabled: true,
    queryFn: getTagsSettings
  });
};
