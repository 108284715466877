import { get, put } from '@/api';

type MaintenanceJobClass = {
  active: boolean;
  vessel_system_assignment_id: number;
  job_trigger_prototype_id: number;
};

export type VesselAssignmentActivation = {
  id: number;
  vessel_system_id: number;
  assignment_identifier: string | null;
  system_index: number;
  parent: { id: number; index: number };
  vessel_name: string;
  maintenance_job_classes: MaintenanceJobClass[];
};

export type UpdateMaintenanceJobPrototypesVesselAssignmentActivationsParams = {
  active: boolean;
  vessel_system_assignment_id: number;
  job_trigger_prototype_id: number;
};

export const getMaintenanceJobPrototypesVesselAssignmentActivations = async (
  id: number | undefined
) => {
  if (!id) return;

  const response = await get<VesselAssignmentActivation[]>(
    `/vessel-systems/maintenance-job-prototypes/${id}/assignment-activations`
  );

  return response.data;
};

export const updateMaintenanceJobPrototypesVesselAssignmentActivations = async (
  params: UpdateMaintenanceJobPrototypesVesselAssignmentActivationsParams
) => {
  const { vessel_system_assignment_id, job_trigger_prototype_id, ...rest } = params;

  const response = await put(
    `vessel-systems/assignments/${vessel_system_assignment_id}/job-prototypes/${job_trigger_prototype_id}/class`,
    rest
  );

  return response.data;
};
