import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SYSTEM_TASKS } from '@/api/system-tasks/query-keys.ts';
import { SYSTEM_TASK_TYPE, SystemTask } from '@/types/system-tasks';
import { checkAsyncFileTaskIsAlreadyInProgress } from '@/api/system-tasks/helpers';
import { getPmsSparePartsInventoryExcelExport } from './api';

export const useGetPmsSparePartsExcelExportMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: getPmsSparePartsInventoryExcelExport,
    retry: false,
    onMutate: () => {
      const pendingTaks = queryClient.getQueryData([SYSTEM_TASKS]) as SystemTask[] | undefined;

      checkAsyncFileTaskIsAlreadyInProgress(
        pendingTaks,
        SYSTEM_TASK_TYPE.EXPORT_INVENTORY_SPARE_PARTS_EXCEL
      );
    },
    onSuccess: data => {
      const triggeredSystemTask: SystemTask = {
        ...data,
        type: SYSTEM_TASK_TYPE.EXPORT_INVENTORY_SPARE_PARTS_EXCEL
      };

      if (data.execution_type === 'sync') triggeredSystemTask.event = 'async_action.completed';

      queryClient.setQueryData([SYSTEM_TASKS], (oldTaks: SystemTask[] | undefined) => [
        ...(oldTaks || []),
        triggeredSystemTask
      ]);
    }
  });
};
