import VesselSystemSelector from 'common/components/selectors/VesselSystemSelector';
import VesselSystemInfo from 'common/components/pms/jobs/components/VesselSystemInfo';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import PersonSelector from 'common/components/selectors/PersonSelector';
import CommentsPopover from 'common/components/popovers/CommentsPopover';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import CheckedButton from 'common/components/buttons/CheckedButton';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';
import { capitalizeFirst } from '@/common/utils/texts';

export const getColumns = ({ groupsIsVisible }) => [
  {
    header: 'System',
    key: 'vessel_system',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 512,
    maxWidth: 512
  },
  {
    header: 'Vessel System',
    key: 'vessel_system_id',
    canFilter: true,
    hidden: true,
    type: 'collection',
    component: VesselSystemSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Vessels Assigned',
    key: 'vessels_assigned',
    type: 'string',
    minWidth: 184,
    maxWidth: 184,
    canFilter: false
  },
  {
    header: 'Drawing No.',
    key: 'drawing_no',
    type: 'string',
    minWidth: 120,
    maxWidth: 120,
    sort: true,
    canFilter: false
  },
  {
    header: 'Drawing File',
    key: 'drawing_file',
    type: 'attachments',
    minWidth: 102,
    maxWidth: 102,
    sort: false,
    canFilter: false,
    className: 'text-center'
  },
  {
    header: 'Position No.',
    key: 'part_position_no',
    type: 'string',
    sort: true,
    minWidth: 112,
    maxWidth: 112
  },
  {
    header: 'Part No.',
    key: 'part_no',
    type: 'string',
    sort: true,
    minWidth: 112,
    maxWidth: 112
  },
  {
    header: 'Unit',
    key: 'part_unit',
    type: 'string',
    sort: true,
    minWidth: 112,
    maxWidth: 112
  },
  {
    header: 'Part Description',
    key: 'description',
    type: 'string',
    sort: true,
    minWidth: 344,
    maxWidth: 344
  },
  {
    header: 'System Group',
    key: 'group',
    type: 'string',
    sort: true,
    canFilter: false,
    hidden: !groupsIsVisible,
    minWidth: 328,
    maxWidth: 328
  },
  {
    header: 'Submaker',
    key: 'submaker',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 264,
    maxWidth: 264
  },
  {
    header: 'Sub-maker',
    key: 'submaker_id',
    type: 'collection',
    canFilter: true,
    hidden: true,
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company', role: 'maker' } }
  },
  {
    header: 'Sub-maker Part no.',
    key: 'submaker_part_no',
    type: 'string',
    sort: true,
    canFilter: true,
    minWidth: 112,
    maxWidth: 112
  },
  {
    header: 'IHM Related',
    key: 'ihm_related',
    type: 'boolean',
    sort: false,
    canFilter: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ],
    minWidth: 90,
    maxWidth: 90,
    className: 'text-center'
  },
  {
    header: 'NOx technical file IMO no.',
    key: 'nox_technical_file_imo_no',
    type: 'string',
    canFilter: true,
    sort: true,
    minWidth: 137,
    maxWidth: 137
  },
  {
    header: 'Minimum required no. of spares onboard',
    key: 'minimum_required_rob',
    type: 'number',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Minimum required no. of spares onboard',
    key: 'minimum_required_rob_desc',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 204,
    maxWidth: 204
  },
  {
    header: '',
    key: 'comments',
    type: 'string',
    minWidth: 92,
    maxWidth: 92
  },
  {
    key: 'attachments_count',
    field: 'attachments',
    minWidth: 102,
    maxWidth: 102,
    className: 'text-start'
  },
  {
    header: 'Min Spares Official/Company',
    key: 'is_rob_requirement_official',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Official', value: 'true' },
      { label: 'Company', value: 'false' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const rows = {
  vessel_system: data => {
    const vesselSystem = data?.vessel_systems?.[0];

    if (!vesselSystem) return '-';

    return (
      <VesselSystemInfo
        vesselSystem={{ ...vesselSystem, description: vesselSystem?.vessel_system_description }}
        attributes={{
          is_critical: vesselSystem?.is_critical,
          is_environmental_critical: vesselSystem?.is_environmental_critical,
          is_navigational: vesselSystem?.is_navigational
        }}
        showGroup={false}
      />
    );
  },
  group: data => {
    const group = data?.group;

    if (!group) return '-';

    return (
      <VesselSystemInfo vesselSystem={{ ...group, description: group?.name }} showGroup={false} />
    );
  },
  vessels_assigned: data => (
    <NamesInTooltip names={(data?.vessel_systems?.[0]?.vessels || []).map(v => v?.name)} />
  ),
  submaker: data =>
    data?.submaker?.full_name ? (
      <TextWithTooltip className="h-auto">{data.submaker.full_name}</TextWithTooltip>
    ) : (
      '-'
    ),
  ihm_related: data => (
    <CheckedButton
      isChecked={data?.ihm_related}
      style={{ width: 16, height: 16 }}
      uncheckedIconColor="violet"
      hideUncheckedIcon
    />
  ),
  comments: data => (
    <div className="d-flex align-items-center justify-content-center">
      {data?.comments ? <CommentsPopover comments={data.comments} /> : '-'}
    </div>
  ),
  drawing_no: data =>
    data?.drawing_no ? (
      <TextWithTooltip className="h-auto">{data.drawing_no}</TextWithTooltip>
    ) : (
      '-'
    ),
  minimum_required_rob_desc: data =>
    data?.minimum_required_rob ? (
      <div className="d-flex w-100p text-truncate">
        <div className="cpe-6">{data.minimum_required_rob}</div>
        <div
          className={`${
            data?.is_rob_requirement_official
              ? 'text-violet border-violet'
              : 'text-orange border-orange'
          } cps-6 border-start text-truncate`}
        >
          {data?.is_rob_requirement_official ? 'Official' : 'Company Requirement'}
        </div>
      </div>
    ) : (
      '-'
    ),
  part_no: data =>
    data?.part_no ? <TextWithTooltip className="h-auto">{data.part_no}</TextWithTooltip> : '-',
  submaker_part_no: data =>
    data?.submaker_part_no ? (
      <TextWithTooltip className="h-auto">{data.submaker_part_no}</TextWithTooltip>
    ) : (
      '-'
    ),
  description: data =>
    data?.description ? (
      <TextWithTooltip className="h-auto">{data.description}</TextWithTooltip>
    ) : (
      '-'
    ),
  nox_technical_file_imo_no: data =>
    data?.nox_technical_file_imo_no ? (
      <TextWithTooltip className="h-auto">{data.nox_technical_file_imo_no}</TextWithTooltip>
    ) : (
      '-'
    ),
  drawing_file: data => (
    <UploadFiles
      group="spare-parts"
      files={data?.drawing_file ? [data?.drawing_file] : []}
      size="xs"
      viewOnly
    />
  ),
  attachments_count: data => ({
    hasAttachments: data?.attachments?.length > 0,
    data,
    downloadFiles: () => downloadAllFilesOfEntity({ id: data?.id, type: 'spare_part' }),

    canAddAttachments: false
  }),
  part_unit: data => capitalizeFirst(data.part_unit || '')
};
