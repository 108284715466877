import { FC } from 'react';

import SvgRender from '@/ts-common/components/general/SvgRender';
import arrows from 'common/assets/svg/table/sorting.svg';
import arrow from 'assets/svg/common/arrow.svg';
import styled from '@emotion/styled';

type SortingHeaderProps = {
  selectedSorting?: 'asc' | 'desc';
  title: string;
};

const SortingHeader: FC<SortingHeaderProps> = ({ selectedSorting, title }) => {
  return (
    <div>
      <HeaderContainer hasSorting={!!selectedSorting} className="d-flex w-fit border-radius-3">
        {title}
        <SvgRender
          src={selectedSorting ? arrow : arrows}
          style={{
            height: 14,
            width: 10,
            transform: selectedSorting === 'asc' ? 'rotate(180deg)' : 'none'
          }}
          className={`ms-1 sorting-arrows cursor-pointer ${
            selectedSorting === 'asc' ? 'cmb-4' : selectedSorting === 'desc' ? 'cmt-4' : ''
          }`}
        />
      </HeaderContainer>
    </div>
  );
};

export default SortingHeader;

const HeaderContainer = styled.div(
  ({ hasSorting }: { hasSorting: boolean }) => `
  background-color: ${hasSorting ? 'rgba(57, 98, 141, 0.1)' : 'transparent'}
`
);
