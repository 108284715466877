import { RootState } from '@/store';
import { ReduxTableLabelKeyType } from '@/store/tables/lists/keys';
import { TablesStateType } from '@/store/tables/reducer';
import { createSelector } from 'reselect';

export const selectTableReducer = (state: RootState) => state.tables as TablesStateType;

export const selectTableListsReducer = (state: RootState) => selectTableReducer(state).lists;

export const selectTableList = (state: RootState, label: ReduxTableLabelKeyType) =>
  selectTableListsReducer(state)[label];

export const selectTableListInvalid = (state: RootState, label: ReduxTableLabelKeyType) =>
  selectTableList(state, label)?.invalid;

export const selectTableListSelectedRows = (state: RootState, label: ReduxTableLabelKeyType) =>
  selectTableList(state, label)?.selectedRows;

export const selectTableListSelectedRowsCount = createSelector(
  [selectTableListSelectedRows],
  selectedRows => selectedRows?.length
);

export const selectTableListData = createSelector([selectTableList], list => list?.data || []);

export const selectTableListDataIds = createSelector(
  selectTableListData,
  data => data.map(item => item.id) || []
);

export const selectHasTableFilters = (state: RootState) =>
  selectTableReducer(state).hasSavedTableFilters;

export const selectTableListPaging = (state: RootState, label: ReduxTableLabelKeyType) =>
  selectTableList(state, label)?.paging || {};

export const selectTableListHasPendingRequest = createSelector(
  [selectTableList],
  list => list?.pendingRequest
);

export const selectTableListIsEmpty = createSelector(
  [selectTableListData, selectTableListHasPendingRequest],
  (data, pendingRequest) => (pendingRequest ? false : !data.length)
);
