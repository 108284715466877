import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const ITDashboard = React.lazy(() => retry(() => import('@/views/it/dashboard/index.jsx')));
const ITIntegrationLogs = React.lazy(() =>
  retry(() => import('@/views/it/integration-logs/index.tsx'))
);

const itDashboard = [
  {
    path: paths.it_dashboard,
    component: ITDashboard,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'IT Dashboard',
    permissions: [permissions.office.it.dashboard.view]
  },
  {
    path: `${paths.it_integration_logs}/:report?`,
    component: ITIntegrationLogs,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'IT Integration Logs',
    permissions: [permissions.office.it.integration_logs.view]
  }
];

export default itDashboard;
