import { useState, useEffect } from 'react';
import CkEditor from 'common/components/form/inputs/ckeditor';
import Switch from 'common/components/form/inputs/Switch';

import InputSaveActions from './InputSaveActions';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';

import { useActions, usePrevious } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectJobId,
  selectIsA4Layout,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as jobRestrictedFieldsActions from 'store/jobs-fields/actions';

const FieldComponent = ({
  disabled,
  setDescription,
  setHasBeenCanceledOrSaved,
  description,
  isFocused,
  setIsFocused,
  showA4Layout,
  ...rest
}) => {
  const [setJobField, setJobFieldError, updateJob, checkIfFieldIsRequired, setIsA4Layout] =
    useActions([
      jobProfileActions.setJobField,
      jobProfileActions.setJobFieldError,
      jobProfileActions.updateJob,
      jobRestrictedFieldsActions.checkIfFieldIsRequired,
      jobProfileActions.setIsA4Layout
    ]);
  const isEditMode = useSelector(checkIfEditMode);
  const isA4Layout = useSelector(selectIsA4Layout) && showA4Layout;
  const jobDescriptionError = useSelector(state => selectJobFieldError(state, 'description'));
  const jobDescription = useSelector(state => selectJobField(state, 'description'));
  const isJobLocked = useSelector(selectIsJobLocked);

  const onSave = async () => {
    try {
      await updateJob({ description: jobDescription });
      setDescription(jobDescription);
      setHasBeenCanceledOrSaved(true);
      setTimeout(() => setIsFocused(false), 600);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="d-flex job-profile__description-title justify-content-between align-items-center">
        <span className="form-label">DESCRIPTION</span>
        {showA4Layout ? (
          <div className="d-flex justify-content-end align-items-center">
            <span className="form-label pe-1">A4 LAYOUT</span>
            <Switch
              value={isA4Layout}
              className="mb-0"
              onChange={() => setIsA4Layout(!isA4Layout)}
              size={'xs'}
            />
          </div>
        ) : null}
      </div>
      <div>
        <CkEditor
          isFocused={isFocused && !isJobLocked}
          onFocus={focus =>
            focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100)
          }
          className={`job-description-editor mb-0 ${
            isFocused && !isJobLocked ? '' : 'job-description-editor--hide'
          } ${isA4Layout ? 'mt-1' : ''}`}
          placeholder={isJobLocked || disabled ? null : 'Add some text…'}
          id="job-description"
          isFullEditor={isA4Layout}
          key={isJobLocked || disabled}
          maxHeight={isA4Layout ? '600px' : ''}
          minHeight={isA4Layout ? '' : '32px'}
          onChange={v => {
            setJobFieldError({ description: null });
            setJobField('description', v);
          }}
          value={jobDescription ? jobDescription : ''}
          disabled={disabled || isJobLocked}
          {...rest}
        />

        {!isEditMode ? (
          jobDescriptionError ? (
            <div className="fs-10 text-coral text-end">*{jobDescriptionError}</div>
          ) : null
        ) : null}

        {isEditMode && !isJobLocked ? (
          <InputSaveActions
            onCancel={() => {
              setJobField('description', description);
              setHasBeenCanceledOrSaved(true);
              setTimeout(() => setIsFocused(false), 600);
            }}
            onSave={onSave}
            disabled={!jobDescription && checkIfFieldIsRequired('description')}
          />
        ) : null}
      </div>
    </>
  );
};

const Description = ({ className, avoidCheck = false, showA4Layout = true, disabled }) => {
  const [updateJob] = useActions([jobProfileActions.updateJob]);

  const [isFocused, setIsFocused] = useState(false);
  const [hasBeenCanceledOrSaved, setHasBeenCanceledOrSaved] = useState(false);

  const isJobLocked = useSelector(state => selectIsJobLocked(state, avoidCheck ? false : true));

  const jobDescription = useSelector(state => selectJobField(state, 'description'));
  const isVisible = useSelector(selectIfFieldIsVisible('description'));
  const jobId = useSelector(selectJobId);

  const [description, setDescription] = useState(jobDescription);

  const prevIsFocused = usePrevious(isFocused);

  useEffect(() => {
    const updateJobDescription = async () => {
      try {
        await updateJob({ description: jobDescription });
        setDescription(jobDescription);
      } catch (error) {
        console.error(error);
      }
    };

    if (prevIsFocused && !isFocused && description !== jobDescription && !hasBeenCanceledOrSaved) {
      updateJobDescription();
    }
    setHasBeenCanceledOrSaved(() => false);
  }, [isFocused, description, jobDescription]);

  useEffect(() => {
    if (jobId) {
      setDescription(jobDescription);
    }
  }, [jobId]);

  if (!isVisible) return null;

  return (
    <div
      className={`job-profile__description job-input job-input--with-actions job-input--description ${
        isFocused ? 'is-focused' : ''
      } ${className || ''}`}
    >
      <UserCanEdit
        field="description"
        fallback={() => (
          <FieldComponent
            setDescription={setDescription}
            setHasBeenCanceledOrSaved={setHasBeenCanceledOrSaved}
            description={description}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            showA4Layout={showA4Layout}
            disabled={true}
          />
        )}
        avoidCheck={avoidCheck}
      >
        <FieldComponent
          disabled={isJobLocked || disabled}
          setDescription={setDescription}
          setHasBeenCanceledOrSaved={setHasBeenCanceledOrSaved}
          description={description}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          showA4Layout={showA4Layout}
        />
      </UserCanEdit>
    </div>
  );
};

export default Description;
