import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { ENTITY_LOGS_PAGINATED } from './query-keys';
import { EntityLogsPaginatedResponse, EntityLogsRequestParams } from '@/common/types/logs';

export const useInvalidatePaginatedEntityLogs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: EntityLogsRequestParams) => Promise.resolve(params),
    retry: false,
    onSuccess: async (params: EntityLogsRequestParams) => {
      queryClient.setQueryData(
        [ENTITY_LOGS_PAGINATED, params],
        (oldData: InfiniteData<EntityLogsPaginatedResponse | null, number> | undefined) => {
          if (!oldData) return undefined;

          return {
            pages: [],
            pageParams: []
          };
        }
      );
      await queryClient.invalidateQueries({ queryKey: [ENTITY_LOGS_PAGINATED, params] });
    }
  });
};
