import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectRequisitionTags,
  selectIsTemplateRequisition,
  selectActiveRequisition,
  selectActiveRequisitionIncludeInBudget
} from 'common/components/purchasing/requisition/store/selectors';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ShowRemarksAndAttachmentsButton from './ShowRemarksAndAttachmentsButton';
import ShowLinkedFindingsButton from './ShowLinkedFindingsButton';
import LinkedWithEventButton from './LinkedWithEventButton';
import SvgRender from 'common/components/general/SvgRender';
import budgetIcon from 'common/assets/svg/accounting/budget.svg';
import TopBarTags from './TopBarTags';

const TopBar = ({ className, showRemarksButton = false, isSummary }) => {
  const isTemplate = useSelector(selectIsTemplateRequisition);
  const tags = useSelector(selectRequisitionTags);
  const active = useSelector(selectActiveRequisition);
  const includeInBudget = useSelector(selectActiveRequisitionIncludeInBudget);
  const companyName = active?.company?.name
    ? `${active?.company?.abbreviation ? `${active?.company?.abbreviation} - ` : null}${
        active?.company?.name
      }`
    : null;

  return (
    <div>
      <div className={`d-flex align-items-center w-100p ${className || ''}`}>
        <div className="d-flex align-items-center w-100p rounded bg-shades-2 px-1">
          <div className="d-flex align-items-center text-primary fs-12">
            <div className="cme-4">{active?.vessel?.name ? 'Vessel:' : 'Company: '}</div>

            <TextWithTooltip className="fw-bold h-auto">
              {active?.vessel?.name || companyName || '-'}
            </TextWithTooltip>
          </div>

          {tags?.length && !isSummary ? (
            <div className="d-flex align-items-center text-primary fs-12 max-width-580">
              {!isTemplate ? <div className="small-seperator cpy-6 mx-3 bg-violet"></div> : null}

              <div className="cme-4">Tags:</div>
              <TextWithTooltip className="h-auto fw-bold">
                {(tags || []).map(tag => tag?.name).join(', ')}
              </TextWithTooltip>
            </div>
          ) : null}

          <div className="d-flex align-items-center text-primary fs-12">
            {tags?.length || active?.vessel?.name || active?.company?.name ? (
              <div className="small-seperator cpy-6 mx-3 bg-violet"></div>
            ) : null}
            <SvgRender
              src={budgetIcon}
              style={{ width: 12, height: 12 }}
              className={includeInBudget ? 'text-warning' : 'text-violet'}
            />
            <div
              className={`fw-medium ms-1 fs-12 ${includeInBudget ? 'text-warning' : 'text-violet'}`}
            >
              {includeInBudget ? 'Include in' : 'Exclude from'} Budget
            </div>
          </div>
        </div>

        {active?.events?.length ? <LinkedWithEventButton /> : null}
        {!isTemplate && <ShowLinkedFindingsButton />}
        {!isTemplate && showRemarksButton && <ShowRemarksAndAttachmentsButton />}
      </div>
      {isSummary ? <TopBarTags tags={tags} /> : null}
    </div>
  );
};

export default TopBar;

TopBar.propTypes = {
  className: PropTypes.string,
  showRemarksButton: PropTypes.bool,
  isSummary: PropTypes.bool
};
