import Title from '../../components/Title';
import CircledButton from 'common/components/buttons/CircledButton';
import {
  setSystemFormType,
  setSystemFormParentId,
  setSelectedTab
} from 'common/components/pms/setup/store/actions';
import SystemContainer from './SystemContainer';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import {
  selectCurrentFormSystem,
  selectPmsLibraryIsUsedInSetup
} from 'common/components/pms/setup/store/selectors';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';
import PropTypes from 'prop-types';

const Library = () => {
  const currentFormSystem = useAppSelector(selectCurrentFormSystem);
  const isUsedInSetup = useAppSelector(selectPmsLibraryIsUsedInSetup);

  const dispatch = useAppDispatch();

  return (
    <div className="d-flex flex-column h-100p library">
      <div className="d-flex align-items-center">
        <Title text="SYSTEMS LIBRARY" className="flex-1" />
        {isUsedInSetup ? (
          <AuthCheck permissions={[permissions.office.pms.library.systems.create]}>
            <CircledButton
              disabled={!!currentFormSystem}
              onClick={() => {
                dispatch(setSelectedTab(null));
                dispatch(setSystemFormType('system'));
                dispatch(setSystemFormParentId(null));
              }}
              type={'add'}
              wrappedLabel="Add System"
              svgStyle={{ width: 10, height: 10 }}
              className="cms-4 fs-12"
            />
          </AuthCheck>
        ) : null}
      </div>
      <SystemContainer />
    </div>
  );
};

Library.propTypes = {
  showButton: PropTypes.bool
};

export default Library;
