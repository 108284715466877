import { Row, Col } from 'reactstrap';
import { useFormState } from 'utils/hooks';
import NumberInput from 'common/components/form/inputs/NumberInput';
import infoLineIcon from 'common/assets/svg/common/info-line.svg';
import SvgRender from 'common/components/general/SvgRender';

import _get from 'lodash/get';

const AccountRow = ({ formState }) => {
  const { fields, changeField } = useFormState(formState);

  const account = _get(fields, 'account.value');

  return (
    <Row className="fs-12 text-primary mb-1 align-items-center me-3 cpe-2 cms-4">
      <Col className={fields.is_crew.value ? 'ps-3' : ''}>
        {`${account?.code}. ${account?.name}`}

        {account.is_parent_of_crew_accounts ? (
          <div className="d-flex text-violet fs-12 mt-1 cmb-6 pe-16">
            <SvgRender
              src={infoLineIcon}
              style={{ width: 14, height: 14 }}
              className={`cme-12 pointer-events-none cmt-2 text-red`}
            />

            <div className="text-red">
              For Seaman account balances, add a negative number if seaman should receive money from
              vessel. Add a positive, if seaman should give money to the vessel
            </div>
          </div>
        ) : null}
      </Col>

      {!account?.is_parent_of_crew_accounts ? (
        <>
          <Col xs="auto">
            <strong>$</strong>
          </Col>
          <Col xs={2}>
            <NumberInput
              className="mb-0"
              placeholder="Add amount"
              fixedDecimalScale={2}
              innerClassName="text-end"
              onChange={changeField('amount')}
              {...fields.amount}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};

export default AccountRow;
