import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import _isArray from 'lodash/isArray';

import { errorHandler } from 'common/utils/notifications';
import { SUPPLIER_FIELDS } from '../categories/items/config';
import { setItemFieldError } from './actions';
import { isInvalidApprovedQuantity } from './helpers-ts';

export const supplierStatuses = ['rfq', 'qtn', 'pr', 'po', 'dlv', 'ev', 'comp'];

export const templateStatuses = ['rfq', 'qtn', 'pr'];

export const createSupplierHistory = history => {
  if (!_isArray(history)) return history;

  const orderedHistory = _orderBy(history, ['created_at'], ['desc']).filter(h => h.status);

  const firstDlvStatusIndex = orderedHistory.findIndex(h => h.status.label === 'dlv');
  const firstPrStatusIndex = orderedHistory.findIndex(h => h.status.label === 'pr');

  return orderedHistory.reduce((prev, cur, index) => {
    if (cur.status.label === 'dlv') {
      if (index === firstDlvStatusIndex) {
        const deliveryStatusHistory = orderedHistory.filter(h => h.delivery_status);

        prev.push({
          status: cur.status,
          created_at: deliveryStatusHistory[0]?.created_at, // latest entry
          delivery_status: _groupBy(deliveryStatusHistory, 'delivery_status')
        });
      }
    } else if (cur.status.label === 'pr') {
      if (index === firstPrStatusIndex) {
        prev.push(cur);
      }
    } else {
      prev.push(cur);
    }

    return prev;
  }, []);
};

const everyQuantityValidationHandler = (
  items,
  { supplierRequisitionID, validationField },
  dispatch
) => {
  let hasErrors = false;

  //first we loop every field to add the field error
  Object.keys(items).forEach(itemID => {
    let quantityHasError = false;
    const item = items[itemID].supplier_details[supplierRequisitionID];

    if (validationField === 'approved_quantity') {
      quantityHasError = isInvalidApprovedQuantity(
        items[itemID].supplier_details[supplierRequisitionID]
      );
    } else {
      quantityHasError = item[validationField] === null;
    }

    if (quantityHasError) {
      hasErrors = true;
      dispatch(
        setItemFieldError(
          { itemID, supplierRequisitionID },
          { [`${validationField}_error`]: 'Required' }
        )
      );
    }
  });

  return hasErrors;
};

const handleDeliveryValidation = (items, params, dispatch) => {
  const validationField = SUPPLIER_FIELDS.delivered_quantity.key;

  const hasErrors = everyQuantityValidationHandler(items, { ...params, validationField }, dispatch);

  //if at least 1 has error throw it
  if (hasErrors) {
    dispatch(
      errorHandler({
        title: 'ERROR',
        message: `Please add Delivered Quantities for all items that you are ordering.`
      })
    );

    return true;
  } else {
    return false;
  }
};

const handleSendForApprovalValidation = (items, { supplierRequisitionID }, dispatch) => {
  const validationField = SUPPLIER_FIELDS.proposed_quantity.key;

  const atLeastOneHasValue = Object.keys(items).some(itemID => {
    const value = items[itemID].supplier_details[supplierRequisitionID][validationField];

    return value !== null && value !== undefined;
  });

  if (!atLeastOneHasValue) {
    dispatch(
      errorHandler({
        title: 'ERROR',
        message:
          'To send requisition for approval at least one item of the supplier must have a proposed quantity.'
      })
    );
    return true;
  } else {
    return false;
  }
};

export const handleStatusSubmissionValidations = ({ type, ...rest }, { getState, dispatch }) => {
  const items = getState().purchasing.requisitions.items;

  switch (type) {
    case 'send-for-approval':
      return handleSendForApprovalValidation(items, rest, dispatch);

    case 'delivery':
      return handleDeliveryValidation(items, rest, dispatch);

    default:
      return false;
  }
};

export const getPreventionMessageOfChangingVesselCompany = (
  newValue,
  type,
  { is_for_vessel, vessel, has_spare_parts }
) => {
  switch (type) {
    case 'vessel':
      if (newValue?.id && !vessel?.id && has_spare_parts)
        return 'By changing the selected vessel, you are going to lose all selected spare parts which do not belong to this Vessel. Are you sure?';
      else if (newValue?.id !== vessel?.id && has_spare_parts)
        return 'By changing the selected vessel, you are going to lose all selected spare parts which are not common between the two Vessels. Are you sure?';
      return null;

    case 'is_for_vessel':
      if (is_for_vessel && !newValue && has_spare_parts)
        return 'By removing the selected vessel, you are going to lose all selected spare parts. Are you sure?';
      return null;

    default:
      return null;
  }
};

export const shouldRemoveCategoryItem = (id, vesselSpareParts) => {
  if (id.startsWith('spare_part')) {
    // Remove spare parts that do not belong to the selected vessel
    if (!vesselSpareParts?.length) return true;

    return !vesselSpareParts.find(sparePart => `spare_part_${sparePart.id}` === id);
  } else {
    // Do not remove items
    return false;
  }
};
