import EmptyPage from '@/common/components/general/EmptyPage';
import vessel from 'common/assets/svg/vessels/vessel.svg';
import styled from '@emotion/styled';

const EmpyState = () => {
  return (
    <Container>
      <EmptyPage pageText={'No Vessel Selected.'} pageIcon={vessel} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .empty-page {
    top: 0;
    left: 0;
    padding-left: 0;
    padding-top: 0;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15vw;
  }

  .empty-page__bg {
    left: 5vw;
    width: 34.479vw;
    height: 34.479vw;
  }
`;

export default EmpyState;
