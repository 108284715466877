import { useState, useEffect } from 'react';

import { useActions } from 'utils/hooks';
import {
  selectJobField,
  selectJobFieldError,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { isUserAllowedToEdit } from 'common/components/jobs/_base/permissions/helpers';
import { selectIfFieldIsVisible, selectRestrictedFields } from 'store/jobs-fields/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import useDebounce from 'common/utils/hooks/useDebounce';
import _isEqual from 'lodash/isEqual';
import Tag from '@/common/components/general/Tag.tsx';
import { useAppSelector } from '@/store/hooks';

const Tags = ({ avoidCheck = false }) => {
  const [setJobField, setJobFieldError] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError
  ]);
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const tags = useAppSelector(state => selectJobField(state, 'tags'));

  const typeOfLoggedInUser = useAppSelector(state => selectJobField(state, 'typeOfLoggedInUser'));
  const jobTagsError = useAppSelector(state => selectJobFieldError(state, 'tags'));
  const isJobLocked = useAppSelector(state => selectIsJobLocked(state, true));
  const restrictedFields = useAppSelector(state =>
    selectRestrictedFields(state)?.find(item => item.field === 'tags')
  );

  const isVisible = useAppSelector(selectIfFieldIsVisible('tags'));

  const [inputTags, setInputTags] = useState(tags);

  const debouncedTags = useDebounce(inputTags, 1000);

  useEffect(() => {
    if (!_isEqual(debouncedTags, tags)) setJobField('tags', debouncedTags, true);
  }, [debouncedTags]);

  useEffect(() => {
    if (!_isEqual(inputTags, tags)) setInputTags(tags);
  }, [tags]);

  if (!isVisible || isOnBoard) return null;

  return (
    <>
      <Tag
        className="mb-0"
        tags={inputTags || []}
        type={'job'}
        onChange={v => {
          if (jobTagsError && v.length) setJobFieldError({ tags: null });
          setInputTags(v);
        }}
        disabled={
          !isUserAllowedToEdit({
            restrictedFields,
            typeOfLoggedInUser,
            avoidCheck
          }) || isJobLocked
        }
      />
      {jobTagsError ? <div className="fs-10 text-coral">*{jobTagsError}</div> : null}
    </>
  );
};

export default Tags;
