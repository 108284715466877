import _pickBy from 'lodash/pickBy';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';

import { selectRob } from '@/captain-reports/templates/components/helpers';
import { strToNumber } from '@/ts-common/utils/numbers';
import { FuelGradeCategoryLabel, FuelGradeViscosities } from '@/common/types/enums';
import { CaptainReportBunkerField, ReportStatus } from '@/common/types/captain-reports';

export const checkIfArrayHasValues = (array: unknown[]) => {
  return array.some(
    element =>
      Object.keys(
        _pickBy(element, val => (_isArray(val) ? checkIfArrayHasValues(val) : val !== null))
      ).length !== 0
  );
};

export const isReportStatusLocked = (
  status: ReportStatus,
  isOnboard: boolean,
  isEdittingApprovedReport: boolean
) => {
  const officeLockedStatuses = ['approved', 'rebuild', 'draft'];
  const onBoardLockedStatuses = ['approved', 'pending'];

  if (isOnboard) {
    return onBoardLockedStatuses.includes(status);
  } else {
    if (status === 'approved' && isEdittingApprovedReport) {
      return false;
    }

    return officeLockedStatuses.includes(status);
  }
};

export const parseBsViscositiesByFuelGrade = (
  bsViscositiesByFuelGrade: FuelGradeViscosities | undefined,
  prevConsumption: CaptainReportBunkerField | undefined,
  fuelGradeCategory: FuelGradeCategoryLabel
) => {
  const hfo = _get(prevConsumption, 'rob_hfo.value');
  const lfo = _get(prevConsumption, 'rob_lfo.value');

  return (
    bsViscositiesByFuelGrade?.values.map(e => ({
      label: `label_${e}`,
      suffix: selectRob({ hfo, lfo }, e, fuelGradeCategory),
      name: e,
      value: strToNumber(e) as string | number,
      fuelGradeCategory
    })) ?? []
  );
};
