import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import useRouter from 'use-react-router';
import { useActions } from 'utils/hooks';
import { useAppSelector } from '@/store/hooks';
import { selectAccount } from '@/store/account/selectors-ts';
import * as sidebarActions from 'store/sidebar/actions';
import { toggleBeaconDrawerAction } from 'store/beacon/sidebar/slice';
import { selectBeaconDrawerIsOpen } from 'store/beacon/sidebar/selectors';
import { isAuthorized } from '@/utils/permissions/authorize';

import SvgRender from 'common/components/general/SvgRender';
import User from './User';

import logo_small from 'assets/svg/logos/logo_small.svg';
import star from 'common/assets/svg/common/star.svg';
import filters from 'common/assets/svg/common/filters.svg';
import settings from 'common/assets/svg/actions/settings.svg';
import dashboard from 'assets/svg/sidebar/dashboard.svg';
import beacon from 'common/assets/svg/common/beacon.svg';
import it from 'assets/svg/sidebar/it.svg';
import AuthCheck from 'components/permissions/AuthCheck';
import settingsItems from 'components/layouts/sidebar/panel/settings/_constants';
import NotificationBadge from 'components/general/NotificationBadge';

import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';

const Action = ({ action, activePanel }) => {
  return (
    <AuthCheck permissions={action.permissions ? action.permissions : null}>
      <div
        className={`sidebar-action text-center mb-2${
          activePanel === action.label ? ' active' : ''
        } ${action.className || ''}`}
      >
        <Button
          type="button"
          color="link"
          data-cy={action.dataCy}
          className={`p-0 text-white d-inline-flex align-items-center justify-content-center ${
            action.isActive && action.isActive() ? 'is-active' : ''
          }`}
          onClick={action.onClick ? action.onClick : () => {}}
        >
          {action.renderComponent ? (
            action.renderComponent()
          ) : (
            <SvgRender
              src={action.icon}
              style={{
                height: action.height ? action.height : 24,
                width: action.width ? action.width : 24
              }}
            />
          )}
        </Button>
      </div>
    </AuthCheck>
  );
};

const Navbar = () => {
  const { location, history } = useRouter();
  const account = useAppSelector(selectAccount);
  const activePanel = useAppSelector(state => state.sidebar.activePanel);
  const beaconIsActive = useAppSelector(selectBeaconDrawerIsOpen);
  const [togglePanel, toggleBeaconDrawer] = useActions([
    sidebarActions.togglePanel,
    toggleBeaconDrawerAction
  ]);

  const actions = {
    top: [
      {
        icon: dashboard,
        onClick: () => togglePanel('dashboards'),
        label: 'dashboards',
        width: 18,
        height: 18,
        className: 'pb-2 separated-action',
        permissions: [permissions.office.dashboard.view]
      },
      {
        icon: star,
        onClick: () => togglePanel('favorites'),
        label: 'favorites',
        width: 20,
        height: 20
      },
      {
        icon: filters,
        onClick: () => togglePanel('search'),
        label: 'search',
        width: 20,
        height: 20
      }
    ],
    bottom: [
      {
        label: 'notifications',
        renderComponent: () => <NotificationBadge />,
        onClick: () => togglePanel('notifications')
      },
      {
        icon: beacon,
        onClick: () => toggleBeaconDrawer(true),
        isActive: () => beaconIsActive
      },
      {
        icon: it,
        onClick: () =>
          history.push(
            isAuthorized(account, [permissions.office.it.dashboard.view])
              ? paths.it_dashboard
              : paths.it_integration_logs
          ),
        isActive: () => [paths.it_dashboard, paths.it_integration_logs].includes(location.pathname),
        permissions: [
          permissions.office.it.dashboard.view,
          permissions.office.it.integration_logs.view
        ]
      },
      {
        icon: settings,
        onClick: () => togglePanel('settings'),
        label: 'settings',
        dataCy: 'sidebar-navbar--bottom-settings',
        permissions: [permissions.office.settings.view],
        isActive: () =>
          settingsItems.some(
            item =>
              location.pathname.includes(item.path) ||
              (item.submenu && item.submenu.some(inner => location.pathname.includes(inner.path)))
          )
      }

      // { icon: profile, onClick: null, width: 20, height: 20 }
      //
    ]
  };

  return (
    <div className="sidebar-navbar py-4 d-flex flex-column justify-content-between align-items-center">
      <div className="sidebar-navbar--top">
        <div className="sidebar-brand mb-4">
          <Link to={'/'}>
            <img src={logo_small} alt="Orca logo" width={34} height={40} />
          </Link>
        </div>
        {actions.top.map((action, index) => (
          <Action action={action} key={index} activePanel={activePanel} />
        ))}
      </div>

      <div className="sidebar-navbar--bottom">
        {actions.bottom.map((action, index) => (
          <Action action={action} key={index} activePanel={activePanel} />
        ))}

        <User />
      </div>
    </div>
  );
};

export default Navbar;
