import { useState } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import AssignToVesselModal from 'common/components/pms/setup/views/components/modals/AssignToVesselModal';
import {
  getSingleSystem,
  addVesselAssignment,
  assignVessel,
  getVesselAssignments,
  getVesselSystemsAction,
  setSelectedTab,
  getVesselSubAssignments,
  setSelectedAssignment,
  setSelectedSubAssignment
} from '@/common/components/pms/setup/store/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectSelectedSystem,
  selectCurrentSystem,
  selectSelectedTab,
  selectCurrentAssignments,
  selectselectedAssignment,
  selectAssignmentsLoading,
  selectSystemFormType,
  selectVesselId,
  selectSelectedSubAssignment,
  selectSubAssignmentsLoading,
  selectSubAssignments
} from 'common/components/pms/setup/store/selectors';
import _get from 'lodash/get';
import VesselTab from './VesselTab';
import AssignmentNumber from './AssignmentNumber';
import _uniqBy from 'lodash/uniqBy';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

const Header = () => {
  const dispatch = useAppDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedVesselToEdit, setSelectedVesselToEdit] = useState(null);

  const systemFormType = useAppSelector(selectSystemFormType);

  const assignmentsLoading = useAppSelector(selectAssignmentsLoading);
  const subAssignmentsloading = useAppSelector(selectSubAssignmentsLoading);

  const selectedSystem = useAppSelector(selectSelectedSystem);
  const currentSystem = useAppSelector(selectCurrentSystem);
  const selectedTab = useAppSelector(selectSelectedTab);
  const currentAssignments = useAppSelector(selectCurrentAssignments);
  const selectedAssignment = useAppSelector(selectselectedAssignment);
  const selectedSubAssignment = useAppSelector(selectSelectedSubAssignment);
  const currentVesselId = useAppSelector(selectVesselId);

  const subAssignments = useAppSelector(state =>
    selectSubAssignments(state, selectedAssignment?.id)
  );

  const onAddVesselSystem = async () => {
    try {
      setIsAdding(true);
      await dispatch(
        addVesselAssignment({
          vessel_id: currentVesselId,
          count: 1,
          system_id: selectedSystem
        })
      );
      await dispatch(
        getVesselAssignments({ vessel_id: currentVesselId, system_id: selectedSystem })
      );

      setIsAdding(false);
    } catch (e) {
      console.error(e);

      setIsAdding(false);
    }
  };

  const onCreate = async params => {
    try {
      setCreating(true);
      await dispatch(assignVessel({ ...params, system_id: selectedSystem }));
      if (selectedVesselToEdit) {
        await dispatch(
          getVesselAssignments({
            vessel_id: selectedVesselToEdit.id,
            system_id: currentSystem?.parent_id
          })
        );
      } else {
        await dispatch(getSingleSystem({ id: selectedSystem }));
      }
      await dispatch(getVesselSystemsAction({ keepOpened: true, hideLoading: true }));

      setCreating(false);
      setIsAddModalOpen(false);
      setSelectedVesselToEdit(false);
    } catch (e) {
      setCreating(false);
      setSelectedVesselToEdit(false);
    }
  };

  const onVesselTabPress = id => {
    dispatch(setSelectedTab(id));

    if (selectedSystem && !currentSystem?.parent_id) {
      dispatch(getVesselAssignments({ vessel_id: id, system_id: selectedSystem }));
    } else if (selectedSystem && currentSystem?.parent_id) {
      dispatch(getVesselAssignments({ vessel_id: id, system_id: currentSystem?.parent_id }));
      dispatch(getVesselSubAssignments({ vessel_id: id, parent_id: selectedSystem }));
    }
  };

  const onAssignmentClick = assignment => {
    dispatch(setSelectedAssignment(assignment));
  };

  const onSubAssignmentClick = assignment => {
    dispatch(setSelectedSubAssignment(assignment));
  };

  const isSubSystem = currentSystem?.parent_id;

  return (
    <div className="single-system-header">
      <div className="d-flex align-items-center">
        <div
          onClick={() => {
            dispatch(setSelectedAssignment(null));
            dispatch(setSelectedTab(null));
          }}
          className={`single-system-header__tab cursor-pointer ${
            !selectedTab ? 'single-system-header__tab-selected' : ''
          }`}
        >
          SPECIFICATIONS
        </div>

        {/* {isSubSystem ? (
          <div className="ms-auto fs-10 text-violet d-flex align-items-center">
            <>
              <div className="simple-dot max-height-8 max-width-8 bg-coral me-1"></div>
              <div>Red vessels & numbers contain Jobs that are NOT triggered.</div>
            </>
          </div>
        ) : null} */}
      </div>

      <div className="divider" />

      {!systemFormType ? (
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-1 d-flex align-items-center flex-wrap">
            {_uniqBy(_get(currentSystem, 'vessels', []), 'id').map((el, index) => (
              <VesselTab
                isSubSystem={isSubSystem}
                vessel={el}
                onClick={() => onVesselTabPress(el.id)}
                selected={selectedTab === el.id}
                key={`vessel-${index}`}
              />
            ))}
          </div>
          {isSubSystem ? (
            <div />
          ) : (
            <AuthCheck permissions={[permissions.office.pms.library.system_assignments.create]}>
              <CircledButton
                style={{ width: 16, height: 16 }}
                onClick={() => setIsAddModalOpen(true)}
                type={'add'}
                label="Assign to vessel"
                svgStyle={{ height: 7, width: 7 }}
              />
            </AuthCheck>
          )}
        </div>
      ) : null}

      {!systemFormType && selectedTab && selectedAssignment && currentAssignments.length >= 1 ? (
        <>
          <AssignmentNumber
            data={currentAssignments}
            selectedId={selectedAssignment?.id}
            loading={assignmentsLoading}
            hideAddButton={isSubSystem || isAdding}
            onNumberClick={onAssignmentClick}
            onAddAction={onAddVesselSystem}
            showPreventionModal={true}
            type={'system'}
          />

          {subAssignments?.length ? (
            <AssignmentNumber
              title="Sub-systems"
              data={subAssignments}
              selectedId={selectedSubAssignment?.id}
              loading={subAssignmentsloading}
              hideAddButton={isSubSystem}
              onNumberClick={onSubAssignmentClick}
              type={'subSystem'}
            />
          ) : null}
        </>
      ) : null}
      {isSubSystem ? null : (
        <AssignToVesselModal
          isOpen={isAddModalOpen}
          setIsOpen={setIsAddModalOpen}
          onSave={onCreate}
          disabled={creating}
          selectedVesselToEdit={selectedVesselToEdit}
          setSelectedVesselToEdit={setSelectedVesselToEdit}
        />
      )}
    </div>
  );
};

export default Header;
