import paths from '@/routing/routes/_paths';
import { ReduxTableLabelKeyType } from '@/store/tables/lists/keys.ts';
import { CREW_SEAMEN_TABLE_KEY } from '@/api/crew/query-keys.ts';
import {
  COMPANY_SUPPLIER_EVALUATIONS,
  COMPANY_CHARTERERS_EVALUATIONS
} from '@/api/contacts-companies/query-keys.ts';

import { ITEMS_ORDERED_TABLE_KEY } from '@/api/reports/items-ordered/query-keys.ts';
import { FLEET_CREW_LIST_TABLE_KEY } from '@/api/reports/fleet-crew-list/query-keys.ts';
import { PMS_JOBS_LIST_TABLE_KEY } from '@/api/pms/query-keys.ts';
import {
  PURCHASING_REQUISITIONS_TABLE_KEY,
  PURCHASING_REQUISITION_APPROVALS_TABLE_KEY
} from '@/api/purchasing/query-keys.ts';
import { LEDGER_CARDS_TABLE_KEY } from '@/api/accounts/query-keys.ts';
import { EVENTS_TABLE_KEY } from '@/api/events/query-keys.ts';
import { FORM_SUBMISSIONS_TABLE_KEY } from '@/api/form-submissions/query-keys.ts';
import { FORM_TABLE_REPORTS_TABLE_KEY } from '@/api/form-table-reports/query-keys.ts';
import { INDEXES_TABLE_KEY } from '@/api/indexes/query-keys.ts';
import { INVOICES_TABLE_KEY } from '@/api/invoices/query-keys.ts';
import { REPORTS_CII_SINGLE_VESSEL } from '@/api/reports/cii-single-vessel/query-keys';

const reactQueryTableKeys = [
  CREW_SEAMEN_TABLE_KEY,
  ITEMS_ORDERED_TABLE_KEY,
  FLEET_CREW_LIST_TABLE_KEY,
  PMS_JOBS_LIST_TABLE_KEY,
  PURCHASING_REQUISITIONS_TABLE_KEY,
  PURCHASING_REQUISITION_APPROVALS_TABLE_KEY,
  LEDGER_CARDS_TABLE_KEY,
  EVENTS_TABLE_KEY,
  FORM_SUBMISSIONS_TABLE_KEY,
  FORM_TABLE_REPORTS_TABLE_KEY,
  INDEXES_TABLE_KEY,
  INVOICES_TABLE_KEY,
  REPORTS_CII_SINGLE_VESSEL,
  COMPANY_CHARTERERS_EVALUATIONS,
  COMPANY_SUPPLIER_EVALUATIONS
] as const;

export type SavableTableKeysType =
  | (typeof reactQueryTableKeys)[number]
  | 'dashboard'
  | Partial<ReduxTableLabelKeyType>;

type TablePathType = Record<SavableTableKeysType, { path: string; label: string }>;

const tablePaths = {
  document_manager_vessels_per_document: {
    path: paths.vessels_document_manager_per_document,
    label: 'Document Manager - Vessels Per document'
  },
  document_manager_vessels_list: {
    path: paths.vessels_document_manager,
    label: 'Document Manager - Vessels List'
  },
  document_manager_crew: { path: paths.crew_document_manager, label: 'Document Manager - Crew' },
  document_manager_crew_documents: {
    path: `${paths.crew_document_manager}/documents`,
    label: 'Document Manager - Crew'
  },
  crew_list: {
    path: paths.crew_list,
    label: 'Crew List'
  },
  inventory_spare_parts: {
    path: paths.pms_inventory,
    label: 'Spare Parts Inventory'
  },
  purchasing_requisitions: {
    path: paths.purchasing_requisitions,
    label: 'Purchasing Requisition'
  },
  purchasing_requisitions_ev: {
    path: paths.purchasing_requisitions_ev,
    label: 'Purchasing Delivered'
  },
  purchasing_requisitions_approvals: {
    path: paths.purchasing_requisitions_approval,
    label: 'Purchasing Approval View'
  },
  dashboard: { path: paths.dashboard, label: 'Dashboard' },
  persons: { path: paths.persons, label: 'Contacts' },
  companies: { path: paths.companies, label: 'Contacts' },
  crew: { path: paths.crew, label: 'Contacts' },
  crew_planning: { path: paths.crew_planning, label: 'Crew Planning' },
  vessels: { path: paths.vessels, label: 'Vessels' },
  voyages: { path: paths.voyages, label: 'Voyages' },
  estimations: { path: paths.estimations, label: 'Tools - Estimation' },
  'market-indexes': { path: paths.market_indexes, label: 'Tools - Market Indexes' },
  'bunker-prices': { path: paths.bunker_prices, label: 'Tools - Bunker Prices' },
  ticketing_events: { path: paths.ticketing, label: 'Tools - Ticketing' },
  ticketing_crew: { path: `${paths.ticketing}/crew`, label: 'Tools - Ticketing' },
  captain_reports: {
    path: paths.captain_reports,
    label: 'Vessel Reports - Captain Reports'
  },
  projects: {
    path: paths.projects,
    label: 'Projects'
  },
  jobs_list: {
    path: paths.regular_jobs,
    label: 'Jobs'
  },
  jobs_flag_extensions_dispensations_list: {
    path: paths.flag_extensions_dispensations_jobs,
    label: 'Jobs'
  },
  jobs_orca_jobs_list: {
    path: paths.orca_jobs,
    label: 'Jobs'
  },
  pms_jobs_list: {
    path: paths.pms_jobs,
    label: 'PMS Jobs'
  },
  pms_jobs_per_system: {
    path: paths.pms_jobs_per_system,
    label: 'PMS Jobs / Per System'
  },
  reports_event: {
    path: paths.reports_event,
    label: 'Event Reports'
  },
  pms_running_hours: {
    path: paths.pms_running_hours,
    label: 'Running Hours'
  },
  reports_tanks_condition_summary: {
    path: paths.reports_tanks_condition_summary,
    label: 'Tanks Condition Summary'
  },
  reports_critical_equipment_performance_test_and_data: {
    path: paths.reports_critical_equipment_performance_test_and_data,
    label: 'Critical Equipment Performance Test & Data'
  },
  reports_megger_and_vibration_report_for_motors: {
    path: paths.reports_megger_and_vibration_report_for_motors,
    label: 'Megger and Vibration Report for motors'
  },
  reports_root_cause_analysis_statistics: {
    path: paths.reports_root_cause_analysis_statistics,
    label: 'Root Cause Analysis Statistics'
  },
  reports_stoppages: {
    path: paths.reports_stoppages,
    label: 'Stoppage Report'
  },
  reports_ports_and_cargoes_statistics: {
    path: paths.reports_ports_and_cargoes_statistics,
    label: 'Ports and Cargoes Statistics Report'
  },
  reports_audits_and_inspections_statistics: {
    path: paths.reports_audits_and_inspections_statistics,
    label: 'Audits and Inspections Statistics'
  },
  reports_vetting_statistics: {
    path: paths.reports_vetting_statistics,
    label: 'Vetting Statistics'
  },
  reports_risk_assessment_statistics: {
    path: paths.reports_risk_assessment_statistics,
    label: 'Risk Assessment Statistics'
  },
  reports_job_statistics: {
    path: paths.reports_job_statistics,
    label: 'Job Statistics for a Specific Period'
  },
  reports_crew_wages: {
    path: paths.reports_crew_wages,
    label: 'Crew Wages Report'
  },
  reports_crew_medical_statistics: {
    path: paths.reports_crew_medical_statistics,
    label: 'Crew Medical'
  },
  jobs_triggers: { path: paths.jobs_triggers, label: 'Jobs & Findings - Triggers' },
  comparison: {
    path: paths.comparison,
    label: 'Comparison'
  },
  vessel_document_reminders: {
    path: paths.vessel_document_reminders,
    label: 'Reminders on Docs Reminders'
  },
  reports_crew: {
    path: paths.reports_crew,
    label: 'Crew Reports'
  },
  reports_past_promotions: {
    path: paths.reports_past_promotions,
    label: 'Reports Past Promotion'
  },
  reports_promotions: {
    path: paths.reports_promotions,
    label: 'Reports Promotions'
  },
  events: {
    path: paths.events,
    label: 'Events'
  },
  form_submissions: {
    path: paths.forms,
    label: 'Forms'
  },
  ports: {
    path: paths.ports,
    label: 'Ports'
  },
  reports_voyage: {
    path: paths.reports_voyage,
    label: 'Voyage Reports'
  },
  reports_purchasing_items_ordered_on_board: {
    path: paths.reports_purchasing_items_ordered_on_board,
    label: 'Purchasing Items Ordered Report'
  },
  reports_purchasing_budgets: {
    path: paths.reports_purchasing_budgets,
    label: 'Purchasing Budget Report'
  },
  reports_lubricants_supply_monitoring: {
    path: paths.reports_lubricants_supply_monitoring,
    label: 'Lubricants Supply Monitoring'
  },
  reports_lubricants_remaining_days_estimator: {
    path: paths.reports_lubricants_remaining_days_estimator,
    label: 'Lubricants Remaining Days Estimator'
  },
  reports_fresh_water_generator_performance: {
    path: paths.reports_fresh_water_generator_performance,
    label: 'Fresh Water Generator Performance'
  },
  reports_fresh_water_generator_performance_fleet: {
    path: paths.reports_fresh_water_generator_performance_fleet,
    label: 'Fresh Water Generator Performance - Fleet'
  },
  reports_mrv: {
    path: paths.reports_mrv,
    label: 'EU MRV'
  },
  reports_imo_dcs: {
    path: paths.reports_imo_dcs,
    label: 'IMO DCS'
  },
  reports_stern_tube_report: {
    path: paths.reports_stern_tube_report,
    label: 'Stern Tube Report'
  },
  reports_cii_reference_lines: {
    path: paths.reports_cii_reference_lines,
    label: 'Reference Lines for the whole Fleet'
  },
  reports_cii_fleet_for_one_year: {
    path: paths.reports_cii_fleet_for_one_year,
    label: 'Fleet for one Year'
  },
  reports_cii_fleet_running_legs: {
    path: paths.reports_cii_fleet_running_legs,
    label: 'Fleet for one Year'
  },
  reports_port_statement_bunkering_and_bunker_survey: {
    path: paths.reports_port_statement_bunkering_and_bunker_survey,
    label: 'Port Statement Bunkering & Bunker Surveys'
  },
  reports_captain_reports_bunkers: {
    path: paths.reports_captain_reports_bunkers,
    label: 'Captain Reports Bunkers'
  },
  reports_change_logs: {
    path: paths.reports_change_logs,
    label: 'Change Logs'
  },
  reports_evaluations: {
    path: paths.reports_evaluations,
    label: 'Evaluations'
  },
  reports_onboard_nationalities: {
    path: paths.reports_onboard_nationalities,
    label: 'Nationalities'
  },
  reports_on_signer_vessel_agent_statistics: {
    path: paths.reports_on_signer_vessel_agent_statistics,
    label: 'Seamen Onboard Statistics'
  },
  reports_on_signer_evaluation_statistics: {
    path: paths.reports_on_signer_evaluation_statistics,
    label: 'Evaluation Statistics'
  },

  reports_vessel_agent_evaluation_statistics: {
    path: paths.reports_vessel_agent_evaluation_statistics,
    label: 'On Signer Evaluation Statistics'
  },

  reports_overlapping_days: {
    path: paths.reports_overlapping_days,
    label: 'Overlapping Days'
  },

  reports_training_needs: {
    path: paths.reports_training_needs,
    label: 'Training Needs'
  },

  reports_trainings: {
    path: paths.reports_trainings,
    label: 'Trainings'
  },

  reports_retention_rate: {
    path: paths.reports_retention_rate,
    label: 'Retention Rate'
  },

  reports_vaccinations: {
    path: paths.reports_vaccinations,
    label: 'Vaccination Report'
  },
  reports_not_for_reemployment: {
    path: paths.reports_not_for_reemployment,
    label: 'Not for Reemployment'
  },
  reports_newly_hired: {
    path: paths.reports_newly_hired,
    label: 'Newly Hired'
  },
  reports_fixtures: {
    path: paths.reports_fixtures,
    label: 'Fixtures'
  },
  reports_crew_fleet_crew_list: {
    path: paths.reports_crew_fleet_crew_list,
    label: 'Fleet crew list'
  },
  reports_off_hire_totals: {
    path: paths.reports_off_hire_totals,
    label: 'Off hires totals'
  },
  form_fields: {
    path: paths.form_fields,
    label: 'Form Fields'
  },
  form_planning: {
    path: paths.form_planning,
    label: 'Form Planning'
  },
  mga_action_types: {
    path: paths.mga_action_types_settings,
    label: 'MGA Actions'
  },
  accounting_budget: {
    path: paths.accounting_budget_settings,
    label: 'Budget'
  },
  root_cause_analysis: {
    path: paths.root_cause_analysis,
    label: 'Root Cause Analysis'
  },
  it_dashboard: {
    path: paths.it_dashboard,
    label: 'IT Dashboard'
  },
  crew_evaluation_obligations: {
    path: paths.crew_evaluation_obligations,
    label: 'Evaluation'
  },
  event_templates: {
    path: paths.event_templates,
    label: 'Event Templates'
  },
  event_underwater_services_types: {
    path: paths.event_underwater_services_types,
    label: 'Event Underwater Svc. Types'
  },
  event_off_hire: {
    path: paths.event_off_hire,
    label: 'Event Off Hire'
  },
  vetting_setup: {
    path: paths.vetting_setup,
    label: 'Vetting Setup'
  },
  reports_findings: {
    path: paths.findings,
    label: 'Findings'
  },
  purchasing_budgeted_quantity: {
    path: paths.purchasing_budgeted_quantity,
    label: 'Purchasing Budgeted Quantity'
  },
  accounting_invoices: {
    path: paths.accounting_invoices,
    label: 'Invoices'
  },
  events_calendar: {
    path: paths.events_calendar,
    label: 'Events Calendar'
  },
  events_calendar_overview: {
    path: paths.events_calendar_overview,
    label: 'Events Calendar Overview'
  },
  accounting_contracts: {
    path: paths.accounting_contracts,
    label: 'Accounting Contracts'
  },
  accounting_contracts_payments: {
    path: paths.accounting_contracts_payments,
    label: 'Accounting Contracts Payments'
  },
  tags: {
    path: paths.tags,
    label: 'Tags'
  }
} as TablePathType;

export default tablePaths;
