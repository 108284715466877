import { FC, useState } from 'react';
import { Button } from 'reactstrap';
import { useRestartReviewProcessMutation } from '@/api/review-processes/mutations';
import { useAppSelector } from '@/store/hooks';
import { selectReviewProcessId } from '../store/selectors-ts';

import arrow from '@/common/assets/svg/common/arrows/back-arrow.svg';
import info from '@/ts-common/assets/svg/common/info-line.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type RestartReviewProcessActionButtonProps = {
  entityId: number | string;
  fetchReviewProcess: () => void;
};

const RestartReviewProcessActionButton: FC<RestartReviewProcessActionButtonProps> = ({
  entityId,
  fetchReviewProcess
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tooltipID, avoidRender } = useTooltipID('restart');

  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, entityId));
  const restartReviewProcessMutation = useRestartReviewProcessMutation();

  const onRestart = async () => {
    if (!reviewProcessId) return;

    await restartReviewProcessMutation.mutateAsync({ id: reviewProcessId });
    await fetchReviewProcess();
  };

  return (
    <>
      <Button
        className="mb-2 ms-1 p-0 w-24 h-24 text-dark d-inline-flex align-items-center justify-content-center"
        size="sm"
        color="yellow"
        onClick={() => setIsModalOpen(true)}
        id={tooltipID}
      >
        <SvgRender src={arrow} style={{ width: 12, height: 12 }} />
      </Button>
      {!avoidRender && !isModalOpen && (
        <Tooltip target={tooltipID}>Restart Approval Process</Tooltip>
      )}

      <DangerousActionModal
        onAccept={onRestart}
        onCancel={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        acceptButtonText="PROCEED"
        acceptButtonBackground="primary"
        cancelButtonText="CANCEL"
        icon={info}
        svgStyle={{ width: 84, height: 84 }}
        header="Restart Approval Process"
      >
        This will reset all progress and restart
        <br /> the approval process. This action
        <br />
        cannot be undone. Are you
        <br />
        sure you want to proceed?
      </DangerousActionModal>
    </>
  );
};

export default RestartReviewProcessActionButton;
