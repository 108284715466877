import { createSelector } from 'reselect';

const selectPurchasingCategoriesReducer = state => state.purchasing.categories;

export const selectPurchasingCategories = createSelector(
  [selectPurchasingCategoriesReducer],
  categories => categories.categories
);

export const selectPurchasingFilteredCategories = createSelector(
  [selectPurchasingCategoriesReducer],
  categories => categories.filteredCategories
);

export const selectActiveCategoriesGroup = createSelector(
  [selectPurchasingCategoriesReducer],
  categories => categories.activeCategoriesGroup
);

export const selectIsCategoryAssignmentDrawerOpen = createSelector(
  [selectPurchasingCategoriesReducer],
  categories => categories.isCategoryAssignmentDrawerOpen
);
