import Information from '@/ts-common/components/general/Information';
import GroupSelector from '@/common/components/selectors/VesselSystemGroupSelector.tsx';
import { FC } from 'react';

type SystemGroupSelectProps = {
  value: number | null;
  error: string | null;
  parentGroupId?: number;
  onChange: (id: number) => void;
};

const SystemGroupSelect: FC<SystemGroupSelectProps> = ({
  value,
  error,
  parentGroupId,
  onChange
}) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex fs-10 align-items-center text-primary mb-1">
        <div>GROUP</div>
        <Information
          svgStyle={{ width: 12, height: 12 }}
          className="cms-12 text-primary"
          message="Assign this Machine to specific Group in your tree."
        />
      </div>
      <GroupSelector
        placeholder="Select group"
        onChange={({ value }) => onChange(value)}
        className="mb-2"
        isClearable={true}
        listParams={{ parent_id: parentGroupId }}
        invisible={true}
        value={value}
        error={error}
      />
    </div>
  );
};

export default SystemGroupSelect;
