import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { stringifyObj } from 'utils/urls';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceJobHasSystemRunningHours,
  selectMaintenanceDetaisVesselSystemAssignmentId,
  selectMaintenanceDetaisField
} from 'common/components/pms/jobs/store/selectors';
import { displayDate } from 'common/utils/dates';

import SvgRender from 'common/components/general/SvgRender';
import ShadowBox from 'common/components/general/ShadowBox';
import ContentLoader from 'react-content-loader';
import CircledButton from 'common/components/buttons/CircledButton';

import jobsIcon from 'common/assets/svg/common/jobs.svg';
import past from 'common/assets/svg/common/history.svg';
import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';
import * as pmsActions from 'common/components/pms/jobs/store/actions';

const JobHistory = ({ jobID, drawerIsOpen }) => {
  const [pmsJobHistoryData, setPmsJobHistoryData] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const prototype = useSelector(state => selectJobField(state, 'prototype_id'));
  const carriedOutAt = useSelector(state => selectMaintenanceDetaisField(state, 'carried_out_at'));

  const systemAssignmentId = useSelector(selectMaintenanceDetaisVesselSystemAssignmentId);
  const hasSystemRunningHoursFlag = useSelector(selectMaintenanceJobHasSystemRunningHours);

  const { history, match } = useRouter();

  const [fetchPmsJobHistory] = useActions([pmsActions.fetchPmsJobHistory]);

  const getTableFilters = () => {
    const filters = [
      {
        name: 'prototype_id',
        operation: 'oneOf',
        value: [prototype] || []
      },
      { name: 'is_completed', operation: '=', value: 'true' },
      {
        name: 'vessel_system_assignment_id',
        operation: 'oneOf',
        value: [systemAssignmentId] || []
      }
    ];

    if (!isOnBoard) {
      filters.push({
        name: 'vessel_id',
        operation: 'oneOf',
        value: [vessels?.[0]?.id] || []
      });
    }

    if (carriedOutAt) {
      // History jobs should have a carried_out_at in the past
      filters.push({
        name: 'carried_out_at',
        operation: '<',
        value: carriedOutAt
      });
    }

    return filters;
  };

  const fetchPmsJobHistoryData = async () => {
    if (isLoadingHistory) return;

    const filters = getTableFilters();

    const params = {
      paging: { current_page: 1, per_page: 100 },
      sorting: { carried_out_at: 'desc' },
      filters: [...filters, { name: 'id', operation: 'notIn', value: [jobID] }] // exlude the existing job from history
    };

    setIsLoadingHistory(true);

    try {
      const fetchedData = await fetchPmsJobHistory(params);

      setPmsJobHistoryData(fetchedData?.data);
      setIsLoadingHistory(false);
    } catch (error) {
      console.error(error);
      setPmsJobHistoryData([]);
      setIsLoadingHistory(false);
    }
  };

  useEffect(() => {
    if (
      drawerIsOpen &&
      jobID &&
      systemAssignmentId &&
      ((!isOnBoard && vessels?.[0]?.id) || isOnBoard)
    ) {
      fetchPmsJobHistoryData();
    } else {
      setPmsJobHistoryData([]);
    }
  }, [drawerIsOpen, jobID, vessels?.[0]?.id, isOnBoard, systemAssignmentId, carriedOutAt]);

  const onClick = () => {
    window.open(`${paths?.pms_jobs}?${stringifyObj({ filters: getTableFilters() })}`, '_blank');
  };

  return (
    <>
      {pmsJobHistoryData?.length > 0 ? (
        <div className="mx-2 mt-2 pms-job-history">
          <Row className="justify-content-between align-items-center w-100p cmb-6" noGutters>
            <Col className="d-flex align-items-center text-primary">
              <SvgRender className={`cme-4`} src={past} style={{ height: 12, width: 12 }} />
              <div className="fs-10 fw-bold lh-16">JOB'S HISTORY</div>
            </Col>

            <Col className="d-flex justify-content-end">
              <Button
                type="button"
                color="primary"
                className="d-inline-flex align-items-center cps-12 cpe-12 height-24 border-radius-5"
                onClick={onClick}
                size="sm"
              >
                <SvgRender src={jobsIcon} style={{ width: 14, height: 14 }} />
                <span className="ps-1 lh-1 fw-medium">View Jobs</span>
              </Button>
            </Col>
          </Row>

          {isLoadingHistory ? (
            <div className="w-100p">
              <ContentLoader
                speed={1}
                backgroundColor="#f8f9fb"
                foregroundColor="#dadce4"
                preserveAspectRatio="none"
                width="100%"
                height="32px"
                viewBox="0 0 100 10"
              >
                <rect x="0" y="0" rx="0.5" ry="0.5" width="100" height="10" />
              </ContentLoader>
            </div>
          ) : (
            pmsJobHistoryData?.map((item, index) => (
              <ShadowBox
                className="px-1 cpt-6 cpb-6 cmb-2 w-100p"
                flat
                color="light-1"
                key={index}
                whiteOnHover
              >
                <Row className="g-0 ps-1">
                  <Col xs={hasSystemRunningHoursFlag ? 7 : 11}>
                    <div className="text-primary fs-12 fw-normal">
                      <div className="text-violet d-flex">
                        Date & Time Carried out:{' '}
                        <div className="text-primary">
                          &nbsp;{displayDate(item?.carried_out_at) || '-'}
                        </div>
                      </div>
                    </div>
                  </Col>

                  {hasSystemRunningHoursFlag ? (
                    <Col xs={4}>
                      <div className="text-primary fs-12 fw-normal">
                        <div className="text-violet d-flex">
                          RH when Carried out:{' '}
                          <div className="text-primary">
                            &nbsp;{item?.total_system_running_hours || '-'}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : null}

                  <Col xs={1} className="d-flex justify-content-end cpe-2">
                    <CircledButton
                      type="view"
                      className="d-flex justify-content-center pms-job-history_row-button"
                      svgStyle={{ width: 12, height: 12 }}
                      style={{ width: 19, height: 19 }}
                      onClick={e => {
                        e.preventDefault();
                        history.replace({
                          pathname: history.location.pathname.replace(
                            `${match.params.jobID}`,
                            `${item?.id}`
                          ),
                          search: history.location.search
                        });
                      }}
                      size="small"
                    />
                  </Col>
                </Row>
              </ShadowBox>
            ))
          )}
        </div>
      ) : null}
    </>
  );
};

JobHistory.propTypes = {
  jobID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  drawerIsOpen: PropTypes.bool
};

export default JobHistory;
