import { booleanField, dateField, numberField, optionField } from 'common/utils/form/fieldTypes';

export const fifoInitialInventoryConfig = {
  store: optionField(),
  quantity: numberField(),
  date: dateField(),
  price_per_unit: numberField()
};

const config = {
  started_at: dateField({ required: true }),
  ended_at: dateField({ required: true }),

  initial_balances: {
    account: optionField(),
    amount: numberField(),
    is_crew: booleanField({ initialValue: false })
  }
};

export default config;
