import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDeliveryReport, getDeliveryReportStatus } from './actions';
import {
  PurchasingRequisitionDeliveryReportData,
  PurchasingRequisitionDeliveryReportStatus
} from '@/common/types/purchasing.ts';
import _sortBy from 'lodash/sortBy';

export interface DeliveryReportState {
  status: PurchasingRequisitionDeliveryReportStatus | null;
  data: PurchasingRequisitionDeliveryReportData | null;
}

export const INITIAL_STATE: DeliveryReportState = {
  status: null,
  data: null
};

const deliveries = createSlice({
  name: 'delivery_report',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDeliveryReport.pending, (state, action) => {
      if (!action.meta.arg.id) {
        state.status = null;
        state.data = null;
      }
    });

    builder.addCase(
      getDeliveryReport.fulfilled,
      (state, action: PayloadAction<PurchasingRequisitionDeliveryReportData>) => {
        const { supplier_details, ...rest } = action.payload;

        state.data = { ...rest, supplier_details: _sortBy(supplier_details, s => s.id) };
      }
    );

    builder.addCase(getDeliveryReportStatus.pending, (state, action) => {
      if (!action.meta.arg.id) {
        state.status = null;
        state.data = null;
      }
    });

    builder.addCase(
      getDeliveryReportStatus.fulfilled,
      (state, action: PayloadAction<PurchasingRequisitionDeliveryReportStatus>) => {
        state.status = action.payload;
      }
    );
  }
});

export default deliveries.reducer;
