import { useState, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';

import { ProfileDrawer } from 'common/components/drawer';

import FormMain from './FormMain';

import config from './config';
import _get from 'lodash/get';
import * as pmsActions from 'common/components/pms/setup/store/actions';

import { useForm, useFormState, useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectSelectedSystem,
  selectCurrentSystem,
  selectCurrentSystemVessels
} from 'common/components/pms/setup/store/selectors';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import TitleHeader from 'common/components/pms/setup/views/system/specifications/jobs/jobs-form/TitleHeader';
import { periodicityEnums } from 'common/utils/fixed';
import InfoModal from '@/common/components/modals/InfoModal';
import SvgRender from '@/ts-common/components/general/SvgRender';
import errorInCircle from 'common/assets/svg/common/error-in-circle.svg';
import useRouter from 'use-react-router';
import paths from '@/routing/routes/_paths';

const INITIAL_VALUE = 1;

const JobsForm = ({ drawer, onFinishSave, selectedJobForEdit, onClose, isNewJob }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingJob, setIsFetchingJob] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { formState, collectValues, loadValues, handleSubmitError, isDirty } = useForm(config);
  const { fields, changeField, selectField, subStates, addSubform, removeSubform } =
    useFormState(formState);

  const { location } = useRouter();

  const [createMaintenanceJob, editMaintenanceJob, getMaintenanceJob] = useActions([
    pmsActions.createMaintenanceJob,
    pmsActions.editMaintenanceJob,
    pmsActions.getMaintenanceJob
  ]);

  const selectedSystem = useSelector(selectSelectedSystem);
  const currentSystem = useSelector(selectCurrentSystem);
  const currentSystemVessels = useSelector(selectCurrentSystemVessels);

  const isOnLibrarySetup = paths.pms_library === location.pathname;

  const onSubmit = async () => {
    const values = collectValues();

    if (!values || !selectedSystem) return;

    if (
      !isInfoModalOpen &&
      isDirty &&
      currentSystemVessels.length &&
      isOnLibrarySetup &&
      selectedJobForEdit
    ) {
      setIsInfoModalOpen(true);
      return;
    }

    const params = {
      title: values.title,
      description: values.description,
      assignee_crew_rank_id: values.assignee_crew_rank_id?.id,
      importance_id: values.importance_id,
      departments: values.departments,

      main_department_id: values.main_department_id,
      form_uids: (values.forms || []).map(f => f.uid),
      checklist_options: values.checklist_options?.filter(e => e.title) || [],
      is_drydock: values.is_drydock,
      is_safety: values.is_safety,

      maintenance: {
        is_class: values.is_class,

        job_type_id: values.job_type_id,
        spare_parts: values.spare_parts
          .map(el => ({ spare_part_id: el.spare_part_id?.id, quantity: el.quantity }))
          .filter(el => el.spare_part_id),
        attachments: values.attachments.map(el => el.id),
        attachments_is_required: values.attachments_is_required,
        remarks_is_required: values.remarks_is_required,
        time_spent_is_required: values.time_spent_is_required
      }
    };
    const periodicity = {};

    if (
      values.interval_unit === periodicityEnums.system_running_hours &&
      !values.second_interval_unit &&
      !values.second_interval
    ) {
      periodicity.type = periodicityEnums.system_running_hours;
      periodicity.system_running_hours = values.interval;
      periodicity.interval = null;
      periodicity.interval_unit = null;
    } else if (
      values.interval_unit === periodicityEnums.system_running_hours &&
      (values.second_interval_unit || values.second_interval)
    ) {
      periodicity.type = periodicityEnums.system_running_hours_or_interval;
      periodicity.system_running_hours = values.interval;
      periodicity.interval = values.second_interval || null;
      periodicity.interval_unit = values.second_interval_unit || null;
    } else if (values.interval_unit === periodicityEnums.as_needed) {
      periodicity.type = values.interval_unit;
      periodicity.system_running_hours = null;
      periodicity.interval = null;
      periodicity.interval_unit = null;
    } else {
      periodicity.type = periodicityEnums.interval;
      periodicity.system_running_hours = null;
      periodicity.interval = values.interval || null;
      periodicity.interval_unit = values.interval_unit || undnullefined;
    }
    params.periodicity = periodicity;
    params.system_id = selectedSystem;

    try {
      setIsSubmitting(true);

      if (selectedJobForEdit) {
        params.id = selectedJobForEdit;
        await editMaintenanceJob(params);
      } else {
        await createMaintenanceJob(params);
      }

      setIsSubmitting(false);
      setIsInfoModalOpen(false);
      onFinishSave();
      drawer.close();
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  const loadJob = useCallback(async () => {
    try {
      setIsFetchingJob(true);

      const job = await getMaintenanceJob({ id: selectedJobForEdit });
      const initialValues = selectDataFromState(job, config);

      loadValues({
        ...initialValues,
        main_department_id: isNewJob
          ? _get(currentSystem, 'crew_department_id', null)
          : job?.main_department_id,

        departments: isNewJob
          ? currentSystem.department_id
            ? [currentSystem.department_id]
            : []
          : job?.departments?.map(j => j.id),
        assignee_crew_rank_id: job.assignee_crew_rank,
        attachments: _get(job, 'maintenance_attachments', []),
        job_type_id: _get(job, 'maintenance_details.job_type.id', null),
        spare_parts: _get(job, 'maintenance_spare_parts', []).map(el => ({
          spare_part_id: el.spare_part,
          quantity: el.quantity
        })),
        interval:
          job.periodicity?.type === periodicityEnums.as_needed
            ? INITIAL_VALUE
            : job.periodicity?.system_running_hours || job.periodicity?.interval,
        interval_unit: job.periodicity?.type?.includes('running_hours')
          ? periodicityEnums.system_running_hours
          : job.periodicity?.type === periodicityEnums.as_needed
          ? periodicityEnums.as_needed
          : job.periodicity?.interval_unit,
        is_class: _get(job, 'maintenance_details.is_class', false),
        forms: (_get(job, 'forms', null) || []).map(({ form, id }) => ({
          job_form_id: id,
          ...form
        })),
        second_interval_unit: job.periodicity?.type?.includes('running_hours')
          ? job.periodicity?.interval_unit
          : null,
        second_interval: job.periodicity?.type?.includes('running_hours')
          ? job.periodicity?.interval
          : null,
        is_safety: _get(job, 'is_safety', false),
        is_drydock: _get(job, 'is_drydock', false),
        attachments_is_required: _get(job, 'maintenance_details.attachments_is_required', false),
        remarks_is_required: _get(job, 'maintenance_details.remarks_is_required', false),
        time_spent_is_required: _get(job, 'maintenance_details.time_spent_is_required', false)
      });

      setIsFetchingJob(false);
    } catch (e) {
      console.error(e);
      setIsFetchingJob(false);
    }
  }, [currentSystem, selectedJobForEdit, isNewJob, getMaintenanceJob, loadValues]);

  useEffect(() => {
    if (!drawer.isOpen && onClose) {
      onClose();
    }
  }, [drawer.isOpen, onClose]);

  useEffect(() => {
    if (drawer.isOpen) {
      if (selectedJobForEdit) {
        loadJob();
      } else {
        loadValues({
          main_department_id: _get(currentSystem, 'crew_department_id', null) || null,
          departments: currentSystem?.department_id ? [currentSystem?.department_id] : [],
          is_class: _get(currentSystem, 'is_class', false),
          spare_parts: []
        });
      }
    }
  }, [selectedJobForEdit, currentSystem, drawer.isOpen, loadJob, loadValues]);

  return (
    <ProfileDrawer
      drawer={drawer}
      size="lg"
      className={'pms-jobs-setup-drawer'}
      dontCloseOnClickOutside={true}
      isLoading={isFetchingJob}
      header={
        <div>
          <TitleHeader
            selectedJobForEdit={selectedJobForEdit}
            isSafety={fields?.is_safety}
            isDrydock={fields?.is_drydock}
            selectField={selectField}
          />
        </div>
      }
      footer={
        <>
          <Button
            color="cancel"
            className="p-0 fs-10 me-2"
            onClick={drawer.close}
            disabled={isSubmitting}
          >
            CANCEL
          </Button>
          <Button
            onClick={onSubmit}
            disabled={isSubmitting}
            color="primary"
            className="px-2 height-28 fs-10 d-flex align-items-center me-4"
          >
            SAVE
          </Button>
        </>
      }
    >
      <div className="w-100p h-100p overflow-hidden job-profile__body pb-2">
        <div className="ps-4 h-100p pe-2 pt-2">
          <FormMain
            fields={fields}
            selectField={selectField}
            changeField={changeField}
            subStates={subStates}
            addSubform={addSubform}
            removeSubform={removeSubform}
            jobId={selectedJobForEdit}
            isOpen={drawer.isOpen}
          />
        </div>
      </div>
      <InfoModal
        transparent
        backdropClassName="blurred-backdrop"
        container=".pms-jobs-setup-drawer"
        onAccept={onSubmit}
        isOpen={isInfoModalOpen}
        actionIcon={
          <SvgRender
            src={errorInCircle}
            className="text-orange"
            style={{ width: 88, height: 88 }}
          />
        }
        showHeader={false}
        transaparent
        actionText="OK"
        body={
          <div className="fs-18 mt-3 pb-3">
            The changes will not be reflected in the <br /> jobs that are currently open. All
            changes <br /> will be reflected in the next jobs that will <br /> be created, after the
            currently open jobs <br /> are marked as Done.
          </div>
        }
        size="md"
      />
    </ProfileDrawer>
  );
};

export default JobsForm;
