import { Link } from 'react-router-dom';
import { constructUrlPath } from '@/captain-reports/utils/helpers.js';
import { FC, PropsWithChildren } from 'react';
import { CaptainReportTemplateType } from '@/common/types/captain-reports';
import paths from 'routing/routes/_paths';

// eslint-disable-next-line react-refresh/only-export-components
export const reportURL = {
  port_statement: `${paths.captain_reports}/port-statement`,
  instructed_speed: constructUrlPath('instructed_speed'),
  system_running_hours: constructUrlPath('system_running_hours'),
  port_document_overview: constructUrlPath('port_document_overview'),
  passage_plan: constructUrlPath('passage_plan')
};

// eslint-disable-next-line react-refresh/only-export-components
export const getReportURL = (templateType: CaptainReportTemplateType) => {
  const url = reportURL[templateType as keyof typeof reportURL] || paths.captain_reports;

  return url;
};

const ReportLink: FC<
  PropsWithChildren<{ templateType: CaptainReportTemplateType; reportId: string | number }>
> = ({ templateType, reportId, children, ...rest }) => {
  return (
    <Link to={`${getReportURL(templateType)}/${reportId}`} {...rest}>
      {children}
    </Link>
  );
};

export default ReportLink;
