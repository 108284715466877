import ColoredTable from '@/common/components/colored-table';
import CollapsabledSubsystems from './CollapsabledSubsystems';
import EmpyState from './EmpyState';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import _get from 'lodash/get';
import { VesselSystemsAssignmentsData } from '@/common/types/vessel-systems';
import { FC, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectselectedAssignment } from '@/common/components/pms/setup/store/selectors';
import {
  getSingleSystem,
  getVesselAssignments,
  getVesselSubAssignments,
  setSelectedAssignment,
  setSelectedSystem,
  setSelectedTab
} from '@/common/components/pms/setup/store/actions';
import { selectPmsAssignVesselAssignmentToGroup } from '@/store/settings/selectors';
import PageLoader from '@/ts-common/components/general/PageLoader';

type TableProps = {
  vesselFilter: number | null;
  isLoading: boolean;
  systemSpeficationsIsCollapsed: boolean;
};

const Table: FC<TableProps> = ({
  vesselFilter,
  isLoading,
  systemSpeficationsIsCollapsed,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const selectedAssignment = useAppSelector(selectselectedAssignment);
  const assignmentHasGroup = useAppSelector(selectPmsAssignVesselAssignmentToGroup);
  const [isLoadingSystemSpefications, setIsLoadingSystemSpefications] = useState<boolean>(false);

  const onSelectSystem = useCallback(
    async (data: VesselSystemsAssignmentsData) => {
      setIsLoadingSystemSpefications(true);
      const currentSystem = data.vessel_system;
      const vesselId = data.vessel_id;

      dispatch(getSingleSystem({ id: currentSystem.id }));
      dispatch(setSelectedTab(vesselId));
      dispatch(setSelectedSystem(currentSystem.id));
      dispatch(setSelectedAssignment({ id: data.id }));

      if (!currentSystem.parent_id) {
        const assignments = await dispatch(
          getVesselAssignments({ vessel_id: vesselId, system_id: currentSystem.id })
        );
        const assignment = assignments.find(s => s.id === data.id);

        dispatch(setSelectedAssignment(assignment || assignments[0]));
      } else if (currentSystem.parent_id) {
        const assignments = await dispatch(
          getVesselAssignments({ vessel_id: vesselId, system_id: currentSystem.parent_id })
        );
        const assignment = assignments.find(s => s.id === data.id);

        dispatch(setSelectedAssignment(assignment || assignments[0]));
        dispatch(getVesselSubAssignments({ vessel_id: vesselId, parent_id: currentSystem.id }));
      }

      setIsLoadingSystemSpefications(false);
    },
    [dispatch]
  );

  return (
    <Container>
      <ColoredTable
        hideTopPagination
        hideTableReorder
        hideTableSearch
        hideTableFilters
        hideTableHead={!vesselFilter}
        collapsableRows={(data: VesselSystemsAssignmentsData) =>
          data.children?.length ? <CollapsabledSubsystems subsystems={data.children} /> : null
        }
        color="white"
        getRowClassName={(data: VesselSystemsAssignmentsData) =>
          selectedAssignment && selectedAssignment.id === data.id ? 'selected-system' : ''
        }
        emptyStateComponent={isLoading || vesselFilter ? null : <EmpyState />}
        rows={{
          description: (data: VesselSystemsAssignmentsData) => (
            <div
              className={`overflow-hidden pointer ${
                selectedAssignment && selectedAssignment.id === data.id ? 'fw-medium' : ''
              }`}
              onClick={() => onSelectSystem(data)}
            >
              <TextWithTooltip>{data.description}</TextWithTooltip>
            </div>
          ),
          vessel_system_group_id: (data: VesselSystemsAssignmentsData) => {
            const group = assignmentHasGroup ? data.group : data.vessel_system.group;

            return (
              <div className="overflow-hidden">
                <TextWithTooltip>{`${_get(group, 'name', '')}`}</TextWithTooltip>
              </div>
            );
          }
        }}
        {...rest}
      />

      <PageLoader isLoading={!systemSpeficationsIsCollapsed && isLoadingSystemSpefications} />
    </Container>
  );
};

const Container = styled.div`
  .colored-table {
    .cell-collapse-row {
      padding-left: ${variables.size4};
    }

    .collapse-toggle-header__top > button {
      color: ${variables.glaucous} !important;

      > .svg-wrap {
        border-color: currentColor;
      }
    }

    .app-table .collapsed-row-content__inner {
      padding: 0;
      margin-top: -2px;

      :before {
        display: none;
      }
    }

    .selected-system {
      box-shadow: 0 -2px 7px 0 rgb(0, 0, 0, 0.1) !important;
    }
  }
`;

export default Table;
