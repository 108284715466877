import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import useDrawer from 'common/components/drawer/useDrawer';
import { FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import Input from 'common/components/form/inputs/Input';
import { useForm, useFormState } from 'utils/hooks';
import { optionField, stringField, hiddenField } from 'common/utils/form/fieldTypes';
import {
  selectSystemGroupStateData,
  selectSystemGroupStateType
} from 'common/components/pms/setup/store/selectors';
import {
  setActiveSystemGroupState,
  createSystemGroup,
  updateSystemGroup
} from 'common/components/pms/setup/store/actions';

import AsyncSelector from 'common/components/selectors/AsyncSelector';

import { selectListOptionsFromStore } from 'store/lists/selectors';
import { constructDrawerTitle, constructDrawerButtonTitle } from './helpers';

const config = {
  id: hiddenField(),
  parent_id: hiddenField(),
  name: stringField({ required: true }),
  code: stringField({ required: true }),
  type: optionField()
};

const Form = ({ refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, collectValues, loadValues, hasErrors, resetForm } = useForm(config);
  const { fields, changeField, selectField } = useFormState(formState);
  const active = useSelector(selectSystemGroupStateData);
  const type = useSelector(selectSystemGroupStateType);
  const drawer = useDrawer(false);
  const listOptions = useSelector(state =>
    selectListOptionsFromStore(state, 'vessel-system-group-types')
  );

  const dispatch = useDispatch();

  const drawerTitle = useMemo(() => constructDrawerTitle(type, active), [active, type]);
  const drawerSaveButtonTitle = useMemo(() => constructDrawerButtonTitle(type), [type]);

  const onClose = useCallback(() => {
    resetForm();
    dispatch(setActiveSystemGroupState({ type: null, data: null }));
    drawer.close();
  }, [dispatch, drawer, resetForm]);

  useEffect(() => {
    if (type && type !== 'delete') {
      drawer.open();
    }
  }, [drawer, type]);

  useEffect(() => {
    if (drawer.isOpen) {
      resetForm();

      if (active?.id) {
        const { type: activeType, ...rest } = active;

        if (type !== 'add_sub_group') {
          const type = listOptions?.find(f => f?.label === activeType);

          loadValues({ ...rest, type });
        } else {
          loadValues({ parent_id: active?.id });
        }
      }
    }
  }, [active, drawer.isOpen, type, listOptions, loadValues, resetForm]);

  const onSubmit = async () => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSubmitting(true);

      const { type: formType, ...rest } = values;

      const params = {
        ...rest,
        type: formType?.label || null
      };

      if (type === 'create') {
        await dispatch(createSystemGroup(params));
      }

      if (type === 'edit') {
        await dispatch(updateSystemGroup(params));
      }

      if (type === 'add_sub_group') {
        await dispatch(createSystemGroup(params));
      }

      setIsSubmitting(false);
      refetchData();
      onClose();
    } catch (err) {
      setIsSubmitting(false);

      console.error(err);
    }
  };

  return (
    <Drawer {...drawer} close={onClose} className="spare-parts-drawer">
      <DrawerHeader>{drawerTitle}</DrawerHeader>

      <FormDrawer>
        <FormBody>
          <Input
            data-testid="pms-group-add-modal-code"
            onChange={changeField('code')}
            label="Code"
            placeholder="Add code"
            {...fields.code}
          />

          <Input
            onChange={changeField('name')}
            label="Group Name"
            placeholder="Add name"
            {...fields.name}
          />

          <AsyncSelector
            label="Type"
            placeholder="Select type"
            getOptionValue={option => option.name}
            getOptionLabel={option => option.name}
            isMulti={false}
            type="vessel-system-group-types"
            onChange={val => selectField('type')(val)}
            {...fields.type}
          />
        </FormBody>
      </FormDrawer>

      <FormFooter>
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={onClose}
          disabled={isSubmitting || hasErrors}
        >
          CANCEL
        </Button>

        <Button onClick={onSubmit} disabled={isSubmitting} color="primary" className="px-4">
          {drawerSaveButtonTitle}
        </Button>
      </FormFooter>
    </Drawer>
  );
};

export default Form;

Form.propTypes = {
  refetchData: PropTypes.func.isRequired
};
