import { Col } from 'reactstrap';
import { useMemo } from 'react';
import { selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';
import { selectMaintenanceJobHasSystemRunningHours } from 'common/components/pms/jobs/store/selectors';
import { selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTimeStamp } from '@/common/components/pms/jobs/store/selectors-ts.ts';
import RunningHoursDueInput from './RunningHoursDueInput';
import DueDateInput from './DueDateInput';
import { getConfig } from './helpers';
import UpdatedValueIcon from 'common/components/pms/jobs/inputs/maintenance/main/system-running-hours/UpdatedValueIcon';
import LastRhReportInfo from '@/common/components/pms/jobs/inputs/maintenance/main/system-running-hours/LastRhReportInfo.tsx';
import { useAppSelector } from '@/store/hooks';

const SystemRunningHours = ({ key, className, type }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const isJobLocked = useAppSelector(selectIsJobLocked);
  const hasSystemRunningHoursFlag = useAppSelector(selectMaintenanceJobHasSystemRunningHours);
  const lastRunningHoursTimestamp = useAppSelector(
    selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTimeStamp
  );

  const { styleClass, title, icon, ...configuration } = useMemo(
    () => getConfig(type, isJobLocked, isOnBoard, hasSystemRunningHoursFlag),
    [type, isJobLocked, isOnBoard, hasSystemRunningHoursFlag]
  );
  const isPrevious = useMemo(() => type === 'previous', [type]);
  const isDueTypeField = useMemo(() => type === 'due', [type]);

  const getColumnSize = () => {
    if (isDueTypeField && lastRunningHoursTimestamp) return 7;
    if (isPrevious && lastRunningHoursTimestamp) return 5;
    if (isDueTypeField) return 6;
    return 5;
  };

  const columnSize = getColumnSize();

  return (
    <Col key={key} xs={columnSize} className={`d-flex system-running-hour ${className || ''}`}>
      <div
        className={`system-running-hour__sidebar d-flex align-items-center flex-column ${
          styleClass || ''
        }`}
      >
        <div className="title fs-9 fw-bold text-white pt-1">{title}</div>
        <UpdatedValueIcon isDueTypeField={isDueTypeField} icon={icon} />
      </div>
      <div className="d-flex flex-column">
        <DueDateInput
          isPrevious={isPrevious}
          isDueTypeField={isDueTypeField}
          type={type}
          {...configuration}
        />
        <RunningHoursDueInput
          isPrevious={isPrevious}
          isDueTypeField={isDueTypeField}
          type={type}
          {...configuration}
        />
      </div>
      {isDueTypeField ? <LastRhReportInfo /> : null}
    </Col>
  );
};

export default SystemRunningHours;
