import {
  CiiReferenceBoundaryLowerBound,
  CiiRating,
  CiiReferenceBoundaryUpperBound,
  CiiReferenceType
} from '@/common/types/reports-enviromental';
import { InformationProps } from '@/ts-common/components/general/Information';
import { numberToStr } from 'common/utils/numbers';
import { constructCiiValues } from 'common/utils/vessels/helpers';
import { FC } from 'react';
import Information from 'common/components/general/Information';

type BoundaryProps = {
  rating: CiiRating;
  lowerBound: CiiReferenceBoundaryLowerBound;
  upperBound: CiiReferenceBoundaryUpperBound;
};

export const Boundary: FC<BoundaryProps> = ({ rating, lowerBound, upperBound }) => {
  return (
    <div className="d-flex align-items-start">
      <span className={`cii-rating-${rating} fw-bold`}>{rating}</span>:{' '}
      {constructCiiValues(upperBound, lowerBound)}
    </div>
  );
};

type VesselCiiReferenceYearlyTooltipProps = CiiReferenceType & Omit<InformationProps, 'message'>;

const VesselCiiReferenceYearlyTooltip: FC<VesselCiiReferenceYearlyTooltipProps> = ({
  boundaries,
  year,
  cii,
  ...rest
}) => {
  return (
    <Information
      hideArrow={!boundaries?.length}
      tooltipClassname={`${boundaries?.length ? '' : 'd-none'}`}
      {...rest}
      message={
        <div className={`d-flex flex-column justify-content-start align-items-start`}>
          <div className="d-flex align-items-center border-bottom border-platinum cpb-2">
            CII Ref for {year || '-'}:{' '}
            <span className="fw-bold">{cii || cii === 0 ? numberToStr(cii, 2, 2) : '-'}</span>
          </div>

          <div className="cpt-4">
            {boundaries?.map((boundary, index) => (
              <Boundary
                key={index}
                rating={boundary.rating}
                lowerBound={boundary.lower_bound}
                upperBound={boundary.upper_bound}
              />
            ))}
          </div>
        </div>
      }
    />
  );
};

export default VesselCiiReferenceYearlyTooltip;
