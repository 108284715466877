import {
  stringField,
  optionField,
  numberField,
  booleanField,
  hiddenField
} from 'common/utils/form/fieldTypes';

const config = {
  description: stringField({ isRequired: true }),
  notes: stringField(),
  vessel_system_group_id: optionField(),
  maker_id: optionField(),
  licensee_id: optionField(),
  type: stringField(),
  is_critical: booleanField({ initialValue: false }),
  is_navigational: booleanField({ initialValue: false }),
  is_environmental_critical: booleanField({ initialValue: false }),
  report_running_hours: booleanField({ initialValue: false }),
  manual_file_id: optionField(),
  drawing_file_id: optionField(),
  drawing_no: stringField(),
  budgetary_code: stringField(),
  crew_department_id: optionField(),
  department_id: optionField(),
  parent_id: numberField(),
  multiplicity: stringField(),
  attribute_group: optionField(),
  attributes: {
    attribute_id: hiddenField(),
    id: hiddenField(),
    name: stringField(),
    type: optionField(),
    value: optionField(),
    file: optionField()
  }
};

export default config;
