import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

import ColoredTable from 'common/components/colored-table';
import { useTable } from 'common/components/table';
import {
  setActiveSystemGroupState,
  deleteSystemGroup
} from 'common/components/pms/setup/store/actions';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';
import PageActions from 'components/layouts/page/table/PageActions';
import {
  selectSystemGroupStateType,
  selectSystemGroupStateData
} from 'common/components/pms/setup/store/selectors';
import { OuterTableWrapper } from 'common/components/colored-table/styles/WithNestedTable';
import CollapsedTable from './CollapsedTable';
import { getRows } from './helpers';
import Form from './Form';
import permissions from '@/common/utils/permissions/constants';

const SystemGroups = ({ label }) => {
  const dispatch = useDispatch();
  const type = useSelector(selectSystemGroupStateType);
  const active = useSelector(selectSystemGroupStateData);

  const setSelectedItem = useCallback(
    data => {
      dispatch(setActiveSystemGroupState(data));
    },
    [dispatch]
  );

  const onDelete = async id => {
    if (!id) return;

    try {
      await dispatch(deleteSystemGroup({ id }));
      table.refetchData();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedItem({ data: null, type: null });
    };
  }, [setSelectedItem]);

  const rows = useMemo(() => getRows(setSelectedItem), [setSelectedItem]);

  const table = useTable({
    label,
    columns: [
      {
        header: 'Code',
        key: 'code',
        sort: true,
        canFilter: false,
        minWidth: 265,
        maxWidth: 265
      },
      {
        header: 'Group Name',
        key: 'name',
        sort: true,
        minWidth: 430,
        maxWidth: 430
      },
      {
        header: 'Type',
        key: 'type',
        sort: true
      }
    ],
    defaultRequestParams: { visible: true, paging: true, sorting: true, filters: false }
  });

  return (
    <Container>
      <PageActions
        className="top-actions"
        actions={[
          {
            type: 'create',
            color: 'primary',
            text: 'Create new',
            dataCy: 'create-new-system-group',
            onClick: () => {
              setSelectedItem({ data: null, type: 'create' });
            },
            permissions: [permissions.office.pms.configuration.system_groups.edit]
          }
        ]}
      />

      <OuterTableWrapper>
        <ColoredTable
          hideTableFilter
          hideTableSearch
          collapsableRows={data =>
            data.system_groups?.length ? (
              <CollapsedTable data={data.system_groups} table={table} />
            ) : null
          }
          rows={rows}
          {...table}
        />
      </OuterTableWrapper>

      <Form refetchData={table.refetchData} />

      <DangerousActionModal
        onAccept={() => onDelete(active?.id)}
        onCancel={() => setSelectedItem({ type: null, data: null })}
        isOpen={type === 'delete'}
        header={'Delete System Group'}
      >
        {`Are you sure you want to delete ${active?.code ? `${active.code}.` : ''} ${
          active?.name
        }?`}
        <br /> This cannot be undone.
      </DangerousActionModal>
    </Container>
  );
};

const Container = styled.div`
  .top-actions {
    position: absolute;
    top: 0;
    right: 1%;
    width: auto;
  }
`;

export default SystemGroups;

SystemGroups.propTypes = {
  label: PropTypes.string.isRequired
};
