import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import SvgRender from 'common/components/general/SvgRender';
import pollution from 'common/assets/svg/common/pollution.svg';

import { FC, ReactNode } from 'react';

type FieldLabelProps = {
  label: ReactNode;
  subLabel?: ReactNode;
  info?: ReactNode;
  className?: string;
  tooltipClassName?: string;
  fieldKey?: string;
};

const FieldLabel: FC<FieldLabelProps> = ({
  label,
  subLabel,
  info,
  className,
  tooltipClassName = '',
  fieldKey
}) => {
  const { tooltipID, avoidRender } = useTooltipID('field-label-reports');

  if (avoidRender) return null;

  return (
    <div className="d-flex align-items-center" style={{ pointerEvents: 'all' }}>
      {fieldKey?.startsWith('cii_reduction_') && (
        <SvgRender src={pollution} style={{ width: 14, height: 14 }} className="text-red cme-4" />
      )}
      <span className={`${className ? className : ''}`} id={tooltipID}>
        {label}{' '}
        {subLabel ? (
          <span className="text-initial text-violet form-sublabel">{`${subLabel}`}</span>
        ) : null}{' '}
      </span>
      {info ? (
        <Tooltip innerClassName={tooltipClassName} target={tooltipID}>
          {info}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default FieldLabel;
