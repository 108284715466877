import { useState } from 'react';
import PropTypes from 'prop-types';

import AttributeInputs from './AttributeInputs';
import HeaderInput from './HeaderInput';
import { useFormState } from 'utils/hooks';
import { FormState } from 'common/entities/form/FormTypes';
import ViewAttributes from './ViewAttributes';
import { VesselAttributeType, VesselAttributesGroupType } from 'common/entities/pms/SetupTypes';
import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import FieldValue from '@/common/components/pms/setup/views/system/components/FieldValue';
import { Col, Row } from 'reactstrap';
import Spinner from '@/ts-common/components/general/Spinner';

const VesselSystemAttributes = ({ attributes, group, formState, isViewMode }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { selectField, subStates, setSubStates, fields } = useFormState(formState);
  const attributeStates = subStates('attributes');

  return (
    <div>
      {!isViewMode ? (
        <Row>
          <Col xs={8}>
            <HeaderInput
              group={fields.attribute_group}
              selectField={selectField}
              setIsLoading={setIsLoading}
              setSubStates={setSubStates}
            />
          </Col>
          {isLoading ? (
            <Col xs={4} className="px-0 d-flex align-items-end">
              <Spinner size={24} />
            </Col>
          ) : null}
          <Col xs={12} className="mt-2">
            <AttributeInputs attributeStates={attributeStates} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} className="d-flex flex-column">
            <FieldLabel>SYSTEM ATTRIBUTES</FieldLabel>
            <FieldValue>{group?.name ?? '-'}</FieldValue>
          </Col>
          <Col xs={12} className="mt-2">
            <ViewAttributes attributes={attributes} />
          </Col>
        </Row>
      )}
    </div>
  );
};

VesselSystemAttributes.propTypes = {
  isViewMode: PropTypes.bool.isRequired,
  formState: FormState,
  group: VesselAttributesGroupType,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: VesselAttributeType.isRequired,
      attribute_id: PropTypes.number,
      file: PropTypes.object,
      file_id: PropTypes.number,
      id: PropTypes.number.isRequired,
      value: PropTypes.string,
      vessel_system_id: PropTypes.number
    })
  )
};

export default VesselSystemAttributes;
