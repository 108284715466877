import { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import { selectListOptionsFromStore } from 'store/lists/selectors';

import SvgRender from 'common/components/general/SvgRender';
import calendarIcon from 'common/assets/svg/common/calendar.svg';

import * as listActions from 'store/lists/actions';

const Field = ({ fieldConfig, currentAssignment, fieldKey }) => {
  const { type, list, ...rest } = fieldConfig;
  const listOptions = useSelector(state => selectListOptionsFromStore(state, list));
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  useEffect(() => {
    if (list && !listOptions.length) fetchListOptions(list);
  }, []);

  let value = currentAssignment[fieldKey] ? currentAssignment[fieldKey] : '-';

  if (type === 'date' || type === 'date-input') {
    value = (
      <>
        <SvgRender src={calendarIcon} style={{ width: 14, height: 14 }} className="me-1" />
        <div>
          {currentAssignment[fieldKey]
            ? moment(currentAssignment[fieldKey]).format(rest?.format || 'DD/MM/YYYY')
            : '-'}
        </div>
      </>
    );
  }

  if (type === 'select') {
    value =
      currentAssignment[fieldKey] && listOptions
        ? listOptions.find(el => el.label === currentAssignment[fieldKey])?.name
        : '-';
  }

  return <div className={`fw-bold fs-12 d-flex align-items-center`}>{value}</div>;
};

export default Field;
