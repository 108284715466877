const retry = (fn, retriesLeft = 5, interval = 100) => {
  return new Promise((resolve, reject) => {
    const attempt = fn();

    if (!attempt || typeof attempt.then !== 'function') {
      reject(new Error('Dynamic import did not return a Promise'));
      return;
    }

    attempt.then(resolve).catch(error => {
      console.error(`Error loading component, retries left: ${retriesLeft}`, error);

      if (/loading chunk \d+ failed/i.test(error.message)) {
        // If chunk fails to load, force a reload
        console.warn('Chunk loading failed, reloading page...');
        window.location.reload();
        return;
      }

      if (retriesLeft <= 1) {
        reject(error);
      } else {
        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval)
            .then(resolve)
            .catch(reject);
        }, interval);
      }
    });
  });
};

export default retry;
