import React from 'react';
import { selectCurrentSystemVessels } from 'common/components/pms/setup/store/selectors';
import { useSelector } from 'react-redux';
import SvgRender from 'common/components/general/SvgRender';
import vessels from 'common/assets/svg/common/vessels.svg';

const SystemVessels = () => {
  const currentSystemVessels = useSelector(selectCurrentSystemVessels);

  return currentSystemVessels?.length ? (
    <>
      <div className="small-separator mx-2 bg-platinum py-1"></div>
      <div className="text-violet fs-12 d-flex align-items-center">
        <SvgRender src={vessels} style={{ width: 14, height: 14 }} className="cme-6" />
        {currentSystemVessels?.map(vessel => vessel.name).join(', ')}
      </div>
    </>
  ) : null;
};

export default SystemVessels;
