const selectInventoryReducer = state => state.inventory;

const selectInventoryHistoryDrawer = state => selectInventoryReducer(state)?.historyDrawer;

export const selectInventoryHistoryDrawerIsOpen = state =>
  selectInventoryHistoryDrawer(state)?.isOpen;

export const selectInventoryHistoryDrawerActive = state =>
  selectInventoryHistoryDrawer(state)?.active;

export const selectInventoryLatestSurvey = state => selectInventoryReducer(state)?.latestSurvey;

export const selectInventoryIsSavingSurvey = state => selectInventoryReducer(state)?.isSavingSurvey;

export const selectInventoryItemExpandedRowsIds = state =>
  selectInventoryReducer(state)?.inventoryExpandedRowsIds;

export const selectShowComments = state => selectInventoryReducer(state)?.showComments;
