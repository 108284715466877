import { AsyncSystemTaskResult, SystemTask, SystemTaskType } from '@/types/system-tasks';
import { get } from '@/utils/api';

export type GetSystemTasksParams = {
  type: SystemTaskType;
};

export const getSystemTasks = async () => {
  const response = await get<SystemTask[]>(`/system-tasks`);

  return response.data;
};

export const getSystemTask = async ({ id }: { id: number }) => {
  const response = await get<SystemTask>(`/system-tasks/${id}`);

  return response.data;
};

export const getSystemTaskResult = async ({ id }: { id: number }) => {
  const response = await get<AsyncSystemTaskResult>(`/system-tasks/${id}/result`);

  return response.data;
};
