import {
  FIELDS,
  ITEMS_CONFIGURATION_KEYS,
  ItemConfigurationKey,
  ItemFieldType,
  SUPPLIER_FIELDS
} from 'common/components/purchasing/requisition/categories/items/config';

const SUPLIER_COLUMNS_COUNT = Object.keys(SUPPLIER_FIELDS)?.length;

export const plainFields = [
  { ...FIELDS.code, editable: false },
  FIELDS.rob,
  FIELDS.requested_packaging_id,
  FIELDS.requested_quantity,
  { ...FIELDS.comments, label: 'COMMENTS' },
  { ...FIELDS.file, label: 'FILE' },
  FIELDS.revised_quantity,
  { key: 'suppliers', label: 'SUPPLIERS' },
  {
    ...FIELDS.sum_proposed_quantity,
    label: 'TOTAL PROPOSED QTY',
    editable: false
  },
  {
    ...FIELDS.sum_proposed_price,
    label: 'TOTAL PROPOSED USD',
    editable: false
  },
  {
    ...FIELDS.sum_approved_quantity,
    label: 'TOTAL APPROVED QTY',
    editable: false
  },
  {
    ...FIELDS.sum_approved_price,
    label: 'TOTAL APPROVED USD',
    editable: false
  },
  {
    ...FIELDS.sum_delivered_quantity,
    label: 'TOTAL DELIVERED QTY',
    editable: false
  },
  {
    ...FIELDS.sum_delivered_price,
    label: 'TOTAL DELIVERED USD',
    editable: false
  }
];

export const supplierFields = Object.keys(SUPPLIER_FIELDS).map(key => ({
  ...SUPPLIER_FIELDS[key],
  label: SUPPLIER_FIELDS[key]['equivalentCurrency']
    ? `${SUPPLIER_FIELDS[key]['label']} (EQUIVALENT)`
    : SUPPLIER_FIELDS[key]['label']
}));

export const initializeDefaultColumnState = () => {
  return plainFields.map((field, index) => ({ ...field, sort_index: index, visible: true }));
};

export const initializeDefaultSupplierColumnState = () => {
  return supplierFields
    .map((field, index) => ({ ...field, sort_index: index, visible: true }))
    .filter(col => !col.excludeFromTableConfiguration);
};

export const exportColumnConfig = (columns, supplierColumns) => {
  const supplierContainerIndex = columns.findIndex(el => el.key === 'suppliers');

  const appendedArray = [
    ...columns.slice(0, supplierContainerIndex + 1),
    ...supplierColumns,
    ...columns.slice(supplierContainerIndex + 1)
  ];

  return appendedArray.map(({ key, visible, editable }, i) => ({
    key,
    visible,
    editable,
    sort_index: i
  }));
};

export const importColumnConfig = config => {
  if (!config?.length) return [];

  const columns = [];
  const supplierColumns = [];
  const supplierContainerIndex = config.findIndex(el => el.key === 'suppliers');
  let startingIndexOfCommonField = null;

  //Since the config contains a custom column with col.key 'suppliers' we need to find the first non-supplier column
  //which comes after the supplier custom column. So we loop every non-supplier field and we find the min index which is what we need
  plainFields.forEach(field => {
    const foundIndex = config.findIndex(col => col.key === field.key);
    if (
      foundIndex !== -1 &&
      foundIndex > supplierContainerIndex &&
      ((startingIndexOfCommonField && foundIndex < startingIndexOfCommonField) ||
        !startingIndexOfCommonField)
    ) {
      startingIndexOfCommonField = foundIndex;
    }
  });

  //here we separate the columns for the drawer so we can also check if old configs contain the (EQUIVALENT) label for equivalent fields
  //and if not add it to their title, also we can filter out the delete column, think of this as a chance to fix our past mistakes :)
  config
    .filter(col => col.key !== 'delete')
    .forEach((el, i) => {
      if (i <= supplierContainerIndex || i >= startingIndexOfCommonField) {
        columns.push({ ...el, sort_index: columns.length });
      } else {
        supplierColumns.push({
          ...el,
          sort_index: supplierColumns.length
        });
      }
    });

  //After we push we check SUPLIER_COLUMNS_COUNT to check if there is any missing column
  if (supplierColumns?.length !== SUPLIER_COLUMNS_COUNT) {
    supplierFields.forEach(sf => {
      if (!supplierColumns.find(col => col.key === sf.key)) {
        supplierColumns.push({ ...sf, sort_index: supplierColumns.length, visible: false });
      }
    });
  }

  //After we push we check plainFields length to check if there is any missing column
  if (columns.length !== plainFields.length) {
    plainFields.forEach(f => {
      if (!columns.find(col => col.key === f.key)) {
        columns.push({ ...f, sort_index: columns.length, visible: false });
      }
    });
  }

  return { columns, supplierColumns };
};

export const getColumnVisibitity = (
  field: ItemFieldType,
  itemsTableConfiguration: ItemConfigurationKey[]
): boolean => {
  if (field.key === FIELDS.code.key) return true;
  if (!field?.itemsConfigurationKeys?.length) return false;

  if (
    itemsTableConfiguration.includes(ITEMS_CONFIGURATION_KEYS.usd) &&
    field.itemsConfigurationKeys.includes(ITEMS_CONFIGURATION_KEYS.usd)
  ) {
    // When USD only is selected, we show the USD-currency fields and some other fields (depending on the status)
    return true;
  } else if (
    itemsTableConfiguration.includes(ITEMS_CONFIGURATION_KEYS.packing) &&
    field.itemsConfigurationKeys.includes(ITEMS_CONFIGURATION_KEYS.packing)
  ) {
    return true;
  } else if (
    itemsTableConfiguration.includes(ITEMS_CONFIGURATION_KEYS.quality) &&
    field.itemsConfigurationKeys.includes(ITEMS_CONFIGURATION_KEYS.quality)
  ) {
    return true;
  } else if (
    itemsTableConfiguration.includes(ITEMS_CONFIGURATION_KEYS.discount) &&
    field.itemsConfigurationKeys.includes(ITEMS_CONFIGURATION_KEYS.discount)
  ) {
    return true;
  }

  return false;
};

export const getComparedViewColumnVisibitity = (field: ItemFieldType): boolean => {
  if (field.key === FIELDS.code.key) return true;

  return field.showInComparisonView ? true : false;
};
