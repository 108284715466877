import { useMutation } from '@tanstack/react-query';

import queryClient from '@/utils/query';
import {
  updateMaintenanceJobPrototypesVesselAssignmentActivations,
  UpdateMaintenanceJobPrototypesVesselAssignmentActivationsParams,
  VesselAssignmentActivation
} from './api';
import { MAINTENANCE_JOB_PROTOTYPES_VESSEL_ASSIGNMENT_ACTIVATIONS } from './query-keys';

const optimisticallyUpdateCurrentVesselAssignmentActivations = (
  currentVesselAssignmentActivations: VesselAssignmentActivation[],
  params: UpdateMaintenanceJobPrototypesVesselAssignmentActivationsParams
) => {
  return currentVesselAssignmentActivations.map(
    (currentVesselAssignmentActivation: VesselAssignmentActivation) => {
      if (currentVesselAssignmentActivation.id === params.vessel_system_assignment_id) {
        return {
          ...currentVesselAssignmentActivation,
          maintenance_job_classes: [
            {
              ...currentVesselAssignmentActivation.maintenance_job_classes[0],
              active: params.active
            }
          ]
        };
      }

      return currentVesselAssignmentActivation;
    }
  );
};

export const useUpdateMaintenanceJobPrototypesVesselAssignmentActivationsMutation = (
  onSettledExtra: () => void,
  jobTriggerPrototypeId: number | undefined
) => {
  return useMutation({
    mutationFn: updateMaintenanceJobPrototypesVesselAssignmentActivations,
    onMutate: async params => {
      const queryKey = [
        MAINTENANCE_JOB_PROTOTYPES_VESSEL_ASSIGNMENT_ACTIVATIONS,
        jobTriggerPrototypeId
      ];

      await queryClient.cancelQueries({
        queryKey
      });

      const cachedVesselAssignmentActivations = queryClient.getQueryData(queryKey);

      if (!cachedVesselAssignmentActivations) {
        return { cachedVesselAssignmentActivations: [] };
      }

      queryClient.setQueryData(
        queryKey,
        (currentVesselAssignmentActivations: VesselAssignmentActivation[]) =>
          optimisticallyUpdateCurrentVesselAssignmentActivations(
            currentVesselAssignmentActivations,
            params
          )
      );

      return { cachedVesselAssignmentActivations };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [MAINTENANCE_JOB_PROTOTYPES_VESSEL_ASSIGNMENT_ACTIVATIONS, jobTriggerPrototypeId],
        context?.cachedVesselAssignmentActivations || []
      );
    },
    onSettled: () => {
      onSettledExtra();
      queryClient.invalidateQueries({
        queryKey: [MAINTENANCE_JOB_PROTOTYPES_VESSEL_ASSIGNMENT_ACTIVATIONS, jobTriggerPrototypeId]
      });
    }
  });
};
