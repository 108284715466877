import React, { useState } from 'react';

import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';
import add from 'common/assets/svg/actions/add.svg';

import { useSelector, useDispatch } from 'react-redux';
import {
  toggleCategoryAssignmentDrawer,
  setActiveCategoriesGroup
} from 'store/purchasing/categories/actions';
import { selectIsCategoryAssignmentDrawerOpen } from 'store/purchasing/categories/selectors';
import { getPurchasingGroup } from 'common/components/purchasing/library-setup/store/actions';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import { getSystemGroup } from 'common/components/pms/setup/store/actions';
import { selectPmsSingleGroupIsLoading } from 'common/components/pms/setup/store/selectors';
import { selectPurchasingSingleGroupIsLoading } from 'common/components/purchasing/library-setup/store/selectors';

const CategoryAssignment = ({ group, origin }) => {
  const categoryCount = _get(group, 'categories.length', 0) || _get(group, 'categories_count', 0);
  const isOpen = useSelector(selectIsCategoryAssignmentDrawerOpen);
  const [isHovered, setIsHovered] = useState(false);
  const isLoading = useSelector(state =>
    origin === 'purchasing'
      ? selectPurchasingSingleGroupIsLoading(state)
      : selectPmsSingleGroupIsLoading(state)
  );
  const dispatch = useDispatch();
  const { tooltipID, avoidRender } = useTooltipID('category-count');

  const onClick = () => {
    if (isOpen) return;
    dispatch(setActiveCategoriesGroup({ ...group, origin }));
    dispatch(toggleCategoryAssignmentDrawer(true));
  };

  const getCategories = async () => {
    if (isLoading) return;

    try {
      if (origin === 'purchasing') await dispatch(getPurchasingGroup({ id: group.id }));
      else await dispatch(getSystemGroup({ id: group.id }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleMouseEnter = async () => {
    setIsHovered(true);

    if (!isLoading && categoryCount && !group.purchasing_category_groups?.length) {
      await getCategories();
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (categoryCount) {
    return (
      <>
        <div
          className="category-count bg-yellow d-flex align-items-center justify-content-center text-dark me-1 cursor-pointer"
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          id={tooltipID}
        >
          <strong>{categoryCount}</strong>
        </div>

        {!avoidRender && !isLoading && isHovered && group?.purchasing_category_groups?.length ? (
          <Tooltip
            isOpen={isHovered}
            target={tooltipID}
            innerClassName="min-width-fit max-width-fit"
          >
            <div className="text-start">
              {group?.purchasing_category_groups.map((category_group, index) => (
                <div
                  key={category_group.id}
                  className={`${
                    index === group?.purchasing_category_groups?.length - 1 ? '' : 'mb-1'
                  } fs-12 d-flex align-items-center`}
                >{`${category_group.category.name}${
                  category_group?.account?.name
                    ? ` | ${
                        category_group?.account?.code ? `${category_group?.account?.code}.` : ''
                      } ${category_group?.account?.name}`
                    : ''
                }`}</div>
              ))}
            </div>
          </Tooltip>
        ) : null}
      </>
    );
  } else {
    return (
      <div
        className="category-addition d-flex align-items-center justify-content-center cme-12 border border-dark cursor-pointer"
        onClick={onClick}
      >
        <SvgRender src={add} style={{ width: 6, height: 6 }} />
      </div>
    );
  }
};

export default CategoryAssignment;
