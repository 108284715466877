import { FC, ReactNode, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import copyToClipboard from 'copy-to-clipboard';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID.ts';

type CopyTextProps = {
  children: ReactNode;
  text?: string;
  customText?: string;
  infoTooltip?: string;
  tooltipClassName?: string;
  containerClassName?: string;
  onCopy?: () => Promise<void> | void;
  isDisabled?: boolean;
};

const CopyText: FC<CopyTextProps> = ({
  children,
  text,
  customText,
  infoTooltip,
  tooltipClassName,
  containerClassName,
  onCopy,
  isDisabled,
  ...rest
}) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const { avoidRender, tooltipID } = useTooltipID('copy-body');

  const copy = async () => {
    try {
      if (onCopy) {
        await onCopy();
      } else {
        if (text) {
          copyToClipboard(text);
        }
      }

      setIsTooltipActive(true);
      setTimeout(() => {
        setIsTooltipActive(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      setIsTooltipActive(false);
    }
  };

  return (
    <div className={isDisabled ? 'disabled' : 'cursor-pointer'} onClick={copy}>
      {!avoidRender && (
        <UncontrolledTooltip
          innerClassName={`copy-tooltip-container ${
            isTooltipActive || infoTooltip ? '' : 'copy-tooltip-container-hide'
          } ${tooltipClassName || ''}`}
          arrowClassName="d-none"
          placement="top"
          target={tooltipID}
          {...rest}
        >
          {isTooltipActive ? customText || 'Copied!' : infoTooltip || ''}
        </UncontrolledTooltip>
      )}

      <div id={tooltipID} className={`text-truncate lh-1 ${containerClassName || ''}`}>
        {children}
      </div>
    </div>
  );
};

export default CopyText;
