import _findKey from 'lodash/findKey';
import _pickBy from 'lodash/pickBy';
import { BaseInvoiceRequestParamsType } from '@/api/invoices/api';

import { AccountType } from '@/common/types/account';
import { Department } from '@/common/types/enums';
import { PermissionType } from '@/common/types/permissions';
import permissions from '@/common/utils/permissions/constants';
import { isAuthorized } from 'utils/permissions/authorize';
import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';
import { ConfigKey, permittedFormFields } from '@/views/accounting/invoices/form/invoice/config';

export const isDepartmentAccessDisabledByPermission = (
  { departments }: { departments: Department[] },
  permission: PermissionType,
  account: AccountType
) => {
  if (isAuthorized(account, [permission])) {
    const permissionDepartments = (getAuthorizedPermissionSettings(account, permission)
      ?.departments || []) as number[];
    const assignedDepartments = departments.map(v => v.id);

    return permissionDepartments.length && assignedDepartments.length
      ? !permissionDepartments.some(dep => assignedDepartments.includes(dep))
      : false;
  } else {
    return true;
  }
};

export const isActionDisabledByPermissions = (
  {
    departments,
    activeID
  }: { departments: Department[]; activeID: number | string | null | undefined },
  fieldKey: ConfigKey,
  account: AccountType
) => {
  if (!activeID) {
    // When in create mode, check only for the CREATE permission
    return !isAuthorized(account, [permissions.office.accounting.invoices.create]);
  }

  const fieldPermission = _findKey(permittedFormFields, keys => keys.includes(fieldKey));

  return fieldPermission
    ? isDepartmentAccessDisabledByPermission({ departments }, fieldPermission, account)
    : true;
};

export const removeUnauthorizedFormFieldParams = (
  requestParams: BaseInvoiceRequestParamsType,
  {
    departments,
    activeID,
    account
  }: {
    departments: Department[];
    activeID: number | string | null | undefined;
    account: AccountType;
  }
) => {
  return _pickBy(
    requestParams,
    (_, key) => !isActionDisabledByPermissions({ departments, activeID }, key as ConfigKey, account)
  );
};
