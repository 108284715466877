import { FC, PropsWithChildren, useState } from 'react';
import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

const getTooltipVisibilityModifier = (element: Node) => {
  const openedDrawer = document.getElementById('drawer-container')?.querySelector('.drawer.open');
  const isContained =
    element instanceof HTMLElement ? element.closest('.drawer.open') !== null : false;

  if (openedDrawer && !isContained) {
    return false;
  } else {
    return true;
  }
};

const Tooltip: FC<PropsWithChildren<UncontrolledTooltipProps>> = ({
  target,
  placement,
  innerClassName = '',
  popperClassName = '',
  children,
  ...rest
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  return (
    <UncontrolledTooltip
      popperClassName={`${popperClassName} ${!isTooltipVisible ? `d-none` : ''}`}
      target={target}
      placement={placement ?? 'top'}
      innerClassName={innerClassName ?? 'show-more-tooltip'}
      boundariesElement="window"
      fade={false}
      {...rest}
      modifiers={[
        {
          name: 'tooltipInDrawer',
          enabled: true,
          phase: 'afterWrite',
          fn: ({ state }) => {
            setIsTooltipVisible(getTooltipVisibilityModifier(state.elements.reference as Node));
          }
        }
      ]}
    >
      {children}
    </UncontrolledTooltip>
  );
};

export default Tooltip;
