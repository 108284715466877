import TopPagination from '@/common/components/table/main/TopPagination';
import { TablePaging, TableRequestParams } from '@/common/types/front-entities/table';
import { FC } from 'react';

type TopFiltersProps = {
  refetchData: (params: TableRequestParams) => void;
  paging: TablePaging;
};

const TopFilters: FC<TopFiltersProps> = ({ refetchData, paging }) => {
  return (
    <div className="d-flex align-items-center cmb-12">
      <div className="text-end flex-1">
        <TopPagination standAlone state={{ paging }} fetchData={refetchData} />
      </div>
    </div>
  );
};

export default TopFilters;
