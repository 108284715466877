import Image from './Image';
import Document from './Document';
import UnknownType from './UnknownType';
import Email from './Email';
import { FileWithStream, FileType, FileWithRawEmail, Attachment } from '@/ts-common/types/files';
import { FC } from 'react';
import { RotateDirectionType } from '../../store/types';

type PreviewProps = {
  file: FileWithStream;
  containerWidth: number;
  containerHeight: number;
  type: FileType;
  isGalleryHovered: boolean;
  rotateDirection: RotateDirectionType;
};

const getComponentType = (type: FileType) => {
  switch (type) {
    case 'image':
      return Image;
    case 'doc':
    case 'pdf':
    case 'spreadsheets':
    case 'txt':
      return Document;
    case 'eml':
      return Email;
    default:
      return UnknownType;
  }
};

const Preview: FC<PreviewProps> = ({
  file,
  containerWidth,
  containerHeight,
  type,
  isGalleryHovered,
  rotateDirection
}) => {
  const CenterComponent = getComponentType(type);
  const parsedFile = type === 'eml' ? (file as FileWithRawEmail) : (file as Attachment);

  return (
    <CenterComponent
      isGalleryHovered={isGalleryHovered}
      fileType={type}
      file={parsedFile}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      rotateDirection={rotateDirection}
    />
  );
};

export default Preview;
