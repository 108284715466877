import PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { VesselAttributeType } from 'common/entities/pms/SetupTypes';
import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import FieldValue from '@/common/components/pms/setup/views/system/components/FieldValue';

const ViewAttributes = ({ attributes }) => {
  return (
    <Row className="g-0">
      {attributes.map(({ attribute, value, file, file_id }, index) => {
        if (attribute?.type === 'text' || attribute?.type === 'number')
          return (
            <Col key={`attr-${index}`} xs={4} className="d-flex flex-column text-primary mb-2">
              <FieldLabel>{attribute?.name ?? '-'}</FieldLabel>
              <FieldValue>{value}</FieldValue>
            </Col>
          );

        return (
          <Col key={`attr-${index}`} xs={4} className="d-flex flex-column text-primary mb-2">
            <FieldLabel>{attribute?.name ?? '-'}</FieldLabel>
            <UploadFiles files={file_id ? [file] : []} onChange={() => null} viewOnly={true} />
          </Col>
        );
      })}
    </Row>
  );
};

ViewAttributes.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: VesselAttributeType.isRequired,
      attribute_id: PropTypes.number,
      file: PropTypes.object,
      file_id: PropTypes.number,
      id: PropTypes.number.isRequired,
      value: PropTypes.string,
      vessel_system_id: PropTypes.number
    })
  )
};

export default ViewAttributes;
