export const columns = [
  {
    header: 'ASSIGNMENT IDENTIFIER',
    key: 'assignment_identifier',
    headerClassName: 'text-primary fw-bold',
    className: 'text-primary fw-medium fs-12 rounded-9'
  },
  {
    header: 'VESSEL',
    key: 'vessel_name',
    headerClassName: 'text-primary fw-bold',
    className: 'text-primary fw-medium fs-12 py-1 rounded-9'
  },
  {
    header: '',
    key: 'activation',
    minWidth: 52,
    maxWidth: 52
  }
];
