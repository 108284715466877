import { createSelector } from 'reselect';

export const selectCompanyReducer = state => state.companies;
export const selectComparyProfileReducer = state => state.companies.profile;
export const selectCompanyAccountReducer = state => state.companies.account;

export const selectCompanyProfileId = state => selectComparyProfileReducer(state)?.id;

export const selectCompanyExternalIds = state => selectComparyProfileReducer(state)?.external_ids;

export const selectComparyProfileName = createSelector(
  selectComparyProfileReducer,
  company => company?.company_name
);

// Accounting
export const selectCompanyAccountCode = state => selectCompanyAccountReducer(state)?.code;

export const selectCompanyAccountName = state => selectCompanyAccountReducer(state)?.name;

export const selectCompanyAccountIsLoading = state => selectCompanyReducer(state)?.accountLoading;

export const selectCompanyAccountExternalIds = state =>
  selectCompanyAccountReducer(state)?.external_ids;

export const selectCompanyAccountParentGroupName = state =>
  selectCompanyAccountReducer(state)?.group?.name;
