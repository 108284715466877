import { useCallback, useMemo } from 'react';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';

import useActions from 'common/utils/hooks/useActions';
import Switch from 'common/components/form/inputs/Switch';
import BodyCellActions from './BodyCellActions';
import BodyCellCiiValue from './BodyCellCiiValue';
import AttachmentsButton from './AttachmentsButton';
import CellWarningTooltip from './CellWarningTooltip';
import ActionsField from './ActionsField';

import { formatDate, displayDate } from 'common/utils/dates';
import { numberToStr } from 'common/utils/numbers';
import { selectTableRowCellWarning } from 'common/components/table/store/selectors';
import * as tableActions from 'common/components/table/store/actions';
import { useAppSelector } from '@/store/hooks';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import AuthCheck from 'components/permissions/AuthCheck';

const formatFieldData = (col, data, isOnBoard) => {
  const value = _get(data, col.data_key, _get(data, col.key, ''));
  const isUserLocalDate = col.key === 'updated_at' || col.key === 'created_at';

  if (col.key === 'id' && isOnBoard) return data?.orca_id || data?.id;

  if (col.type === 'number') {
    return numberToStr(value);
  } else if (col.type === 'price') {
    return numberToStr(value, 2, 2);
  } else if (col.type === 'date') {
    return formatDate(value, { time: true });
  } else if (col.type === 'integer') {
    return numberToStr(value, 0, 0);
  } else if (col.type === 'datetime') {
    if (col.showUtc === false || isUserLocalDate) {
      return formatDate(value);
    } else {
      return displayDate(value);
    }
  } else if (col.type === 'cii') {
    return <BodyCellCiiValue column={col} data={data} />;
  } else {
    return value;
  }
};

const renderField = (col, row, data, setDangerousModal, groupedCol, rowIndex, isOnBoard) => {
  if (row) {
    if (col.field) {
      if (col.field === 'toggle') {
        const value = _get(data, col.key, false);

        return (
          <AuthCheck
            permissions={row?.permissions}
            avoidCheck={!row?.permissions}
            unAuthorizedRender={
              <Switch
                name={col.key}
                value={value}
                switchClassName="justify-content-center"
                className="mb-0"
                size={'sm'}
                disabled={true}
              />
            }
          >
            <Switch
              name={col.key}
              value={value}
              onChange={() => row.onChange({ id: data.id, [col.key]: !value })}
              switchClassName="justify-content-center"
              className="mb-0"
              size={'sm'}
              disabled={row?.disabled ? row.disabled(data) : false}
            />
          </AuthCheck>
        );
      } else if (
        col.field === 'numberInput' ||
        col.field === 'textInput' ||
        col.field === 'listSelect'
      ) {
        return <ActionsField data={data} col={col} row={row} type={col.field} />;
      } else if (col.field === 'actions') {
        return (
          <div className="d-inline-flex align-items-center pe-1 app-table--body--row__actions--inner">
            <BodyCellActions
              setDangerousModal={setDangerousModal}
              col={col}
              row={row}
              rowIndex={rowIndex}
              data={data}
            />
          </div>
        );
      } else if (col.field === 'attachments') {
        return <AttachmentsButton row={row} data={data} />;
      }
    } else if (_isFunction(row)) {
      return row(data, col, groupedCol, rowIndex);
    }
  } else {
    if (col.truncate) {
      return (
        <TextWithTooltip className="h-auto">
          {formatFieldData(col, data, isOnBoard)}
        </TextWithTooltip>
      );
    }

    return formatFieldData(col, data, isOnBoard);
  }
};

const BodyCell = ({
  col,
  row,
  data,
  groupedCol,
  dataGroup,
  setDangerousModal,
  style,
  isLastSticky,
  isLastCell,
  groupWidth,
  isGroup,
  rowIndex,
  label,
  onCellDoubleClick = () => {},
  highlightColumnOnHover
}) => {
  const [setTableHoveredColumn] = useActions([tableActions.setTableHoveredColumn]);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const cellWarning = useAppSelector(state =>
    selectTableRowCellWarning(state, label, rowIndex, col?.key)
  );

  const setColumnOnHover = useCallback(
    key => {
      if (col.key) setTableHoveredColumn({ key, table: label });
    },
    [label, col.key]
  );

  const cellProps = useMemo(() => {
    const props = {};

    if (highlightColumnOnHover && !col?.avoidColumnHover) {
      props.onMouseEnter = () => setColumnOnHover(col?.key);
    }

    return props;
  }, [highlightColumnOnHover, col?.key, col?.avoidColumnHover]);

  const getClassNames = useCallback(column => {
    let classNames = [];
    const isSticky = isGroup ? groupedCol.sticky : column.sticky;

    classNames.push(
      `col${column.width || groupWidth ? `-${groupWidth ? groupWidth : column.width}` : ''}`
    );

    if (isSticky) classNames.push('sticky');
    if (isLastSticky) classNames.push('last-sticky');
    if (isLastCell) classNames.push('last-cell');

    if (column.field === 'actions') {
      classNames.push('app-table--body--row__actions');
      if (column.visible) {
        classNames.push('app-table--body--row__actions--visible');
      }
    }
    if (column.field === 'attachments')
      classNames.push('app-table--body--row__attachments', 'col-auto');

    if (isGroup) classNames.push('body-group-cell', 'bg-base');

    return classNames.join(' ');
  }, []);

  if (col.hidden) return null;

  const formattedStyles = isGroup ? { ...style, left: col?.left, right: col?.right } : style;

  return (
    <div
      data-group={dataGroup}
      data-cy={col.key}
      data-key={col.key}
      data-type={col?.type || ''}
      className={`cell ${getClassNames(col)} ${
        col.className ? col.className : col.bodyClassName ? col.bodyClassName : ''
      }`}
      style={style ? formattedStyles : null}
      onDoubleClick={() => onCellDoubleClick(data, col, isGroup)}
      {...cellProps}
    >
      {cellWarning ? (
        <CellWarningTooltip warning={cellWarning}>
          {renderField(col, row, data, setDangerousModal, groupedCol, rowIndex, isOnBoard)}
        </CellWarningTooltip>
      ) : (
        renderField(col, row, data, setDangerousModal, groupedCol, rowIndex, isOnBoard)
      )}
    </div>
  );
};

export default BodyCell;
