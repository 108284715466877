import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useState, useMemo, useCallback } from 'react';
import _get from 'lodash/get';
import useDrawer from 'common/components/drawer/useDrawer';

import ColoredTable from 'common/components/colored-table';
import { useTable } from 'common/components/table';
import { cloneSystem, deleteSystem } from 'common/components/pms/setup/store/actions';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { OuterTableWrapper } from 'common/components/colored-table/styles/WithNestedTable';

import PageActions from 'components/layouts/page/table/PageActions';
import Form from './Form';

import {
  columns,
  getRows,
  drawerTypes,
  modalDetails,
  VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEMS
} from './helpers';
import permissions from '@/common/utils/permissions/constants';

const VesselSystems = ({ label }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState();
  const [type, setType] = useState();
  const drawer = useDrawer(false);

  const onDelete = async id => {
    if (!id) return;

    try {
      await dispatch(deleteSystem({ id }));
      table.refetchData();
    } catch (err) {
      console.error(err);
    }
  };

  const onClone = async id => {
    if (!id) return;

    try {
      await dispatch(cloneSystem({ id }));
      table.refetchData();
    } catch (err) {
      console.error(err);
    }
  };

  const table = useTable({
    label,
    columns,
    top: {
      filters: [{ name: VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEMS, operation: 'oneOf', value: null }]
    }
  });

  const setSelectedItem = useCallback(
    (data, type) => {
      setType(type);
      setActive(data);

      if (drawerTypes.includes(type)) {
        drawer.open();
      }
    },
    [drawer]
  );

  const rows = useMemo(() => getRows(setSelectedItem), [setSelectedItem]);

  return (
    <Container>
      <PageActions
        className="top-actions"
        actions={[
          {
            type: 'create',
            color: 'primary',
            text: 'Create new',
            dataCy: 'create-new-vessel-system',
            onClick: () => {
              setSelectedItem(null, 'create');
            },
            permissions: [permissions.office.pms.library.systems.create]
          }
        ]}
      />

      <OuterTableWrapper>
        <ColoredTable
          hideTopPagination={false}
          hideTableFilters={false}
          hideTableSearch={false}
          hideTableReorder={false}
          collapsableRows={data => {
            const subSystems = _get(data, 'children') || [];
            if (!subSystems.length) return;

            return (
              <div className="ps-4">
                <ColoredTable
                  hideTopPagination
                  hideTableFilters
                  hideTableSearch
                  hideTableReorder
                  hideTableHead
                  rows={rows}
                  {...table}
                  state={{ data: subSystems }}
                />
              </div>
            );
          }}
          rows={rows}
          {...table}
        />
      </OuterTableWrapper>

      <Form active={active} drawer={drawer} type={type} refetchData={table.refetchData} />

      <DangerousActionModal
        transparent
        actionIcon={modalDetails[type]?.actionIcon}
        onAccept={() => (type === 'delete' ? onDelete(active?.id) : onClone(active?.id))}
        closeModal={setSelectedItem}
        actionHoverColor={modalDetails[type]?.color}
        isOpen={type === 'delete' || type === 'clone_system'}
        actionText={modalDetails[type]?.actionText}
        header={modalDetails[type]?.header}
        body={
          <div>
            <div>
              You are about to {type === 'delete' ? 'delete' : 'clone'} {active?.description}.{' '}
            </div>
            <div>Are you sure you want to continue?</div>
          </div>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  .top-actions {
    position: absolute;
    top: 0;
    right: 1%;
    width: auto;
  }
`;

export default VesselSystems;

VesselSystems.propTypes = {
  label: PropTypes.string.isRequired
};
