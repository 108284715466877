import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { useFormState, useForm, useActions } from 'utils/hooks';

import CollapseForm from 'common/components/collapse/CollapseForm';
import Spinner from 'common/components/general/Spinner';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';

import {
  selectAssignmentsLoading,
  selectRunningHoursCaptainReportId
} from 'common/components/pms/setup/store/selectors';
import * as pmsActions from 'common/components/pms/setup/store/actions';

import Form from './form';
import Field from './Field';
import runningHours from 'captain-reports/assets/svg/running-hours.svg';
import ReportLink from 'captain-reports/components/ReportLink';
import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import { selectPmsAssignVesselAssignmentToGroup } from '@/store/settings/selectors';
import { useAppSelector } from '@/store/hooks';

const AssignmentTab = ({
  isSubSystem,
  title,
  headerLink,
  config,
  hideDeleteAction = true,
  deleteAction,
  currentAssignment,
  afterSave
}) => {
  const keys = Object.keys(config) || [];
  const formConfig = Object.assign({}, ...keys.map(e => ({ [e]: config[e].fieldType })));
  const { formState, loadValues, collectValues } = useForm(formConfig);
  const { fields, changeField, selectField } = useFormState(formState);

  const [updateVesselAssignment] = useActions([pmsActions.updateVesselAssignment]);

  const [showForm, setShowForm] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const loading = useSelector(selectAssignmentsLoading);
  const captainReportId = useSelector(selectRunningHoursCaptainReportId);
  const isGroupVisible = useAppSelector(selectPmsAssignVesselAssignmentToGroup);

  const onEditClick = () => {
    setShowForm(true);
  };

  const onClose = () => {
    setShowForm(false);
  };

  const onDelete = () => {
    try {
      deleteAction();

      setShowForm(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (currentAssignment) {
      loadValues({
        ...currentAssignment
      });
    }
  }, [currentAssignment, loadValues]);

  const onSave = async () => {
    const values = collectValues();
    if (!values) return;

    const { group, ...rest } = values;
    const params = { ...rest, id: currentAssignment.id };

    if (isGroupVisible && !isSubSystem) params.vessel_system_group_id = group?.id || null;

    try {
      await updateVesselAssignment(params);
      afterSave && afterSave();

      onClose();
    } catch (e) {}
  };

  const deleteModalToggleHandler = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  useEffect(() => {
    setShowForm(false);
  }, [currentAssignment?.id, loading]);

  if (!currentAssignment && !loading) return null;

  return (
    <CollapseForm
      circledButtonProps={{ style: { width: 20, height: 20 }, svgStyle: { width: 11, height: 11 } }}
      setIsEditing={onEditClick}
      isEditing={showForm}
      hideExpandButton={true}
      className="mt-1 main-collapse"
      reversedExpandBtn={true}
      onClose={onClose}
      onSave={onSave}
      onRemove={hideDeleteAction ? null : deleteModalToggleHandler}
      headerClassName="w-100p"
      header={
        <div className="d-flex align-items-center justify-content-between fs-12">
          <div className="lh-1 text-violet">{title}</div>
          {headerLink && captainReportId && (
            <div className="me-4 text-moody-blue">
              <ReportLink
                templateType="system_running_hours"
                reportId={captainReportId}
                className="d-flex align-items-center fw-medium text-decoration-none text-moody-blue"
                target="_blank"
              >
                Go to Captain Report
                <SvgRender
                  src={runningHours}
                  style={{ width: 20, height: 20 }}
                  className="cms-12"
                />
              </ReportLink>
            </div>
          )}
        </div>
      }
    >
      {showForm ? (
        <>
          <Form
            keys={keys}
            config={config}
            fields={fields}
            changeField={changeField}
            selectField={selectField}
            isSubSystem={isSubSystem}
          />

          <DangerousActionModal
            transparent
            action={'Delete'}
            onAccept={onDelete}
            closeModal={deleteModalToggleHandler}
            isOpen={isDeleteModalOpen}
            actionText={'DELETE'}
            header={'Delete Assignment'}
            body={`Are you sure you want to delete ${currentAssignment?.description} ? This cannot be undone.`}
          />
        </>
      ) : (
        <div className={`system-view position-relative`}>
          <div className="divider" />
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="d-flex ">
                <Row className="w-100p">
                  {keys.map(key => (
                    <Col
                      key={key}
                      xs={config[key]?.form?.view?.width || 'auto'}
                      className={`${config[key]?.form?.view?.className || ''}`}
                    >
                      {config[key]?.form?.view?.customComponent ? (
                        config[key]?.form?.view?.customComponent(fields[key]?.value, {
                          currentAssignment,
                          isSubSystem
                        })
                      ) : (
                        <>
                          <FieldLabel>{config[key].label}</FieldLabel>
                          <Field
                            fieldConfig={config[key]}
                            currentAssignment={currentAssignment}
                            fieldKey={key}
                          />
                        </>
                      )}
                    </Col>
                  ))}
                </Row>
              </div>
            </>
          )}
        </div>
      )}
    </CollapseForm>
  );
};

export default AssignmentTab;
