import { useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { Row, Col, Label } from 'reactstrap';
import Input from 'common/components/form/inputs/Input';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Textarea from 'common/components/form/inputs/Textarea';
import Checkbox from 'common/components/form/inputs/Checkbox';
import UploadFiles from 'common/components/form/inputs/upload-files';
import Information from 'common/components/general/Information';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { selectCurrentSystem } from 'common/components/pms/setup/store/selectors';

import GroupSelector from '@/common/components/selectors/VesselSystemGroupSelector.tsx';
import Switch from 'common/components/form/inputs/Switch';
import CustomMakerOption from 'common/components/pms/setup/views/system/components/CustomMakerOption';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';

const Form = ({ formState }) => {
  const { fields, changeField, selectField, setFieldValue } = useFormState(formState);

  const currentSystem = useSelector(selectCurrentSystem);
  const groupsIsVisible = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const getDefaultOptions = async () => {
    const listOptions = await getInitialAsyncValues('parties', null, null, {
      type: 'company',
      role: 'maker'
    });

    const modifiedListOptions = listOptions?.length
      ? listOptions
      : [{ id: 'create', title: 'create' }];

    return modifiedListOptions;
  };

  const loadModifiedOptions = async search => {
    const listOptions = await getAsyncOptions(search, 'parties', {
      type: 'company',
      role: 'maker'
    });

    const modifiedListOptions = listOptions?.length
      ? listOptions
      : [{ id: 'create', title: 'create' }];

    return modifiedListOptions;
  };

  return (
    <Row>
      <Col xs={4}>
        <Input
          placeholder="Add no."
          label="DRAWING NO."
          onChange={changeField('drawing_no')}
          {...fields.drawing_no}
        />
      </Col>
      <Col xs={6}>
        <UploadFiles
          label="DRAWING FILE"
          uploadText="Upload file"
          group="vessel-systems"
          files={fields.drawing_file.value ? [fields.drawing_file.value] : []}
          onChange={files => selectField('drawing_file')(files && files.length ? files[0] : null)}
          singleUpload={true}
        />
      </Col>
      <Row>
        <Col xs={4}>
          <Input
            placeholder="Add no."
            label="POSITION NO."
            onChange={changeField('part_position_no')}
            {...fields.part_position_no}
          />
        </Col>
        <Col xs={4}>
          <Input
            placeholder="Add no."
            label="PART NO."
            onChange={changeField('part_no')}
            {...fields.part_no}
          />
        </Col>

        <Col xs={4}>
          <Select
            label="UNIT"
            placeholder="Select unit"
            className="mb-0"
            isAsync={false}
            isMulti={false}
            onChange={selectField('part_unit')}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            options={[
              { label: 'Piece', value: 'piece' },
              { label: 'Set', value: 'set' }
            ]}
            {...fields.part_unit}
          />
        </Col>
      </Row>
      <Col xs={12}>
        <Input
          placeholder="Add description"
          label="DESCRIPTION"
          onChange={changeField('description')}
          {...fields.description}
        />
      </Col>
      {groupsIsVisible && (
        <Col xs={12}>
          <GroupSelector
            key={currentSystem?.vessel_system_group_id}
            placeholder="Select group"
            className="mb-2"
            onChange={({ value }) => selectField('vessel_system_group_id')(value)}
            label="GROUP"
            listParams={{ parent_id: currentSystem?.vessel_system_group_id }}
            isClearable
            {...fields.vessel_system_group_id}
          />
        </Col>
      )}

      <Col xs={8}>
        <Select
          label={
            <div className="d-flex align-items-center">
              <div className="lh-14">Sub-Maker</div>
              <Information
                svgStyle={{ width: 14, height: 14 }}
                className="cms-12 text-primary cpt-2"
                tooltipClassname="text-start max-width-344"
                message='Available options come from Menu > Contacts > Companies page, where Company`s Type is "Maker”.'
              />
            </div>
          }
          placeholder="Select sub-maker"
          onChange={selected =>
            selected?.id === 'create'
              ? window.open('/companies/create')
              : setFieldValue('submaker_id', selected)
          }
          defaultOptions={getDefaultOptions}
          loadOptions={loadModifiedOptions}
          components={{ Option: CustomMakerOption }}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.full_name}
          isAsync
          isClearable
          {...fields.submaker_id}
        />
      </Col>
      <Col xs={4}>
        <Input
          placeholder="Add no."
          label={<div className="lh-16">SUB-MAKER PART NO.</div>}
          onChange={changeField('submaker_part_no')}
          {...fields.submaker_part_no}
        />
      </Col>
      <Col xs={12} className="d-flex fs-12">
        <Checkbox
          label="IHM Related"
          value={fields.ihm_related.value}
          onChange={() => selectField('ihm_related')(!fields.ihm_related.value)}
          className="text-primary"
        />
        <Information
          svgStyle={{ width: 14, height: 14 }}
          className="cms-12 text-primary cpt-2"
          message="Inventory of Hazardous Materials"
        />
      </Col>
      <Col xs={8}>
        <Input
          placeholder="Add no."
          label="NOX TECHNICAL FILE IMO NO."
          onChange={changeField('nox_technical_file_imo_no')}
          {...fields.nox_technical_file_imo_no}
        />
      </Col>

      <Col xs={12} className="form-group-spacing">
        <UploadFiles
          label="PHOTOS AND OTHER INFO"
          uploadText="Upload file(s)"
          group="vessel-systems"
          files={fields.attachments.value ? fields.attachments.value : []}
          onChange={files => selectField('attachments')(files)}
        />
      </Col>

      <Col xs={12}>
        <div className="d-flex align-items-center cmb-4">
          <Label className="form-label cme-12 mb-0">MINIMUM REQUIRED NO. OF SPARES ONBOARD</Label>

          <Information
            svgStyle={{ width: 14, height: 14 }}
            className="text-primary mt-0 pt-0"
            tooltipClassname="text-start max-width-fit"
            message={
              <div>
                We are going to show a warning on jobs using this part,
                <br />
                when ROB drops below this value.
              </div>
            }
          />
        </div>

        <Row className="align-items-center" noGutters>
          <Col xs={8}>
            <NumberInput
              placeholder="Add no."
              onChange={changeField('minimum_required_rob')}
              {...fields.minimum_required_rob}
            />
          </Col>
          <Col xs={4} className="ps-1">
            <Switch
              value={!fields.is_rob_requirement_official?.value}
              onChange={() =>
                selectField('is_rob_requirement_official')(
                  !fields.is_rob_requirement_official?.value
                )
              }
              className="pb-0"
              size="sm"
              switchClassName="flex-row-reverse justify-content-start violet-orange-switch"
              labelClassName={`me-auto cms-4 text-nowrap ${
                fields.is_rob_requirement_official?.value ? 'text-violet' : 'text-orange'
              }`}
              label={fields.is_rob_requirement_official?.value ? 'Official' : 'Company Requirement'}
            />
          </Col>
        </Row>
      </Col>

      <Col xs={12}>
        <Row>
          <Col xs={8}>
            <NumberInput
              label="OPTIMAL NO. OF SPARES ONBOARD"
              placeholder="Add no."
              onChange={changeField('optimal_rob')}
              {...fields.optimal_rob}
            />
          </Col>
        </Row>
      </Col>

      <Col xs={12}>
        <Textarea
          placeholder="Add some comments"
          label="COMMENTS"
          onChange={changeField('comments')}
          {...fields.comments}
        />
      </Col>
    </Row>
  );
};

export default Form;
