import React, { useCallback, useEffect, useState } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import Job from './job';
import useDrawer from 'common/components/drawer/useDrawer';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';

import Spinner from 'common/components/general/Spinner';
import CollapseForm from 'common/components/collapse/CollapseForm';

import * as pmsActions from 'common/components/pms/setup/store/actions';
import {
  selectSelectedSystem,
  selectPmsJobs,
  selectPmsJobsLoading
} from 'common/components/pms/setup/store/selectors';

import PmsModal from 'common/components/pms/setup/views/components/modals/PmsModal';
import JobsForm from './jobs-form';

import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

const Jobs = ({ systemFormType }) => {
  const drawer = useDrawer(false);
  const jobsList = useSelector(selectPmsJobs);
  const jobsLoading = useSelector(selectPmsJobsLoading);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedJobForEdit, setSelectedJobForEdit] = useState(false);
  const [selectedJobForDelete, setSelectedJobForDelete] = useState(false);

  const selectedSystem = useSelector(selectSelectedSystem);

  const [getMaintenanceJobs, deleteMaintenanceJob] = useActions([
    pmsActions.getMaintenanceJobs,
    pmsActions.deleteMaintenanceJob
  ]);

  useEffect(() => {
    if (selectedSystem) {
      getMaintenanceJobs({ system_id: selectedSystem });
    }
  }, [selectedSystem]);

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedJobForDelete(null);
  };

  const onAcceptDelete = async () => {
    try {
      if (selectedSystem) {
        await deleteMaintenanceJob({ system_id: selectedSystem, id: selectedJobForDelete.id });
        setSelectedJobForDelete(null);
        getMaintenanceJobs({ system_id: selectedSystem });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFinishSave = () => {
    if (selectedJobForEdit) {
      setSelectedJobForEdit(null);
    }
    getMaintenanceJobs({ system_id: selectedSystem });
  };

  const onEdit = (e, id) => {
    e.stopPropagation();
    setSelectedJobForEdit(id);
    drawer.open();
  };

  const onClose = useCallback(() => {
    setSelectedJobForEdit(null);
  }, []);

  const onDelete = (e, job) => {
    e.stopPropagation();
    setSelectedJobForDelete(job);
    setIsDeleteModalOpen(true);
  };

  const onAdd = () => {
    drawer.open();
  };

  return (
    <CollapseForm
      circledButtonProps={{ style: { width: 20, height: 20 }, svgStyle: { width: 11, height: 11 } }}
      hideExpandButton={true}
      className="mt-1 main-collapse"
      hideActions={true}
      reversedExpandBtn={true}
      header={<div className="fs-12 lh-1 text-violet">Jobs</div>}
    >
      <div className="system-view spare-parts jobs text-primary  position-relative mt-1">
        {systemFormType ? null : jobsLoading ? (
          <Spinner />
        ) : jobsList.length ? (
          <div>
            {jobsList.map(el => (
              <Job key={el.id.toString()} job={el} onEdit={onEdit} onDelete={onDelete} />
            ))}
          </div>
        ) : null}
        {!jobsLoading ? (
          <AuthCheck permissions={[permissions.office.pms.library.systems.edit_jobs]}>
            <CircledButton
              onClick={onAdd}
              type={'add'}
              disabled={systemFormType}
              className={`small_circled-btn ${jobsList.length ? 'cmt-12' : ''}`}
              label="Add Job"
              svgStyle={{ height: 7, width: 7 }}
              style={{ width: 16, height: 16 }}
            />
          </AuthCheck>
        ) : null}
        <JobsForm
          onClose={onClose}
          onAdd={onAdd}
          drawer={drawer}
          onFinishSave={onFinishSave}
          selectedJobForEdit={selectedJobForEdit}
        />
        <PmsModal
          isOpen={isDeleteModalOpen}
          header="Delete Job"
          closeModal={closeDeleteModal}
          description={
            selectedJobForDelete ? (
              <div>
                Are you sure you want to delete the job{' '}
                <strong>{selectedJobForDelete.title}</strong>?
                <br /> This cannot be undone.
              </div>
            ) : (
              ''
            )
          }
          onAccept={onAcceptDelete}
        />
      </div>
    </CollapseForm>
  );
};

export default Jobs;
