import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import moment from 'moment';
import { validateNumber } from './number';
import { INVALID_FIELD_MESSAGE, REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import { selectPreviousCaptainReport } from 'captain-reports/store/selectors';
import { numberToStr } from 'common/utils/numbers';

export const isHiddenFromVesselFieldValid = (field, templateValidation) => {
  switch (templateValidation.type) {
    case 'float':
      const { isValid } = validateNumber(field, templateValidation);

      return isValid;
    default:
      return true;
  }
};

export const validateFieldFromTemplate = (field, templateValidation, setError) => {
  switch (templateValidation.type) {
    case 'float':
      const { validationType, isValid } = validateNumber(field, templateValidation);

      if (!isValid && templateValidation?.is_required && validationType === 'required') {
        setError('required');

        return {
          ...field,
          error: REQUIRED_FIELD_MESSAGE
        };
      } else if (!isValid && !field.comment) {
        setError('template');

        return {
          ...field,
          error: INVALID_FIELD_MESSAGE
        };
      } else if (isValid && (field.comment || field.error)) {
        return {
          ...field,
          error: null,
          comment: null
        };
      }
      return { ...field };
    default:
      return { ...field };
  }
};

export const validateField = (field, state, setError, fieldKey, status, section = {}) => {
  const templateValidation = _get(state, `captainReports.templateFieldValidations[${fieldKey}]`);

  if (_isArray(field)) {
    return field.map((el, index) => {
      return loopFields(el, state, setError, status, {
        sectionLabel: fieldKey,
        sectionActiveTab: index
      });
    });
  } else {
    if (field.validation) {
      const error = field.validation(state, field.value, status, fieldKey, section);

      if (error) {
        setError('required');
      }

      return {
        ...field,
        error
      };
    } else if (templateValidation) {
      return validateFieldFromTemplate(field, templateValidation, setError);
    } else if (field.error) {
      setError(field.isRequired ? 'required' : '');

      return {
        ...field,
        error: field.error
      };
    } else {
      return {
        ...field
      };
    }
  }
};

export const loopFields = (params, state, setError, status, section) => {
  let newState = {};

  Object.keys(params).forEach(fieldKey => {
    newState[fieldKey] = validateField(
      params[fieldKey],
      state,
      setError,
      fieldKey,
      status,
      section
    );
  });

  return newState;
};

export const validateForm = (params, state, status) => {
  let errors = { required: false, template: false };

  const setError = type => {
    errors = { ...errors, [type]: true };
  };

  const newState = loopFields(params, state, setError, status);

  return {
    ...newState,
    errors
  };
};

export const validateCharterParty = (state, value, status) => {
  const isOnboard = state.isOnBoard;

  if (isOnboard) return null;

  if (!value && status === 'approved') {
    return 'Required for report approval';
  }

  return null;
};

export const validateWeatherCourse = (_, value) => {
  if (!value && value !== 0) {
    return REQUIRED_FIELD_MESSAGE;
  }

  return null;
};

export const validateTimeZone = (_, value) => {
  if (!value && value !== 0) {
    return REQUIRED_FIELD_MESSAGE;
  }

  return null;
};

export const validateTimeStampIsNull = (_, value) => {
  if (!value) {
    return REQUIRED_FIELD_MESSAGE;
  }

  return null;
};

export const validateTimeStamp = (state, value) => {
  const isOnboard = state.isOnBoard;

  if (!value) {
    return REQUIRED_FIELD_MESSAGE;
  }

  const timeZone = _get(state, 'captainReportsForm.timezone.value', null);
  if (!timeZone && timeZone !== 0) {
    return null;
  }

  const previousTimeStamp = _get(state, 'captainReports.totals.last_report_timestamp', null);

  const previousLocalTimeStamp = _get(state, 'captainReports.previousReport.local_timestamp', null);

  if (!previousTimeStamp || !isOnboard) {
    return null;
  }

  const timeStamp = _get(state, 'captainReportsForm.timestamp.value', null);

  if (moment(timeStamp).isSameOrBefore(moment(previousTimeStamp))) {
    return `A Report on ${moment(previousLocalTimeStamp).format('DD/MM/YYYY HH:mm')} exists`;
  }

  return null;
};

export const validateDeliveryCurrentPort = () => {
  // Have it as required in template, but prevent validating in form

  return null;
};

export const validateMeRevCounter = (state, value, _, fieldKey, section) => {
  // ME Rev Counter field (must be >= the previous report value)
  if (!value && value !== 0) {
    return REQUIRED_FIELD_MESSAGE;
  }

  const previousReportData = selectPreviousCaptainReport(state);
  const previousRevCounter = _get(
    previousReportData,
    `${section?.sectionLabel}[${section?.sectionActiveTab}].${fieldKey}.value`
  );

  if (previousRevCounter && previousRevCounter !== 0 && value < previousRevCounter) {
    return `Previous: ${numberToStr(previousRevCounter)}`;
  }

  return null;
};
