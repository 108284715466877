import { useEffect } from 'react';
import { FC } from 'react';
import { History } from 'history';

import SvgRender from 'common/components/general/SvgRender';
import brokenPage from 'assets/svg/common/broken-page.svg';
import arrow from 'assets/svg/common/arrow-404.svg';

type ErrorBoundaryFallbackProps = {
  history: History;
};

const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = ({ history }) => {
  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      if (e.state !== null) {
        history.go(0);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="error-page" data-cy="error_page">
      <SvgRender src={brokenPage} style={{ width: '40vmax', height: '48vmin' }} />
      <div className="text-container fs-24 fs-hd-28">
        <div className="text-primary">Oops... Something went wrong.</div>
        <div className="text-primary text-center">We{`'`}re working on it.</div>
        <div
          className="mt-3 mt-hd-5 d-flex align-items-center justify-content-center cursor-pointer"
          onClick={() => {
            history.push('/');
            history.go(0);
          }}
        >
          <div className="text-violet text-center">Let’s get you home safely</div>
          <SvgRender
            src={arrow}
            style={{ width: '4vmin', height: '4vmin' }}
            className="animated-arrow text-violet ms-5"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
