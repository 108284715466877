import ShadowBox from '@/common/components/general/ShadowBox';
import { useAppSelector } from '@/store/hooks';
import { FC } from 'react';
import {
  selectReviewProcessRestartedAt,
  selectReviewProcessRestartedBy
} from '@/common/components/review-process/store/selectors-ts';
import { displayDate } from '@/ts-common/utils/dates';

type RestartedLogProps = {
  activeEntityId: number | string;
};

const RestartedLog: FC<RestartedLogProps> = ({ activeEntityId }) => {
  const restartedBy = useAppSelector(state =>
    selectReviewProcessRestartedBy(state, activeEntityId)
  );
  const restartedAt = useAppSelector(state =>
    selectReviewProcessRestartedAt(state, activeEntityId)
  );

  return (
    <ShadowBox
      color="lighter-blue"
      className="px-2 cpy-6 cmb-12 cmt-12 fw-bold fs-12 text-primary"
      flat
    >
      The Review Process was <span className="text-green">restarted</span> by {restartedBy} at&nbsp;
      {displayDate(restartedAt)}
    </ShadowBox>
  );
};

export default RestartedLog;
