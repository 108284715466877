import { stringField, optionField, numberField, booleanField } from 'common/utils/form/fieldTypes';

const config = {
  title: stringField({ required: true }),
  description: stringField(),
  job_type_id: optionField(),
  importance_id: stringField({ required: true }),
  assignee_crew_rank_id: optionField(),
  is_class: booleanField({ initialValue: false }),
  main_department_id: optionField(),
  attachments: optionField({ initialValue: [] }),
  attachments_is_required: booleanField({ initialValue: false }),
  departments: optionField({ initialValue: [] }),
  spare_parts: {
    spare_part_id: optionField(),
    quantity: numberField({ required: true })
  },

  interval: numberField({ required: true }),
  interval_unit: optionField({ required: true }),
  second_interval: numberField(),
  second_interval_unit: optionField(),
  checklist_options: optionField(),

  forms: optionField(),
  is_safety: booleanField({ initialValue: false }),
  is_drydock: booleanField({ initialValue: false }),
  remarks_is_required: booleanField({ initialValue: false }),
  time_spent_is_required: booleanField({ initialValue: false })
};

export default config;
