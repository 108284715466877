import { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import Input from 'common/components/form/inputs/Input';
import { useForm, useFormState } from 'utils/hooks';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import {
  createSubsystem,
  createSystem,
  updateSystem
} from 'common/components/pms/setup/store/actions';
import NumberInput from 'common/components/form/inputs/NumberInput';

import GroupSelector from '@/common/components/selectors/VesselSystemGroupSelector.tsx';
import Information from 'common/components/general/Information';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { constructDrawerTitle, constructDrawerButtonTitle } from './helpers';
import Checkbox from 'common/components/form/inputs/Checkbox';

import {
  getMakerDefaultOptions,
  loadModifiedOptions
} from 'common/components/pms/setup/views/system/specifications/system-details/form';
import CustomMakerOption from 'common/components/pms/setup/views/system/components/CustomMakerOption';

import config from 'views/pms/library/vessel-systems/config';

const Form = ({ active, drawer, type, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, collectValues, loadValues, hasErrors, resetForm } = useForm(config);
  const { fields, changeField, selectField, setFieldValue } = useFormState(formState);
  const parentGroupId = active?.vessel_system_group_id;
  const systemType = useMemo(
    () => (active?.parent_id ? 'subSystem' : 'system'),
    [active?.parent_id]
  );

  const dispatch = useDispatch();

  const drawerTitle = useMemo(() => constructDrawerTitle(type, active), [active, type]);
  const drawerSaveButtonTitle = useMemo(() => constructDrawerButtonTitle(type), [type]);

  const onClose = useCallback(() => {
    resetForm();
    drawer.close();
  }, [drawer, resetForm]);

  useEffect(() => {
    if (drawer.isOpen) {
      resetForm();

      if (active?.id) {
        if (type !== 'add_sub_system') {
          loadValues({
            ...active,
            vessel_system_group_id: active?.group || null,
            maker_id: active?.maker || null,
            licensee_id: active?.licensee || null,
            is_critical: active?.is_critical || null,
            is_environmental_critical: active?.is_environmental_critical || null,
            report_running_hours: active?.report_running_hours || null
          });
        }
      }
    }
  }, [active, drawer.isOpen, type, loadValues, resetForm]);

  const onSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      setIsSubmitting(true);

      const {
        vessel_system_group_id,
        maker_id,
        licensee_id,
        is_navigational,
        is_critical,
        is_environmental_critical,
        report_running_hours,
        ...rest
      } = values;

      const params = {
        ...rest,
        vessel_system_group_id: vessel_system_group_id?.id || null,
        maker_id: maker_id?.id || null,
        licensee_id: licensee_id?.id || null,
        is_navigational: is_navigational || false,
        is_critical: is_critical || false,
        is_environmental_critical: is_environmental_critical || false,
        report_running_hours: report_running_hours || false
      };

      if (type === 'create') {
        await dispatch(createSystem(params));
      }

      if (type === 'edit') {
        await dispatch(updateSystem({ ...params, id: active?.id }));
      }

      if (type === 'add_sub_system') {
        await dispatch(createSubsystem({ ...params, parent_id: active?.id })).unwrap();
      }

      setIsSubmitting(false);
      refetchData();
      onClose();
    } catch (err) {
      setIsSubmitting(false);

      console.error(err);
    }
  };

  return (
    <Drawer {...drawer} close={onClose}>
      <DrawerHeader>{drawerTitle}</DrawerHeader>

      <FormDrawer>
        <FormBody>
          <Row className="mt-1">
            {type === 'add_sub_system' && (
              <Col xs={12}>
                <Row className="d-flex align-items-center w-100p">
                  <Col xs={3}>
                    <NumberInput
                      allowNegative={false}
                      placeholder="Add no."
                      onChange={changeField('multiplicity')}
                      label="Multiplicity"
                      {...fields.multiplicity}
                    />
                  </Col>

                  <Col>
                    <div className="fs-12 text-primary text-primary cmt-2">
                      Same sub-system(s) on system
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            <Col xs={12}>
              <Input
                placeholder="Add description"
                onChange={changeField('description')}
                label="Description"
                {...fields.description}
              />
            </Col>

            <Col xs={12}>
              <div className="form-label fs-10">GROUP</div>

              <GroupSelector
                placeholder="Select group"
                onChange={({ value }) => selectField('vessel_system_group_id')(value)}
                isClearable={true}
                key={parentGroupId}
                isStyled={false}
                listParams={{ parent_id: parentGroupId }}
                {...fields.vessel_system_group_id}
              />
            </Col>

            <Col xs={7}>
              <StyledSelect
                showLabel
                invisible={false}
                label={
                  <div className="d-flex align-items-center">
                    <div className="lh-14">Maker</div>
                    <Information
                      svgStyle={{ width: 12, height: 12 }}
                      className="cms-12 text-primary"
                      tooltipClassname="text-start max-width-344"
                      message='Available options come from Menu > Contacts > Companies page, where Company`s Type is "Maker”.'
                    />
                  </div>
                }
                placeholder="Select maker"
                onChange={selected =>
                  selected?.id === 'create'
                    ? window.open('/companies/create')
                    : setFieldValue('maker_id', selected)
                }
                defaultOptions={getMakerDefaultOptions}
                loadOptions={loadModifiedOptions}
                isAsync
                defaultOptionsTriggerChange={drawer.isOpen}
                isClearable
                getOptionValue={option => option.id}
                getOptionLabel={option => option.full_name}
                components={{ Option: CustomMakerOption }}
                {...fields.maker_id}
              />
            </Col>

            <Col xs={5}>
              <Input
                placeholder="Add type"
                onChange={changeField('type')}
                label="Type"
                {...fields.type}
              />
            </Col>

            <Col xs={7}>
              <StyledSelect
                showLabel
                label={
                  <div className="d-flex align-items-center">
                    <div className="lh-14">Licensee</div>
                    <Information
                      svgStyle={{ width: 12, height: 12 }}
                      className="cms-12 text-primary"
                      tooltipClassname="text-start max-width-344"
                      message='Available options come from Menu > Contacts > Companies page, where Company`s Type is "Maker”.'
                    />
                  </div>
                }
                placeholder="Select licensee"
                onChange={selected =>
                  selected?.id === 'create'
                    ? window.open('/companies/create')
                    : setFieldValue('licensee_id', selected)
                }
                defaultOptions={getMakerDefaultOptions}
                loadOptions={loadModifiedOptions}
                defaultOptionsTriggerChange={drawer.isOpen}
                invisible={false}
                isAsync
                isClearable
                getOptionValue={option => option.id}
                getOptionLabel={option => option.full_name}
                components={{ Option: CustomMakerOption }}
                {...fields.licensee_id}
              />
            </Col>

            <Col xs={5}>
              <Input
                placeholder="Add no"
                label="Drawing No."
                className="mb-3"
                onChange={changeField('drawing_no')}
                {...fields.drawing_no}
              />
            </Col>

            <Col xs={'auto'} className="pe-3">
              <Checkbox
                label="Critical"
                value={fields.is_critical.value}
                onChange={e => selectField('is_critical')(e.target.checked)}
                className="mb-2 text-primary"
              />
            </Col>

            <Col xs={'auto'} className="pe-3">
              <Checkbox
                label="Environmental"
                value={fields.is_environmental_critical.value}
                onChange={e => selectField('is_environmental_critical')(e.target.checked)}
                className="mb-2 text-primary"
              />
            </Col>

            <Col xs={'auto'} className="pe-3">
              <Checkbox
                label="Navigational"
                value={fields.is_navigational.value}
                onChange={e => selectField('is_navigational')(e.target.checked)}
                className="mb-2 text-primary"
              />
            </Col>

            {systemType === 'system' && type !== 'add_sub_system' ? (
              <Col xs="auto">
                <Checkbox
                  label="Running Hours to be reported"
                  value={fields.report_running_hours.value}
                  onChange={e => selectField('report_running_hours')(e.target.checked)}
                  className="mb-2 text-primary"
                />
              </Col>
            ) : null}
          </Row>
        </FormBody>
      </FormDrawer>

      <FormFooter>
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={onClose}
          disabled={isSubmitting || hasErrors}
        >
          CANCEL
        </Button>

        <Button onClick={onSubmit} disabled={isSubmitting} color="primary" className="px-4">
          {drawerSaveButtonTitle}
        </Button>
      </FormFooter>
    </Drawer>
  );
};

export default Form;

Form.propTypes = {
  refetchData: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['edit', 'add_sub_system', 'delete', 'clone_system', 'create']),
  active: PropTypes.object,
  drawer: DrawerState.isRequired
};
