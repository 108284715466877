import FileContainer from './FileContainer';
import ProfileImage from 'common/components/general/ProfileImage';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import img from 'common/assets/svg/files/img.svg';
import { Size } from './_types';
import { File } from 'common/entities/files/FileTypes';
import { imageUrlSelector } from 'common/utils/image-size';
import { icon } from '@/ts-common/utils/files.ts';
import { isFileSynced } from '@/api/files/api';
import FileRemoveButton from './FileRemoveButton';
import FileSyncTooltip from '@/ts-common/components/form/inputs/upload-files/FileSyncTooltip';

import useTooltipID from 'common/utils/hooks/useTooltipID';

const FilePreview = ({
  size,
  file,
  previewImageBaseUrl,
  viewOnly,
  canRemove,
  onFileClick,
  onFileRemove,
  imageSizeLabel,
  singleUpload,
  index
}) => {
  const { tooltipID, avoidRender } = useTooltipID('file-preview');
  const { is_synced } = isFileSynced(file);

  return (
    <div
      className={`file-preview ${!singleUpload ? 'me-1' : ''} d-flex flex-column ${
        !singleUpload ? 'cpb-6' : ''
      } ${size === 'full' ? 'w-100p' : null}`}
    >
      <FileContainer size={size}>
        <Thumbnail
          size={size}
          id={tooltipID}
          onClick={() => (is_synced ? onFileClick(file) : null)}
          data-testid="files-thumbnail"
          data-cy={`file-${index + 1}`}
        >
          {file.mime_type?.startsWith('image') ? (
            <ProfileImage
              className="w-100p h-100p"
              image={
                previewImageBaseUrl +
                imageUrlSelector({ file: file, label: imageSizeLabel || null })
              }
              fallback={img}
            />
          ) : (
            <ProfileImage
              className="w-100p h-100p file-fallback"
              fallback={icon(file.mime_type, file.extension)}
            />
          )}
        </Thumbnail>
        {!viewOnly && canRemove && (
          <FileRemoveButton file={file} size={size} onClick={() => onFileRemove(file)} />
        )}
      </FileContainer>
      {size === 'lg' && (
        <FileContainer size={size} height="initial">
          <div
            className="text-truncate text-nowrap fs-9 fw-medium cmt-4 text-preview"
            dataCy="filename"
          >
            {file?.filename}
          </div>
        </FileContainer>
      )}

      {avoidRender ? null : <FileSyncTooltip file={file} tooltipID={tooltipID} />}
    </div>
  );
};

const spacing = { lg: '26%' };

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  border-radius: inherit;
  cursor: pointer;

  .profile-image-wrap,
  .profile-image-wrap > img {
    border-radius: inherit;
  }

  .file-fallback {
    padding: ${({ size }) => spacing?.[size] || '12%'};
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${variables.squidInk};
    opacity: 0.25;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    display: none;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
`;

FilePreview.propTypes = {
  size: Size.isRequired,
  file: File.isRequired,
  viewOnly: PropTypes.bool,
  canRemove: PropTypes.bool,
  previewImageBaseUrl: PropTypes.string,
  imageSizeLabel: PropTypes.string,
  singleUpload: PropTypes.bool,
  onFileClick: PropTypes.func,
  onFileRemove: PropTypes.func
};

export default FilePreview;
