import React, { useMemo } from 'react';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import FieldError from 'captain-reports/templates/components/FieldError';
import * as reportActions from 'captain-reports/store/actions';
import useError from 'captain-reports/utils/useError';
import {
  selectHiddenFromVesselTemplateFieldValidation,
  isReportLocked,
  selectFieldTemplateValidation,
  selectFieldTemplateTooltip
} from 'captain-reports/store/selectors';
import { isHiddenFromVesselFieldValid } from 'captain-reports/templates/config/validations';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import useComment from 'captain-reports/utils/useComment';

const ValidationWrapper = ({
  errorClassName,
  className,
  field,
  value,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  children,
  staticPosition = true,
  labelClassName,
  customAllowedOptions,
  right
}) => {
  const [setComment] = useActions([reportActions.setComment]);
  const tooltip = useSelector(selectFieldTemplateTooltip(field.key));

  const validationHiddenFromVessel = useSelector(state =>
    selectHiddenFromVesselTemplateFieldValidation(state, field.key)
  );
  const templateValidation = useSelector(selectFieldTemplateValidation(field.key));
  const isOnBoard = useSelector(state => state.isOnBoard);

  const isLocked = useSelector(isReportLocked);
  const isVesselValidationValid = useMemo(() => {
    if (validationHiddenFromVessel) {
      return isHiddenFromVesselFieldValid({ value }, validationHiddenFromVessel);
    }

    return true;
  }, [validationHiddenFromVessel, value]);

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const comment = useComment({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const isTemplateValidationError = error || _isObject(error);

  const hasError =
    isTemplateValidationError ||
    (templateValidation?.limit?.length && comment) ||
    !isVesselValidationValid;
  const isRequired = _isString(error) && error.includes('required');

  const isOnboardHidden = templateValidation?.hide === true && isOnBoard;

  const onSaveComment = comment => {
    setComment({
      key: field.key,
      comment,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  };

  return (
    <div
      className={`validation-wrapper ${hasError && !comment ? 'validation-wrapper--error' : ''}`}
    >
      {field.label ? (
        <FieldLabel
          info={tooltip}
          className={`form-label fw-bold text-uppercase ${labelClassName || ''}`}
          label={field.label}
          subLabel={field.subLabel}
          fieldKey={field.key}
        />
      ) : null}

      <div
        className={`d-flex position-relative         
       ${right ? 'flex-row-reverse ps-1' : hasError ? 'flex-row' : 'flex-column'} ${
         hasError ? errorClassName || '' : ''
       } ${className || ''}`}
      >
        <div className="flex-1 w-100p">{children}</div>

        {hasError && !isRequired && !isOnboardHidden ? (
          <FieldError
            onSaveComment={onSaveComment}
            comment={comment}
            error={error}
            isLocked={isLocked}
            isVesselValidationValid={isVesselValidationValid}
            staticPosition={staticPosition}
            validationTemplate={
              !isVesselValidationValid ? validationHiddenFromVessel : templateValidation
            }
            right={right}
            field={field}
            customAllowedOptions={customAllowedOptions}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ValidationWrapper;
