import { VesselSystemBase } from '@/common/types/vessel-systems';
import { FC } from 'react';
import { components, SingleValueProps, OptionProps } from 'react-select';
import critical from 'common/assets/svg/common/critical-color.svg';
import burger from '@/common/assets/svg/common/chapter.svg';
import emptyRibbon from '@/common/assets/svg/common/ribbon-line.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';

export const SingleValue: FC<SingleValueProps<VesselSystemBase>> = ({ children, ...props }) => (
  <components.SingleValue className="d-flex flex-nowrap align-items-center" {...props}>
    <div className="flex-1 text-truncate">{props.data.description}</div>
    {props.data?.is_critical && (
      <SvgRender className="ms-1 text-purple" src={critical} style={{ minWidth: 14, height: 14 }} />
    )}
  </components.SingleValue>
);

const OptionContent: FC<{ data: VesselSystemBase }> = ({ data }) => {
  return (
    <>
      <div className="flex-1">{data.description}</div>
      {data?.is_critical && (
        <SvgRender
          className="ms-1 text-purple"
          src={critical}
          style={{ minWidth: 12, height: 12 }}
        />
      )}
    </>
  );
};

export const Option: FC<OptionProps<VesselSystemBase>> = ({ children, ...props }) => {
  return (
    <components.Option className="d-flex align-items-center" {...props}>
      <OptionContent data={props.data} />
    </components.Option>
  );
};

export const OptionWithParentIcon: FC<OptionProps<VesselSystemBase>> = ({ children, ...props }) => {
  return (
    <components.Option className="d-flex align-items-center" {...props}>
      <SvgRender
        className={`${props.data.parent_id ? 'ms-2 cme-4' : 'me-1'}`}
        src={props.data.parent_id ? emptyRibbon : burger}
        style={{ minWidth: 12, height: 12 }}
      />
      <OptionContent data={props.data} />
    </components.Option>
  );
};

export const sortVesselSystemsOptionsPerParent = (vesselSystems: VesselSystemBase[]) => {
  const parentMap = new Map<number | null, VesselSystemBase[]>(); // Group by parent_id
  const itemMap = new Map<number, VesselSystemBase>(); // Track all items by id
  const sortedList: VesselSystemBase[] = [];

  // Populate itemMap
  for (const item of vesselSystems) {
    itemMap.set(item.id, item);
  }

  // Group items by parent_id
  for (const item of vesselSystems) {
    if (!parentMap.has(item.parent_id)) {
      parentMap.set(item.parent_id, []);
    }
    parentMap.get(item.parent_id)!.push(item);
  }

  // Function to recursively add items
  function addItems(parentId: number | null) {
    if (!parentMap.has(parentId)) return;

    for (const item of parentMap.get(parentId)!) {
      sortedList.push(item);
      addItems(item.id); // Add children
    }
  }

  // Add all root-level parents first
  addItems(null);

  // Find orphaned children (children whose parent is missing from the response)
  for (const [parentId, children] of parentMap.entries()) {
    if (parentId !== null && !itemMap.has(parentId)) {
      const refChild = children[0];

      if (!refChild.parent) continue;

      // Add the orphan parent first
      sortedList.push(refChild.parent);

      // Add orphaned children
      for (const child of children) {
        child.parent = refChild.parent;
        sortedList.push(child);
      }
    }
  }

  return sortedList;
};
