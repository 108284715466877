import SvgRender from 'common/components/general/SvgRender';
import checkedIcon from 'common/assets/svg/common/check-circle-solid.svg';
import uncheckedIcon from 'common/assets/svg/common/check-circle-bold.svg';
import x from 'common/assets/svg/common/x-circle.svg';
import { FC } from 'react';

type CheckedButtonProps = {
  isChecked?: boolean;
  className?: string;
  uncheckedIconColor?: string;
  hideUncheckedIcon?: boolean;
};

const CheckedButton: FC<CheckedButtonProps> = ({
  isChecked,
  className,
  uncheckedIconColor = 'red',
  hideUncheckedIcon,
  ...rest
}) => {
  return (
    <SvgRender
      src={isChecked ? checkedIcon : hideUncheckedIcon ? x : uncheckedIcon}
      className={`aknowledge-btn ${isChecked ? 'aknowledge-btn--shadow' : ''} text-${
        !isChecked && hideUncheckedIcon ? uncheckedIconColor : 'turquoise'
      } pointer-cursor ${className || ''}`}
      {...rest}
    />
  );
};

export default CheckedButton;
