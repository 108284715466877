import { useState, useEffect, useRef, FC, useCallback } from 'react';

import Spinner from '@/ts-common/components/general/Spinner';
import Preview from './preview';
import { Attachment, Stream } from '@/ts-common/types/files';
import { RotateDirectionType } from '../store/types';
import { getFileType } from '@/ts-common/utils/files';
import useWindowWidth from '@/ts-common/utils/hooks/useWindowWidth';
import { getFileStream } from '@/api/files/api';
import { isAttachment } from './helpers';

type GalleryCenterContainerProps = {
  file: Attachment;
  isGalleryHovered: boolean;
  rotateDirection: RotateDirectionType;
};

const GalleryCenterContainer: FC<GalleryCenterContainerProps> = ({
  file,
  isGalleryHovered,
  rotateDirection
}) => {
  const fileType = getFileType(file.mime_type, file.extension);
  const [containerWidth, setContainerWidth] = useState<number | undefined>();
  const [containerHeight, setContainerHeight] = useState<number | undefined>();

  const containerRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();

  const [isLoading, setIsLoading] = useState(false);
  const [fileStream, setFileStream] = useState<Stream>(null);

  const loadFile = useCallback(async () => {
    if (!file?.id) return;

    try {
      setIsLoading(true);

      const stream = await getFileStream(file.id);

      setFileStream(stream);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setFileStream(null);
      setIsLoading(false);
    }
  }, [file?.id]);

  useEffect(() => {
    loadFile();
  }, [loadFile]);

  useEffect(() => {
    if (containerRef?.current?.clientWidth && containerRef?.current?.clientHeight) {
      setContainerWidth(containerRef?.current?.clientWidth);
      setContainerHeight(containerRef?.current?.clientHeight);
    }
  }, [windowWidth, containerRef?.current?.clientWidth]);

  const parsedFile = isAttachment(fileStream)
    ? { ...file, ...fileStream }
    : fileType.type === 'eml'
      ? { ...file, stream: fileStream }
      : file;

  return (
    <div
      ref={containerRef}
      className={`gallery__center--container w-100p ${
        rotateDirection !== 'top' && rotateDirection !== 'bottom'
          ? 'd-flex align-items-center justify-content-center'
          : ''
      }`}
    >
      {isLoading ? (
        <div className="position-absolute top-50  start-0 w-100p text-center ">
          <Spinner size={48} className="gallery__center--container-loader" />
        </div>
      ) : !file ? null : (
        <Preview
          key={file?.id}
          isGalleryHovered={isGalleryHovered}
          file={parsedFile}
          containerWidth={containerWidth || 0}
          containerHeight={containerHeight || 0}
          type={fileType?.type}
          rotateDirection={rotateDirection}
        />
      )}
    </div>
  );
};

export default GalleryCenterContainer;
