import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import ShadowBox from 'common/components/general/ShadowBox';
import Textarea from 'common/components/form/inputs/Textarea';
import UploadFiles from 'common/components/form/inputs/upload-files';
import CkEditor from 'common/components/form/inputs/ckeditor';

import { selectCurrentSystem } from 'common/components/pms/setup/store/selectors';

import CheckList from './CheckList';
import Maintenance from './maintenance';
import Forms from './forms';
import Tabs from './Tabs';
import Header from './Header';

import add from 'common/assets/svg/actions/add.svg';

import Checkbox from 'common/components/form/inputs/Checkbox';

const FormMain = ({
  fields,
  selectField,
  changeField,
  subStates,
  addSubform,
  removeSubform,
  jobId,
  isOpen
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const currentSystem = useSelector(selectCurrentSystem);

  return (
    <div className="d-flex flex-column h-100p">
      <Header fields={fields} selectField={selectField} changeField={changeField} />

      <div className="job-input job-input--with-actions job-input--title mb-2 cmt-12">
        <Textarea
          maxLength={255}
          className="mb-0 cps-2"
          placeholder="Add title"
          name="title"
          onChange={changeField('title')}
          rows={1}
          {...fields.title}
        />
        <div className="fs-12 fw-medium text-violet cmt-4">
          {currentSystem?.description ? currentSystem.description : '-'}
        </div>
      </div>

      <Tabs fields={fields} selectField={selectField} changeField={changeField} isOpen={isOpen} />

      <Row className="h-100p">
        <Col xs={6} className="h-100p">
          <ShadowBox
            className="pms-job-section cpt-4 cps-12 cpe-12 h-100p w-100p"
            color="bg-light-gray"
            flat
          >
            <Maintenance
              fields={fields}
              selectField={selectField}
              changeField={changeField}
              subStates={subStates}
              addSubform={addSubform}
              removeSubform={removeSubform}
            />

            <div className="mt-4">
              <div className="text-primary fs-10">
                <strong>DESCRIPTION</strong>
              </div>
              <div className="job-profile__description job-input job-input--with-actions job-input--description form-group-spacing">
                <CkEditor
                  isFocused={isFocused}
                  onFocus={focus =>
                    focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100)
                  }
                  className={`job-description-editor mb-0  ${
                    isFocused ? '' : 'job-description-editor--hide'
                  } `}
                  placeholder="Add some text…"
                  id="job-description"
                  isFullEditor={false}
                  minHeight={'32px'}
                  onChange={v => selectField('description')(v)}
                  value={fields.description?.value ? fields.description.value : ''}
                  error={fields.description.error}
                />
              </div>

              <UploadFiles
                label="ATTACHED MANUALS"
                group="jobs.attachments"
                uploadIcon={add}
                files={fields.attachments.value ? fields.attachments.value : []}
                onChange={files => selectField('attachments')(files)}
                size="lg"
              />
            </div>
          </ShadowBox>
        </Col>

        <Col xs={6} className="h-100p">
          <ShadowBox
            className="pms-job-section cpt-12 cps-12 cpe-12 h-100p w-100p"
            color="bg-light-gray"
            flat
          >
            <div className="mb-3">
              <Checkbox
                onChange={e => selectField('attachments_is_required')(e.target.checked)}
                className="mb-2 pb-0 text-primary fs-12"
                label="Attachments are mandatory for Vessel"
                {...fields.attachments_is_required}
              />
              <Checkbox
                onChange={e => selectField('time_spent_is_required')(e.target.checked)}
                className="mb-2 pb-0 text-primary fs-12"
                label="Work Duration is mandatory for Vessel"
                {...fields.time_spent_is_required}
              />
              <Checkbox
                onChange={e => selectField('remarks_is_required')(e.target.checked)}
                className="mb-2 pb-0 text-primary fs-12"
                label="Remarks are mandatory for Vessel"
                {...fields.remarks_is_required}
              />
            </div>

            <Forms selectField={selectField} fields={fields} isPms={true} />
            <CheckList jobId={jobId} selectField={selectField} fields={fields} />
          </ShadowBox>
        </Col>
      </Row>
    </div>
  );
};

export default FormMain;
