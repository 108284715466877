import { AccountingCoaGroupEntended } from '@/common/types/accounting';
import { createSlice } from '@reduxjs/toolkit';
import { getChartOfAccountsGroupsAction } from './actions';
import {
  getAscendanceTree,
  getRootItemsIDs,
  getSubItems,
  getTreeOfSearchedItems,
  normalizeTreeStructure
} from '@/ts-common/utils/tree-structure';
import _mapValues from 'lodash/mapValues';

type GroupId = number;

type GroupFormType = {
  isOpen: boolean;
  groupId?: GroupId | null;
  parentGroupId?: GroupId | null;
};

type GroupModalType = {
  isOpen: boolean;
  groupId?: GroupId | null;
};

type GroupsType = Record<GroupId, AccountingCoaGroupEntended>;
type RootGroupsType = GroupId[];
type SubGroupsType = Record<GroupId, GroupId[] | undefined>;

type StateType = {
  groups: GroupsType;
  rootGroups: RootGroupsType;
  subGroups: SubGroupsType;
  groupsInSeach: Record<GroupId, boolean>;
  groupsLevel: Record<GroupId, GroupId>;

  search: string;
  isLoading: boolean;

  form: GroupFormType;
  deleteGroupModal: GroupModalType;
};

const INITIAL_STATE: StateType = {
  form: {
    isOpen: false,
    groupId: null,
    parentGroupId: null
  },

  search: '',

  isLoading: false,

  groups: {},
  rootGroups: [],
  subGroups: {},
  groupsInSeach: {},
  groupsLevel: {},

  deleteGroupModal: { isOpen: false, groupId: null }
};

const slice = createSlice({
  name: 'settings_chart_of_groups',
  initialState: INITIAL_STATE,
  reducers: {
    setGroupForm: (state, { payload }: { payload: Partial<GroupFormType> }) => {
      state.form = { ...payload, isOpen: payload.isOpen || false };

      return state;
    },
    setGroupPreventionModal: (state, { payload }: { payload: GroupModalType }) => {
      state.deleteGroupModal = { ...payload, isOpen: payload.isOpen };

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getChartOfAccountsGroupsAction.pending, (state, { meta }) => {
        return { ...state, search: meta.arg.search ?? '', isLoading: true };
      })
      .addCase(getChartOfAccountsGroupsAction.fulfilled, (state, { payload, meta }) => {
        const subGroups = getSubItems(payload);
        const groups = normalizeTreeStructure(payload.map((a, i) => ({ ...a, sort_index: i })));
        const rootGroups = getRootItemsIDs(payload);

        const groupsInSearch = meta.arg.search
          ? getTreeOfSearchedItems(state.groups, state.subGroups, payload)
          : {};

        const groupsLevel = _mapValues(groups, account =>
          account.parent_id ? getAscendanceTree(account.id, groups).length : 1
        );

        state.isLoading = false;
        state.rootGroups = rootGroups as RootGroupsType;
        state.groups = groups as GroupsType;
        state.subGroups = subGroups as SubGroupsType;
        state.groupsInSeach = groupsInSearch;
        state.groupsLevel = groupsLevel;

        return state;
      })
      .addCase(getChartOfAccountsGroupsAction.rejected, state => {
        return { ...state, isLoading: false };
      });
  }
});

export const { setGroupForm, setGroupPreventionModal } = slice.actions;

export default slice.reducer;
