import { useQuery } from '@tanstack/react-query';
import {
  getCaptainReportsAlarmsDue,
  GetCaptainReportsAlarmsDueParams,
  getCaptainReportSummary,
  GetCaptainReportSummaryParams
} from './api';
import { CAPTAIN_REPORT_ALARMS_DUE, CAPTAIN_REPORT_SUMMARY } from './query-keys';

export const useGetCaptainReportsAlarmsDue = (params: GetCaptainReportsAlarmsDueParams) => {
  return useQuery({
    queryKey: [CAPTAIN_REPORT_ALARMS_DUE, params],
    queryFn: () => getCaptainReportsAlarmsDue(params),
    enabled: !!params.vessel_id,
    retry: false
  });
};

export const useGetCaptainReportSummary = (params: {
  report_id?: number;
  vessel_id: number;
  enabled: boolean;
}) => {
  const { enabled, ...rest } = params;

  return useQuery({
    queryKey: [CAPTAIN_REPORT_SUMMARY, rest],
    queryFn: () =>
      rest.report_id ? getCaptainReportSummary(rest as GetCaptainReportSummaryParams) : null,
    enabled,
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });
};
