import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectIsGroupOpen } from '@/common/components/pms/setup/store/selectors';
import Group from 'common/components/pms/setup/views/systems-groups/groups/group';

const SubGroup = ({ id, subGroups }) => {
  const isOpen = useSelector(state => selectIsGroupOpen(state, id));

  return (
    <Collapse isOpen={isOpen}>
      {isOpen ? (
        <div className={`chapters-tree__view-submenu`}>
          {subGroups.map(sub => (
            <Group key={sub.toString()} id={sub} />
          ))}
        </div>
      ) : null}
    </Collapse>
  );
};

export default SubGroup;
