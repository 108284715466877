import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getMonthAndDaysDurationString } from '@/ts-common/utils/dates';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';

import { selectWidgetState } from 'store/dashboards/selectors';

const Seamen = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.crew} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Name',
            key: 'name',
            type: 'string',
            headerClassName: 'text-violet',
            width: 3
          },
          {
            header: 'Rank',
            key: 'rank',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Nationality',
            key: 'nationality',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Status',
            key: 'status',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Vessel',
            key: 'vessel',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Onboard duration',
            key: 'onboard_duration',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          name: data => {
            return (
              <Link to={`${paths.crew}/${data.id}`} target="_blank">
                <TextWithTooltip>{data.full_name}</TextWithTooltip>
              </Link>
            );
          },
          rank: data => {
            return <TextWithTooltip>{data.rank?.name || '-'}</TextWithTooltip>;
          },
          nationality: data => {
            return <TextWithTooltip>{data.nationality?.name || '-'}</TextWithTooltip>;
          },
          status: data => {
            return <TextWithTooltip>{data.status?.name || '-'}</TextWithTooltip>;
          },
          vessel: data => {
            return <TextWithTooltip>{data.active_vessel?.name || '-'}</TextWithTooltip>;
          },
          onboard_duration: data => {
            return data?.initial_contract && data?.initial_contract?.sign_on_date
              ? getMonthAndDaysDurationString(data.initial_contract.sign_on_date)
              : '-';
          },
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.crew}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default Seamen;
