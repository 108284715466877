import { Attachment, Stream } from '@/ts-common/types/files';

export const rotateDirectionEnums = {
  top: '0deg',
  right: '90deg',
  bottom: '180deg',
  left: '270deg'
} as const;

export const isAttachment = (stream: Stream): stream is Attachment =>
  stream !== null && (stream as Attachment)?.url_inline !== undefined;
