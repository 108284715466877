import { getInventoryLatestSurvey, createInventorySurvey } from './actions';
import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  latestSurvey: null,
  isSavingSurvey: null,
  historyDrawer: {
    isOpen: false,
    active: null
  },
  inventoryExpandedRowsIds: [],
  showComments: false
};

const slice = createSlice({
  name: 'performance',
  initialState: INITIAL_STATE,
  reducers: {
    setHistoryInventoryDrawerAction: (state, { payload }) => {
      state.historyDrawer = {
        isOpen: payload?.isOpen,
        active: payload?.active
      };

      return state;
    },
    setInventoryExpandedRowsIds: (state, { payload }) => {
      state.inventoryExpandedRowsIds = payload;

      return state;
    },
    setShowComments: (state, { payload }) => {
      state.showComments = payload;

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createInventorySurvey.pending, state => {
        state.isSavingSurvey = true;

        return state;
      })
      .addCase(createInventorySurvey.fulfilled, state => {
        state.isSavingSurvey = false;

        return state;
      })
      .addCase(createInventorySurvey.rejected, state => {
        state.isSavingSurvey = false;

        return state;
      })
      .addCase(getInventoryLatestSurvey.pending, state => {
        state.latestSurvey = null;

        return state;
      })
      .addCase(getInventoryLatestSurvey.fulfilled, (state, { payload }) => {
        state.latestSurvey = payload;

        return state;
      });
  }
});

export const { setHistoryInventoryDrawerAction, setInventoryExpandedRowsIds, setShowComments } =
  slice.actions;

export default slice.reducer;
