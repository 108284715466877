import { FC } from 'react';

import styled from '@emotion/styled';
import Title from '../../components/Title';
import GroupsContainer from './GroupsContainer';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectGroupsCollapsed,
  selectPmsLibraryIsUsedInCategories
} from '@/common/components/pms/setup/store/selectors';
import { setGroupsCollapsed } from '@/common/components/pms/setup/store/actions';
import ViewButton from '@/common/components/buttons/ViewButton';
import HideButton from '@/common/components/buttons/HideButton';

type GroupsProps = {
  className?: string;
};

const Groups: FC<GroupsProps> = ({ className = '' }) => {
  const dispatch = useAppDispatch();
  const groupsCollapsed = useAppSelector(selectGroupsCollapsed);
  const pmsLibraryUsedInPurchasingCategories = useAppSelector(selectPmsLibraryIsUsedInCategories);

  return (
    <div className={`d-flex flex-column h-100p w-100p position-relative ${className}`}>
      <CollapsedContainer
        isCollapsed={groupsCollapsed}
        className="d-flex flex-column position-absolute top-0 h-100p"
      >
        <ViewButton
          className="cmb-12"
          onClick={() => dispatch(setGroupsCollapsed(!groupsCollapsed))}
        />
        <CollapsedTitle className="d-flex flex-column align-items-center cpt-12 w-32 h-100p border-radius-7">
          <RotatedText className="text-dark fs-12">SYSTEM GROUPS</RotatedText>
        </CollapsedTitle>
      </CollapsedContainer>

      <UncollapsedContainer
        isCollapsed={groupsCollapsed}
        className="d-flex align-items-center w-100p"
      >
        <Title
          text="SYSTEM GROUPS"
          className="w-100p text-nowrap"
          showIcon={!pmsLibraryUsedInPurchasingCategories}
        />
        {!pmsLibraryUsedInPurchasingCategories && (
          <HideButton
            className="cms-4"
            onClick={() => dispatch(setGroupsCollapsed(!groupsCollapsed))}
          />
        )}
      </UncollapsedContainer>

      <AnimatedGroupsContainer isCollapsed={groupsCollapsed} className="h-100p">
        <GroupsContainer className="h-100p" />
      </AnimatedGroupsContainer>
    </div>
  );
};

export default Groups;

const CollapsedTitle = styled.div`
  background-color: rgba(134, 147, 171, 0.1);
`;

const RotatedText = styled.strong`
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
`;

const CollapsedContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  opacity: ${isCollapsed ? '1' : '0'};
  transition: all 0.5s ease-in-out;
`
);

const UncollapsedContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  opacity: ${isCollapsed ? '0' : '1'};
  transition: all 0.5s ease-in-out;
  z-index: ${isCollapsed ? '-1' : '1'}
  `
);

const AnimatedGroupsContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  opacity: ${isCollapsed ? '0' : '1'};
  transition: all 0.5s ease-in-out;
  z-index: ${isCollapsed ? '-1' : '1'}
  `
);
