import { Row, Col } from 'reactstrap';
import { TableTopFilters } from 'common/entities/tables/TopFilterTypes';

import VesselSystemGroupSelector from 'common/components/selectors/VesselSystemGroupSelector';
import PropTypes from 'prop-types';
import TableExcelExport from 'common/components/table/TableExcelExport';
import FilterButton from 'common/components/buttons/FilterButton';
import useTableTopFilterValue from '@/common/components/filters/useTableTopFilterValue';

const RequiredPartsFilter = ({ isActive, onChange }) => {
  return (
    <FilterButton
      isActive={isActive}
      onClick={() => onChange(isActive ? null : 'true')}
      status="error"
      size="sm"
    >
      Required Spare Parts Set
    </FilterButton>
  );
};

const TopFilters = ({ topFilters, setTopFilters, label }) => {
  const [group, setGroup] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'vessel_system_group_id'
  );
  const [hasRequiredSpareParts, setHasRequiredSpareParts] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'has_required_spare_parts'
  );

  return (
    <Row className="w-100p align-items-center" noGutters>
      <Col xs={3} className="me-1">
        <VesselSystemGroupSelector
          filter={{ value: group ? group : null }}
          isMulti={false}
          isClearable
          onChange={({ value }) => setGroup(value?.id ? [value?.id] : null)}
        />
      </Col>
      {label === 'pms_job_prototypes' && (
        <>
          <Col xs={'auto'}>
            <RequiredPartsFilter
              isActive={hasRequiredSpareParts}
              onChange={value => setHasRequiredSpareParts(value)}
            />
          </Col>
          <Col xs="auto" className="ps-2 ms-2 border-start d-flex align-items-center">
            <TableExcelExport
              className="ms-auto me-1"
              label={label}
              exportUrl="/vessel-systems/maintenance-job-prototypes/export"
            />
          </Col>
        </>
      )}
    </Row>
  );
};

TopFilters.propTypes = {
  topFilters: TableTopFilters.isRequired,
  setTopFilters: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default TopFilters;
