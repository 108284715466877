import { useState, useEffect, useCallback } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import GroupFormModal from '../../components/modals/GroupFormModal';
import Header from 'common/components/tree-layout/main/Header';
import PageLoader from 'common/components/general/PageLoader';

import Group from './group';
import Loader from 'common/components/tree-layout/components/Loader';
import SvgRender from 'common/components/general/SvgRender';
import pms from 'common/assets/svg/common/setup.svg';
import useRouter from 'use-react-router';

import { useActions } from 'utils/hooks';
import { useAppSelector } from '@/store/hooks';
import {
  selectRootGroups,
  selectGroups,
  selectGroupsLoading,
  selectActiveGroup,
  selectPmsLibraryIsUsedInCategories,
  selectPmsLibraryIsUsedInSetup,
  selectAllGroupsExpanded,
  selectGroupsSearch,
  selectHasCollapsedSubGroupsContent
} from 'common/components/pms/setup/store/selectors';
import { parseQueryParams } from 'common/utils/urls';

import * as pmsActions from 'common/components/pms/setup/store/actions';
import { selectSelectedSettingsCategoryId } from 'common/components/purchasing/library-setup/store/selectors';

import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';
import { getVesselRouteForLibrarySetup } from '@/views/pms/library/_components/tabsConfig';

const GroupsContainer = ({ className = '' }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { history, match, location } = useRouter();

  const activeGroup = useAppSelector(selectActiveGroup);

  const isUsedInSetup = useAppSelector(selectPmsLibraryIsUsedInSetup);
  const isUsedInPurchasingCategories = useAppSelector(selectPmsLibraryIsUsedInCategories);
  const selectedPurchasingCategoryId = useAppSelector(selectSelectedSettingsCategoryId);

  const rootGroups = useAppSelector(selectRootGroups);
  const groups = useAppSelector(selectGroups);
  const groupsLoading = useAppSelector(selectGroupsLoading);
  const groupsSearch = useAppSelector(selectGroupsSearch);

  const allGroupsExpanded = useAppSelector(selectAllGroupsExpanded);
  const hasCollapsedContent = useAppSelector(selectHasCollapsedSubGroupsContent);

  const shouldShowChildsAsRoot = selectedPurchasingCategoryId && isUsedInPurchasingCategories;

  const [createSystemGroup, getSystemGroups, setActiveGroup, setSelectedSystem, toggleAllGroups] =
    useActions([
      pmsActions.createSystemGroup,
      pmsActions.getSystemGroups,
      pmsActions.setActiveGroup,
      pmsActions.setSelectedSystem,
      pmsActions.toggleAllGroups
    ]);

  const initialize = useCallback(async () => {
    if (isInitialized) return;

    try {
      if (match.params.group_id) {
        setActiveGroup(+match.params.group_id);
      } else {
        setActiveGroup('all');
      }

      const searchParams = parseQueryParams(location.search);
      if (searchParams.system_id) {
        setSelectedSystem(+searchParams.system_id);
      } else {
        setSelectedSystem(null);
      }
      setIsInitialized(true);
    } catch (e) {
      setIsInitialized(true);
    }
  }, [isInitialized, location.search, match.params.group_id, setActiveGroup, setSelectedSystem]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onCreate = async params => {
    try {
      setCreating(true);
      await createSystemGroup(params);
      setIsAddModalOpen(false);
      setCreating(false);
      getSystemGroups({ keepOpened: true });
    } catch (e) {
      setCreating(false);
    }
  };

  const onSearchChange = useCallback(
    search => getSystemGroups({ search: search || undefined, keepOpened: true }),
    [getSystemGroups]
  );

  const onSearchClear = () => {
    if (groupsSearch) return getSystemGroups({ search: undefined, keepOpened: true });
  };

  useEffect(() => {
    if (isUsedInSetup) getSystemGroups({ vessel_id: match.params.vessel_id });
  }, [match.params.vessel_id, getSystemGroups, isUsedInSetup]);

  return (
    <div className={`groups-container w-100p cmt-12 d-flex flex-column ${className}`}>
      <div className="chapters-tree flex-1">
        <Header
          search={groupsSearch}
          placeholder="Search groups"
          onSearchChange={onSearchChange}
          onSearchClear={onSearchClear}
          allExpanded={allGroupsExpanded}
          hasCollapsedContent={hasCollapsedContent}
          toggleCollapse={open => toggleAllGroups(open)}
        />

        {isUsedInSetup ? (
          <div
            onClick={() => {
              setSelectedSystem(null);
              history.push(getVesselRouteForLibrarySetup('', match.params.vessel_id));
              setActiveGroup('all');
            }}
            className={`d-flex align-items-center ps-2 mt-3 mb-1 cursor-pointer chapters-tree__view-title ${
              activeGroup === 'all' ? 'active' : ''
            }`}
          >
            <SvgRender style={{ widht: 14, height: 14 }} src={pms} />
            <span className=" fw-medium cms-4">All System Groups</span>
          </div>
        ) : null}

        {!groupsLoading ? (
          (shouldShowChildsAsRoot ? Object.keys(groups) : rootGroups).map(el => {
            return <Group key={el.toString()} id={el} />;
          })
        ) : (
          <Loader />
        )}
      </div>
      {isUsedInSetup ? (
        <AuthCheck permissions={[permissions.office.pms.library.edit_groups]}>
          <div className="groups-container__footer pt-1">
            <CircledButton
              data-testid="pms-groups-add-btn"
              onClick={() => setIsAddModalOpen(true)}
              type={'add'}
              label="Add group"
              svgStyle={{ height: 7, width: 7 }}
              style={{ width: 16, height: 16 }}
            />
          </div>
        </AuthCheck>
      ) : null}
      <GroupFormModal
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        onSave={onCreate}
        disabled={creating}
      />
      <PageLoader isLoading={!isInitialized} />
    </div>
  );
};

export default GroupsContainer;
