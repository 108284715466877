import React from 'react';
import Switch from 'common/components/form/inputs/Switch';
import SvgRender from 'common/components/general/SvgRender';
import safety_enabled from 'common/assets/svg/jobs/guards.svg';
import safety_disabled from 'common/assets/svg/jobs/safety_disabled.svg';
import SystemVessels from 'common/components/pms/setup/views/system/specifications/jobs/jobs-form/SystemVessels';
import DrydockSwitch from 'common/components/jobs/_base/components/DrydockSwitch';

const TitleHeader = ({ selectedJobForEdit, isSafety, isDrydock, selectField }) => {
  return (
    <div className="d-flex align-items-center mb-0">
      <div>{selectedJobForEdit ? 'Edit Job' : 'Add Job'}</div>
      <div className="small-separator mx-2 bg-platinum py-1"></div>

      <div
        className={`d-flex align-items-center ${
          isSafety?.value ? 'bg-mint-green text-primary' : 'bg-light-gray-2 text-violet'
        } cpx-6 cpy-6 border-radius-3 me-1`}
      >
        <SvgRender
          src={isSafety?.value ? safety_enabled : safety_disabled}
          style={{ width: 12, height: 12 }}
          className="text-violet cme-4"
        />
        <div className="fs-10 cme-4">Safety</div>
        <Switch
          value={isSafety?.value}
          className="mb-0"
          onChange={() => selectField('is_safety')(!isSafety?.value)}
          switchClassName="green-switch"
          size={'xs'}
        />
      </div>

      <DrydockSwitch
        isDrydock={isDrydock?.value}
        onChange={() => selectField('is_drydock')(!isDrydock?.value)}
      />

      <SystemVessels />
    </div>
  );
};

export default TitleHeader;
