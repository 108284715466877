import { RootState } from '@/store';

const selectJobsReducer = (state: RootState) => state.jobs;

export const selectMaintenance = (state: RootState) => selectJobsReducer(state).maintenance;

export const selectMaintenanceDetais = (state: RootState) =>
  selectMaintenance(state)?.maintenance_details;

export const selectMaintenanceDetaisVesselSystemAssignment = (state: RootState) =>
  selectMaintenanceDetais(state)?.vessel_system_assignment;

export const selectMaintenanceDetaisVesselSystemAssignmentLastRunningHours = (state: RootState) =>
  selectMaintenanceDetaisVesselSystemAssignment(state)?.last_running_hours_report_assignment;

export const selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTimeStamp = (
  state: RootState
) => selectMaintenanceDetaisVesselSystemAssignmentLastRunningHours(state)?.timestamp;

export const selectMaintenanceDetaisVesselSystemAssignmentLastRunningHoursTotalRunningHours = (
  state: RootState
) => selectMaintenanceDetaisVesselSystemAssignmentLastRunningHours(state)?.total_running_hours;
