import React, { useEffect, useState, useCallback } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';

import AddNewForm from 'common/components/jobs/_base/modules/forms/components/AddNewForm';
import SavedJobForms from './SavedJobForms';
import { useSelector } from 'react-redux';
import { selectCurrentSystemVessels } from 'common/components/pms/setup/store/selectors';

const JobForms = ({ isTabActive, savedForms, onSaveForms, setActiveJobTab }) => {
  const jobHasForms = savedForms?.length;
  const isVisible = jobHasForms || isTabActive;
  const excludeFormIds = (savedForms || []).map(f => f.id);
  const currentSystemVessels = useSelector(selectCurrentSystemVessels);

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (isTabActive && !isAdding && !jobHasForms) {
      setIsAdding(true);
    }
  }, [isTabActive]);

  const onCreate = useCallback(
    newForm => {
      onSaveForms([...(savedForms || []), newForm]);
      onCancel();
    },
    [savedForms, onSaveForms]
  );

  const onCancel = useCallback(() => {
    setIsAdding(false);

    if (!excludeFormIds?.length) {
      setActiveJobTab('forms', false);
    }
  }, [excludeFormIds?.length]);

  const onRemove = useCallback(
    removedForm => {
      onSaveForms(
        [...(savedForms || [])].filter(
          f => f.id !== removedForm.id || f.job_form_id !== removedForm.job_form_id
        )
      );
    },
    [savedForms, onSaveForms]
  );

  if (!isVisible) return null;

  return (
    <div className="job-forms mb-3 cmt-10 pe-1">
      <div className="d-flex align-items-center text-primary fs-10 fw-bold justify-content-between mb-2 me-1 lh-1">
        FORMS
      </div>

      {jobHasForms ? <SavedJobForms savedForms={savedForms} onRemove={onRemove} /> : null}

      {isAdding ? (
        <AddNewForm
          onCreate={onCreate}
          onCancel={onCancel}
          excludeFormIds={excludeFormIds}
          formOrigin="pms_jobs"
          vesselIds={currentSystemVessels?.map(vessel => vessel?.id)}
          isPms={true}
          isForVessel={true}
        />
      ) : null}

      <CircledButton
        type="add"
        className={`text-primary fw-bold fs-12 lh-1 job-circled-btn`}
        label="Add more"
        svgStyle={{ width: 8, height: 8 }}
        style={{ width: 20, height: 20 }}
        disabled={isAdding}
        onClick={() => setIsAdding(true)}
      />
    </div>
  );
};

export default JobForms;
