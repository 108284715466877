import Groups from './groups';
import SystemsLibrary from './library';
import styled from '@emotion/styled';
import { useAppSelector } from '@/store/hooks';
import { selectGroupsCollapsed } from '../../store/selectors';

const GroupsAndSystems = () => {
  const groupsCollapsed = useAppSelector(selectGroupsCollapsed);

  return (
    <div className="systems-library-container h-100p w-100p">
      <div className="h-100p w-100p d-flex flex-nowrap">
        <GroupsContainer isCollapsed={groupsCollapsed} className={`cme-12`}>
          <Groups />
        </GroupsContainer>
        <LibraryContainer isCollapsed={groupsCollapsed} className={'overflow-hidden'}>
          <SystemsLibrary />
        </LibraryContainer>
      </div>
    </div>
  );
};

export default GroupsAndSystems;

const GroupsContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  transition: width 0.5s ease-in-out;
  width: ${isCollapsed ? `32px` : `50%`};
  `
);

const LibraryContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  transition: width 0.5s ease-in-out;
  width: ${isCollapsed ? `calc(100% - 32px)` : `50%`};
  `
);
