import SvgRender from 'common/components/general/SvgRender';

import check from 'common/assets/svg/common/check-circle.svg';
import error from 'common/assets/svg/common/x-circle.svg';

const CheckedLabel = ({ isChecked, label }) => {
  return (
    <div className="d-inline-flex align-items-center">
      <SvgRender
        src={isChecked ? check : error}
        style={{ width: 16, height: 16 }}
        className={`cme-12 text-${isChecked ? 'turquoise' : 'violet'}`}
      />
      <div className="fs-12">{label}</div>
    </div>
  );
};

export default CheckedLabel;
