import Information from 'common/components/general/Information';

import {
  dateField,
  numberField,
  stringField,
  booleanField,
  optionField
} from 'common/utils/form/fieldTypes';
import CheckedLabel from 'common/components/labels/CheckedLabel';
import GroupFormField from '../components/assignment-tab/form/GroupFormField';
import GroupField from '../components/assignment-tab/GroupField';

export const runningHoursConfig = {
  running_hours: {
    type: 'number',
    inputProps: {
      placeholder: 'Add value',
      className: 'mb-0',
      invisible: true
    },
    form: {
      view: {
        width: 4
      },
      edit: {
        width: 4
      }
    },
    fieldType: numberField(),
    label: 'RUNNING HOURS'
  },
  last_date_of_running_hours_reading: {
    type: 'date-input',
    fieldType: dateField(),
    inputProps: {
      invisible: true,
      placeholder: '- / - / -',
      className: 'mb-0'
    },
    format: 'DD/MM/YYYY',
    label: 'LAST DATE OF RUNNING HOURS READING'
  }
};

export const systemDetailsConfig = {
  extra_description: {
    type: 'string',
    fieldType: stringField(),
    form: {
      view: {
        width: 3
      },
      edit: {
        width: 3
      }
    },
    inputProps: {
      invisible: true,
      placeholder: 'Add identifier',
      className: 'mb-2'
    },
    label: (
      <span className="d-flex align-items-center assignment-identifier-field">
        <span className="pe-2">ASSIGNMENT IDENTIFIER</span>
        <Information
          svgStyle={{ width: 12, height: 12, marginTop: 2 }}
          tooltipClassname="tooltip-inner-max-content"
          message={
            <span>
              Add here text that identifies this specific system to <br /> vessel assignment, e.g.
              1, 2, 3, 4, “Port” / “Starboard” etc.
            </span>
          }
        />
      </span>
    )
  },
  serial_no: {
    type: 'string',
    fieldType: stringField(),
    form: {
      view: {
        width: 3
      },
      edit: {
        width: 3
      }
    },
    inputProps: {
      invisible: true,
      placeholder: 'Add no.',
      className: 'mb-2'
    },
    label: 'SERIAL NO.'
  },
  status: {
    type: 'select',
    form: {
      view: {
        width: 3
      },
      edit: {
        width: 3
      }
    },
    fieldType: stringField({ initialValue: 'operational' }),
    list: 'vessel-system-assignment-status',
    inputProps: {
      showLabel: true,
      styled: true,
      placeholder: 'Select status',
      className: 'system-form__selector mb-2'
    },
    label: 'STATUS'
  },
  installation_date: {
    type: 'date-input',
    fieldType: dateField(),
    inputProps: {
      invisible: true,
      placeholder: '- / - / -',
      className: 'mb-2'
    },
    format: 'DD/MM/YYYY',
    label: 'INSTALLATION DATE'
  },
  group: {
    type: 'select',
    form: {
      view: {
        width: 12,
        customComponent: (value, { isSubSystem }) => (
          <GroupField value={value} isSubSystem={isSubSystem} />
        )
      },
      edit: {
        width: 12,
        customComponent: (field, { selectField }, { isSubSystem }) => (
          <GroupFormField selectField={selectField} isSubSystem={isSubSystem} {...field} />
        )
      }
    },
    fieldType: optionField(),
    label: 'GROUP'
  },
  cms_reference_code: {
    type: 'string',
    inputProps: {
      placeholder: 'Add no.',
      className: 'system-form__input mb-0 border-end border-white me-1',
      invisible: true
    },
    form: {
      view: {
        className: 'mt-2 cpt-2 border-end text-nowrap pe-1 min-width-156 me-2'
      },
      edit: {
        width: 3
      }
    },
    fieldType: stringField(),
    label: 'CMS REFERENCE CODE'
  },
  is_class: {
    type: 'checkbox',
    inputProps: {
      className: 'mb-0 mt-2',
      labelClassName: 'fs-12 text-primary'
    },
    form: {
      view: {
        width: 3,
        className: 'mt-2',
        customComponent: value => (
          <div className="mt-2">
            <CheckedLabel isChecked={value} label="Class" />
          </div>
        )
      },
      edit: {
        width: 3
      }
    },
    fieldType: booleanField({ initialValue: false }),
    label: 'Class'
  }
};
