import TextWithTooltip from '@/ts-common/components/general/TextWithTooltip';
import LinkedEntitiesTable from '../_components/LinkedEntitiesTable';
import SvgRender from '@/ts-common/components/general/SvgRender';
import removeSvg from 'common/assets/svg/actions/remove.svg';
import critical from 'common/assets/svg/common/critical-color.svg';

import { FC } from 'react';
import { VesselSystemBase } from '@/common/types/vessel-systems';

type LinkedVesselSystemsProps = {
  vesselSystems: VesselSystemBase[];
  removeVesselSystem: (index: number) => void;
};

const LinkedVesselSystems: FC<LinkedVesselSystemsProps> = ({
  vesselSystems,
  removeVesselSystem
}) => {
  if (!vesselSystems?.length) return null;

  return (
    <LinkedEntitiesTable
      hideTableHead
      columns={[
        { key: 'vessel_system', width: 11 },
        { key: 'actions', field: 'actions' }
      ]}
      state={{ data: vesselSystems }}
      rows={{
        vessel_system: (data: VesselSystemBase) => (
          <div className="overflow-hidden d-flex align-items-center flex-nowrap">
            <div className="overflow-hidden">
              <TextWithTooltip>{data.description}</TextWithTooltip>
            </div>
            {data.is_critical ? (
              <SvgRender
                className="ms-1 text-purple"
                src={critical}
                style={{ minWidth: 14, height: 14 }}
              />
            ) : null}
          </div>
        ),
        actions: () => ({
          options: [
            {
              label: 'Remove',
              icon: removeSvg,
              svgStyle: { width: 8, height: 8 },
              onClick: (_: unknown, __: unknown, index: number) => removeVesselSystem(index)
            }
          ]
        })
      }}
    />
  );
};

export default LinkedVesselSystems;
