import { RootState } from '@/store';
import { createSelector } from 'reselect';
import {
  SummarySupplier,
  SumPerCategory,
  SumPerCategoryPriceKeys
} from '@/common/types/purchasing';

const getKey = (_: RootState, key: string | number) => key;

const getSecondKey = (_: RootState, __: RootState, key: string | number) => key;

const selectRequisitionSummaryReducer = (state: RootState) => state.purchasing.requisitionSummary;

export const selectRequisitionSuppliers = (state: RootState) =>
  selectRequisitionSummaryReducer(state).suppliers;

export const selectRequisitionSupplier = createSelector(
  [selectRequisitionSuppliers, getKey],
  (suppliers: SummarySupplier[], supplierRequisitionID: string | number) =>
    suppliers.find(s => s.id === supplierRequisitionID)
);

export const selectSupplierSumPerCategory = createSelector(
  [selectRequisitionSupplier, getSecondKey],
  (supplier, categoryID: string | number) =>
    supplier?.sum_per_categories.find(item => item.category_id === categoryID)
);

export const getSupplierTypeByPrice = (supplierSumPerCategory?: SumPerCategory) => {
  const approved = `total_approved_price`;
  const proposed = `total_proposed_price`;

  return supplierSumPerCategory?.[approved]
    ? 'approved'
    : supplierSumPerCategory?.[proposed]
      ? 'proposed'
      : null;
};

export const selectSupplierSumPerCategoryPrice = (
  state: RootState,
  supplierRequisitionID: string | number,
  categoryID: string | number,
  isEquivalent: boolean
) => {
  const supplierSumPerCategory = selectSupplierSumPerCategory(
    state,
    supplierRequisitionID as any,
    categoryID
  );
  const baseKey = getSupplierTypeByPrice(supplierSumPerCategory) || 'discounted';
  const priceKey = `total_${baseKey}_price${
    isEquivalent ? '_base_currency_equivalent' : ''
  }` as keyof SumPerCategoryPriceKeys;

  return supplierSumPerCategory?.[priceKey] || null;
};

export const selectTotalDiscountedPriceBaseCurrencyEquivalent = (
  state: RootState,
  supplierRequisitionID: number
) =>
  selectRequisitionSupplier(state, supplierRequisitionID)
    ?.total_discounted_price_base_currency_equivalent;

export const selectTotalProposedPriceBaseCurrencyEquivalent = (
  state: RootState,
  supplierRequisitionID: number
) =>
  selectRequisitionSupplier(state, supplierRequisitionID)
    ?.total_proposed_price_base_currency_equivalent;

export const selectTotalApprovedPriceBaseCurrencyEquivalent = (
  state: RootState,
  supplierRequisitionID: number
) =>
  selectRequisitionSupplier(state, supplierRequisitionID)
    ?.total_approved_price_base_currency_equivalent;
