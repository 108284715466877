import { StepType } from '@/common/types/review-process';

const actions = {
  purchasingRequisitionApproval: { label: 'purchasing_requisition_approval', name: 'Requisition' },
  purchasingForwardingApproval: { label: 'purchasing_forwarding_approval', name: 'Forwarding' },
  formsApproval: { label: 'form_submission_creation', name: 'Form' },
  manualApproval: { label: 'manual_version', name: 'Manual' },
  crewContractsApproval: { label: 'crew_contract', name: 'Crew Contracts' },
  pmsJobsApproval: { label: 'pms_jobs', name: 'Pms Jobs' },
  invoice: { label: 'invoices', name: 'Invoices' },
  manualVersion: { label: 'manual_version', name: 'Manual Version' },
  accountingContracts: { label: 'contracts', name: 'Accounting Contracts' }
} as const;

export const stepTypes = {
  static: 'static',
  dynamic: 'dynamic',
  multiple_dynamic: 'multiple_dynamic'
} as const;

export const getStepIsStatic = (stepType: StepType) => stepType === stepTypes.static;

export const getStepIsDynamic = (stepType: StepType) => stepType === stepTypes.dynamic;

export const getStepIsMultipleDynamic = (stepType: StepType) =>
  stepType === stepTypes.multiple_dynamic;

export default actions;
