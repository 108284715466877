/* 
  These are the jobs related selectors. 
  Do NOT place any PMS selector in this file. 
  Intead, place them in store/pms/jobs/selectors.
*/

import { createSelector } from 'reselect';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { jobEnums } from 'common/utils/fixed';

import { filterJobStatuses } from 'common/components/jobs/_base/_helpers';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { selectAccount } from 'store/account/selectors';
import {
  validateOfficeUserPermissions,
  validateOnboardUserVesselInfoPermissions,
  validateOnboardUserJobInfoPermissions
} from '../permissions/helpers';

export const selectJobsReducer = state => state.jobs;
const selectJobsFormErrors = createSelector(selectJobsReducer, jobs => jobs.formErrors);
const selectIsOnboard = state => state.isOnBoard;

const getKey = (_, key) => key;
const getSecondKey = (_, __, key) => key;

const typesWithBasicLayoutFields = [
  jobEnums.regular,
  jobEnums.flag_dispensation,
  jobEnums.orca_jobs
];

export const isJobSaving = createSelector(selectJobsReducer, jobs => jobs.isSaving);
export const selectJobId = createSelector(selectJobsReducer, jobs => jobs.id);

export const selectJobType = state => selectJobsReducer(state)?.type || null;

export const selectIsRegularJob = state => selectJobType(state) === jobEnums.regular;

export const selectHasBasicJobLayout = state =>
  typesWithBasicLayoutFields.includes(selectJobType(state));

export const typeOfLoggedInUser = createSelector(
  selectJobsReducer,
  jobs => jobs.typeOfLoggedInUser
);

export const isJobArchived = state => selectJobsReducer(state)?.is_archived;

export const selectSelectedJob = createSelector(selectJobsReducer, jobs => jobs.selectedJob);

export const selectOrcaId = state => selectJobsReducer(state)?.orca_id;

export const checkIfEditMode = createSelector(selectJobId, id => {
  if (id) {
    return true;
  } else {
    return false;
  }
});

export const selectJobField = (state, field) => selectJobsReducer(state)?.[field];

export const selectJobPrefilledVessel = (state, field) => selectJobsReducer(state)?.prefilledVessel;

export const selectJobFieldError = (state, field) => selectJobsFormErrors(state)?.[field];

export const selectActiveTabs = createSelector(selectJobsReducer, jobs => jobs.activeTabs);

export const isTabActive = createSelector(selectActiveTabs, getKey, (tabs, tab) => {
  return tabs.indexOf(tab) !== -1;
});

export const selectIsA4Layout = createSelector(selectJobsReducer, jobs => jobs.isA4Layout);

export const selectJobFindings = state => selectJobsReducer(state).findings;

export const selectJobEvents = state => selectJobsReducer(state).events;

const selectChecklist = createSelector(selectJobsReducer, jobs => jobs.checklist.options);

export const selectChecklistOption = id =>
  createSelector([selectChecklist], options => {
    return options.find(opt => opt.id === id);
  });

export const selectSingleVesselJobField = state => selectJobField(state, 'vessels')?.[0]?.id;

export const selectOfficeUserIsAuthorized = createSelector(
  [checkIfEditMode, selectAccount],
  (editMode, account) => validateOfficeUserPermissions({ account, editMode })
);

export const selectOnboardUserIsAuthorizedToEditVesselInfo = createSelector(
  [selectAccount],
  account => validateOnboardUserVesselInfoPermissions(account)
);

export const selectOnboardUserIsAuthorizedToEditJobInfo = createSelector([selectAccount], account =>
  validateOnboardUserJobInfoPermissions(account)
);

export const selectIsJobLocked = createSelector(
  [selectJobsReducer, getKey, getSecondKey, selectJobStatuses, selectIsOnboard, selectAccount],
  (
    job,
    authorizeBasedOnPermissions = false,
    authorizeOnboardUserVesselInfoPermissions = false,
    statuses,
    isOnBoard,
    account
  ) => {
    if (!job.id)
      return authorizeBasedOnPermissions
        ? !validateOfficeUserPermissions({ account, editMode: false })
        : false;

    const jobStatus = statuses.find(el => el.id === job.status_id);
    if (!jobStatus) return false;

    const { edit_side } = jobStatus;

    if (authorizeBasedOnPermissions) {
      if (edit_side === 'vessel') {
        if (isOnBoard) {
          if (authorizeOnboardUserVesselInfoPermissions) {
            return !validateOnboardUserVesselInfoPermissions(account);
          } else {
            return !validateOnboardUserJobInfoPermissions(account);
          }
        }

        return true;
      } else {
        if (!isOnBoard) return !validateOfficeUserPermissions({ account, editMode: true });

        return true;
      }
    }

    return (edit_side === 'vessel' && !isOnBoard) || (edit_side === 'office' && isOnBoard);
  }
);

export const selectJobHasLinkedJobs = createSelector(
  [selectJobsReducer],
  job => job?.has_linked_jobs
);

export const selectJobHasChecklist = state => selectJobsReducer(state)?.checklist?.options?.length;

export const selectJobNotes = state => selectJobsReducer(state)?.notes;

export const selectJobHasNotes = state => selectJobsReducer(state)?.has_notes;

export const selectFilterJobStatuses = createSelector(
  [
    selectJobsReducer,
    getKey,
    selectJobStatuses,
    state => selectListOptionsFromStore(state, 'departments'),
    state => selectListOptionsFromStore(state, 'party-and-crew-departments'),
    selectIsOnboard
  ],
  (job, vesselJobsOnly, jobStatuses, departmentsOptions, party_n_crew_departments, isOnBoard) => {
    const { departments, main_department, is_for_vessel } = job;

    return filterJobStatuses({
      statuses: jobStatuses,
      departments: departments,
      mainDepartment: main_department,
      isForVessel: is_for_vessel,
      vesselJobsOnly,
      departmentsOptions,
      party_n_crew_departments,
      isOnBoard
    });
  }
);

/* Due date selectors*/

export const selectJobDueDate = createSelector(selectJobsReducer, jobs => jobs.due_date);

export const selectJobDueDateShowTime = state => selectJobsReducer(state).due_date_show_time;

export const selectJobDueDateRemarks = createSelector(
  selectJobsReducer,
  jobs => jobs.due_date_remarks
);
export const selectJobDueDateChangedAt = createSelector(
  selectJobsReducer,
  jobs => jobs.due_date_changed_at
);
export const selectJobDueDateChangedBy = createSelector(
  selectJobsReducer,
  jobs => jobs.due_date_changed_by
);
export const selectJobDueDatePrevious = createSelector(
  selectJobsReducer,
  jobs => jobs.due_date_previous
);
export const selectTableShouldRefetchData = state =>
  selectJobsReducer(state).tableShouldRefetchData;
