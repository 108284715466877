import {
  AsyncSystemTaskEvent,
  AsyncSystemTaskProgress,
  SystemTask,
  SystemTaskType
} from '@/types/system-tasks';

export const getSystemTaskIsCompleted = (taks?: SystemTask | null) => taks?.status === 'completed';

export const getAsyncSystemTaskIsCompleted = (asyncTask?: SystemTask | null) =>
  (asyncTask?.event && asyncTask.event === 'async_action.completed') || false;

export const getAsyncSystemTaskHasFailed = (asyncTask?: SystemTask | null) =>
  (asyncTask?.event &&
    ['async_action.failed', 'async_action.terminated'].includes(asyncTask.event)) ||
  (asyncTask?.status && ['failed', 'terminating', 'terminated'].includes(asyncTask.status)) ||
  false;

export const calculateAsyncSystemTaskProgressPercentage = (
  event?: AsyncSystemTaskEvent,
  progress?: AsyncSystemTaskProgress
) => {
  if (event === 'async_action.completed') return 100;
  if (!progress) return 0;

  const { processed, total } = progress;

  if (total === 0) return 0;

  return Math.round((processed / total) * 100);
};

export const checkAsyncFileTaskIsAlreadyInProgress = (
  pendingTaks: SystemTask[] | undefined,
  type: SystemTaskType
) => {
  if (pendingTaks && pendingTaks.find(t => t.type === type)) {
    throw new Error('The file is already being downloaded. Please wait.');
  }
};
