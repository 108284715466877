import SingleSystem from '@/common/components/pms/setup/views/system';
import styled from '@emotion/styled';
import ViewButton from '@/common/components/buttons/ViewButton';
import HideButton from '@/common/components/buttons/HideButton';
import variables from '@/common/assets/scss/abstracts/_exports.module.scss';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectSelectedSystem,
  selectSystemFormType,
  selectSystemCollapsed
} from '@/common/components/pms/setup/store/selectors';
import { setSystemCollapsed } from '@/common/components/pms/setup/store/actions';

const CollapsableSystemContrainer = () => {
  const systemCollapsed = useAppSelector(selectSystemCollapsed);
  const selectedSystem = useAppSelector(selectSelectedSystem);
  const systemFormType = useAppSelector(selectSystemFormType);

  const dispatch = useAppDispatch();

  return (
    <Side className="position-relative h-100p" isCollapsed={systemCollapsed}>
      <CollapsedContainer
        isCollapsed={systemCollapsed}
        className="d-flex flex-column position-absolute top-0 h-100p"
      >
        <ViewButton
          className="cmb-12"
          onClick={() => dispatch(setSystemCollapsed(!systemCollapsed))}
        />
        <div className="d-flex flex-column align-items-center cpt-12 w-32 h-100p border-radius-7 bg-white">
          <RotatedText className="text-dark fs-12">SPECIFICATIONS</RotatedText>
        </div>
      </CollapsedContainer>

      <UncollapsedContainer
        isCollapsed={systemCollapsed}
        className="d-inline-flex align-items-center justify-content-end position-absolute"
      >
        <HideButton
          className={!selectedSystem && !systemFormType ? 'cme-12' : ''}
          onClick={() => dispatch(setSystemCollapsed(!systemCollapsed))}
        />
      </UncollapsedContainer>

      <AnimatedSingleSystemContainer
        isCollapsed={systemCollapsed}
        className="h-100p position-relative"
      >
        <SingleSystem />
      </AnimatedSingleSystemContainer>
    </Side>
  );
};

const RotatedText = styled.strong`
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
`;

const CollapsedContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  opacity: ${isCollapsed ? '1' : '0'};
  transition: all 0.5s ease-in-out;
`
);

const UncollapsedContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  opacity: ${isCollapsed ? '0' : '1'};
  transition: opacity 0.5s ease-in-out;
  z-index: ${isCollapsed ? '-2' : '2'};
  top: ${variables.size12};
  right: 0;
  `
);

const AnimatedSingleSystemContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  width: ${isCollapsed ? '32px' : `${variables.pmsSingleSystemWidth}px`};
  opacity: ${isCollapsed ? '0' : '1'};
  transition: all 0.5s ease-in-out;
  z-index: ${isCollapsed ? '-1' : '1'};
  overflow: ${isCollapsed ? 'hidden' : 'visible'};
  `
);

const Side = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
  width: ${isCollapsed ? '32px' : `${variables.pmsSingleSystemWidth}px`};
  transition: width 0.5s ease-in-out;
  `
);

export default CollapsableSystemContrainer;
