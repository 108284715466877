import PropTypes from 'prop-types';
import Table, { useTable } from 'common/components/table';
import TopFilters from 'views/pms/library/_components/TableTopFilters';
import SparePartsForm from 'common/components/pms/setup/views/system/specifications/spare-parts/spare-parts-form';

import { rows, getColumns } from './tableConfig';
import { useCallback, useState } from 'react';
import { useActions } from 'utils/hooks';
import useDrawer from 'common/components/drawer/useDrawer';

import edit from 'common/assets/svg/actions/edit.svg';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import permissions from '@/common/utils/permissions/constants';
import { VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES } from '../job-prototypes/tableConfig';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';

const SpareParts = ({ label }) => {
  const [selectedPartForEdit, setSelectedPartForEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [setSelectedSystem, getSingleSystem] = useActions([
    pmsActions.setSelectedSystem,
    pmsActions.getSingleSystem
  ]);

  const drawer = useDrawer(false);
  const groupsIsVisible = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const table = useTable({
    label,
    columns: getColumns({ groupsIsVisible }),
    top: {
      filters: [
        {
          name: VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES,
          operation: 'oneOf',
          value: null
        },
        {
          name: 'vessel_system_group_id',
          operation: 'oneOf',
          value: null
        }
      ]
    }
  });

  const onFinishSave = () => {
    setSelectedPartForEdit(null);
    table.refetchData();
  };

  const onEdit = useCallback(
    async data => {
      const systemId = data?.vessel_systems?.[0]?.id;

      if (!systemId) return;

      setIsLoading(true);
      await getSingleSystem({ id: systemId });

      setSelectedSystem(systemId);
      setSelectedPartForEdit(data.id);

      drawer.open();
      setIsLoading(false);
    },
    [drawer, getSingleSystem, setSelectedSystem]
  );

  const onClose = useCallback(() => setSelectedPartForEdit(null), []);

  return (
    <div>
      <Table
        loader
        rows={{
          ...rows,
          actions: data => ({
            options: [
              {
                label: 'Edit Job',
                icon: edit,
                onClick: () => onEdit(data),
                permissions: [permissions.office.pms.library.systems.edit_spare_parts]
              }
            ]
          })
        }}
        topFiltersComponent={<TopFilters label={label} />}
        {...table}
        fetching={table.fetching || isLoading}
      />

      <SparePartsForm
        onClose={onClose}
        drawer={drawer}
        onFinishSave={onFinishSave}
        selectedPartForEdit={selectedPartForEdit}
      />
    </div>
  );
};

SpareParts.propTypes = {
  label: PropTypes.string.isRequired
};

export default SpareParts;
