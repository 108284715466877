import PropTypes from 'prop-types';
import ModularTable, { useModularTable } from '@/common/components/ModularTable';
import TopFilters from 'views/pms/library/_components/TableTopFilters';
import JobsForm from 'common/components/pms/setup/views/system/specifications/jobs/jobs-form';
import PmsModal from 'common/components/pms/setup/views/components/modals/PmsModal';
import DownloadAttachment from '@/common/components/buttons/DownloadAttachment';

import useDrawer from 'common/components/drawer/useDrawer';
import edit from 'common/assets/svg/actions/edit.svg';
import deleteIcon from 'common/assets/svg/actions/delete.svg';
import moment from 'moment';

import { rows, columns, VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES } from './tableConfig';
import { useCallback, useState } from 'react';
import { useActions } from 'utils/hooks';
import { constructColumns } from '@/common/components/table/utils/modular-table/helpers';
import { downloadAttachedManuals } from '@/common/components/jobs/maintenance/store/actions';
import { useDispatch } from 'react-redux';
import { saveDownloadedFile } from '@/common/utils/downloads';
import * as pmsActions from 'common/components/pms/setup/store/actions';
import { getVesselSystemMaintenanceJob } from '@/common/api/vessel-systems/api';
import permissions from '@/common/utils/permissions/constants';
import ActivationCirlce from '@/common/components/general/ActivationCirlce';
import ActivationDrawer from './activation-drawer';

const JobPrototypes = ({ label }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);

  const [selectedJobTriggerPrototypeForActivation, setSelectedJobTriggerPrototypeForActivation] =
    useState();
  const [selectedJobForDelete, setSelectedJobForDelete] = useState(null);
  const [selectedJobForEdit, setSelectedJobForEdit] = useState(null);
  const [setSelectedSystem, getSingleSystem, deleteMaintenanceJob] = useActions([
    pmsActions.setSelectedSystem,
    pmsActions.getSingleSystem,
    pmsActions.deleteMaintenanceJob
  ]);

  const editDrawer = useDrawer(false);
  const activationDrawer = useDrawer(false);
  const dispatch = useDispatch();

  const table = useModularTable({
    label,
    config: () => ({
      columns: constructColumns(columns, [], table.label)
    }),
    top: {
      filters: [
        {
          name: VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES,
          operation: 'oneOf',
          value: null
        },
        {
          name: 'vessel_system_group_id',
          operation: 'oneOf',
          value: null
        },
        {
          name: 'has_required_spare_parts',
          operation: '=',
          value: null
        }
      ]
    }
  });

  const onFinishSave = () => {
    setSelectedJobForEdit(null);
    table.refetchData();
  };

  const handleEditActivation = data => {
    setSelectedJobTriggerPrototypeForActivation(data);
    activationDrawer.open();
  };

  const handleCloseEditActivation = () => {
    setSelectedJobTriggerPrototypeForActivation(undefined);
    activationDrawer.close();
  };

  const onEdit = useCallback(
    async data => {
      const systemId = data?.maintenance_details?.vessel_system_id;
      const prototypeId = data?.id;

      if (!systemId) return;

      setIsLoading(true);
      await getSingleSystem({ id: systemId });

      setSelectedSystem(systemId);
      setSelectedJobForEdit(prototypeId);
      editDrawer.open();
      setIsLoading(false);
    },
    [editDrawer, getSingleSystem, setSelectedSystem]
  );

  const onCloseEdit = useCallback(() => setSelectedJobForEdit(null), []);

  const onDelete = useCallback(data => {
    setSelectedJobForDelete(data);
  }, []);

  const onDeleteAccepted = async () => {
    try {
      await deleteMaintenanceJob({
        system_id: selectedJobForDelete?.maintenance_details?.vessel_system_id,
        id: selectedJobForDelete.id
      });
      setSelectedJobForDelete(null);
      table.refetchData();
    } catch (e) {
      console.error(e);
    }
  };

  const requestAttachments = async data => {
    try {
      setIsLoadingAttachments(true);
      const res = await getVesselSystemMaintenanceJob({ id: data.id });
      setIsLoadingAttachments(false);
      return res?.maintenance_attachments || [];
    } catch (err) {
      console.error(err);
      setIsLoadingAttachments(false);
    }
  };

  const downloadHandler = async (id, description) => {
    try {
      setIsLoadingAttachments(true);
      const response = await dispatch(downloadAttachedManuals({ id }));

      saveDownloadedFile(response, `${description}-${moment().format('dd-mm-yyyy-HH:mm')}`);
      setIsLoadingAttachments(false);
    } catch (err) {
      setIsLoadingAttachments(false);
      console.error(err);
    }
  };

  return (
    <div>
      <ModularTable
        style="default"
        highlightColumnOnHover={false}
        setPageMaxHeight={false}
        loader
        getRowClassName={() => 'align-items-start'}
        rows={{
          ...rows,
          maintenance_attachments_count: data => (
            <div className="me-auto">
              <DownloadAttachment
                isDisabled={isLoadingAttachments}
                data={{
                  attachments: data.maintenance_attachments,
                  attachments_count: data?.maintenance_attachments?.length
                }}
                downloadFiles={() => downloadHandler(data?.id, data.description)}
                requestAttachments={() => requestAttachments(data)}
                hasAttachments={data?.maintenance_attachments?.length > 0}
                canAddAttachments={false}
              />
            </div>
          ),
          activation: data => (
            <div className="max-width-fit">
              <ActivationCirlce
                onClick={() => handleEditActivation(data)}
                isActive={data.has_active_jobs}
              />
            </div>
          ),
          actions: data => ({
            options: [
              {
                label: 'Edit Job',
                icon: edit,
                onClick: () => onEdit(data),
                permissions: [permissions.office.pms.library.systems.edit_jobs]
              },
              {
                label: 'Delete Job',
                icon: deleteIcon,
                onClick: () => onDelete(data),
                permissions: [permissions.office.pms.library.systems.delete_jobs]
              }
            ]
          })
        }}
        topFiltersComponent={
          <TopFilters
            label={table.label}
            topFilters={table.topFilters}
            setTopFilters={table.setTopFilters}
          />
        }
        {...table}
        fetching={table.fetching || isLoading}
      />

      <ActivationDrawer
        drawer={activationDrawer}
        onClose={handleCloseEditActivation}
        jobTriggerPrototype={selectedJobTriggerPrototypeForActivation}
        onUpdateVesselAssignmentActivation={table.refetchData}
      />

      <JobsForm
        onClose={onCloseEdit}
        drawer={editDrawer}
        onFinishSave={onFinishSave}
        selectedJobForEdit={selectedJobForEdit}
      />

      <PmsModal
        isOpen={!!selectedJobForDelete}
        header="Delete Job"
        closeModal={() => setSelectedJobForDelete(null)}
        actionText="DELETE"
        description={
          selectedJobForDelete ? (
            <div>
              Are you sure you want to delete <strong>{selectedJobForDelete.title}</strong>?
              <br /> This cannot be undone.
            </div>
          ) : (
            ''
          )
        }
        onAccept={onDeleteAccepted}
      />
    </div>
  );
};

JobPrototypes.propTypes = {
  label: PropTypes.string.isRequired
};

export default JobPrototypes;
