import React from 'react';
import PropTypes from 'prop-types';

const VesselTab = ({ selected, onClick, vessel, isSubSystem }) => {
  return (
    // ${isSubSystem && vessel?.untriggered_maintenance_jobs_count ? 'untriggered' : ''}
    <div
      onClick={onClick}
      className={`vessel-tab text-nowrap d-flex align-items-center justify-content-between fs-10 fw-bold cme-4 cmb-4 position-relative ${
        selected ? 'vessel-tab__selected' : ''
      }`}
    >
      {vessel?.name}
    </div>
  );
};

VesselTab.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onAddAction: PropTypes.func,
  vessel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
    // untriggered_maintenance_jobs_count: PropTypes.bool
  }),
  isSubSystem: PropTypes.bool
};

export default VesselTab;
