import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceFetching,
  selectIsUnscheduledJob
} from 'common/components/pms/jobs/store/selectors';
import { useSelector } from 'react-redux';

import Loader from './Loader';
import Main from './main';

const Maintenance = ({ side, className }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const fetching = useSelector(selectMaintenanceFetching);

  const isUnscheduled = useSelector(selectIsUnscheduledJob);

  return (
    <div className={`job-maintenance ${className || ''}`}>
      <div className={`job-maintenance__container `}>
        {fetching ? <Loader /> : <Main isUnscheduled={isUnscheduled} side={side} jobId={jobId} />}
      </div>
    </div>
  );
};

export default Maintenance;
