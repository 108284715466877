import { FC } from 'react';
import { Attachment, Size } from '@/ts-common/types/files';
import { imageUrlSelector } from '@/ts-common/utils/image-size';
import { icon } from '@/ts-common/utils/files';
import { isFileSynced } from '@/api/files/api';

import FileContainer from './FileContainer';
import FileSyncTooltip from './FileSyncTooltip';
import FileRemoveButton from './FileRemoveButton';

import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import img from '@/ts-common/assets/svg/files/img.svg';

import Image from '@/ts-common/components/general/Image';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type FilePreviewTypes = {
  size: Size;
  file: Attachment;
  previewImageBaseUrl: string;
  viewOnly?: boolean;
  onFileClick: (file: Attachment) => void;
  onFileRemove: (file: Attachment) => void;
  imageSizeLabel?: string;
  singleUpload?: boolean;
  index: number;
};

const FilePreview: FC<FilePreviewTypes> = ({
  size,
  file,
  previewImageBaseUrl,
  viewOnly,
  onFileClick,
  onFileRemove,
  imageSizeLabel,
  singleUpload,
  index
}) => {
  const { tooltipID, avoidRender } = useTooltipID();
  const { is_synced } = isFileSynced(file);

  return (
    <div
      className={`file-preview ${!singleUpload ? 'me-1' : ''} d-flex flex-column ${
        !singleUpload ? 'pb-1' : ''
      } ${size === 'full' ? 'w-100p' : null}`}
    >
      <FileContainer size={size}>
        <Thumbnail
          id={tooltipID ? tooltipID : ''}
          onClick={() => (is_synced ? onFileClick(file) : null)}
          data-testid="files-thumbnail"
          data-cy={`file-${index + 1}`}
          size={size}
        >
          {file.mime_type?.startsWith('image') ? (
            <Image
              className="w-100p h-100p"
              src={previewImageBaseUrl + imageUrlSelector({ file, label: imageSizeLabel || null })}
              fallback={img}
            />
          ) : (
            <Image
              className="w-100p h-100p file-fallback"
              src={icon(file.mime_type, file.extension)}
            />
          )}
        </Thumbnail>
        {!viewOnly && (
          <FileRemoveButton file={file} size={size} onClick={() => onFileRemove(file)} />
        )}
      </FileContainer>
      {size === 'lg' && (
        <FileContainer size={size} height="initial">
          <div className="text-truncate text-nowrap fs-9 fw-medium cmt-4 text-preview">
            {file.filename}
          </div>
        </FileContainer>
      )}

      {avoidRender ? null : <FileSyncTooltip file={file} tooltipID={tooltipID} />}
    </div>
  );
};

const spacing: {
  [key in 'lg' | 'sm' | 'xs' | 'full']: string;
} = {
  lg: '26%',
  sm: '',
  xs: '',
  full: ''
};

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  border-radius: inherit;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  .file-fallback {
    padding: ${({ size }: { size: Size }) => spacing[size] || '12%'};
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${variables.squidInk};
    opacity: 0.25;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    display: none;
  }

  &:hover {
    &:before {
      display: block;
    }
  }
`;

export default FilePreview;
