import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

export const calculatePaddingLeft = (depth: number): number => {
  // Define initial padding and increments
  const basePadding = 36;
  const increments = [25, 24, 23];

  // Calculate the padding-left value based on depth
  if (depth === 1) {
    return basePadding;
  } else {
    let padding = basePadding;
    for (let i = 1; i < depth; i++) {
      // Determine which increment to use based on the current depth
      const increment = increments[(i - 1) % increments.length];
      padding += increment;
    }
    return padding;
  }
};

export const OuterTableWrapper = styled.div`
  .collapsed-row-content__inner {
    &::before {
      height: 0 !important;
    }
  }

  .cell-collapse-row {
    position: relative !important;
  }

  .app-table--body--row {
    .cell {
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  .app-table--body--row-wrap {
    &.is-open {
      background-color: white;
    }

    &:hover {
      box-shadow: none !important;
    }

    .collapse-toggle-header__toggle {
      color: ${variables.moodyBlue} !important;

      .svg-wrap {
        border-color: ${variables.moodyBlue} !important;
      }
    }
  }
`;

export const NestedTableWrapper = styled.div`
  .app-table--body--row-wrap {
    &.is-open {
      box-shadow: none !important;
    }
  }

  .app-table--body--row {
    .cell {
      &:first-child {
        border-radius: 7px 0 0 7px;
      }
      &:last-child {
        border-radius: 0 7px 7px 0;
      }
    }
    padding-left: 0.75rem;
    padding-right: 0.45rem;
    &:hover {
      & > .cell {
        background-color: ${variables.formShades1} !important;
      }
    }
  }
`;

export const OuterTableWithInnerRowsWrapper = styled.div`
  .collapsed-row-content__inner {
    &::before {
      height: 0 !important;
    }

    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .cell--inner {
    color: ${variables.nautica};
  }

  .cell-collapse-row {
    position: relative !important;
  }

  .app-table--body--row {
    .collapse-toggle-header {
      position: absolute;
      left: 17px;
      z-index: 2;
    }

    .cell {
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  .app-table--body--row-wrap {
    &.is-open {
      background-color: white;
      box-shadow: none !important;
      margin-bottom: 0.02rem !important;
    }

    &:hover {
      box-shadow: none !important;
    }

    .collapse-toggle-header__toggle {
      color: ${variables.nautica} !important;

      .svg-wrap {
        border-color: ${variables.nautica} !important;
      }
    }
  }
`;

export const NestedTableWithInnerRowsWrapper = styled.div`
  .app-table--body--row-wrap {
    &.is-open {
      box-shadow: none !important;
      margin-bottom: 0.02rem !important;
    }
  }

  .collapse-toggle-header {
    padding-left: ${({ depth }: { depth: number }) => `${depth * 15}px`};
  }

  .app-table--body--row {
    .collapse-toggle-header {
      padding-left: ${({ depth }: { depth: number }) => `${depth * 24}px`} !important;
    }

    .cell {
      &:first-child {
        border-radius: 7px 0 0 7px;
      }
      &:last-child {
        border-radius: 0 7px 7px 0;
      }
      &:nth-child(2) {
        padding-left: ${({ depth }: { depth: number }) =>
          `${calculatePaddingLeft(depth)}px`} !important;
      }
    }
    padding-right: 0.45rem;
    &:hover {
      & > .cell {
        background-color: ${variables.formShades1} !important;
      }
    }
  }
`;

export const NestedWhiteTableWithInnerRowsWrapper = styled.div`
  .app-table--body--row-wrap {
    &.is-open {
      margin-bottom: 0.02rem !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }

  .app-table--body--row {
    position: relative;

    .collapse-toggle-header {
      padding-left: ${({ depth }: { depth: number }) => `${depth * 24}px`} !important;
    }

    .cell {
      &:first-child {
        border-radius: 7px 0 0 7px;
      }
      &:last-child {
        border-radius: 0 7px 7px 0;
      }
      &:nth-child(2) {
        padding-left: ${({ depth }: { depth: number }) =>
          `${calculatePaddingLeft(depth)}px`} !important;
      }
    }

    padding-right: 0.45rem;

    &:hover {
      .cell {
        background-color: ${variables.white} !important;
      }
    }
  }
`;

export const OuterWhiteTableWithInnerRowsWrapper = styled.div`
  .collapsed-row-content__inner {
    &::before {
      height: 0 !important;
    }

    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .cell--inner {
    color: ${variables.nautica};
  }

  .cell-collapse-row {
    position: relative !important;
  }

  .app-table--body--row {
    .collapse-toggle-header {
      position: absolute;
      left: 17px;
      z-index: 2;
    }

    .cell {
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }

    &:hover {
      .cell {
        background-color: ${variables.white} !important;
      }
    }
  }

  .app-table--body--row-wrap {
    &.is-open {
      background-color: white;
      box-shadow: none !important;
      margin-bottom: 0.02rem !important;
      background-color: unset !important;

      &:hover {
        background-color: unset !important;
      }
    }

    .app-table--body--row {
      &:hover {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
      }
    }

    &:hover {
      box-shadow: none !important;
    }

    .collapse-toggle-header__toggle {
      color: ${variables.nautica} !important;

      .svg-wrap {
        border-color: ${variables.nautica} !important;
      }
    }
  }
`;

export const GrayFlatTableWrapper = styled.div`
  .app-table {
    &--main {
      & .app-table--head {
        &-inner {
          & .cell {
            &--inner {
              color: ${variables.violet};
              font-size: ${variables.size12};
              text-transform: none;
              & .sorting-arrows {
                color: ${variables.violet};
              }
            }
          }
        }
      }
      & .app-table--body {
        &--row {
          background-color: transparent;
          &:hover {
            box-shadow: none;
          }
          & .cell {
            background-color: transparent;
            color: ${variables.primary};
          }
        }
      }
    }
  }
`;
