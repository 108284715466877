import { ReviewProcess } from '@/common/types/review-process';
import { EntityReviewProcess } from './selectors-ts';

export const constructEntities = (
  entities: Record<number | string, EntityReviewProcess>,
  entity_id: number | string,
  payload: ReviewProcess
) => {
  const data = {
    ...payload,
    id: payload?.id || null,
    name: payload.name || '',
    steps: payload?.steps || [],
    isEnabled: payload?.id ? true : entities[entity_id]?.isEnabled
  };

  return {
    ...entities,
    [entity_id]: data
  };
};
