import React from 'react';

import NumberInput from 'common/components/form/inputs/NumberInput';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { strToNumber } from 'common/utils/numbers';
import { isReportLocked } from 'captain-reports/store/selectors';

import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import useAutocompleteValues from 'captain-reports/utils/useAutocompleteValues';
import _debounce from 'lodash/debounce';
import _isObject from 'lodash/isObject';
import useInitializeFromPreviousReport from 'captain-reports/utils/useInitializeFromPreviousReport';
import useError from 'captain-reports/utils/useError';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const NumberField = ({
  field,
  tooltip,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  onChangeFallback,
  subGroupIndex,
  ...rest
}) => {
  const isLocked = useSelector(isReportLocked);

  useInitializeFromPreviousReport({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const autocompleteArray = useAutocompleteValues(
    field.autocompleteValues ? field.autocompleteValues.map(el => ({ ...el, subGroupIndex })) : []
  );

  const isTemplateValidationError = error && _isObject(error);

  const debouncedAutoComplete = _debounce(() => {
    const result = field.autocomplete(...autocompleteArray);

    if (result !== value) {
      setValue({
        key: field.key,
        value: result,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, 600);

  const onChange = e => {
    setValue({
      key: field.key,
      value: strToNumber(e.target.value),
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });

    if (onChangeFallback) {
      onChangeFallback(strToNumber(e.target.value));
    }
  };

  useUpdateEffect(() => {
    if (field.autocomplete) {
      debouncedAutoComplete();
    }
  }, [...autocompleteArray]);

  const { hideLabel, inputProps = {} } = field;
  const { subLabel, ...restInputProps } = inputProps;

  return (
    <>
      <div className={`d-flex align-items-center ${!field.label ? 'mt-1' : ''}`}>
        <ValidationWrapper
          field={{ ...field, label: null }}
          value={value}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
          staticPosition={false}
        >
          <NumberInput
            onChange={onChange}
            label={
              hideLabel ? null : (
                <FieldLabel
                  className={field?.labelClassName || ''}
                  info={tooltip}
                  label={field.label}
                  subLabel={field.subLabel}
                  fieldKey={field.key}
                />
              )
            }
            placeholder=""
            className="w-100p"
            value={value === null || value === undefined ? '' : value.toString()}
            disabled={isLocked || field.disabled}
            decimalScale={field.decimalScale ? field.decimalScale : undefined}
            {...{ ...restInputProps, 'data-testid': `captain-reports-${field.key}` }}
            error={isTemplateValidationError ? null : error}
            {...rest}
          />
        </ValidationWrapper>
        {inputProps.subLabel && (
          <div className="mt-3 fs-10 text-violet ms-1 fw-bold">{inputProps.subLabel}</div>
        )}
      </div>
      {inputProps.bottomLabel && (
        <div className="cmt-4 fs-10 text-violet fw-normal">{inputProps.bottomLabel}</div>
      )}
    </>
  );
};

export default NumberField;
