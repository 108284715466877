import _get from 'lodash/get';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Row, Col } from 'reactstrap';
import TreeTitleActions from 'common/components/tree-layout/main/TreeTitleActions';
import { capitalizeFirst } from 'common/utils/texts';

import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

const getActions = (data, setSelectedItem) => [
  {
    name: 'Edit',
    onClick: () => {
      setSelectedItem({ data, type: 'edit' });
    },
    permissions: [permissions.office.pms.configuration.system_groups.edit]
  },
  {
    name: 'Add sub-group',
    onClick: () => {
      setSelectedItem({ data, type: 'add_sub_group' });
    },
    isShown: true,
    permissions: [permissions.office.pms.configuration.system_groups.edit]
  },
  {
    name: 'Delete',
    onClick: () => {
      setSelectedItem({ data, type: 'delete' });
    },
    isShown: true,
    permissions: [permissions.office.pms.configuration.system_groups.edit]
  }
];

export const getRows = setSelectedItem => ({
  name: data => <TextWithTooltip className="h-auto">{_get(data, 'name')}</TextWithTooltip>,
  type: data => {
    const actions = getActions(data, setSelectedItem);
    return (
      <Row className="w-100p align-items-center">
        <Col xs={11}>
          <TextWithTooltip className="h-auto">
            {capitalizeFirst(data?.type?.split('_')?.join(' ')) || '-'}
          </TextWithTooltip>
        </Col>

        <Col className="d-flex align-items-center justify-content-end pe-0">
          <AuthCheck permissions={[permissions.office.pms.library.edit_groups]}>
            <TreeTitleActions actions={actions} />
          </AuthCheck>
        </Col>
      </Row>
    );
  }
});

export const constructDrawerTitle = (type, data) => {
  switch (type) {
    case 'edit':
      return <div>Edit System Group</div>;
    case 'create':
      return <div>Create new System Group</div>;
    case 'add_sub_group':
      return (
        <div className="d-flex flex-column">
          <div>Add Sub-Group</div>
          <div className="cmt-6 fw-bold fs-12">{data?.name}</div>
        </div>
      );

    default:
      return null;
  }
};

export const constructDrawerButtonTitle = (type, data) => {
  switch (type) {
    case 'edit':
      return 'SAVE';
    case 'create':
      return 'CREATE';
    case 'add_sub_group':
      return 'ADD';

    default:
      return null;
  }
};
