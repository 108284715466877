import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

import { selectJobField } from '@/common/components/jobs/_base/store/selectors.js';

const PmsJobs = React.lazy(() => retry(() => import('@/views/pms/list/index.jsx')));
const PmsRunningHours = React.lazy(() =>
  retry(() => import('@/views/pms/running-hours/index.jsx'))
);
const Inventory = React.lazy(() => retry(() => import('@/views/pms/inventory/index.jsx')));
const PmsSystemJobs = React.lazy(() => retry(() => import('@/views/pms/system/index.jsx')));
const SystemRunningHours = React.lazy(() =>
  retry(() => import('@/views/captain-reports/system-running-hours/index.jsx'))
);
const PmsLibrary = React.lazy(() => retry(() => import('@/views/pms/library/index.jsx')));

const jobs = [
  {
    path: `${paths.pms_jobs}/create`,
    component: PmsJobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.jobs.create],
    documentTitle: 'Create Unplanned Maintenance Job'
  },
  {
    path: `${paths.pms_jobs}/:jobID?`,
    component: PmsJobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.pms.jobs.view,
      permissions.office.jobs.edit,
      permissions.office.jobs.view
    ],
    documentTitle: state => selectJobField(state, 'document_title') || 'PMS Jobs'
  },
  {
    path: `${paths.pms_jobs_per_system}/create`,
    component: PmsSystemJobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.jobs.create],
    documentTitle: 'Create Unplanned Maintenance Job'
  },
  {
    path: `${paths.pms_jobs_per_system}/:jobID?`,
    component: PmsSystemJobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.pms.jobs.view,
      permissions.office.jobs.edit,
      permissions.office.jobs.view
    ],
    documentTitle: state => selectJobField(state, 'document_title') || 'PMS Jobs'
  },

  {
    path: `${paths.pms_running_hours}`,
    component: PmsRunningHours,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.pms.running_hours.view],
    documentTitle: 'PMS Running Hours'
  },
  {
    path: `${paths.pms_running_hours}/:id`,
    component: SystemRunningHours,
    type: 'private',
    exact: true,
    app: true,
    permissions: [permissions.office.pms.running_hours.view],
    documentTitle: 'System Running Hours'
  },
  {
    path: [
      `${paths.pms_library}/job-prototypes`,
      `${paths.pms_library}/spare-parts`,
      `${paths.pms_library}/vessel-systems`,
      `${paths.pms_library}/vessel-system-assignments`,
      `${paths.pms_library}/groups/:group_id`,
      `${paths.pms_library}/:vessel_id?`,
      `${paths.pms_library}/:vessel_id?/groups/:group_id`
    ],
    component: PmsLibrary,
    app: true,
    type: 'private',
    documentTitle: (_, { location }) =>
      `PMS Library${
        location.pathname.includes('/job-prototypes')
          ? ' - Job Prototypes'
          : location.pathname.includes('/spare-parts')
            ? ' - Spare Parts'
            : location.pathname.includes('/vessel-systems')
              ? ' - Vessel Systems'
              : location.pathname.includes('/vessel-system-assignments')
                ? ' - Vessel System Assignments'
                : ''
      }`,
    key: 'pms-library',
    exact: true,
    permissions: {
      0: [permissions.office.pms.library.view],
      1: [permissions.office.pms.library.view],
      2: [permissions.office.pms.library.systems.view],
      3: [permissions.office.pms.library.systems.view],
      4: [permissions.office.pms.library.view],
      5: [permissions.office.pms.library.view],
      6: [permissions.office.pms.library.view]
    }
  },
  {
    path: [
      `${paths.pms_configuration}/system-groups`,
      `${paths.pms_configuration}/system-attributes`,
      `${paths.pms_configuration}/job-types`
    ],
    component: PmsLibrary,
    app: true,
    type: 'private',
    documentTitle: (_, { location }) =>
      `PMS Configuration${
        location.pathname.includes('/system-attributes')
          ? ' - System Attributes'
          : location.pathname.includes('/job-types')
            ? ' - Job Types'
            : location.pathname.includes('/system-groups')
              ? ' - System Groups'
              : ''
      }`,
    key: 'pms-configuration',
    exact: true,
    permissions: {
      0: [permissions.office.pms.configuration.system_groups.view],
      1: [permissions.office.pms.configuration.system_attributes.view],
      4: [permissions.office.pms.configuration.system_job_types.view]
    }
  },
  {
    path: `${paths.pms_inventory}`,
    component: Inventory,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.pms.spare_parts_inventory.view],
    documentTitle: 'Spare Parts Inventory'
  }
];

export default jobs;
