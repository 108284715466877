import _get from 'lodash/get';
import { FormConfigType } from '@/common/utils/form/fieldTypes';

type DataFromStateReturnType<T> = T extends null ? undefined : T;

const selectDataFromState = <T = Record<string, unknown>>(
  state: T | null,
  config: FormConfigType
): DataFromStateReturnType<T> => {
  // state = data object, config = useForm config
  if (!state) return undefined as any;

  const data: T = { ...state };

  const values = Object.keys(config).reduce((acc: Record<string, unknown>, key) => {
    if (data[key] === false) acc[key] = false;
    else {
      acc[key] = _get(data, key, null) || null;
    }

    return acc;
  }, {});

  return values as DataFromStateReturnType<T>;
};

export default selectDataFromState;
