import ShadowBox from 'common/components/general/ShadowBox';
import Textarea from 'common/components/form/inputs/Textarea';
import ImportanceDropdownSelector from 'common/components/selectors/ImportanceDropdownSelector';
import FindingCategoriesSelector from '@/common/components/selectors/FindingCategoriesSelector';
import FindingSubCategoriesSelector from '@/common/components/selectors/FindingSubCategoriesSelector';
import FindingTagsSelector from '@/common/components/selectors/FindingTagsSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import EntityLabel from 'common/components/labels/EntityLabel';
import ResolveDates from './ResolveDates';
import { FC } from 'react';
import { ChangeFieldFunc, FieldsStateType, SelectFieldFunc } from '@/common/types/form';
import { Finding } from '@/common/types/findings';
import { Col, Label, Row } from 'reactstrap';
import DateInput from 'common/components/form/inputs/date';
import ClearButton from 'common/components/buttons/ClearButton';

type SidebarProps = {
  changeField: ChangeFieldFunc;
  selectField: SelectFieldFunc;
  active: Finding;
  isOnBoard: boolean;
};

const Sidebar: FC<SidebarProps> = ({ changeField, selectField, active, isOnBoard, ...rest }) => {
  const fields = rest as FieldsStateType<Finding>;
  const shouldDisable = isOnBoard && active;

  return (
    <div className="h-100p py-3 overflow-y-hidden">
      <ShadowBox color="light-1" className={`flex-1 px-3 h-100p py-2`} flat>
        <Textarea
          autoResize
          rows={1}
          maxLength={255}
          disabled={shouldDisable}
          placeholder="Add finding"
          label="Finding"
          onChange={changeField('name')}
          {...fields.name}
        />

        <Row noGutters>
          <Col xs="auto">
            <ImportanceDropdownSelector
              disabled={shouldDisable}
              onChange={selectField('importance_id')}
              initializeWhennEmtpy={!active}
              {...fields.importance_id}
            />
          </Col>
          <Col className="ps-1">
            <FindingCategoriesSelector
              disabled={shouldDisable}
              placeholder="Select category"
              isMulti={false}
              label="Category"
              onChange={value => selectField('category')(value)}
              listParams={{
                audit_ids: fields.origin_audit.value?.event_audit_id
                  ? [fields.origin_audit.value?.event_audit_id]
                  : undefined
              }}
              isClearable
              {...fields.category}
            />
          </Col>
        </Row>

        {isOnBoard ? null : (
          <DepartmentSelector
            label="Department"
            disabled={shouldDisable}
            placeholder="Add department"
            onChange={selectField('department_id')}
            isMulti={false}
            isClearable
            withCrewDepartments
            {...fields.department_id}
          />
        )}

        <FindingSubCategoriesSelector
          placeholder="Add subcategory"
          label="Subcategory"
          disabled={shouldDisable}
          onChange={value => {
            selectField('subcategory')(value);
            selectField('tags')(null);
          }}
          listParams={{
            audit_ids: fields.origin_audit.value?.event_audit_id
              ? [fields.origin_audit.value?.event_audit_id]
              : undefined,
            category_ids: fields.category.value?.id ? [fields.category.value?.id] : undefined
          }}
          isClearable
          {...fields.subcategory}
        />

        <FindingTagsSelector
          placeholder="Select tags"
          isMulti
          label="Tags"
          disabled={shouldDisable}
          onChange={value => selectField('tags')(value)}
          listParams={{
            audit_ids: fields.origin_audit.value?.event_audit_id
              ? [fields.origin_audit.value?.event_audit_id]
              : undefined,
            category_ids: fields.category.value?.id ? [fields.category.value?.id] : undefined,
            subcategory_ids: fields.subcategory.value?.id
              ? [fields.subcategory.value?.id]
              : undefined
          }}
          isClearable
          {...fields.tags}
        />

        <div className="border-bottom pt-1 mb-3"></div>

        <Row noGutters>
          <Col xs={12} className="d-flex align-items-end cmb-12">
            <DateInput
              label="Due Date"
              disabled={shouldDisable}
              onChange={(date: string) => selectField('due_date')(date)}
              className="mb-0 me-1"
              {...fields.due_date}
            />

            {fields.due_date?.value ? (
              <div className="cmb-2">
                <ClearButton
                  disabled={shouldDisable}
                  onClick={() => selectField('due_date')(null)}
                />
              </div>
            ) : null}
          </Col>

          <Col xs={3} className="d-flex flex-column">
            <Label className="form-label">STATUS</Label>
            <EntityLabel
              color={active?.resolved_at ? 'color_5' : 'color_4'}
              className="fw-bold flex-1 w-100p"
              innerClassName="mx-auto fs-10"
              type="status"
            >
              {active?.resolved_at ? 'RESOLVED' : 'UNRESOLVED'}
            </EntityLabel>
          </Col>
          <Col xs={9} className="ps-1">
            <ResolveDates
              data={active}
              fields={fields}
              selectField={selectField}
              disabled={shouldDisable}
            />
          </Col>
        </Row>
      </ShadowBox>
    </div>
  );
};

export default Sidebar;
