import ContentLoader from 'react-content-loader';

const TimelineLoader = () => {
  return (
    <div className="w-100p cmt-4">
      <ContentLoader
        speed={1}
        backgroundColor="#F8F9FB"
        foregroundColor="#f3f3f3"
        preserveAspectRatio="none"
        width="100%"
        height="72px"
        viewBox="0 0 100 100"
      >
        <rect x="0" y="0" rx="0.5" ry="0.5" width="100" height="45%" />
        <rect x="0" y="50%" rx="0.5" ry="0.5" width="100" height="45%" />
      </ContentLoader>
    </div>
  );
};

export default TimelineLoader;
