import { AxiosResponse } from 'axios';

export const getErrorResponseMessage = (error: AxiosResponse): string => {
  if (!error?.data?.errors) return '';

  return Object.keys(error.data.errors).reduce(
    (msg, key) => msg + error.data.errors[key].join('\n') + '\n',
    ''
  );
};
