import rearrange from '@/common/assets/svg/actions/rearrange.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';
import { FC } from 'react';

type TitleProps = {
  text: string;
  className?: string;
  showIcon?: boolean;
};

const Title: FC<TitleProps> = ({ text, className, showIcon = true }) => {
  return (
    <div
      className={`header-title  fs-12 fw-bold d-flex align-items-center ${
        className ? className : ''
      }`}
    >
      {showIcon ? (
        <SvgRender src={rearrange} style={{ width: 10, height: 10 }} className="me-1" />
      ) : null}
      {text}
    </div>
  );
};

export default Title;
