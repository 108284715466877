import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import TYPES from './types';
import REQUISITION_TYPES from 'common/components/purchasing/requisition/store/types';
import _sortBy from 'lodash/sortBy';

export const getRequisitionSummary = createAsyncThunk(
  TYPES.GET_REQUISITION_SUMMARY,
  async (params: { id: number; init_state: boolean }, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await get(`/purchasing-requisitions/${params.id}/summary`);
      const { items, suppliers, ...requisition } = res.data;

      if (params.init_state) {
        const state = getState() as RootState;

        dispatch({
          type: REQUISITION_TYPES.GET_PURCHASING_REQUISITION.SUCCESS,
          payload: requisition
        });

        dispatch({
          type: REQUISITION_TYPES.GET_PURCHASING_REQUISITION_SUPPLIERS.SUCCESS,
          payload: _sortBy(suppliers, s => s.id).map((e, index) => ({
            ...e,
            supplier_number: index + 1,
            system_currency_label: state.settings.system_currency?.label
          }))
        });
      }

      dispatch({
        type: REQUISITION_TYPES.GET_PURCHASING_REQUISITION_ITEMS.SUCCESS,
        payload: { data: items, params }
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
