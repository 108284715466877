import ShadowBox from 'common/components/general/ShadowBox';
import history from 'common/assets/svg/common/history.svg';
import SvgRender from 'common/components/general/SvgRender';
import Log from './Log';
import RestartedLog from './RestartedLog';

import { useMemo } from 'react';
import { parseReviewProcessStepsToLogs } from './helpers';
import { FC } from 'react';
import { Step } from '@/common/types/review-process';
import { useAppSelector } from '@/store/hooks';
import {
  selectReviewProcessRestartedAt,
  selectReviewProcessRestartedBy
} from '../../store/selectors-ts';
import _orderBy from 'lodash/orderBy';

type HistoryProps = {
  reviewProcessSteps: Step[];
  activeEntityId: number | string;
};

const History: FC<HistoryProps> = ({ reviewProcessSteps, activeEntityId }) => {
  const restartedBy = useAppSelector(state =>
    selectReviewProcessRestartedBy(state, activeEntityId)
  );
  const restartedAt = useAppSelector(state =>
    selectReviewProcessRestartedAt(state, activeEntityId)
  );

  const logs = useMemo(() => {
    const flattenLogs = parseReviewProcessStepsToLogs(reviewProcessSteps);

    if (restartedBy && restartedAt) {
      flattenLogs.push({
        type: 'review_process_restarted',
        performed_at: restartedAt
      });
    }

    return _orderBy(flattenLogs, 'performed_at', 'desc');
  }, [reviewProcessSteps]);

  if (!logs?.length) return null;

  return (
    <ShadowBox className="cp-12">
      <div className="d-flex align-items-center fs-12 lh-1 fw-medium text-violet border-bottom cmb-12 cpb-6">
        <SvgRender src={history} className="me-1" style={{ width: 14, height: 14 }} />
        Logs
      </div>

      {logs.map((log, index) =>
        log.type === 'review_process_restarted' ? (
          <RestartedLog activeEntityId={activeEntityId} key={index} />
        ) : (
          <Log
            key={log.id}
            log={log}
            isFirst={index === 0}
            isLast={
              index + 1 === logs.length || logs[index + 1]?.type === 'review_process_restarted'
            }
          />
        )
      )}
    </ShadowBox>
  );
};

export default History;
