import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import _pickBy from 'lodash/pickBy';
import _mapValues from 'lodash/mapValues';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _last from 'lodash/last';
import _every from 'lodash/every';
import _isArray from 'lodash/isArray';
import { strToNumber } from 'common/utils/numbers';
import { selectListOptionsFromStore } from 'store/lists/selectors';

const selectPurchasingRequisitions = state => state.purchasing.requisitions;
const selectIsOnboard = state => state.isOnBoard;

const getKey = (_, key) => key;
const getSecondaryKey = (_, __, key) => key;
const getThirdKey = (_, __, ___, key) => key;
const getFourthKey = (_, __, ___, ____, key) => key;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _isEqual);

/* Totals */
export const selectTotals = createSelector(selectPurchasingRequisitions, reducer => reducer.totals);

export const selectIsLoadedFromPastRequisition = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isLoadedFromPastRequisition
);

/* Categories */
export const selectCategoryData = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.categoryData
);

export const selectCategories = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.categories
);

export const selectCategory = createSelector(
  selectCategoryData,
  getKey,
  (categoryData, catID) => categoryData[catID]
);

export const selectFirstCategory = state => selectCategories(state)?.[0];

/* Drawer - Modals */
export const isPurchasingItemsSelectionDrawerOpen = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isPurchasingItemsSelectionDrawerOpen
);

export const isPmsItemsSelectionDrawerOpen = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isPmsItemsSelectionDrawerOpen
);

export const selectActiveCategoryID = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.activeCategoryID
);

export const isPurchasingCommunicationDrawerOpen = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isCommunicationDrawerOpen
);

/* Items */
export const selectAllCategoryItems = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.categoryItems
);

export const selectAllItems = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.items
);

export const selectCategoryItems = createSelector(
  selectAllCategoryItems,
  getKey,
  (categoryItems, catID) => categoryItems[catID]
);

export const selectCategoryHasItemsOutOfTheListItems = createSelector(selectAllItems, items =>
  Object.keys(_pickBy(items, item => item.is_out_of_the_list === true)).length ? true : false
);

export const isRevisedQuantityEmpty = createSelector(
  selectAllItems,
  items =>
    !Object.values(items).every(
      item => item.revised_quantity || strToNumber(item.revised_quantity) === 0
    )
);

export const selectNumberOfCategoryItems = createSelector(
  selectCategoryItems,
  categoryItems => categoryItems?.length
);

export const selectNumberOfItems = createSelector(
  selectAllItems,
  items => Object.keys(items).length
);

export const selectHasSelectedPmsStores = createDeepEqualSelector(
  selectAllItems,
  items => !!Object.keys(items).find(key => key.startsWith('spare_part'))
);

export const selectItem = createSelector(selectAllItems, getKey, (items, itemId) => items[itemId]);

export const selectItemBasicFields = (state, itemId) => {
  const { supplier_details, ...rest } = selectItem(state, itemId);
  return rest;
};

export const selectItemBasicField = (state, itemId, fieldKey) =>
  selectItemBasicFields(state, itemId)[fieldKey];

export const selectItemSupplierDetails = createSelector(selectItem, item => {
  const { supplier_details } = item;
  return supplier_details;
});

export const selectItemSupplierDetailsRequisition = (state, itemId, supplierRequisitionID) => {
  const itemSupplierDetails = selectItemSupplierDetails(state, itemId);

  return _get(itemSupplierDetails, `[${supplierRequisitionID}]`);
};

export const selectItemSupplierDetailsField = createSelector(
  selectItemSupplierDetails,
  getSecondaryKey,
  getThirdKey,
  (supplierDetails, fieldKey, supplierRequisitionID) =>
    _get(supplierDetails, `[${supplierRequisitionID}][${fieldKey}]`)
);

export const selectItemField = createSelector(
  selectItem,
  getSecondaryKey,
  getThirdKey,
  (item, fieldKey, supplierRequisitionID) =>
    supplierRequisitionID
      ? _get(item, `supplier_details[${supplierRequisitionID}][${fieldKey}]`)
      : item[fieldKey]
);

export const isItemOutOfTheList = createSelector(
  selectItem,
  item => item.is_out_of_the_list === true
);

export const selectIsDifferentField = createSelector(
  selectItem,
  getSecondaryKey,
  getThirdKey,
  getFourthKey,
  (item, fieldKey, comparisonKey, supplierRequisitionID) => {
    const allItemFields = { ...item, ...item?.supplier_details?.[supplierRequisitionID] };

    const value = strToNumber(allItemFields[fieldKey]);
    const comparisonValue = strToNumber(allItemFields[comparisonKey]);

    return (value || value === 0) && (comparisonValue || comparisonValue === 0)
      ? value !== comparisonValue
      : false;
  }
);

export const selectStoreItems = createSelector(selectAllItems, items =>
  Object.keys(items).filter(f => f.startsWith('store'))
);

export const selectPMSItems = createSelector(selectAllItems, items =>
  Object.keys(items).filter(f => f.startsWith('spare_part'))
);
/* -- */

export const selectRequisitionVessel = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.requisitionVessel
);

export const selectRequisitionIsForVessel = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isForVessel
);

export const selectIsTemplateRequisition = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer?.isTemplate
);

export const selectColumnTotals = createSelector(
  selectCategoryItems,
  getSecondaryKey,
  getThirdKey,
  getFourthKey,
  selectAllItems,
  (categoryItems, fieldKey, supplierRequisitionID, isPms, items) =>
    categoryItems?.length
      ? _reduce(
          categoryItems,
          (result, itemID) => {
            const item = items[itemID];

            if (
              (isPms && itemID.startsWith('store')) ||
              (!isPms && itemID.startsWith('spare_part'))
            ) {
              //We need this so the totals will be different for each box, we need spare_part and store totals seperately
              return result + 0;
            }

            const fieldValue = supplierRequisitionID
              ? _get(item, `supplier_details[${supplierRequisitionID}][${fieldKey}]`)
              : item[fieldKey];

            return result + +fieldValue;
          },
          0
        )
      : 0
);

export const selectRowSupplierTotals = createSelector(
  selectItem,
  getSecondaryKey,
  (item, fieldKey) =>
    _reduce(
      Object.keys(item.supplier_details),
      (result, supplierRequisitionID) => {
        const fieldValue = item.supplier_details[supplierRequisitionID][fieldKey];

        return result + +fieldValue;
      },
      0
    )
);

export const selectRequisitionCompany = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.requisitionCompany
);

export const selectRequisitonVesselOrCompany = createSelector(
  selectRequisitionVessel,
  selectRequisitionCompany,
  (vessel, company) => (vessel ? vessel?.name : company?.name)
);

/* Active Requisition */
export const selectActiveRequisition = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.active
);

export const selectActiveRequisitionID = createSelector(
  selectActiveRequisition,
  active => active?.id
);

export const selectActiveRequisitionIncludeInBudget = state =>
  selectActiveRequisition(state)?.include_in_budget;

export const selectActiveRequisitionCode = createSelector(
  selectActiveRequisition,
  active => active?.code
);

export const selectActiveRequisitionRevisedCorrectionComments = createSelector(
  selectActiveRequisition,
  active => active?.revised_correction_comments
);

export const selectIsActiveRequisitionSubmitted = createSelector(
  selectActiveRequisition,
  active => active?.submitted
);

export const selectIsActiveRequisitionRejected = createSelector(
  selectActiveRequisition,
  reducer => reducer?.rejected
);

export const selectActiveRequisitionMergedRequisitions = createSelector(
  selectActiveRequisition,
  reducer => reducer?.merged_requisitions
);

export const selectActiveRequisitionTemplateID = createSelector(
  selectActiveRequisition,
  reducer => reducer?.template_id
);

export const selectActiveRequisitionTemplate = createSelector(
  selectActiveRequisition,
  reducer => reducer?.template
);

export const selectActiveRequisitionIsCreatedOnVessel = createSelector(
  selectActiveRequisition,
  active => active?.is_created_on_vessel
);

export const selectActiveRequisitionEvents = state => selectActiveRequisition(state)?.events;

export const selectActiveRequisitionFindings = state => selectActiveRequisition(state)?.findings;

const selectCollapsedFindings = state => selectPurchasingRequisitions(state).collapsedFindings;

export const selectCollapsedFindingsIsCollapsed = state =>
  selectCollapsedFindings(state).collapsed && selectActiveRequisitionFindings(state)?.length;

export const selectCollapsedFindingsMode = state => selectCollapsedFindings(state).mode;

/* -- */

/* Status */
export const selectOnBoardStatus = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.onBoardStatus
);

export const selectRequisitionStatus = createSelector(
  selectActiveRequisition,
  active => active?.status
);

export const selectRequisitionStatusLabel = createSelector(
  selectRequisitionStatus,
  status => status?.label
);

export const selectRequisitionTags = state => selectActiveRequisition(state)?.tags;

export const selectRequisitionStatusIsDraft = state =>
  selectRequisitionStatusLabel(state) === 'draft';

export const selectVesselRequisitionIsLockedForOffice = createSelector(
  selectOnBoardStatus,
  selectActiveRequisitionIsCreatedOnVessel,
  selectIsOnboard,
  (onBoardStatus, isCreatedOnVessel, isOnBoard) =>
    isCreatedOnVessel && !isOnBoard && onBoardStatus !== 'submitted' ? true : false
);

export const selectCanEditRequisition = createSelector(
  selectOnBoardStatus,
  selectIsOnboard,
  selectActiveRequisition,
  selectVesselRequisitionIsLockedForOffice,
  (onBoardStatus, isOnBoard, activeRequisition, lockedForOffice) => {
    if (lockedForOffice) return false;

    return isOnBoard
      ? onBoardStatus !== 'submitted'
      : (activeRequisition && onBoardStatus === 'draft') || !activeRequisition;
  }
);

export const selectCanEditHeaderOnSubmittedRequisition = state => {
  const onboardStatus = selectOnBoardStatus(state);
  const activeRequisition = selectActiveRequisition(state);
  const isOnboard = selectIsOnboard(state);

  if (isOnboard) {
    return selectCanEditRequisition(state);
  } else if (onboardStatus === 'draft' || onboardStatus === 'for_correction') {
    return selectCanEditRequisition(state);
  } else {
    return (activeRequisition && onboardStatus === 'submitted') || !activeRequisition;
  }
};

/* -- */

export const selectIsAutoSaving = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isAutoSaving
);

export const selectIsEligibleForPendingReview = state =>
  selectPurchasingRequisitions(state).isEligibleForPendingReview;

/* Suppliers */
export const selectIsAddingSupplier = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.isAddingSupplier
);

export const selectSuppliersWithEquivalentCurrency = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.suppliersWithEquivalentCurrency
);

export const selectSuppliers = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.suppliers
);

export const selectVisibleSuppliers = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.visibleSuppliers
);

export const selectIsSupplierVisible = createSelector(
  selectVisibleSuppliers,
  getKey,
  (visibleSuppliers, supplierRequisitionID) =>
    !!visibleSuppliers.find(sid => sid === supplierRequisitionID)
);

export const hasSuppliers = createSelector(selectSuppliers, suppliers => suppliers.length !== 0);

export const selectSuppliersData = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.suppliersData
);

export const selectSupplier = createSelector(
  selectSuppliersData,
  getKey,
  (suppliersData, supplierRequisitionID) => suppliersData[supplierRequisitionID]
);

export const selectSupplierDeliveryRemarks = (state, id) =>
  selectSupplier(state, id)?.delivery_remarks;

export const selectSupplierRemarks = (state, id) => selectSupplier(state, id)?.current_comment;

export const selectSupplierStatus = (state, id) => selectSupplier(state, id)?.status;

export const selectSupplierNumber = (state, id) => selectSupplier(state, id)?.supplier_number;

export const selectSupplierFullName = (state, id) => selectSupplier(state, id)?.supplier_full_name;

export const selectSupplierIsRejected = (state, id) => selectSupplier(state, id)?.rejected;

export const selectSupplierStatusLabel = createSelector(
  selectSupplier,
  supplier => supplier?.status?.label
);

export const selectSupplierDeliveryStatus = (state, id) =>
  selectSupplier(state, id)?.delivery_status;

export const selectSupplierEmails = createSelector(
  selectSupplier,
  supplier => supplier?.supplier_emails
);

export const selectSearchingEmailLink = state =>
  selectPurchasingRequisitions(state).searchingEmailLink;

export const selectSupplierAttachments = (state, id) =>
  selectSupplier(state, id)?.attachments || [];

export const selectSupplierAttachmentsByStatus = createSelector(
  selectSupplierAttachments,
  getKey,
  getSecondaryKey,
  (attachments, id, statusId) =>
    attachments?.filter(
      file =>
        file.pivot.purchasing_requisition_supplier_id === id && file.pivot.status_id === statusId
    )
);

export const selectSupplierCurrentComment = (state, id) =>
  selectSupplier(state, id)?.current_comment;

export const selectSupplierInvoices = (state, id) => selectSupplier(state, id)?.invoices || [];

export const selectSupplierCurrency = createSelector(
  selectSupplier,
  supplier => supplier?.quotation_currency?.label || 'USD'
);

export const selectSupplierCurrencySymbol = (state, id) =>
  selectSupplier(state, id)?.quotation_currency?.symbol;

export const selectSuppliersHistory = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.suppliersHistory
);

export const selectSupplierHistory = createSelector(
  selectSuppliersHistory,
  getKey,
  (suppliersHistory, supplierRequisitionID) => suppliersHistory[supplierRequisitionID]
);

export const selectIsSupplierRejected = createSelector(
  selectSupplier,
  supplier => supplier?.rejected
);

export const selectSupplierDeliveryStatusHistory = createSelector(
  selectSupplierHistory,
  getKey,
  getSecondaryKey,
  (supplierHistory, _, deliveryStatus) => {
    if (!_isArray(supplierHistory)) {
      return null;
    }

    return _get(
      supplierHistory.find(h => h?.status?.label === 'dlv'),
      `delivery_status.${deliveryStatus}[0]`,
      null
    );
  }
);

export const selectSupplierDeliveryInfo = createSelector(selectSuppliersData, suppliersData =>
  _reduce(
    suppliersData,
    (prev, curr) => {
      if (curr.actual_delivery_date && curr.actual_delivery_port?.id)
        prev.push({
          deliveryPort: curr.actual_delivery_port,
          deliveryDate: curr.actual_delivery_date
        });

      return prev;
    },
    []
  )
);

export const selectSupplierReportIsDelivered = createSelector(
  selectSupplierHistory,
  getKey,
  supplierHistory => _last(supplierHistory)?.delivery_status === 'reviewed'
);

export const selectSupplierReportIsSubmitted = createSelector(
  selectSupplierHistory,
  getKey,
  supplierHistory => _last(supplierHistory)?.delivery_status === 'submitted'
);

export const selectSuppliersEvaluations = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.suppliersEvaluations
);

export const selectSupplierEvaluation = createSelector(
  selectSuppliersEvaluations,
  getKey,
  (suppliersEvaluations, supplierRequisitionID) => suppliersEvaluations[supplierRequisitionID]
);

export const selectSupplierVesselEvaluation = createSelector(selectSupplier, supplier =>
  (supplier?.evaluations || []).find(e => e.side === 'vessel')
);

export const selectIsSupplierForwardingCaseEnabled = createSelector(
  selectSupplier,
  supplier => supplier?.is_forwarding_enabled
);

export const selectRequisitionSupplierIDs = createSelector(selectSuppliersData, suppliersData =>
  Object.keys(suppliersData).map(
    supplierRequisitionID => suppliersData[supplierRequisitionID].supplier_id
  )
);

export const selectItemSuppliers = createSelector(
  selectSuppliersData,
  selectSuppliers,
  (suppliersData, suppliers) =>
    _mapValues(
      _pickBy(suppliersData, supplier => supplier.status?.label !== 'rqn'),
      supplier => ({
        ...supplier,
        supplier_sort_index: suppliers.findIndex(
          supplierRequisitionID => supplierRequisitionID === supplier.id
        )
      })
    )
);

export const selectVisibleItemSuppliers = createSelector(
  selectIsOnboard,
  selectItemSuppliers,
  selectVisibleSuppliers,
  (isOnBoard, itemSuppliers, visibleSuppliers) =>
    _pickBy(itemSuppliers, (val, key) => (!isOnBoard ? visibleSuppliers?.includes(+key) : val))
);

export const selectItemSuppliersCount = createSelector(
  selectVisibleItemSuppliers,
  itemSuppliers => Object.keys(itemSuppliers).length
);

export const selectSuppliersCount = state => selectSuppliers(state).length;
export const selectVisibleSuppliersCount = state => selectVisibleSuppliers(state).length;

export const suppliersHaveMissingField = createSelector(
  selectAllItems,
  getKey,
  selectItemSuppliers,
  (items, fieldKey, itemSuppliers) => {
    const filledItems = Object.keys(
      _pickBy(items, item =>
        Object.keys(item.supplier_details || {})
          .filter(supplierRequisitionID => itemSuppliers[supplierRequisitionID])
          .every(supplierRequisitionID => item.supplier_details[supplierRequisitionID][fieldKey])
      )
    );
    return !filledItems.length || filledItems.length !== Object.keys(items).length;
  }
);

export const supplierHasMissingField = createSelector(
  selectAllItems,
  getKey,
  getSecondaryKey,
  (items, supplierRequisitionID, fieldKey) =>
    !_every(
      items,
      item =>
        !!item.supplier_details?.[supplierRequisitionID]?.[fieldKey] ||
        item.supplier_details?.[supplierRequisitionID]?.[fieldKey] === 0
    )
);

export const hasValidItemBaseQuantities = createSelector(
  selectAllItems,
  getKey,
  (items, fieldKey) => {
    const hasFieldValue = Object.values(items).some(item => item[fieldKey] > 0);

    return hasFieldValue;
  }
);

export const selectLastDeliveredTooltip = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.lastDeliveredTooltip
);

export const selectActiveTableConfiguration = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.activeTableConfiguration
);

export const selectActiveTableConfigurationID = createSelector(
  selectActiveTableConfiguration,
  config => config.id
);

export const selectActiveTableConfigurationSettings = createSelector(
  selectActiveTableConfiguration,
  config => config.settings
);

export const selectPreventLocationChange = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.preventLocationChange
);

const selectVisibleItemFields = createSelector(selectPurchasingRequisitions, reducer => {
  return reducer.visibleItemFields;
});

export const selectVisibleListedItemFields = createSelector(selectVisibleItemFields, reducer => {
  return reducer.listed;
});

export const selectVisibleUnlistedItemFields = createSelector(selectVisibleItemFields, reducer => {
  return reducer.unlisted;
});

export const selectRequisitionEmails = createSelector(
  selectPurchasingRequisitions,
  reducer => reducer.requisitionEmails
);

export const selectRequisitionLinkedEmails = state =>
  selectPurchasingRequisitions(state).requisitionLinkedEmails;

export const selectShowRemarksAndAttachments = state =>
  selectPurchasingRequisitions(state).showRemarksAndAttachments;

export const selectIsSummaryViewActive = state =>
  selectPurchasingRequisitions(state).isSummaryViewActive;

export const shouldShowSupplierInvoiceHistory = createSelector(
  state => selectListOptionsFromStore(state, 'purchasing-requisition-statuses'),
  selectSupplierStatus,
  selectIsTemplateRequisition,
  (statuses, supplierStatus, isTemplate) => {
    const poStatus = statuses.find(status => status.label === 'dlv');

    return supplierStatus?.sort_index >= poStatus?.sort_index || isTemplate;
  }
);

export const underApprovalSuppliersHaveFilledApprovedQuantities = createSelector(
  selectAllItems,
  selectItemSuppliers,
  (items, itemSuppliers) => {
    const unfilledItems = Object.keys(
      _pickBy(
        items,
        item =>
          Object.keys(item.supplier_details || {}).filter(
            supplierRequisitionID =>
              itemSuppliers[supplierRequisitionID] &&
              itemSuppliers[supplierRequisitionID]?.status?.label === 'po' &&
              !item.supplier_details[supplierRequisitionID].approved_quantity
          ).length
      )
    );

    return unfilledItems.length === 0 ? true : false;
  }
);

export const underSendForApprovalSuppliersNotHaveItemWithProposedQuantity = createSelector(
  selectAllItems,
  items => {
    const filledItems = Object.keys(
      _pickBy(items, item => {
        return Object.keys(item.supplier_details || {}).filter(
          supplierRequisitionID =>
            item.supplier_details[supplierRequisitionID].proposed_quantity > 0
        ).length;
      })
    );

    return filledItems.length > 0 ? false : true;
  }
);
