import { NavigationGroupTab } from '@/common/components/buttons/NavigationGroupTabs';
import { Button } from 'reactstrap';
import { DrawerStateType } from '@/ts-common/types/drawer';

import paths from 'routing/routes/_paths';
import permissions from '@/common/utils/permissions/constants';

import pms from 'common/assets/svg/common/setup.svg';
import attributes from 'common/assets/svg/common/attributes.svg';
import jobs from 'common/assets/svg/common/jobs.svg';
import spares from 'common/assets/svg/jobs/spares.svg';
import vessel from 'common/assets/svg/vessels/vessel.svg';
import system from '@/common/assets/svg/common/system.svg';
import jobsConfiguration from 'assets/svg/sidebar/jobs_configuration.svg';
import categories from 'common/assets/svg/common/categories.svg';

import Setup from 'common/components/pms/setup/views';
import SpareParts from '@/views/pms/library/spare-parts';
import JobPrototypes from '@/views/pms/library/job-prototypes';
import SystemAttributes from '@/views/pms/library/system-attributes';
import JobTypes from '@/views/pms/library/job-types';
import SystemGroups from '@/views/pms/library/system-groups';
import VesselSystems from '@/views/pms/library/vessel-systems';
import VesselSystemsAssignments from '@/views/pms/library/vessel-systems-assignments';
import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';

import { parseQueryParams, stringifyObj } from '@/ts-common/utils/urls';
import { VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS } from '@/views/pms/library/vessel-systems-assignments/tableConfing';
import { VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEMS } from '@/views/pms/library/vessel-systems/helpers';
import { VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES } from '@/views/pms/library/job-prototypes/tableConfig';
import AuthCheck from '@/components/permissions/AuthCheck';

export const getVesselRouteForLibrarySetup = (path: string, vesselId?: number) =>
  `${paths.pms_library}${vesselId ? `/${vesselId}` : ''}${
    path ? `${!path.startsWith('/') ? '/' : ''}${path}` : ''
  }`;

export const getVesselIdFromTableQuery = (search: string) => {
  const { filters } = parseQueryParams(search);

  const vesselFilter = filters?.find(
    f =>
      [
        VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS,
        VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEMS
      ].includes(f.name) && f.value
  );

  return Array.isArray(vesselFilter?.value) ? vesselFilter.value[0] : null;
};

const getVesselRouteForTableQuery = (
  path: string,
  filterKey: string,
  location: Location,
  vesselId?: number
) => {
  if (!vesselId) return `${paths.pms_library}/${path}`;

  const { filters, ...rest } = parseQueryParams(location.search);

  const updatedFilters = [
    ...(filters || []).filter(f => f.name !== filterKey),
    { name: filterKey, operation: 'oneOf', value: [vesselId] }
  ];

  return {
    pathname: `${paths.pms_library}/${path}`,
    search: `${stringifyObj({ filters: updatedFilters, ...rest })}`
  };
};

export const getLibraryTabs = (vesselId?: number) =>
  [
    {
      label: 'library-setup',
      tooltip: 'Setup',
      icon: pms,
      to: getVesselRouteForLibrarySetup('', vesselId),
      isActive: (_, location) =>
        !location.pathname.includes('/vessel-system') &&
        !location.pathname.includes('/job-prototypes') &&
        !location.pathname.includes('/spare-parts')
    },
    {
      label: 'vessel-system-assignments',
      tooltip: 'Vessel System Assignments',
      icon: vessel,
      to: (location: Location) =>
        getVesselRouteForTableQuery(
          'vessel-system-assignments',
          VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS,
          location,
          vesselId
        ),
      isActive: (_, location) => location.pathname.includes('/vessel-system-assignments'),
      permissions: [permissions.office.pms.library.systems.view]
    },
    {
      label: 'vessel-systems',
      tooltip: 'Vessel Systems',
      icon: system,
      to: (location: Location) =>
        getVesselRouteForTableQuery(
          'vessel-systems',
          VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEMS,
          location,
          vesselId
        ),
      isActive: (_, location) => location.pathname.includes('/vessel-systems'),
      permissions: [permissions.office.pms.library.systems.view]
    },
    {
      label: 'library-job-prototypes',
      tooltip: 'Job Prototypes',
      icon: jobs,
      to: (location: Location) =>
        getVesselRouteForTableQuery(
          'job-prototypes',
          VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES,
          location,
          vesselId
        ),
      isActive: (_, location) => location.pathname.includes('/job-prototypes'),
      permissions: [permissions.office.pms.library.view]
    },
    {
      label: 'library-spare-parts',
      tooltip: 'Spare Parts',
      icon: spares,
      to: (location: Location) =>
        getVesselRouteForTableQuery(
          'spare-parts',
          VESSEL_FILTER_KEY_FOR_JOB_PROTOTYPES,
          location,
          vesselId
        ),
      isActive: (_, location) => location.pathname.includes('/spare-parts'),
      permissions: [permissions.office.pms.library.view]
    }
  ] as NavigationGroupTab[];

export const configurationTabs = [
  {
    label: 'system-groups',
    tooltip: 'System Groups',
    icon: categories,
    to: `${paths.pms_configuration}/system-groups`,
    isActive: (_, location) => location.pathname.includes('/system-groups'),
    permissions: [permissions.office.pms.configuration.system_groups.view]
  },
  {
    label: 'system-attributes',
    tooltip: 'System Attributes',
    icon: attributes,
    to: `${paths.pms_configuration}/system-attributes`,
    isActive: (_, location) => location.pathname.includes('/system-attributes'),
    permissions: [permissions.office.pms.configuration.system_attributes.view]
  },
  {
    label: 'job-types',
    tooltip: 'Job Types',
    icon: jobsConfiguration,
    to: `${paths.pms_configuration}/job-types`,
    isActive: (_, location) => location.pathname.includes('/job-types'),
    permissions: [permissions.office.pms.configuration.system_job_types.view]
  }
] as NavigationGroupTab[];

export const getTabs = (view: 'configuration' | 'library', vesselId?: number) =>
  view === 'library' ? getLibraryTabs(vesselId) : configurationTabs;

export const getTabContent = (
  { actionDrawer, logsDrawer }: { actionDrawer: DrawerStateType; logsDrawer: DrawerStateType },
  isToggleLogsTimelineDrawerButtonDisabled: boolean
) => ({
  pms_job_prototypes: {
    Component: JobPrototypes,
    title: 'Job Prototypes',
    action: (
      <ToggleLogsTimelineDrawerButton className="me-5 cpe-4" onClick={() => logsDrawer.open()} />
    )
  },
  pms_spare_parts: { Component: SpareParts, title: 'Spare Parts' },
  pms_system_attributes: {
    Component: SystemAttributes,
    title: 'System Attributes',
    action: (
      <AuthCheck permissions={[permissions.office.pms.configuration.system_attributes.edit]}>
        <Button className="me-7" type="button" color="primary" onClick={actionDrawer.open}>
          Create new Group
        </Button>
      </AuthCheck>
    )
  },
  pms_job_types: { Component: JobTypes, title: 'Job Types' },
  pms_system_groups: { Component: SystemGroups, title: 'System Groups' },
  pms_vessel_systems: { Component: VesselSystems, title: 'Vessel Systems' },
  pms_vessel_system_assignments: {
    Component: VesselSystemsAssignments,
    title: 'Vessel System Assignments'
  },
  pms_setup: {
    title: '',
    Component: Setup,
    action: (
      <ToggleLogsTimelineDrawerButton
        disabled={isToggleLogsTimelineDrawerButtonDisabled}
        className="me-5 cpe-4"
        onClick={() => logsDrawer.open()}
      />
    )
  }
});
