import TopFilters from './TopFilters';
import TableFilters from './TableFilters';
import Table from './Table';
import SystemSpefications from './SystemSpefications';
import useTableTopFilterValue from '@/common/components/filters/useTableTopFilterValue';
import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import { useTable } from '@/common/components/table';
import { columns, styles, VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS } from './tableConfing';
import { useCallback, useState } from 'react';
import { TableRequestParams } from '@/common/types/front-entities/table';
import { getTableList } from '@/store/tables/lists/actions';
import { useAppDispatch } from '@/store/hooks';
import { resetTableData } from '@/store/tables/actions';

const VesselSystemsAssignments = () => {
  const dispatch = useAppDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const fetchTable = useCallback(
    async (params: TableRequestParams) => {
      if (
        !params.filters?.find(
          f => f.name === VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS && f.value
        )
      ) {
        dispatch(resetTableData(params.table));

        return;
      }

      await dispatch(getTableList(params));
    },
    [dispatch]
  );

  const table = useTable({
    label: 'pms_vessel_system_assignments',
    requestTableListFrom: fetchTable,
    columns,
    top: {
      filters: [
        { name: VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS, operation: 'oneOf', value: null }
      ]
    }
  });

  const [vesselFilter] = useTableTopFilterValue(
    { topFilters: table.topFilters },
    VESSEL_FILTER_KEY_FOR_VESSEL_SYSTEM_ASSIGNMENTS
  );

  return (
    <div>
      <TopFilters refetchData={table.refetchData} paging={table.state.paging} />
      <TableFilters {...table} />
      <div className="d-flex flex-nowrap position-relative">
        <TableContainer className="flex-1  position-relative" isCollapsed={isCollapsed}>
          <Table
            vesselFilter={vesselFilter}
            isLoading={table.fetching}
            systemSpeficationsIsCollapsed={isCollapsed}
            {...table}
          />
        </TableContainer>
        {vesselFilter ? (
          <SystemSpefications isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        ) : null}
      </div>
    </div>
  );
};

const TableContainer = styled.div(
  ({ isCollapsed }: { isCollapsed: boolean }) => `
    max-width: ${
      !isCollapsed
        ? `calc(100% - ${styles.expandedWidth + styles.gutter}px)`
        : `calc(100% - ${styles.collapsedWidth + styles.gutter}px)`
    };

    .colored-table > .app-table > .app-table--main {
      position-relative;
   
      &:before {
        content: '';
        box-shadow: 4px 10px 7px 0 rgb(0, 0, 0, 0.3);
        position: absolute;
        width: 1px;
        height: calc(100% - ${variables.size80});
        top: ${variables.size16};
        right: 0;
        display: ${!isCollapsed ? 'block' : 'none'};
      }
    }

  `
);

export default VesselSystemsAssignments;
