import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import { selectCurrentPmsJobActiveTabs } from 'common/components/pms/setup/store/selectors';
import * as pmsActions from 'common/components/pms/setup/store/actions';

import JobForms from './job-forms';

const Forms = ({ selectField, fields }) => {
  const activeTabs = useSelector(selectCurrentPmsJobActiveTabs);

  const [editMaintenanceJobActiveTabs] = useActions([pmsActions.editMaintenanceJobActiveTabs]);

  const setActiveJobTab = (tab, isActive) => {
    editMaintenanceJobActiveTabs(tab, isActive);
  };

  const onSaveForms = useCallback(
    updatedForms => {
      selectField('forms')(updatedForms);
    },
    [selectField]
  );

  return (
    <JobForms
      isTabActive={activeTabs.forms}
      savedForms={fields.forms.value}
      onSaveForms={onSaveForms}
      setActiveJobTab={setActiveJobTab}
    />
  );
};

export default Forms;
