import { useState, useEffect, useCallback } from 'react';
import React from 'react';
import BasicModal from 'common/components/modals/BasicModal';
import { Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import Input from 'common/components/form/inputs/Input';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { useSelector } from 'react-redux';
import * as listActions from 'store/lists/actions';
import { useActions } from 'utils/hooks';

const AddGroupModal = ({
  isOpen,
  setIsOpen,
  onSave,
  disabled,
  initialCode,
  initialName,
  initialType,
  title = 'Add Group',
  modalType
}) => {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState(null);

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const listOptions = useSelector(state =>
    selectListOptionsFromStore(state, 'vessel-system-group-types')
  );

  const iniatializeModal = useCallback(async () => {
    try {
      await getInitialAsyncValues('vessel-system-group-types');

      setCode(initialCode ? initialCode : '');
      setName(initialName ? initialName : '');
      setType(initialType ? listOptions?.find(f => f?.label === initialType) : null);
    } catch (error) {
      console.log(error);
    }
  }, [setCode, setName, setType, initialCode, initialName, initialType, listOptions]);

  useEffect(() => {
    fetchListOptions('vessel-system-group-types');

    if (isOpen) {
      iniatializeModal();
    }
  }, [isOpen, fetchListOptions]);

  const onCreate = () => {
    if (code && name) {
      onSave({ code, name, type: type?.label || null });
    }
  };

  return (
    <BasicModal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      header={
        <div>
          <div>{title}</div>
        </div>
      }
      body={
        <Row>
          <Col xs={4}>
            <Input
              data-testid="pms-group-add-modal-code"
              onChange={e => setCode(e.target.value)}
              className="mb-3"
              label="Code"
              placeholder="Add code"
              value={code}
            />
          </Col>
          <Col xs={12}>
            <Input
              onChange={e => setName(e.target.value)}
              className="mb-3"
              label="Group Name"
              placeholder="Add name"
              value={name}
            />
          </Col>
          <Col>
            <AsyncSelector
              label="Type"
              placeholder="Select type"
              getOptionValue={option => option.name}
              getOptionLabel={option => option.name}
              className="mb-0"
              isMulti={false}
              type="vessel-system-group-types"
              onChange={e => setType(e)}
              loadOptions={search => getAsyncOptions(search, 'vessel-system-group-types')}
              defaultOptions={listOptions}
              value={type}
            />
          </Col>
        </Row>
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button color="cancel" onClick={() => setIsOpen(false)}>
            CANCEL
          </Button>
          <Button color="primary" disabled={disabled || !code || !name} onClick={onCreate}>
            {modalType === 'edit' ? 'UPDATE' : 'ADD'}
          </Button>
        </div>
      }
    />
  );
};

export default AddGroupModal;
