import moment from 'moment';
import paths from 'routing/routes/_paths';

import { dateToLocal } from 'common/utils/dates';
import {
  TYPES,
  COMPONENT_TYPES,
  summaryTypeAlias,
  typesAliases
} from 'components/layouts/sidebar/panel/notifications/_types';
import { reportURL } from 'captain-reports/components/ReportLink';

export const getNotificationComponentType = type => {
  const groups = type?.split('.');

  return groups?.[0] || type;
};

export const getNotificationEntityId = notification => {
  return notification?.data?.metadata?.entity?.id || '';
};

export const notificationTypeOverrides = {
  // if you need to override the default notifications component, define id here the types
  // [TYPES.CAPTAIN_REPORT_APPROVED]: {
  //   component: Notification,
  // },
};

const getTime = date => {
  const timeCreated = dateToLocal(date);
  const now = moment();

  const duration = moment.duration(now.diff(timeCreated));
  const hours = duration.asHours();

  if (hours < 1) {
    const result = duration.asMinutes();
    if (Math.floor(result) === 0) {
      return 'Just now';
    } else {
      return `${Math.floor(result)} minutes ago`;
    }
  } else if (hours > 24) {
    const result = duration.asDays();
    return `${Math.floor(result)} days ago`;
  } else {
    return `${Math.floor(hours)} hours ago`;
  }
};

export const getSummaryTypeAlias = type => summaryTypeAlias?.[type];

export const getSummaryTitlePerType = type => {
  return getTypeAlias(type) || getSummaryTypeAlias(type);
};

export const getNotificationTimestamp = (date, type) => {
  if (!date) return '';

  switch (type) {
    case TYPES.NEWS_AND_ANNOUNCEMENTS_POST_PUBLISHED:
    case TYPES.NEWS_AND_ANNOUNCEMENTS_ANNOUNCEMENT_PUBLISHED:
      return dateToLocal(date)?.format('DD/MM/YYYY HH:mm');
    default:
      return getTime(date);
  }
};

export const getTypeAlias = type => {
  const componentType = getNotificationComponentType(type);

  return typesAliases?.[type] || typesAliases?.[componentType];
};

export const getNotificationEntityUrl = (type, notification) => {
  const componentType = getNotificationComponentType(type);
  const entityId = getNotificationEntityId(notification);

  // Construct URLs either per notification type or per component type
  switch (type) {
    case TYPES.TICKETING_EVENT_CASE_CREATED:
      return `${paths.ticketing}/events`;

    case TYPES.TICKETING_CREW_SWITCH_CASE_CREATED:
      return `${paths.ticketing}/crew`;

    case TYPES.CHARTER_PARTY_SUBCHARTERER_CREATED:
      const { id, vessel_id } = notification?.data?.metadata?.entity?.charter_party || {};

      return id && vessel_id ? `${paths.voyages}/${vessel_id}/charter-parties/edit/${id}` : '';

    case TYPES.CHARTER_PARTY_CREATED:
    case TYPES.CHARTER_PARTY_EDITED:
      const entityVesselId = notification?.data?.metadata?.entity?.vessel_id;

      return entityId && entityVesselId
        ? `${paths.voyages}/${entityVesselId}/charter-parties/edit/${entityId}`
        : '';

    case TYPES.PURCHASING_REVIEW_PROCESS_CREATED:
    case TYPES.PURCHASING_REVIEW_PROCESS_ACTION_REQUIRED:
      const reviewedEntityId = notification?.data?.metadata?.entity?.reviewed_entity?.id;

      return `${paths.purchasing_requisitions}/${reviewedEntityId}`;

    default:
      switch (componentType) {
        case COMPONENT_TYPES.CAPTAIN_REPORTS:
          //  type and id from vessel is nested on report object

          const captainReportEntity = notification?.data?.metadata?.entity;

          const templateType =
            captainReportEntity?.report?.template_type || captainReportEntity?.template_type;

          const captainReportId = captainReportEntity?.report?.id || entityId;

          if (!templateType || !captainReportId) return null;

          if (templateType in reportURL) return `${reportURL[templateType]}/${captainReportId}`;

          return `${paths.captain_reports}/${captainReportId}`;

        case COMPONENT_TYPES.EVENTS:
          return entityId ? `${paths.events}/${entityId}` : null;

        case COMPONENT_TYPES.FORMS:
          const formSubmissionId = notification?.data?.metadata?.entity?.form_submission?.id;

          return formSubmissionId ? `${paths.forms}/${formSubmissionId}` : null;

        case COMPONENT_TYPES.EVALUATION_OBLIGATIONS:
          return `${paths.crew_evaluation_obligations}`;

        case COMPONENT_TYPES.SEAMEN:
          return entityId ? `${paths.crew}/${entityId}` : null;

        case COMPONENT_TYPES.PURCHASING:
        case COMPONENT_TYPES.OFFICE_PURCHASING_REQUISITIONS:
        case COMPONENT_TYPES.VESSEL_PURCHASING_REQUISITIONS:
          return entityId ? `${paths.purchasing_requisitions}/${entityId}` : null;

        case COMPONENT_TYPES.MGA:
          const vesselId = notification?.data?.metadata?.entity?.vessel?.id;
          const period = notification?.data?.metadata?.entity?.id;

          return vesselId && period
            ? `${paths.mga}/overview/${vesselId}/${period}`
            : `${paths.mga}/overview`;

        default:
          return null;
      }
  }
};
