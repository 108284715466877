import { FC, MouseEvent, useEffect } from 'react';

import { Col, Row } from 'reactstrap';
import SparePart from './spare-part';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { SparePartBase } from '@/common/types/pms';
import { DrawerStateType } from '@/ts-common/types/drawer';
import {
  selectSelectedSystem,
  selectSparePartsList,
  selectSparePartsLoading
} from '@/common/components/pms/setup/store/selectors.js';
import { getSpareParts } from '@/common/components/pms/setup/store/actions.js';
import Spinner from '@/ts-common/components/general/Spinner';
import SortingHeader from '@/common/components/pms/setup/views/components/SortingHeader.tsx';
import useCustomSorting from '@/common/utils/hooks/useCustomSorting';

type ListProps = {
  setSelectedPartForDelete: React.Dispatch<React.SetStateAction<SparePartBase | null>>;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPartForEdit: React.Dispatch<React.SetStateAction<number | null>>;
  drawer: DrawerStateType;
};

const List: FC<ListProps> = ({
  setSelectedPartForDelete,
  setIsDeleteModalOpen,
  setSelectedPartForEdit,
  drawer
}) => {
  const dispatch = useAppDispatch();

  const sparePartsList: SparePartBase[] = useAppSelector(selectSparePartsList);
  const selectedSystemId: number = useAppSelector(selectSelectedSystem);
  const sparePartsLoading: boolean = useAppSelector(selectSparePartsLoading);

  const { sorting, handleColumnSorting } = useCustomSorting();

  const onDelete = (e: MouseEvent<HTMLButtonElement>, part: SparePartBase) => {
    e.stopPropagation();
    setSelectedPartForDelete(part);
    setIsDeleteModalOpen(true);
  };

  const onEdit = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    e.stopPropagation();
    setSelectedPartForEdit(id);
    drawer.open();
  };

  useEffect(() => {
    if (sorting === null || !selectedSystemId) return;

    const fetchSpareParts = async () => {
      try {
        await dispatch(getSpareParts({ system_id: selectedSystemId, sorting }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchSpareParts();
  }, [sorting, selectedSystemId]);

  if (!sparePartsList.length) return null;
  if (sparePartsLoading) return <Spinner />;

  return (
    <>
      <Row className="fs-10" noGutters>
        <Col xs={3} onClick={() => handleColumnSorting('drawing_no')}>
          <SortingHeader title="DRAWING No." selectedSorting={sorting?.drawing_no} />
        </Col>
        <Col xs={2} onClick={() => handleColumnSorting('part_no')}>
          <SortingHeader title="PART No." selectedSorting={sorting?.part_no} />
        </Col>
        <Col xs={3} onClick={() => handleColumnSorting('description')}>
          <SortingHeader title="DESCRIPTION" selectedSorting={sorting?.description} />
        </Col>
      </Row>

      {sparePartsList.map(el => (
        <SparePart key={el.id.toString()} part={el} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </>
  );
};

export default List;
