import { useGetPmsSparePartsExcelExportMutation } from '@/api/inventory/spare-parts/mutations.ts';
import ExportIcon from '@/common/components/general/ExportIcon';
import { RequestParamsType } from '@webthatmatters/orca-table';
import { FC } from 'react';

type ExportButtonType = {
  tableRequestParams: RequestParamsType;
};

const ExportButton: FC<ExportButtonType> = ({ tableRequestParams }) => {
  const excelExportMutation = useGetPmsSparePartsExcelExportMutation();

  return (
    <ExportIcon
      onClick={() => excelExportMutation.mutateAsync(tableRequestParams)}
      extension="excel"
      text="Export excel"
      disabled={excelExportMutation.isPending}
    />
  );
};

export default ExportButton;
