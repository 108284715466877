import _reduce from 'lodash/reduce';
import _findKey from 'lodash/findKey';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';

type TreeNodeId = string | number;

type TreeNode = { id: TreeNodeId; parent_id: TreeNodeId | null };

type NormalizedTreeState = Record<TreeNodeId, TreeNode>;

type NormalizedSubTreeState = Record<TreeNodeId, TreeNodeId[] | undefined>;

const getItemsIDs = (nodes: TreeNode[]) => nodes.map(node => node.id);

export const getRootItemsIDs = (items: TreeNode[]) => {
  return getItemsIDs(items.filter(item => !item.parent_id));
};

export const getRootItems = (items: TreeNode[]) => {
  return items.filter(item => !item.parent_id);
};

export const getSubItems = (items: TreeNode[]) => {
  const subItems = _groupBy(
    items.filter(item => item.parent_id),
    item => item.parent_id
  );

  return _reduce(
    Object.keys(subItems),
    (result: NormalizedSubTreeState, id: string) => {
      const parsedId = isNaN(id as any) ? id : parseInt(id);
      const parent = items.find(c => c.id === parsedId);

      if (parent) {
        result[parent.id] = getItemsIDs(subItems[id]);
      }

      return result;
    },
    {}
  );
};

export const normalizeTreeStructure = (items: TreeNode[]) =>
  _reduce(
    items,
    (result: NormalizedTreeState, value: TreeNode) => {
      result[value.id] = value;
      return result;
    },
    {}
  );

export const toggleNestedItems = (subItems: NormalizedSubTreeState, open: boolean) => {
  return !open ? {} : _mapValues(subItems, () => true);
};

export const findParentItem = (parentID: TreeNodeId, stateItems: NormalizedTreeState) => {
  return _findKey(stateItems, c => c.id === parentID);
};

export const getAscendanceTree = (itemID: TreeNodeId, stateItems: NormalizedTreeState) => {
  const item = stateItems[itemID];
  if (!item) return [];

  let tree = [item];

  if (item.parent_id) {
    const parentID = findParentItem(item.parent_id, stateItems);

    if (parentID) tree = [...tree, ...getAscendanceTree(parentID, stateItems)];
  }

  return tree;
};

export const getDescendanceTree = (
  itemID: TreeNodeId,
  stateItems: NormalizedTreeState,
  subItems: NormalizedSubTreeState
) => {
  const item = stateItems[itemID];
  if (!item) return [];

  let tree = [item];

  if (subItems !== null && subItems[itemID]) {
    subItems[itemID]!.forEach(childID => {
      tree = [...tree, ...getDescendanceTree(childID, stateItems, subItems)];
    });
  }

  return tree;
};

export const getTreeOfSearchedItems = (
  stateItems: NormalizedTreeState,
  subItems: NormalizedSubTreeState,
  matchedItems: TreeNode[]
) => {
  let treeInSearch: Record<TreeNodeId, boolean> = {};

  matchedItems.forEach(item => {
    const itemDescendanceTree = getDescendanceTree(
      item.parent_id || item.id,
      stateItems,
      subItems
    ).reduce((obj: Record<TreeNodeId, boolean>, curP: TreeNode) => {
      obj[curP.id] = true;
      return obj;
    }, {});

    const itemAscendanceTree = getAscendanceTree(item.id, stateItems).reduce(
      (obj: Record<TreeNodeId, boolean>, curP: TreeNode) => {
        obj[curP.id] = true;
        return obj;
      },
      {}
    );

    treeInSearch = { ...treeInSearch, ...itemAscendanceTree, ...itemDescendanceTree };
  });

  return treeInSearch;
};

export const getItemBreadcrumbs = (itemID: TreeNodeId, stateItems: NormalizedTreeState) => {
  return getAscendanceTree(itemID, stateItems).reverse();
};
