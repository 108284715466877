import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';
import { selectCaptainReportTemplateName } from '@/captain-reports/store/selectors.js';
import { selectTemplateName } from '@/store/captain-reports-templates/selectors.js';

const CaptainReportsList = React.lazy(() =>
  retry(() => import('@/views/captain-reports/list/index.jsx'))
);
const CaptainReportForm = React.lazy(() =>
  retry(() => import('@/views/captain-reports/list/report-form/index.tsx'))
);
const PortStatement = React.lazy(() =>
  retry(() => import('@/views/captain-reports/port-statement/index.jsx'))
);
const InstructedSpeed = React.lazy(() =>
  retry(() => import('@/views/captain-reports/instructed-speed/index.jsx'))
);
const SystemRunningHours = React.lazy(() =>
  retry(() => import('@/views/captain-reports/system-running-hours/index.jsx'))
);
const PortDocumentOverview = React.lazy(() =>
  retry(() => import('@/views/captain-reports/port-document-overview/index.jsx'))
);
const PassagePlan = React.lazy(() =>
  retry(() => import('@/views/captain-reports/passage-plan/index.tsx'))
);
const CaptainReportsTemplates = React.lazy(() =>
  retry(() => import('@/views/captain-reports/templates/index.jsx'))
);
const CaptainReportTemplateForm = React.lazy(() =>
  retry(() => import('@/views/captain-reports/templates/template-form/index.jsx'))
);

const captainReports = [
  {
    path: `${paths.captain_reports}`,
    component: CaptainReportsList,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.voyages.captain_reports.list.view],
    documentTitle: 'Captain Reports'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/system-running-hours/:id`,
    component: SystemRunningHours,
    permissions: [
      permissions.office.voyages.captain_reports.view,
      permissions.office.voyages.captain_reports.edit
    ],
    documentTitle: 'System Running Hours'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/port-statement/:id`,
    component: PortStatement,
    permissions: [
      permissions.office.voyages.captain_reports.view,
      permissions.office.voyages.captain_reports.edit
    ],
    documentTitle: 'Port Statement'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/port-document-overview/:id`,
    component: PortDocumentOverview,
    permissions: [
      permissions.office.voyages.captain_reports.view,
      permissions.office.voyages.captain_reports.edit
    ],
    documentTitle: 'Port Document Overview'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/instructed-speed/:id`,
    component: InstructedSpeed,
    permissions: [
      permissions.office.voyages.captain_reports.view,
      permissions.office.voyages.captain_reports.edit
    ],
    documentTitle: 'Instructed Speed'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/passage-plan/:id`,
    component: PassagePlan,
    permissions: [
      permissions.office.voyages.captain_reports.view,
      permissions.office.voyages.captain_reports.edit
    ],
    documentTitle: 'Passage Plan'
  },
  {
    path: `${paths.captain_reports}/:id`,
    component: CaptainReportForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.voyages.captain_reports.view,
      permissions.office.voyages.captain_reports.edit
    ],
    documentTitle: selectCaptainReportTemplateName
  },
  {
    path: `${paths.captain_report_templates}`,
    component: CaptainReportsTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.captain_report_templates.view],
    documentTitle: 'Captain Reports Templates'
  },
  {
    path: `${paths.captain_report_templates}/:id`,
    component: CaptainReportTemplateForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.settings.captain_report_templates.view,
      permissions.office.settings.captain_report_templates.edit
    ],
    documentTitle: selectTemplateName
  }
];

export default captainReports;
