import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';

import { useForm, useFormState } from 'utils/hooks';

import Spinner from 'common/components/general/Spinner';
import CheckedLabel from 'common/components/labels/CheckedLabel';

import UploadFiles from 'common/components/form/inputs/upload-files';
import CrewDepartment from 'common/components/selectors/departments/CrewDepartment';
import EntityLabel from 'common/components/labels/EntityLabel';

import CollapseForm from 'common/components/collapse/CollapseForm';

import {
  selectSystemFormParentId,
  selectSystemFormType,
  selectSelectedSystem,
  selectCurrentSystem,
  selectCurrentFormSystem,
  selectIsCreatingSubSystem
} from 'common/components/pms/setup/store/selectors';
import { selectAccount } from '@/store/account/selectors';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';

import {
  getSingleSystem,
  setCurrentFormSystem,
  createSystem,
  createSubsystem,
  updateSystem,
  setSystemFormType,
  setSystemFormParentId,
  setIsCreatingSubSystem,
  getVesselSystemsAction
} from 'common/components/pms/setup/store/actions';

import config from './config';
import SystemForm from './form';
import VesselSystemAttributes from './_components/VesselSystemAttributes';
import { strToNumber } from 'common/utils/numbers';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';
import FieldLabel from '@/common/components/pms/setup/views/system/components/FieldLabel';
import FieldValue from '@/common/components/pms/setup/views/system/components/FieldValue';

const SystemDetails = ({ systemFormType }) => {
  const dispatch = useAppDispatch();
  const selectedSystem = useAppSelector(selectSelectedSystem);
  const currentFormSystem = useAppSelector(selectCurrentFormSystem);
  const formParentId = useAppSelector(selectSystemFormParentId);
  const formType = useAppSelector(selectSystemFormType);

  const account = useAppSelector(selectAccount);
  const system = useAppSelector(selectCurrentSystem);
  const isCreatingSubSystem = useAppSelector(selectIsCreatingSubSystem);
  const groupsIsVisible = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const { formState, loadValues, collectValues, resetForm } = useForm(config);
  const { fields, changeField, selectField, setFieldValue } = useFormState(formState);

  const [showForm, setShowForm] = useState(false);
  const [isFormEditing, setIsFormEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const systemType = systemFormType ? null : system?.parent_id ? 'subSystem' : 'system';
  const actualType = formType ? formType : systemType;

  const onFormClose = () => {
    onClose();
    dispatch(setSystemFormType(null));
    dispatch(setSystemFormParentId(null));
  };

  const getSystem = useCallback(
    async id => {
      try {
        setLoading(true);

        await dispatch(getSingleSystem({ id }));

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (actualType === 'system') return;

    if (system) {
      loadValues({
        vessel_system_group_id: _get(system, 'group', null),
        maker_id: _get(system, 'maker', null),
        licensee_id: _get(system, 'licensee', null),
        is_critical: _get(system, 'is_critical', null),
        is_environmental_critical: _get(system, 'is_environmental_critical', null),
        report_running_hours: _get(system, 'report_running_hours', null),
        crew_department_id: _get(system, 'crew_department', null),
        department_id: _get(system, 'department', null)
      });
    }
  }, [actualType, loadValues]);

  const onEditClick = () => {
    setIsFormEditing(true);
    setShowForm(true);
    dispatch(setCurrentFormSystem(system.id));
  };

  const onCreate = useCallback(
    isCreatingSubSystem => {
      setShowForm(true);
      if (system?.id) {
        dispatch(setCurrentFormSystem(system.id));
      }

      if (isCreatingSubSystem) resetForm();
    },
    [dispatch, resetForm, system?.id]
  );

  const onClose = () => {
    dispatch(setIsCreatingSubSystem(false));
    setIsFormEditing(false);
    setShowForm(false);
    dispatch(setCurrentFormSystem(null));
  };

  const afterSave = () => {
    if (system && system.id) {
      getSystem(system.id);
    }
  };

  useEffect(() => {
    if (system && isFormEditing && !isCreatingSubSystem) {
      loadValues({
        ...system,
        vessel_system_group_id: _get(system, 'group', null),
        maker_id: _get(system, 'maker', null),
        licensee_id: _get(system, 'licensee', null),
        manual_file_id: _get(system, 'manual_file', null),
        drawing_file_id: _get(system, 'drawing_file', null),
        crew_department_id: _get(system, 'crew_department', null),
        department_id: _get(system, 'department', null),
        attribute_group: _get(system, 'attribute_group', null),
        attributes: system.attributes?.map(({ attribute, file, value, ...rest }) => ({
          file,
          value: attribute?.type === 'number' ? strToNumber(value) : value,
          name: attribute?.name,
          type: attribute?.type,
          ...rest
        }))
      });
    }

    if (systemFormType === 'system' || systemFormType === 'sub') {
      onCreate(isCreatingSubSystem);
    }
    if (systemFormType === 'system') {
      resetForm();
    }
  }, [system, systemFormType, isFormEditing, isCreatingSubSystem, loadValues, onCreate, resetForm]);

  useEffect(() => {
    if (selectedSystem) {
      getSystem(selectedSystem);
    }
  }, [selectedSystem, getSystem]);

  const onSave = async () => {
    const values = collectValues();

    if (!values) return;

    setIsSubmitting(true);
    try {
      const { attributes, vessel_system_group_id, ...rest } = values;

      const params = {
        ...rest,
        multiplicity: rest.multiplicity ? strToNumber(rest.multiplicity) : null,
        maker_id: _get(rest.maker_id, 'id', null),
        licensee_id: _get(rest.licensee_id, 'id', null),
        manual_file_id: _get(rest.manual_file_id, 'id', null),
        drawing_file_id: _get(rest.drawing_file_id, 'id', null),
        crew_department_id: _get(rest.crew_department_id, 'id', null),
        department_id: _get(rest.department_id, 'id', null),
        attribute_group_id: _get(rest.attribute_group, 'id', null),
        attributes: attributes
          .map(({ file, value, id, attribute_id, ...attr }) => ({
            ...attr,
            value: attr.type === 'number' ? strToNumber(value) : value,
            attribute_id: attribute_id || id,
            file_id: file?.id,
            id: attribute_id ? id : undefined
          }))
          .filter(attribute => attribute.value || attribute.file_id)
      };

      if (groupsIsVisible) params.vessel_system_group_id = _get(vessel_system_group_id, 'id', null);

      if (!system || systemFormType) {
        params.parent_id = formParentId || undefined;
      } else {
        params.id = system.id;
      }

      if (system && systemFormType !== 'system' && systemFormType !== 'sub') {
        await dispatch(updateSystem(params)).unwrap();
        afterSave && afterSave();
      } else {
        // const selectedSystemGroupId = _get(values.vessel_system_group_id, 'id', null);

        if (systemFormType === 'sub') {
          await dispatch(createSubsystem(params)).unwrap();
        } else {
          await dispatch(createSystem(params)).unwrap();
        }

        // const systemsActionParams = {keepOpened: true}

        // if (params.vessel_system_group_id) {systemsActionParams}

        await dispatch(getVesselSystemsAction({ keepOpened: true }));

        if (systemFormType) {
          onFormClose();
        }
      }

      onClose();
    } catch (e) {
      console.error(e);
    }

    setIsSubmitting(false);
  };

  const renderTite = () => {
    if ((systemFormType === 'sub' || system?.parent_id) && systemFormType !== 'system') {
      return `Sub-system Details`;
    } else {
      return `System Details`;
    }
  };

  return (
    <CollapseForm
      circledButtonProps={{ style: { width: 20, height: 20 }, svgStyle: { width: 11, height: 11 } }}
      setIsEditing={systemFormType ? () => null : onEditClick}
      isEditing={showForm}
      hideExpandButton={true}
      className="main-collapse mb-1"
      reversedExpandBtn={true}
      onClose={systemFormType ? onFormClose : onClose}
      onSave={onSave}
      disabled={isSubmitting}
      header={<div className="fs-12 text-violet">{renderTite()}</div>}
      hideEdit={!isAuthorized(account, [permissions.office.pms.library.systems.edit])}
    >
      {(system && currentFormSystem === system?.id) || systemFormType ? (
        <SystemForm
          system={systemFormType ? null : system}
          fields={fields}
          changeField={changeField}
          selectField={selectField}
          setFieldValue={setFieldValue}
          type={systemType}
          formState={formState}
        />
      ) : (
        <div className="system-view text-primary cursor-pointer position-relative">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Row className="g-0">
                {system?.parent_id ? (
                  <Col xs={12} className="d-flex align-items-end mb-2">
                    <div>
                      <FieldLabel>MULTIPLICITY</FieldLabel>
                      <FieldValue>{_get(system, 'multiplicity', '-')}</FieldValue>
                    </div>

                    <div className="fs-12 ms-6">Same sub-system(s) on system</div>
                  </Col>
                ) : null}

                <Col xs={12} className="mb-2">
                  <FieldLabel>DESCRIPTION</FieldLabel>
                  <FieldValue>{_get(system, 'description', '-')}</FieldValue>
                </Col>

                {groupsIsVisible && (
                  <Col xs={12} className="mb-2">
                    <FieldLabel>GROUP</FieldLabel>
                    <FieldValue>{`${_get(system, 'group.code', '')} ${_get(
                      system,
                      'group.name',
                      '-'
                    )}`}</FieldValue>
                  </Col>
                )}

                <Col xs={4} className="mb-2">
                  <FieldLabel>MAKER</FieldLabel>
                  <FieldValue>
                    <TextWithTooltip>{_get(system, 'maker.company_name', '-')}</TextWithTooltip>
                  </FieldValue>
                </Col>
                <Col xs={4} className="mb-2">
                  <FieldLabel>TYPE</FieldLabel>
                  <FieldValue>
                    <TextWithTooltip>{_get(system, 'type', '-')}</TextWithTooltip>
                  </FieldValue>
                </Col>
                <Col xs={4} className="mb-2">
                  <FieldLabel>LICENSEE</FieldLabel>
                  <FieldValue>
                    <TextWithTooltip>{_get(system, 'licensee.company_name', '-')}</TextWithTooltip>
                  </FieldValue>
                </Col>

                <Col xs={12}>
                  <Row className="mb-2" noGutters>
                    <Col xs={'auto'} className="d-flex align-items-center pe-6">
                      <CheckedLabel isChecked={system?.is_critical} label="Critical" />
                    </Col>
                    <Col xs={'auto'} className="d-flex align-items-center pe-6">
                      <CheckedLabel
                        isChecked={system?.is_environmental_critical}
                        label="Environmental"
                      />
                    </Col>
                    <Col xs={'auto'} className="d-flex align-items-center pe-6">
                      <CheckedLabel isChecked={system?.is_navigational} label="Navigational" />
                    </Col>

                    {actualType === 'system' ? (
                      <Col xs={'auto'} className="d-flex align-items-center">
                        <CheckedLabel
                          isChecked={system?.report_running_hours}
                          label="Running Hours to be reported"
                        />
                      </Col>
                    ) : null}
                  </Row>
                </Col>

                <Col xs={12} className="d-flex border-top pt-3 mt-1">
                  <div className="d-flex justify-content-between w-15p cpe-12">
                    <div>
                      <FieldLabel>MANUAL FILE</FieldLabel>
                      {system?.manual_file_id ? (
                        <UploadFiles
                          className={`square-upload mb-0`}
                          files={system?.manual_file ? [system.manual_file] : []}
                          onChange={() => null}
                          viewOnly={true}
                          showFileName={false}
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                    <div className="small-divider py-3" />
                  </div>
                  <div className="mb-2 w-15p ps-0">
                    <FieldLabel>DRAWING FILE</FieldLabel>
                    {system?.drawing_file_id ? (
                      <UploadFiles
                        className={`square-upload mb-0`}
                        files={system?.drawing_file ? [system.drawing_file] : []}
                        onChange={() => null}
                        viewOnly={true}
                        showFileName={false}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="mb-2 d-flex justify-content-between w-12p px-0">
                    <div className="overflow-hidden">
                      <FieldLabel>DRAWING NO.</FieldLabel>
                      <FieldValue>
                        <TextWithTooltip>{_get(system, 'drawing_no', '-')}</TextWithTooltip>
                      </FieldValue>
                    </div>
                    <div className="small-divider py-3" />
                  </div>
                  <div className="d-flex justify-content-between mb-2 cps-12 w-18p">
                    <div className="overflow-hidden">
                      <FieldLabel>BUDGETARY CODE</FieldLabel>
                      <div className="fw-bold fs-12">
                        <TextWithTooltip>{_get(system, 'budgetary_code', '-')}</TextWithTooltip>
                      </div>
                    </div>
                    <div className="small-divider py-3" />
                  </div>
                  <div className="w-20p cps-12">
                    <FieldLabel>VESSEL CREW DEPT</FieldLabel>
                    {system?.crew_department ? (
                      <CrewDepartment name={system.crew_department.name} />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="w-20p ps-2">
                    <FieldLabel>OFFICE DEPARTMENT</FieldLabel>
                    {system?.department ? (
                      <EntityLabel type="department" color={system.department.color}>
                        {system.department.name}
                      </EntityLabel>
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>

                <Col xs={12} className="mt-1">
                  <FieldLabel>NOTES</FieldLabel>
                  <FieldValue>{_get(system, 'notes', '') || '-'}</FieldValue>
                </Col>

                <Col xs={12} className="border-top pt-3 mt-3">
                  <VesselSystemAttributes
                    isViewMode
                    attributes={system?.attributes}
                    group={system?.attribute_group}
                    formState={formState}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </CollapseForm>
  );
};

export default SystemDetails;
