import React, { FC, ReactNode, useState } from 'react';
import Tooltip from 'common/components/general/Tooltip';
import SvgRender from 'common/components/general/SvgRender';
import info from 'common/assets/svg/common/info.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import type { Placement } from '@popperjs/core';

export type InformationProps = {
  message: ReactNode;
  className?: string;
  tooltipClassname?: string;
  tooltipOffset?: number[];
  svgClassname?: string;
  svgStyle?: React.CSSProperties;
  hoverIcon?: string;
  svgIcon?: string;
  placement?: Placement;
  renderComponent?: (isHovering: boolean) => ReactNode;
  hideArrow?: boolean;
  dataCy?: string;
};

const Information: FC<InformationProps> = ({
  message,
  className,
  tooltipClassname,
  tooltipOffset,
  svgClassname,
  svgStyle,
  hoverIcon = '',
  svgIcon,
  placement,
  renderComponent,
  hideArrow = false,
  dataCy
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { avoidRender, tooltipID } = useTooltipID('information-tooltip');
  if (avoidRender) return null;

  return (
    <div className="d-inline-flex information-tooltip" data-cy={dataCy}>
      <div
        className={`${className ? className : ''} d-flex cursor-pointer`}
        id={tooltipID || ''}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {renderComponent ? (
          renderComponent(isHovering)
        ) : (
          <SvgRender
            src={isHovering && hoverIcon ? hoverIcon : svgIcon || info}
            style={svgStyle || { width: 18, height: 18 }}
            className={`${svgClassname ? svgClassname : ''} pointer-events-none`}
          />
        )}
      </div>

      {tooltipID ? (
        <Tooltip
          fade={false}
          target={tooltipID}
          innerClassName={tooltipClassname ? tooltipClassname : ''}
          offset={tooltipOffset}
          placement={placement}
          hideArrow={hideArrow}
        >
          {message}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default Information;
