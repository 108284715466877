import { useMutation } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { toggleVesselAssignmentJob } from './api';
import { successHandler } from '@/common/utils/notifications';
import {
  getVesselAssignments,
  getVesselSubAssignments
} from '@/common/components/pms/setup/store/actions';
import { selectCurrentAssignments } from '@/common/components/pms/setup/store/selectors';

type ToggleVesselAssignmentJobMutationParamsType = {
  isActive: boolean;
  systemId: number;
  vesselId: number;
};

export const useToggleVesselAssignmentJobMutation = (
  params: ToggleVesselAssignmentJobMutationParamsType
) => {
  const dispatch = useAppDispatch();

  // Current assignments are the parents of clicked subAssignment. They all have the same vessel_system_id so that's how we select it from the store
  const currentAssignments = useAppSelector(selectCurrentAssignments);
  const parentAssignmentVesselSystemId = currentAssignments?.[0]?.vessel_system_id;

  const { isActive, systemId, vesselId } = params;

  return useMutation({
    mutationFn: toggleVesselAssignmentJob,
    onSuccess: () => {
      if (parentAssignmentVesselSystemId) {
        dispatch(
          getVesselAssignments({
            system_id: parentAssignmentVesselSystemId,
            vessel_id: vesselId,
            leaveSelectedAssignment: true
          })
        );
      }

      dispatch(
        getVesselSubAssignments({
          parent_id: systemId,
          vessel_id: vesselId
        })
      );
      dispatch(
        successHandler({
          title: 'Success!',
          message: `${isActive ? 'Deactivated' : 'Activated'} successfully`
        })
      );
    }
  });
};
