import { FC } from 'react';
import { Row, Col, Button } from 'reactstrap';

import VesselSelector from 'common/components/selectors/VesselSelector';
import VesselSystemGroupSelector from 'common/components/selectors/VesselSystemGroupSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import TopPagination from 'common/components/table/main/TopPagination';

import FilterButton from 'common/components/buttons/FilterButton';
import { selectTableListPaging } from '@/common/components/table/store/selector-ts.ts';
import HiddenButton from 'common/components/table/main/HiddenButton';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import { useAppSelector } from '@/store/hooks';
import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';
import { Vessel } from '@/common/types/vessel';
import {
  setInventoryExpandedRowsIds,
  setShowComments
} from '@/common/components/inventory/store/slice';
import { useAppDispatch } from '@/store/hooks';
import { selectShowComments } from '@/common/components/inventory/store/selectors';
import Information from '@/ts-common/components/general/Information';
import SvgRender from '@/ts-common/components/general/SvgRender';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import hideSvg from 'common/assets/svg/common/hide.svg';
import useIsInOnboardSetupFilters from '@/common/components/shared-table-configs/hooks/useIsInOnboardSetupFilters.ts';
import ExportButton from '@/views/pms/inventory/ExportButton.tsx';
import { RequestParamsType } from '@webthatmatters/orca-table';

const LABEL = 'inventory_spare_parts';

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
  requestParams: RequestParamsType;
};

const TableHeader: FC<TopFiltersComponentProps> = ({
  topFilters,
  setTopFilters,
  requestParams
}) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const [vessel, setVessel] = useTableTopFilterValue({ topFilters, setTopFilters }, 'vessel_id');

  const singleVesselView = vessel?.length === 1;

  const dispatch = useAppDispatch();

  const isInOnboardFilters = useIsInOnboardSetupFilters();

  const [vesselSystemGroup, setVesselSystemGroup] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'vessel_system_group_id'
  );
  const [robIsUnderMinimumRequired, setRobIsUnderMinimumRequired] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'rob_is_under_minimum_required'
  );
  const [robIsUnderOptimal, setRobIsUnderOptimal] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'rob_is_under_optimal'
  );

  const paging = useAppSelector(state => selectTableListPaging(state, LABEL));

  const showComments = useAppSelector(selectShowComments);

  return (
    <Row className={`d-flex align-items-center cmb-2 mt-4 w-100p`} noGutters>
      {!isOnBoard && (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{ value: vessel?.length ? vessel : null }}
              isMulti={true}
              isClearable
              autoFocus={false}
              onChange={({ value }: { value: Vessel[] }) => setVessel(value?.length ? value : null)}
              placeholder="Select vessel"
              showIcon
            />
          </TableTopFilter>
        </Col>
      )}

      <Col xs="auto" className="me-1">
        <TableTopFilter>
          <VesselSystemGroupSelector
            filter={{ value: vesselSystemGroup ? vesselSystemGroup : null }}
            isMulti={false}
            onChange={({ value }) => setVesselSystemGroup(value?.id ? [value.id] : null)}
            showIcon
            isClearable
          />
        </TableTopFilter>
      </Col>
      <Col xs="auto" className="me-1">
        <FilterButton
          isActive={robIsUnderOptimal}
          onClick={() => setRobIsUnderOptimal(robIsUnderOptimal ? null : 'true')}
          status="proceeding"
          size="sm"
          tooltip="ROB is less than the Optimal quantity"
          tooltipInnerClassName="max-width-none"
        >
          ROB Warning
        </FilterButton>
      </Col>
      <Col xs="auto" className="me-1">
        <FilterButton
          isActive={robIsUnderMinimumRequired}
          onClick={() => setRobIsUnderMinimumRequired(robIsUnderMinimumRequired ? null : 'true')}
          status="error"
          size="sm"
          tooltip="ROB is less than the Required quantity"
          tooltipInnerClassName="max-width-none"
        >
          ROB Violated
        </FilterButton>
      </Col>

      {!isOnBoard ? (
        <Col xs="auto" className="border-start border-platinum ps-2 ms-1">
          <HiddenButton
            label="Spare Parts"
            topFilters={topFilters}
            setTopFilters={setTopFilters}
            dataCy="hidden"
          />
        </Col>
      ) : null}

      {isInOnboardFilters ? null : (
        <Col xs="auto" className="d-flex align-items-center border-start border-platinum ps-2 ms-1">
          <Button
            className={`bg-${
              showComments ? 'primary' : 'white'
            } height-24 d-flex align-items-center fw-normal cps-10 cpe-10 `}
            onClick={() => {
              dispatch(setShowComments(showComments ? null : true));
              if (showComments) {
                dispatch(setInventoryExpandedRowsIds([]));
              }
            }}
          >
            <div
              className={`d-flex align-items-center text-${
                showComments ? 'white' : 'gray-secondary'
              }`}
            >
              <SvgRender src={showComments ? hideSvg : eyeSvg} style={{ width: 12, height: 12 }} />

              <span className="fs-12 cms-4">Comments</span>
            </div>
          </Button>
          <Information
            svgClassname={'text-primary'}
            className="cms-4"
            svgStyle={{ width: 12, height: 12 }}
            message={
              <span>
                Display All Comments.
                <br />
                When this option is enabled,
                <br />
                you'll see all comments.
              </span>
            }
          />
        </Col>
      )}

      <Col xs="auto" className="cmt-4 ps-2 cms-12 border-start d-flex aling-items-center">
        <ExportButton tableRequestParams={requestParams} />
      </Col>

      <Col xs="auto" className="ms-auto">
        {singleVesselView ? (
          <TopPagination
            hideNavigation
            hidePerPage
            standAlone
            state={{ paging: { ...paging, per_page: null } }}
            customOptions={[20, 50, 100, 200]}
          />
        ) : (
          <TopPagination standAlone state={{ paging }} customOptions={[20, 50, 100, 200]} />
        )}
      </Col>
    </Row>
  );
};

export default TableHeader;
