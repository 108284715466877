import { useDrawer } from 'common/components/drawer';
import FormHeaderPreview from 'common/components/forms/_components/FormHeaderPreview';
import FormsDrawer from 'common/components/forms/drawer';
import { useState } from 'react';

const SavedJobForms = ({ savedForms, onRemove }) => {
  const [selectedForm, setSelectedForm] = useState(null);
  const drawer = useDrawer();

  const onView = form => {
    setSelectedForm(form);
    drawer.open();
  };

  return (
    <div className="job-forms__container">
      {savedForms.map(form => (
        <FormHeaderPreview
          flat
          hideEmptySubmissionMessage
          onView={() => onView(form?.current_form_version ?? form)}
          key={form.id}
          onRemove={onRemove}
          form={form}
          hiddenFields={{ status: true, importance: true }}
        />
      ))}

      <FormsDrawer
        previewMode
        isFormLocked
        drawerType="view"
        drawer={drawer}
        initialForm={selectedForm}
      />
    </div>
  );
};

export default SavedJobForms;
