import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import { Button } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { useActions } from 'utils/hooks';

import AuthCheck from 'components/permissions/AuthCheck';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

import * as sidebarActions from 'store/sidebar/actions';
import * as dashboardsActions from 'store/dashboards/actions';

import arrow from 'assets/svg/common/arrow.svg';
import {
  getMenuItemOrganizationSettings,
  getMenuItemPermissions
} from 'common/utils/permissions/helpers';
import { useSelector } from 'react-redux';

const isLinkActiveCustomLogic = (match, location, item) => {
  if (location.pathname === '/' && item.label === 'the-fleet') {
    return true;
  } else if (location.pathname.includes(paths.filesystem) && item.label === 'manuals') {
    return true;
  } else if (location.pathname.includes(paths.companies) && item.label === 'rolodex') {
    return true;
  } else if (location.pathname.includes(paths.forms) && item.label === 'forms') {
    return true;
  } else if (match && match.isExact) {
    return true;
  } else if (location.pathname.startsWith(`${item.path}/`) && item.path !== '/') {
    return true;
  } else if (item.groupPath && location.pathname.startsWith(`${item.groupPath}`)) {
    return true;
  } else return false;
};

const Item = ({ item, children }) => {
  const pathname = useSelector(state =>
    item?.selectPath ? item.selectPath(state, item) : item?.path
  );

  const [togglePanel, setDashboardForm] = useActions([
    sidebarActions.togglePanel,
    dashboardsActions.setDashboardForm
  ]);

  const onButtonClick = () => {
    switch (item.label) {
      case 'create-new-dashboard':
        setDashboardForm({ isOpen: true, active: null });

        break;
      default:
        break;
    }
  };

  return pathname ? (
    <NavLink
      to={{ pathname, search: '' }}
      data-cy={item.label}
      exact={true}
      isActive={(match, location) => isLinkActiveCustomLogic(match, location, item)}
      onClick={() => togglePanel(null)}
    >
      {children}
    </NavLink>
  ) : (
    <Button
      className="menu-item--nav-subitem-button"
      color="white"
      size="sm"
      onClick={onButtonClick}
    >
      {children}
    </Button>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
    selectPath: PropTypes.func,
    selectPermissions: PropTypes.func
  })
};

const MenuItem = ({ item, onItemClick, isDashboard }) => {
  const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const { location } = useRouter();

  const getParentActiveStatus = item => {
    if (item.submenu && item?.label?.some(label => location.pathname.startsWith(`${label}`))) {
      return true;
    }

    return false;
  };

  const dashboardShared =
    isDashboard && (item?.shared_parties?.length || item?.shared_departments?.length);

  const formattedPermissions = item?.selectPermissions
    ? useSelector(state => item?.selectPermissions(state, item))
    : [];

  return (
    <AuthCheck
      permissions={getMenuItemPermissions(item, formattedPermissions)}
      requiredOrganizationSettings={getMenuItemOrganizationSettings(item)}
      environments={item.environments}
    >
      <div
        className={`menu-item ${item.className || ''} ${dashboardShared ? 'dashboard-shared' : ''}`}
      >
        <div className="menu-item--nav">
          {item.submenu ? (
            <Button
              type="button"
              data-cy={item.label}
              className={getParentActiveStatus(item) ? 'active' : ''}
              color="link"
              onClick={() => onItemClick(item)}
            >
              <SvgRender
                className="menu-item--nav-icon"
                src={item.icon}
                style={{ width: 18, height: 18 }}
              />
              {item.name}
              <SvgRender
                className="menu-item--nav-arrow"
                src={arrow}
                style={{ width: 10, height: 10 }}
              />
            </Button>
          ) : (
            <Item item={item}>
              {item.icon ? (
                <SvgRender
                  className="menu-item--nav-icon"
                  src={item.icon}
                  style={item.iconStyle || { width: 18, height: 18 }}
                />
              ) : (
                <div className="menu-item--nav-icon ps-2" />
              )}
              <div>
                <div className={`${isDashboard && item.is_owned === false ? 'mt-2' : ''}`}>
                  {item.name}
                </div>
                {dashboardShared ? (
                  <div className="cmt-6 cmb-4 d-flex align-items-center fs-10 text-primary max-width-48 shared-dashboard-label">
                    <span className="fw-normal cme-4">Shared</span>
                  </div>
                ) : null}
              </div>
            </Item>
          )}
          {item.addButton ? (
            <AuthCheck permissions={[item.addButtonPermission]}>
              <Link
                onClick={() => togglePanel(null)}
                to={item.addButton}
                className="menu-item--add"
              />
            </AuthCheck>
          ) : null}
        </div>
      </div>
    </AuthCheck>
  );
};

export default MenuItem;
