import { components } from 'react-select';
import CircledButton from 'common/components/buttons/CircledButton';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const CustomMakerOption = ({ children, ...props }) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <Row className="d-flex align-items-center cpt-2 cpb-2" noGutters>
        {data.id !== 'create' ? (
          <>
            <Col xs={'11'} className="fs-14 text-nowrap overflow-x-hidden text-truncate ps-1">
              {data?.full_name}
            </Col>
          </>
        ) : (
          <div className="d-flex align-items-center w-100p ps-1 cpy-2 mt-0">
            <CircledButton
              type="add"
              style={{ width: 19, height: 19 }}
              svgStyle={{ width: 7, height: 7 }}
              label={'Create new'}
              labelClassName={'cms-4'}
            />
          </div>
        )}
      </Row>
    </components.Option>
  );
};

CustomMakerOption.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string || PropTypes.number,
    full_name: PropTypes.string
  })
};

export default CustomMakerOption;
