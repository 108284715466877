import { useEffect, useState, useCallback } from 'react';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';

import {
  getInitialParams,
  getTopFiltersParams,
  getUpdatedTableFiltersParams
} from 'common/utils/filters/helpers';
import { stringifyObj, parseQueryParams } from 'common/utils/urls';
import { initializeFilters, parseOverridenTopFilters } from './helpers';

import {
  selectTableListBasicState,
  selectTableFilters,
  selectTableTopFilters,
  selectAppliedTableFilters,
  selectShouldRefetchTableFiltersOnApply,
  selectTablesPreviousLocationPath,
  selectTablesCurrentLocationPath,
  selectTablesCurrentLocationNavigatorAction,
  selectTablesPreviousLocationSearch,
  selectTablesCurrentLocationSearch,
  selectTableListPageSearch
} from 'common/components/table/store/selectors';
import { useSelector } from 'react-redux';
import { trimLocationPath } from 'common/utils/urls';

import * as tableListActions from 'store/tables/lists/actions';
import * as tableActions from 'store/tables/actions';
import * as tableCommonActions from 'common/components/table/store/actions';

import { getDefaultSorting } from 'store/tables/lists/defaults';

import useActions from 'common/utils/hooks/useActions';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import useCancelablePromise from 'common/utils/hooks/useCancelablePromise';
import useRouter from 'use-react-router';
import useResetCurrentPage from 'common/utils/hooks/useResetCurrentPage';
import { useTableProps } from '@/common/types/front-entities/table';

const useTable = ({
  label,
  columns,
  top,
  defaultRequestParams,
  urlRefetchLevel = 2,
  requestTableListFrom,
  shouldRefetchDataOnUrlChange = false,
  excludedFilters,
  modular = false
}: useTableProps) => {
  const cancelablePromise = useCancelablePromise();
  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [getTableList, setTableFilters, setTableDefaultRequestParams, setTableRequestParams] =
    useActions([
      tableListActions.getTableList,
      tableActions.setTableFilters,
      tableActions.setTableDefaultRequestParams,
      tableCommonActions.setTableRequestParams
    ]);

  const state = useSelector(state => selectTableListBasicState(state, label));
  const filters = useSelector(state => selectTableFilters(state, label));
  const appliedFilters = useSelector(state => selectAppliedTableFilters(state, label));
  const shouldRefetchTableFiltersOnApply = useSelector(state =>
    selectShouldRefetchTableFiltersOnApply(state, label)
  );
  const searchTextValue = useSelector(state => selectTableListPageSearch(state, label));

  const { history, location } = useRouter();
  const { sorting } = state;

  const prevPath = useSelector(selectTablesPreviousLocationPath);
  const currentPath = useSelector(selectTablesCurrentLocationPath);
  const navigatorAction = useSelector(selectTablesCurrentLocationNavigatorAction);

  const prevSearch = useSelector(selectTablesPreviousLocationSearch);
  const currentSearch = useSelector(selectTablesCurrentLocationSearch);

  const tableSearch = useSelector(state => {
    const { searchId } = parseQueryParams(location.search);
    if (state.tables.search && parseInt(state.tables.search.id) === parseInt(searchId)) {
      return state.tables.search;
    } else return null;
  });

  const [initialQuery, setInitialQuery] = useState(
    getInitialParams(
      {
        columns,
        sorting,
        paging:
          defaultRequestParams && defaultRequestParams.paging === false
            ? null
            : state.paging
              ? {
                  per_page: state.paging.default_per_page,
                  current_page: state.paging.current_page
                }
              : null,
        filters: getTopFiltersParams(top, excludedFilters, location.search, true)
      },
      defaultRequestParams
    )
  ); // Default request params (without any URL param)

  const [requestParams, setRequestParams] = useState(() => {
    if (location.search && location.search !== '') {
      const { searchId, filters, sorting, visible, page_size } = parseQueryParams(location.search);
      const params = {};

      if ((defaultRequestParams && defaultRequestParams.paging) || !defaultRequestParams)
        params.paging = page_size
          ? { current_page: state.paging ? state.paging.current_page : 1, per_page: page_size }
          : { ...state.paging };

      if ((defaultRequestParams && defaultRequestParams.filters) || !defaultRequestParams) {
        params.filters = filters ? filters : [];
      }

      if ((defaultRequestParams && defaultRequestParams.sorting) || !defaultRequestParams)
        params.sorting = sorting;
      if ((defaultRequestParams && defaultRequestParams.visible) || !defaultRequestParams)
        params.visible = visible;

      return params;
    } else {
      return initialQuery;
    }
  }); // Stored params after every table fetch request

  const topFilters = useSelector(state => selectTableTopFilters(state, label, top));

  const changeFilters = useCallback(
    newFilters =>
      setTableFilters({
        label,
        filters: newFilters
      }),
    [label, setTableFilters]
  );

  const getParams = initialized => {
    if (!initialized) {
      setInitialized(true);

      initializeFilters(filters, navigatorAction, requestParams, label, columns, setTableFilters);

      return requestParams;
    } else {
      const query = parseQueryParams(location.search);

      const queryParams = {
        filters: query.filters ? query.filters : [],
        visible: query.visible,
        sorting: query.sorting,
        paging: (query.page_size || state.paging) && {
          per_page: query.page_size ? query.page_size : state.paging.default_per_page
        }
      };

      initializeFilters(
        query?.searchId ? [] : filters,
        navigatorAction,
        queryParams,
        label,
        columns,
        setTableFilters
      );

      return queryParams;
    }
  };

  useEffect(() => {
    const newParams = getParams(initialized);

    const { pathname, search } = location;
    const trimmedPath = trimLocationPath(pathname, urlRefetchLevel);

    const pathChanged =
      trimmedPath !== trimLocationPath(prevPath, urlRefetchLevel) ||
      trimLocationPath(currentPath, urlRefetchLevel) !== trimmedPath;
    const searchChanged = search !== prevSearch || currentSearch !== search;

    if (pathChanged || searchChanged || shouldRefetchDataOnUrlChange) {
      const fetchInitialData = async () => {
        const params = searchChanged
          ? {
              ...newParams,
              sorting:
                newParams.sorting && Object.keys(newParams.sorting)?.length
                  ? newParams.sorting
                  : getDefaultSorting(label),
              paging:
                defaultRequestParams && defaultRequestParams.paging === false
                  ? null
                  : { ...newParams.paging, current_page: 1 }
            }
          : {
              ...newParams,
              sorting: getDefaultSorting(label)
            };

        const { searchText } = parseQueryParams(search);
        if (searchText) params.search = searchText;

        await refetchData(params);
      };

      fetchInitialData();
    }
  }, [location.search]);

  useEffect(() => {
    if (!defaultRequestParams) return;
    setTableDefaultRequestParams({ label: label, defaultTableParams: defaultRequestParams });
  }, []);

  useEffect(() => {
    /* Fix bug with the modular table filters which are not initiliazed,
     when the table is initialized but the columns are NOT fetched yet. */
    if (modular && columns?.length && initialized && history.location.search) {
      getParams(true);
    }
  }, [modular, columns?.length, initialized]);

  const updateLocationHistory = updatedParams => {
    if (!initialized) return;

    const { sorting, visible, page_size, searchId, searchText, filters } = parseQueryParams(
      location.search
    );
    const fields = {};

    if (!_isEqual(filters, updatedParams?.filters)) {
      fields.filters = _uniqBy(updatedParams?.filters?.length ? updatedParams.filters : [], 'name');
    }
    if (sorting && !_isEqual(sorting, initialQuery.sorting)) {
      fields.sorting = sorting;
    }
    if (visible && !_isEqual(visible, initialQuery.visible)) {
      fields.visible = visible;
    }

    if (searchText) fields.searchText = searchText;
    if (searchId) fields.searchId = searchId;

    if (
      page_size &&
      (parseInt(page_size) !== parseInt(initialQuery.paging.per_page) ||
        (tableSearch && searchId && parseInt(page_size) !== parseInt(tableSearch.page_size)))
    ) {
      fields.page_size = page_size;
    }

    if (location.search !== `?${stringifyObj(fields)}`) {
      history.replace({
        pathname: location.pathname,
        search: stringifyObj(fields)
      });
    }
  };

  const getUpdatedTopFiltersParams = newFilters => {
    const validTopFilters = _uniqBy(
      newFilters?.filter(f => {
        if (typeof f.value === 'number') {
          return f.value !== 0;
        } else {
          return f.value && f.value.length;
        }
      }),
      'name'
    );

    // Remove item from requestParams.filters that have a matching name and a null value in newFilters
    // TLDR If a new filter comes with a null value we will remove it entirely.
    // const filteredRequestParamsFilters = requestParams.filters?.filter(
    //   rf => !newFilters?.some(nf => nf.name === rf.name && nf.value === null)
    // );

    const params = {
      filters: [
        ...(requestParams.filters?.filter(f => !topFilters.some(t => t.name === f.name)) || []),
        ...(validTopFilters?.map(({ name, operation, value }) => ({ name, operation, value })) ||
          [])
      ]
    };

    if (state.paging) {
      params.paging = { per_page: state.paging.per_page, current_page: 1 };
    }

    if (!_isEqual(params.filters, requestParams.filters) && !fetching) {
      return params;
    }

    return null;
  };

  const setTopFilters = newFilters => {
    const params = getUpdatedTopFiltersParams(newFilters);

    if (params) {
      updateLocationHistory(params);
    }
  };

  useUpdateEffect(() => {
    // Add to URL when filters are applied
    if (appliedFilters && shouldRefetchTableFiltersOnApply) {
      const params = getUpdatedTableFiltersParams(
        { filters, topFilters, excludedFilters, requestParams, state },
        location
      );

      if (params) {
        updateLocationHistory(params);
      }
    }
  }, [appliedFilters, shouldRefetchTableFiltersOnApply]);

  useUpdateEffect(() => {
    // Watch for filters updates
    if (!initialized) return;

    if (!filters.length) {
      changeFilters([{ column: null, operation: null, value: '' }]);
    }
  }, [filters?.length]);

  // Refetch table data
  const refetchData = async (params?: object, extraRequestParams?: object) => {
    setFetching(true);

    const newParams = { ...requestParams, ...(params || {}) };

    const { sorting } = state;

    if ((defaultRequestParams && defaultRequestParams.visible) || !defaultRequestParams) {
      if (!newParams.visible) {
        newParams.visible = getInitialParams({ columns }).visible;
      }
    }

    if ((defaultRequestParams && defaultRequestParams.sorting) || !defaultRequestParams) {
      if (!newParams.sorting) {
        newParams.sorting = getInitialParams({ sorting }).sorting;
      }
    }

    setRequestParams(newParams);

    const { visible, filters, ...rest } = newParams;
    const rParams = {
      ...rest,
      filters: parseOverridenTopFilters(top?.filters, filters),
      visible: visible ? visible.filter(v => v.visible).map(v => v.key) : undefined,
      table: label,
      paging: newParams.paging
        ? { per_page: newParams.paging.per_page, current_page: newParams.paging.current_page }
        : undefined,
      search: (searchTextValue ? searchTextValue : undefined) || params?.search
    };

    setTableRequestParams({ table: label, params: rParams });

    if (requestTableListFrom) {
      await cancelablePromise(requestTableListFrom(rParams, extraRequestParams));
    } else {
      await cancelablePromise(getTableList(rParams));
    }

    setFetching(false);
  };

  useResetCurrentPage(label);

  return {
    label,
    refetchData,
    requestParams,
    setRequestParams,
    state,
    fetching,
    filters,
    setFilters: changeFilters,
    topFilters,
    setTopFilters,
    columns,
    top,
    excludedFilters
  };
};

export default useTable;
