import { useState } from 'react';

import Table, { useTable } from 'common/components/table';
import { useUpdateEffect } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectEventVessel } from 'events/store/events/selectors';
import {
  removeEventFormObligation,
  getEventFormObligations,
  getEventFormSubmissions
} from 'events/store/event-modules/forms/actions';
import Responsible from 'common/components/form-obligations/components/Responsible';
import IgnoreButton from 'common/components/form-obligations/components/IgnoreButton';
import FormDrawer from 'common/components/forms/drawer';
import { useDrawer } from 'common/components/drawer';
import add from 'common/assets/svg/actions/add.svg';
import { createFormObligationSubmission } from 'common/components/form-obligations/store/actions';
import {
  selectTableListDataLength,
  selectTableListIsEmpty,
  selectTableListRequestParams
} from 'common/components/table/store/selectors';
import { getCanSubmitForm } from 'common/components/form-obligations/helpers';
import { selectAccount } from 'store/account/selectors';
import { EVENT_OBLIGATIONS_TABLE_LABEL, EVENT_SUBMISSIONS_TABLE_LABEL } from '.';
import remove from 'common/assets/svg/actions/remove.svg';
import ListLoader from '../components/ListLoader';

const ObligationsTable = () => {
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedObligation, setSelectedObligation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const drawer = useDrawer();

  const account = useSelector(selectAccount);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventVessel = useSelector(selectEventVessel);
  const eventId = useSelector(selectEventId);
  const eventSubmissionsTableRequestParams = useSelector(state =>
    selectTableListRequestParams(state, EVENT_SUBMISSIONS_TABLE_LABEL)
  );
  const eventSubmissionsTableDataCount = useSelector(state =>
    selectTableListDataLength(state, EVENT_SUBMISSIONS_TABLE_LABEL)
  );
  const tableIsEmpty = useSelector(state =>
    selectTableListIsEmpty(state, EVENT_OBLIGATIONS_TABLE_LABEL)
  );

  const fetchData = async params => {
    if (!eventId) return;

    try {
      setIsLoading(true);

      const res = await dispatch(getEventFormObligations({ eventId, ...params })).unwrap();

      setIsLoading(false);
      return res;
    } catch (err) {
      setIsLoading(false);

      console.error(err);
    }
  };

  const onSubmitForm = async params => {
    try {
      await dispatch(
        createFormObligationSubmission({ obligationId: selectedObligation.id, ...params })
      ).unwrap();
      dispatch(getEventFormSubmissions({ eventId, ...eventSubmissionsTableRequestParams }));

      setSelectedForm(null);
      setSelectedObligation(null);
      drawer.close();
    } catch (error) {
      throw error;
    }
  };

  const table = useTable({
    columns: [
      {
        header: 'Form Obligations',
        key: 'form.name',
        sortingKey: 'form',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: true,
        truncate: true,
        width: 7
      },
      {
        header: 'Responsible',
        key: 'responsible',
        sortingKey: '',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: false,
        minWidth: 150,
        maxWidth: 150
      },
      {
        header: 'Days Left',
        key: 'days_left',
        sortingKey: '',
        bodyClassName: 'd-flex align-items-end justify-content-end',
        headerClassName: 'bg-transparent d-flex align-items-end justify-content-end',
        type: 'number',
        sort: true,
        minWidth: 150,
        maxWidth: 150
      },
      {
        header: 'Ignore',
        key: 'ignore',
        sortingKey: '',
        type: 'string',
        hiddenOnBoard: true,
        sort: false,
        bodyClassName: 'd-flex align-items-center justify-content-center',
        headerClassName: 'bg-transparent d-flex align-items-center justify-content-center',
        minWidth: 200,
        maxWidth: 200
      },
      {
        header: 'Actions',
        key: 'actions',
        field: 'actions'
      }
    ],
    label: EVENT_OBLIGATIONS_TABLE_LABEL,
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: false },
    requestTableListFrom: fetchData
  });

  useUpdateEffect(() => {
    table.refetchData();
  }, [eventSubmissionsTableDataCount]);

  if (tableIsEmpty) return;

  return isLoading ? (
    <ListLoader />
  ) : (
    <Table
      hideTableFilters
      hideBottomPagination
      hideTableSearch
      hideTopPagination
      handleTableSort={({ sorting }) => table.refetchData({ sorting })}
      drawer={
        <FormDrawer
          drawerType="add"
          drawer={drawer}
          initialForm={selectedForm}
          onSaveFormSubmission={onSubmitForm}
          initialVesselID={selectedObligation?.is_for_vessel ? eventVessel?.id : null}
          hiddenFields={{
            vessel: !isOnBoard ? false : true,
            type: true
          }}
          filterVesselSelectorOptions={eventVessel ? opt => eventVessel?.id === opt.value : null}
        />
      }
      {...table}
      rows={{
        responsible: data => (
          <Responsible type={data.responsible_type} responsible={data.responsible} />
        ),
        days_left: data => (
          <span
            className={`${data.becoming_overdue || data.is_overdue ? 'fw-medium' : ''} ${
              data.is_overdue ? 'text-danger' : data.becoming_overdue ? 'text-warning' : ''
            }`}
          >
            {data.days_left}
          </span>
        ),
        ignore: data => <IgnoreButton isInitialyIgnored={data.is_ignored} id={data.id} />,
        actions: data => ({
          options: [
            getCanSubmitForm({
              isOnBoard,
              account,
              data
            }) && {
              wrapperClassName: 'bg-yellow',
              label: 'Fill in Form',
              svgStyle: { width: 8, height: 8 },
              icon: add,
              onClick: () => {
                setSelectedForm({ ...data.form, id: data.form?.current_form_version?.id });
                setSelectedObligation(data);
                drawer.open();
              }
            },
            data.type === 'ad-hoc' && {
              label: 'Remove Form Obligation',
              icon: remove,
              preventionModal: true,
              svgStyle: { width: 11, height: 11 },
              modalProps: {
                onAccept: async () => {
                  await dispatch(removeEventFormObligation({ eventId, obligationId: data.id }));
                  table.refetchData();
                },
                header: 'Remove Obligation',
                actionText: 'REMOVE',
                action: 'remove',
                actionHoverColor: 'delete',
                body: (
                  <div className="text-center">
                    You are about to remove this obligation. <br />
                    Are you sure you want to continue?
                  </div>
                )
              }
            }
          ]
        })
      }}
    />
  );
};

export default ObligationsTable;
