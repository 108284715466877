import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectInventoryDepletionMethod } from 'common/components/mga/store/selectors';

import Spinner from 'common/components/general/Spinner';
import AccountGroup from './AccountGroup';
import AccountRow from './AccountRow';

const InitialBalnces = ({ subStates, isLoading, startedAt }) => {
  const initialBalances = subStates('initial_balances');
  const depletionMethod = useSelector(selectInventoryDepletionMethod);

  return (
    <div>
      <div className="border-top mt-2 mb-2"></div>
      <div className="rounded bg-light-gray text-violet cmb-12 cps-12 cpe-12 py-1 d-flex align-items-center">
        <strong className="fs-12 fw-medium">
          * Please fill in all balances as of today. Note that the number you enter here, will be
          the "Opening Balance" in the period.
        </strong>
      </div>

      <Row className="fw-bold fs-12 text-primary mb-1 me-3 cpe-2 cms-4">
        <Col xs={10}>ACCOUNT</Col>
        <Col xs={2}>BALANCE</Col>
      </Row>

      {isLoading ? (
        <Spinner />
      ) : (
        initialBalances.map((state, i) => {
          const isStoreGroup = state?.state?.account?.value?.store_groups_count;

          return isStoreGroup && (depletionMethod === 'avg' || depletionMethod === 'fifo') ? (
            <AccountGroup key={`account-balance-${i}`} formState={state} startedAt={startedAt} />
          ) : (
            <AccountRow key={`account-balance-${i}`} formState={state} />
          );
        })
      )}
    </div>
  );
};

export default InitialBalnces;
