import PropTypes from 'prop-types';

export const VesselAttributeType = PropTypes.shape({
  group_id: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['number', 'text', 'attachment', 'image'])
});

export const VesselAttributesGroupType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(VesselAttributeType)
});
