import { FC, useEffect, useState } from 'react';
import { FileWithRawEmail } from '@/ts-common/types/files';
import PostalMime from 'postal-mime';

type EmailProps = {
  file: FileWithRawEmail;
};

const Email: FC<EmailProps> = ({ file }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isFrameLoaded, setIsFrameLoaded] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const forceRerenderTimes = 0;

  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailHtml, setEmailHtml] = useState<string>('');

  useEffect(() => {
    const parser = new PostalMime();

    const parseEmail = async () => {
      if (!file.stream) return;

      try {
        const email = await parser.parse(file.stream);
        setEmailSubject(email.subject || '');
        setEmailHtml(email.html || '');
      } catch (error) {
        console.error('Error parsing email:', error);
      }
    };

    parseEmail();
  }, [file.extension, file.stream]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isFrameLoaded) {
      timer = setTimeout(() => {
        if (index <= forceRerenderTimes) {
          setIndex(old => old + 1);
        } else {
          setHasError(true);
        }
      }, 2500);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [file?.url_inline, file?.encoded_url, index, isFrameLoaded]);

  const onLoadHandler = () => {
    setIsFrameLoaded(true);
    setHasError(false);
  };

  return (
    <>
      {hasError ? (
        <div className="text-white fs-14 position-absolute top-50 start-0 w-100p text-center">
          Preview Unavailable
        </div>
      ) : null}

      <div className="bg-white" style={{ height: '600px' }}>
        <iframe
          key={index}
          title={file.filename}
          src={`data:text/html,<!DOCTYPE html><html><body>${emailSubject}<br>${encodeURIComponent(
            emailHtml
          )}</body></html>`}
          width="100%"
          height="100%"
          onLoad={onLoadHandler}
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Email;
