import PropTypes from 'prop-types';

import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { getSystemAttributesGroup } from 'common/components/pms/setup/store/actions';

const HeaderInput = ({ selectField, group, setIsLoading, setSubStates }) => {
  const dispatch = useDispatch();

  const handleAttributesChange = async id => {
    if (!id) {
      setSubStates('attributes', []);
      return;
    }
    setIsLoading(true);

    try {
      const res = await dispatch(getSystemAttributesGroup({ id })).unwrap();

      setSubStates(
        'attributes',
        res.attributes.map(attr => ({
          state: {
            id: { value: attr?.id, error: '' },
            name: { value: attr?.name, error: '' },
            type: { value: attr?.type, error: '' },
            value: { value: attr?.value, error: '' },
            file: { value: attr?.file, error: '' }
          }
        }))
      );
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  return (
    <AsyncSelector
      invisible
      label="SYSTEM ATTRIBUTES"
      placeholder="Select system attributes group"
      onChange={attributeGroup => {
        if (group.value?.id === attributeGroup?.id) return;

        selectField('attribute_group')(attributeGroup);
        handleAttributesChange(attributeGroup?.id);
      }}
      loadOptions={search => getAsyncOptions(search, 'vessel-system-attribute-groups', {}, false)}
      defaultOptions={() => getInitialAsyncValues('vessel-system-attribute-groups', null, null)}
      {...group}
    />
  );
};

HeaderInput.propTypes = {
  selectField: PropTypes.func.isRequired,
  group: PropTypes.shape({
    value: PropTypes.shape({ id: PropTypes.number.isRequired, name: PropTypes.string.isRequired })
  }).isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setSubStates: PropTypes.func.isRequired
};

export default HeaderInput;
