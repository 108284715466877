import React, { useState } from 'react';
import Job from './job';
import useDrawer from 'common/components/drawer/useDrawer';
import CollapseForm from 'common/components/collapse/CollapseForm';

import JobsDrawer from 'common/components/pms/setup/views/system/vessels/jobs/drawer';

const Jobs = ({ assignment }) => {
  const drawer = useDrawer(false);
  const [selectedJobForEdit, setSelectedJobForEdit] = useState({});

  const jobsAssignmentsData = assignment?.maintenance_job_prototypes;
  const assignmentVesselId = assignment?.vessel_id;
  const vesselAssignmentId = assignment?.id;

  const onEdit = async job => {
    setSelectedJobForEdit(job);
    drawer.open();
  };

  return (
    <>
      <CollapseForm
        circledButtonProps={{
          style: { width: 20, height: 20 },
          svgStyle: { width: 11, height: 11 }
        }}
        hideExpandButton={true}
        className="mt-1 main-collapse"
        hideActions={true}
        reversedExpandBtn={true}
        header={<div className="fs-12 lh-1 text-violet">Jobs</div>}
      >
        <div
          className={`system-view spare-parts jobs text-primary  position-relative mt-1 ${
            !jobsAssignmentsData?.length ? 'd-flex justify-content-center' : ''
          }`}
        >
          {!jobsAssignmentsData?.length ? (
            <div className="text-violet fs-12">No Jobs at the moment </div>
          ) : (
            <div>
              {jobsAssignmentsData?.map(job => (
                <Job
                  key={job.id}
                  job={job}
                  onEdit={onEdit}
                  vesselId={assignmentVesselId}
                  vesselAssignmentId={vesselAssignmentId}
                  isUntriggered={assignment?.parent_id && !job?.job_count} // We show the untriggered indicator only when a subsystem is selected
                />
              ))}
            </div>
          )}
        </div>
      </CollapseForm>
      <JobsDrawer drawer={drawer} selectedJobForEdit={selectedJobForEdit} />
    </>
  );
};

export default Jobs;
