import EmptyState from './components/EmptyState';
import Header from './components/Header';

import { useSelector } from 'react-redux';
import {
  selectSelectedSystem,
  selectSystemFormType,
  selectSelectedTab,
  selectCurrentSystem,
  selectselectedAssignment,
  selectSelectedSubAssignment,
  selectCurrentAssignments
} from 'common/components/pms/setup/store/selectors';

import Vessels from './vessels';
import Specifications from './specifications';

const System = () => {
  const selectedSystem = useSelector(selectSelectedSystem);
  const systemFormType = useSelector(selectSystemFormType);
  const assignment = useSelector(selectselectedAssignment);
  const subAssignment = useSelector(selectSelectedSubAssignment);
  const currentAssignment = subAssignment || assignment;
  const currentAssignments = useSelector(selectCurrentAssignments);

  const system = useSelector(selectCurrentSystem);
  const selectedTab = useSelector(selectSelectedTab);
  const isSubSystem = !!system?.parent_id;

  const showEmpty = !selectedSystem && !systemFormType;

  return (
    <div className={`single-system h-100p ${showEmpty ? 'pt-0 single-system--empty' : ''}`}>
      {showEmpty ? (
        <EmptyState />
      ) : (
        <>
          <Header />
          <div>
            {selectedSystem ? (
              selectedTab ? (
                <Vessels
                  currentAssignment={currentAssignment}
                  currentAssignments={currentAssignments}
                  selectedSystem={selectedSystem}
                  isSubSystem={isSubSystem}
                  assignment={isSubSystem ? subAssignment : assignment}
                />
              ) : null
            ) : null}
            <Specifications selectedTab={selectedTab} systemFormType={systemFormType} />
          </div>
        </>
      )}
    </div>
  );
};

export default System;
