import { css } from '@emotion/react';

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useDrawer from 'common/components/drawer/useDrawer';
import Table, { useTable } from 'common/components/table';
import { deleteMaintenanceJobType } from 'common/components/pms/setup/store/actions';
import edit from 'common/assets/svg/actions/edit.svg';
import binIcon from 'common/assets/svg/actions/delete.svg';

import PageActions from 'components/layouts/page/table/PageActions';
import Form from './Form';
import permissions from '@/common/utils/permissions/constants';

const JobTypes = ({ label }) => {
  const drawer = useDrawer(false);
  const [active, setActive] = useState();
  const dispatch = useDispatch();

  const table = useTable({
    label,
    defaultRequestParams: { visible: false, paging: true, sorting: true, filters: false },
    columns: [
      {
        header: 'Type',
        key: 'type',
        type: 'string',
        sort: true,
        canFilter: false
      },
      {
        header: 'Actions',
        key: 'actions',
        field: 'actions'
      }
    ]
  });

  const onEdit = data => {
    setActive(data);
    drawer.open();
  };

  const onDelete = async id => {
    if (!id) return;

    try {
      await dispatch(deleteMaintenanceJobType({ id })).unwrap();
      table.refetchData();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div css={CreateNewButtonStyle}>
        <PageActions
          className="job-types-actions"
          actions={[
            {
              type: 'create',
              color: 'primary',
              text: 'Create new',
              dataCy: 'create-new-job-type',
              permissions: [permissions.office.pms.configuration.system_job_types.edit],
              onClick: () => {
                setActive(null);
                drawer.open();
              }
            }
          ]}
        />
      </div>

      <Table
        loader
        hideTableFilters
        hideTableSearch
        rows={{
          type: data => data?.name,
          actions: data => ({
            options: [
              {
                label: 'Edit Job Type',
                icon: edit,
                onClick: () => onEdit(data),
                permissions: [permissions.office.pms.configuration.system_job_types.edit]
              },
              {
                label: 'Delete Job Type',
                icon: binIcon,
                preventionModal: true,
                permissions: [permissions.office.pms.configuration.system_job_types.edit],
                modalProps: {
                  onAccept: () => onDelete(data?.id),

                  actionText: 'Delete',
                  action: 'Delete',
                  actionHoverColor: 'delete',
                  params: { id: data.id },
                  header: 'Delete Job Type',
                  body: (
                    <div className="text-center">
                      Are you sure you want to delete <strong>{`${data?.name}`}</strong>? This
                      cannot be undone.
                    </div>
                  )
                }
              }
            ]
          })
        }}
        {...table}
      />

      <Form active={active} drawer={drawer} refetchData={table.refetchData} />
    </div>
  );
};

export default JobTypes;

const CreateNewButtonStyle = css`
  .job-types-actions {
    position: absolute;
    top: 0;
    right: 1%;
    width: auto;
  }
`;

JobTypes.propTypes = {
  label: PropTypes.string.isRequired
};
