import { Row, Col } from 'reactstrap';

import SparePart from './SparePart';

import CollapseBox from 'common/components/collapse/CollapseBox';
import CircledButton from 'common/components/buttons/CircledButton';

const FormMain = ({ fields, selectField, changeField, subStates, addSubform, removeSubform }) => {
  const sparePartsState = subStates('spare_parts');

  return (
    <CollapseBox
      header={<div className="text-primary fw-bold fs-10">SPARE PARTS</div>}
      expandButton={false}
      hideExpandButton={true}
      headerClassName="px-0 mt-1"
      defaultOpen={true}
      bodyInnerClassName="cpt-6 px-0 pb-0"
      className="job-maintenance"
      innerExpandButton
    >
      <Row>
        <Col xs={12} className="unscheduled-spare-parts">
          <div className="unscheduled-spare-parts__container ">
            <Row className="cmb-4">
              <Col className="fs-10 fw-bold" xs={9}>
                <span className="text-primary">REQUIRED</span>
                <span className="text-violet cms-4">AS PER MANUFACTURER</span>
              </Col>
              <Col className="text-primary fs-10 fw-bold" xs={3}>
                QUANTITY
              </Col>
            </Row>
            {sparePartsState.length > 0 &&
              sparePartsState.map((sparePartState, i) => (
                <SparePart
                  sparePartsState={sparePartsState}
                  onRemove={() => removeSubform('spare_parts', i)}
                  key={i}
                  formState={sparePartState}
                />
              ))}

            <CircledButton
              onClick={() => addSubform('spare_parts')}
              type={'add'}
              className="job-circled-btn text-primary fw-bold fs-12"
              disabled={sparePartsState?.some(e => !e.state.spare_part_id.value) || false}
              label="Add Spare Part"
              svgStyle={{ height: 6, width: 6 }}
              style={{ width: 16, height: 16 }}
            />
          </div>
        </Col>
      </Row>
    </CollapseBox>
  );
};

export default FormMain;
