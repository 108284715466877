import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import arrayMove from 'array-move';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

import { Label } from 'reactstrap';

import { useActions } from 'utils/hooks';

import SortableCheckList from 'common/components/jobs/_base/modules/checklist/sortable-checklist';
import { selectCurrentPmsJobActiveTabs } from 'common/components/pms/setup/store/selectors';

import * as pmsActions from 'common/components/pms/setup/store/actions';

const CheckList = ({ className, jobId, selectField, fields }) => {
  const checklistState = _sortBy(_get(fields, 'checklist_options.value', []), 'sort_index');
  const activeTabs = useSelector(selectCurrentPmsJobActiveTabs);
  const isActive = activeTabs?.checklist_options || checklistState?.length;

  const [editMaintenanceJobActiveTabs] = useActions([pmsActions.editMaintenanceJobActiveTabs]);

  useEffect(() => {
    if (!checklistState?.length && activeTabs?.checklist_options) {
      selectField('checklist_options')([{ title: '' }]);
    }
  }, [activeTabs?.checklist_options]);

  const handleSort = (newIndex, oldIndex) => {
    const newChecklistOptions = arrayMove(checklistState, oldIndex, newIndex);
    const updatedState = newChecklistOptions.map((checklist, index) => ({
      ...checklist,
      sort_index: index + 1
    }));
    setChecklistState(updatedState);
  };

  const setChecklistState = value => {
    selectField('checklist_options')(value);
  };

  const updateChecklistState = (val, __, index) => {
    const updatedState = checklistState.map((c, i) => (i === index ? { ...c, title: val } : c));

    setChecklistState(updatedState);
  };

  const onCancel = () => {
    selectField('checklist_options')(null);
    editMaintenanceJobActiveTabs('checklist_options', false);
  };

  return isActive ? (
    <div className={`${className} job-checklist`}>
      <div className="d-flex align-items-center mb-1">
        <Label className="text-primary fs-10 fw-bold mb-0">CHECKLIST</Label>
      </div>

      <SortableCheckList
        isEditMode={true}
        updateChecklistState={updateChecklistState}
        onSortEnd={({ newIndex, oldIndex }) => handleSort(newIndex, oldIndex)}
        helperClass="job-checklist__sortable"
        useDragHandle={true}
        setChecklistState={setChecklistState}
        checklistState={checklistState}
        onCancel={onCancel}
        placeholder="Title"
      />
    </div>
  ) : null;
};

export default CheckList;
