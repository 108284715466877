import { useEffect, useState } from 'react';

import { Button } from 'reactstrap';
import BasicModal from 'common/components/modals/BasicModal';
import Input from 'common/components/form/inputs/Input';
import { stringField } from 'common/utils/form/fieldTypes';
import { useForm, useFormState } from 'utils/hooks';
import { createPurchasingRequisitionEmailTemplates } from 'common/components/purchasing/requisition/store/actions';
import { useDispatch } from 'react-redux';

const config = {
  name: stringField({ required: true })
};

const SaveTemplateModal = ({ isOpen, closeModal, emailFields }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { formState, resetForm, collectValues } = useForm(config);
  const { fields, selectField } = useFormState(formState);
  const dispatch = useDispatch();

  const saveTemplate = async () => {
    try {
      setIsSaving(true);
      const values = collectValues();

      if (!values) return;

      const { name } = values;
      const { from, cc, body, bcc } = emailFields;

      const params = {
        name,
        type: 'purchasing_requisition',
        from: from.value,
        cc: cc.value?.map(email => email?.supplier_email || email),
        bcc: bcc.value?.map(email => email?.supplier_email || email),
        body: body.value
      };

      await dispatch(createPurchasingRequisitionEmailTemplates(params));

      setIsSaving(false);
      closeModal();
    } catch (error) {
      setIsSaving(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpen) resetForm();
  }, [isOpen]);

  return (
    <BasicModal
      isOpen={isOpen}
      toggle={() => (!isSaving ? closeModal() : {})}
      header={<div>Save as new Template</div>}
      container=".purchasing-communications"
      backdropClassName="blurred-backdrop"
      body={
        <div>
          <Input
            label={'TEMPLATE NAME'}
            value={fields.name.value}
            onChange={e => selectField('name')(e.target.value)}
          />
        </div>
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button color="cancel" onClick={closeModal} disabled={isSaving}>
            CANCEL
          </Button>

          <Button color="primary" onClick={saveTemplate} disabled={isSaving}>
            SAVE
          </Button>
        </div>
      }
    />
  );
};

export default SaveTemplateModal;
