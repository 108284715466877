import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import {
  selectGroupsCollapsed,
  selectIsSystemOpened,
  selectSystemSubSystems,
  selectPmsLibraryIsUsedInSetup
} from 'common/components/pms/setup/store/selectors';

import System from 'common/components/pms/setup/views/systems-groups/library/system';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import SortingHeader from '@/common/components/pms/setup/views/components/SortingHeader.tsx';
import { getSystemSubSystems } from '@/common/components/pms/setup/store/actions.ts';
import Spinner from '@/ts-common/components/general/Spinner';
import useCustomSorting from '@/common/utils/hooks/useCustomSorting';

const SubGroup = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(state => selectIsSystemOpened(state, id));
  const subSystems = useAppSelector(state => selectSystemSubSystems(state, id));
  const groupsCollapsed = useAppSelector(selectGroupsCollapsed);
  const isPmsLibraryIsUsedInSetup = useAppSelector(selectPmsLibraryIsUsedInSetup);

  const { sorting, handleColumnSorting } = useCustomSorting();

  useEffect(() => {
    if (sorting === null) return;

    const fetchSubSystems = async () => {
      setIsLoading(true);

      try {
        await dispatch(getSystemSubSystems({ systemId: id, sorting })).unwrap();
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchSubSystems();
  }, [sorting]);

  return subSystems ? (
    <Collapse isOpen={isOpen}>
      {isOpen ? (
        <div className={`chapters-tree__view-submenu`}>
          {groupsCollapsed && isPmsLibraryIsUsedInSetup && (
            <Row className="g-0 align-items-center mb-2">
              <Col
                xs={1}
                className="d-flex  ps-3 me-1 fw-bold fs-10 min-w-124 w-12p"
                onClick={() => handleColumnSorting('drawing_no')}
              >
                <SortingHeader title="NO." selectedSorting={sorting?.drawing_no} />
              </Col>
              <Col
                xs={6}
                className="d-flex fw-bold fs-10"
                onClick={() => handleColumnSorting('description')}
              >
                <SortingHeader title="SUB-SYSTEM" selectedSorting={sorting?.description} />
              </Col>
            </Row>
          )}
          {isLoading ? (
            <Spinner className="ms-2" />
          ) : (
            subSystems?.map(sub => (
              <System
                key={sub.toString()}
                id={sub}
                // showUntriggeredJobsIndicator={isPmsLibraryIsUsedInSetup}
              />
            ))
          )}
        </div>
      ) : null}
    </Collapse>
  ) : null;
};

export default SubGroup;
