import { stringField, optionField, booleanField, hiddenField } from 'common/utils/form/fieldTypes';

const config = {
  description: stringField({ required: true }),
  vessel_system_group_id: optionField(),
  maker_id: optionField(),
  licensee_id: optionField(),
  type: stringField(),
  is_critical: booleanField({ initialValue: false }),
  is_navigational: booleanField({ initialValue: false }),
  is_environmental_critical: booleanField({ initialValue: false }),
  report_running_hours: booleanField({ initialValue: false }),
  drawing_no: stringField(),
  parent_id: hiddenField(),
  multiplicity: stringField()
};

export default config;
