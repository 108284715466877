import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';

import _reduce from 'lodash/reduce';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

const INITIAL_STATE = {
  // Common
  activeTab: '',
  logDetails: {},
  ticketingCases: [], // [ID_1, ID_2, ID_3]
  ticketingCase: {}, // { ID_1: { port, vessel, ...}, ID_2: { port, vessel, ...} }
  bulkModeEnabledTicketingCases: {}, // { ID_1: true, ID_2: false }
  shouldRefetchTicketingCases: false, // Set as 'true' to refetch table data while closing the trip drawer
  ticketingCaseTripIDs: {}, //{ ID_1: [] }

  // Rotations
  ticketingCaseRotations: {}, // { ID_1: { on_signer: [ROTATION_ID_1, ROTATION_ID_2], off_signer: [ROTATION_ID_1] }, ID_2: { on_signer: [ROTATION_ID_3], off_signer: [] } }
  ticketingRotation: {}, // { ROTATION_ID_1: { id, off_signer, on_signer, date_of_change, ... }, ROTATION_ID_2: { ... } }
  selectedTicketingRotations: {}, // BULK -- { ID_1: { on_signer: { ROTATION_ID_1: true, ROTATION_ID_2: false }, off_signer: { ROTATION_ID_1: true } } }

  // Events
  ticketingCaseEvents: {}, // { ID_1: EVENT_ID_1, ID_2: EVENT_ID_2 }
  ticketingEvent: {}, // { EVENT_ID_1: { ... }, EVENT_ID_2: { .... } }
  ticketingEventParticipants: {}, // { EVENT_ID_1: [...participants], EVENT_ID_2: [] }
  selectedTicketingParticipants: {}, // BULK -- { ID_1: { PARTICIPANT_ID_1: true, PARTICIPANT_ID_2: false }, ID_2: {} }

  // Trip (Drawer State)
  tripDrawerIsOpen: false,
  tripIDs: null,
  tripType: null, // crew || events || list
  tripCaseID: null,
  tripParties: [],
  tripVisibleParties: [], // [ { id, trip_id } ] - when in crew planning, show only the trip on/off signers
  tripOffers: [],
  tripDisabled: false,
  tripIsEditting: false,
  tripIsCollapsed: {}, // { tripID_1: true, tripID_2: false }
  tripData: null, // Trip data - { port, vessel, started_at } used in tripType -> list
  tripHasValidationErrors: false,

  // Prevention modal state
  preventionModal: {
    open: false,
    message: null
  },

  // Ticketing List
  ticketOfferTotals: {
    price: null,
    count: null
  },
  ticketsTotals: {}
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TABLE_TYPES.GET_TABLE_LIST.START:
      if (payload.table && payload.table.startsWith('ticketing_')) {
        const updatedState = {
          activeTab: payload.table,
          selectedTicketingRotations: {},
          selectedTicketingParticipants: {},
          bulkModeEnabledTicketingCases: {}
        };

        if (updatedState.activeTab !== state.activeTab) {
          updatedState.ticketingCases = [];
        }

        return { ...state, ...updatedState };
      }

      return state;

    case TABLE_TYPES.GET_TABLE_LIST.SUCCESS:
      if (payload?.table === 'ticketing_crew') {
        const tableState = payload.data.data.reduce(
          (acc, { on_signer_rotations, off_signer_rotations, id, ...row }) => {
            acc.ticketingCases.push(id);
            acc.ticketingCase[id] = { id, ...row, isCollapsed: false, date: row.date_of_change };

            acc.ticketingCaseRotations[id] = { on_signer: [], off_signer: [] };
            acc.ticketingCaseTripIDs[id] = [];

            const rotations = [...on_signer_rotations, ...off_signer_rotations];

            rotations.forEach(rotation => {
              // If the rotation hasn't been added yet, create it.
              if (!acc.ticketingRotation[rotation.id]) {
                acc.ticketingRotation[rotation.id] = { ...rotation };
              } else {
                // If it already exists, you might want to merge any additional data
                acc.ticketingRotation[rotation.id] = {
                  ...acc.ticketingRotation[rotation.id],
                  ...rotation
                };
              }

              // For off_signer, add the rotation id if the property exists and is not already added.
              if (rotation.off_signer) {
                if (!acc.ticketingCaseRotations[id].off_signer.includes(rotation.id)) {
                  acc.ticketingCaseRotations[id].off_signer.push(rotation.id);
                }
                if (
                  rotation.off_signer_trip_id &&
                  rotation.off_signer_trip?.is_bulk &&
                  !acc.ticketingCaseTripIDs[id].includes(rotation.off_signer_trip_id)
                ) {
                  acc.ticketingCaseTripIDs[id].push(rotation.off_signer_trip_id);
                }
              }

              // For on_signer, add the rotation id if the property exists and is not already added.
              if (rotation.on_signer) {
                if (!acc.ticketingCaseRotations[id].on_signer.includes(rotation.id)) {
                  acc.ticketingCaseRotations[id].on_signer.push(rotation.id);
                }
                if (
                  rotation.on_signer_trip_id &&
                  rotation.on_signer_trip?.is_bulk &&
                  !acc.ticketingCaseTripIDs[id].includes(rotation.on_signer_trip_id)
                ) {
                  acc.ticketingCaseTripIDs[id].push(rotation.on_signer_trip_id);
                }
              }
            });

            // Sort rotations
            if (acc.ticketingCaseRotations[id].on_signer) {
              acc.ticketingCaseRotations[id].on_signer = _orderBy(
                acc.ticketingCaseRotations[id].on_signer,
                [
                  rotationID => acc.ticketingRotation[rotationID].on_signer_trip_id,
                  rotationID =>
                    acc.ticketingRotation[rotationID].on_signer?.details?.rank?.sort_index,
                  rotationID => acc.ticketingRotation[rotationID].on_signer?.full_name
                ],
                ['asc', 'asc']
              );
            }

            if (acc.ticketingCaseRotations[id].off_signer) {
              acc.ticketingCaseRotations[id].off_signer = _orderBy(
                acc.ticketingCaseRotations[id].off_signer,
                [
                  rotationID => acc.ticketingRotation[rotationID].off_signer_trip_id,
                  rotationID =>
                    acc.ticketingRotation[rotationID].off_signer?.details?.rank?.sort_index,
                  rotationID => acc.ticketingRotation[rotationID].off_signer?.full_name
                ],
                ['asc', 'asc']
              );
            }

            acc.ticketingCaseTripIDs[id] = _orderBy(
              acc.ticketingCaseTripIDs[id],
              [tripID => tripID],
              ['asc']
            );

            return acc;
          },
          {
            ticketingCases: [],
            ticketingCase: {},
            ticketingCaseRotations: {},
            ticketingCaseTripIDs: {},
            ticketingRotation: {},
            selectedTicketingRotations: {},
            bulkModeEnabledTicketingCases: {}
          }
        );

        return {
          shouldRefetchTicketingCases: false,
          ...state,
          ...tableState
        };
      } else if (payload?.table === 'ticketing_events') {
        const tableState = payload.data.data.reduce(
          (acc, { event, event_id, id, ...row }) => {
            const { participants, vessel, port, started_at, type, name, ...rest } = event;

            acc.ticketingCases.push(id);
            acc.ticketingCase[id] = {
              id,
              vessel,
              port,
              date: started_at,
              type,
              name,
              isCollapsed: false,
              ...row
            };

            acc.ticketingCaseEvents[id] = event_id;
            acc.ticketingEvent[event_id] = { started_at, type, name, ...rest };
            acc.ticketingCaseTripIDs[id] = [];

            participants.forEach(participant => {
              if (
                participant.trip_id &&
                participant.trip?.is_bulk &&
                !acc?.ticketingCaseTripIDs[id]?.includes(participant.trip_id)
              ) {
                acc.ticketingCaseTripIDs[id]?.push(participant.trip_id);
              }
            });

            acc.ticketingEventParticipants[event_id] = _orderBy(
              participants,
              [participant => participant?.trip_id, participant => participant?.party?.full_name],
              ['asc']
            );

            acc.ticketingCaseTripIDs[id] = _orderBy(
              acc.ticketingCaseTripIDs[id],
              [tripID => tripID],
              ['asc']
            );

            return acc;
          },
          {
            ticketingCases: [],
            ticketingCase: {},
            ticketingCaseTripIDs: {},
            ticketingCaseEvents: {},
            ticketingEvent: {},
            ticketingEventParticipants: {},
            selectedTicketingParticipants: {},
            bulkModeEnabledTicketingCases: {}
          }
        );

        return {
          shouldRefetchTicketingCases: false,
          ...state,
          ...tableState
        };
      }

      return state;

    case TYPES.TOGGLE_TICKETING_ROW_BULK_MODE:
      return {
        ...state,
        bulkModeEnabledTicketingCases: {
          ...state.bulkModeEnabledTicketingCases,
          [payload.ticketingCaseID]: payload.enabled
        }
      };

    case TYPES.BULK_SELECT_TICKETING_ROTATION:
      const isRotationSelected =
        _get(
          state.selectedTicketingRotations,
          `${payload.ticketingCaseID}.${payload.type}.${payload.rotationID}`
        ) === true;

      const selectedRotations = { ...state.selectedTicketingRotations };

      selectedRotations[payload.ticketingCaseID] = {
        ...(selectedRotations[payload.ticketingCaseID] || {}),
        [payload.type]: {
          ...(selectedRotations[payload.ticketingCaseID]?.[payload.type] || {}),
          [payload.rotationID]: !isRotationSelected
        }
      };

      return {
        ...state,
        selectedTicketingRotations: selectedRotations
      };

    case TYPES.CLEAR_SELECTED_TICKETING_ROTATIONS:
      return {
        ...state,
        selectedTicketingRotations: {
          ...state.selectedTicketingRotations,
          [payload.ticketingCaseID]: {}
        }
      };

    case TYPES.BULK_SELECT_TICKETING_PARTICIPANT:
      const isParticipantSelected =
        _get(
          state.selectedTicketingParticipants,
          `${payload.ticketingCaseID}.${payload.eventParticipantID}`
        ) === true;

      const selectedParticipants = { ...state.selectedTicketingParticipants };

      selectedParticipants[payload.ticketingCaseID] = {
        ...(selectedParticipants[payload.ticketingCaseID] || {}),
        [payload.eventParticipantID]: !isParticipantSelected
      };

      return {
        ...state,
        selectedTicketingParticipants: selectedParticipants
      };

    case TYPES.CLEAR_SELECTED_TICKETING_PARTICIPANTS:
      return {
        ...state,
        selectedTicketingParticipants: {
          ...state.selectedTicketingParticipants,
          [payload.ticketingCaseID]: {}
        }
      };

    // Trip
    case TYPES.OPEN_TRIP_DRAWER:
      return {
        ...state,
        shouldRefetchTicketingCases: false,
        tripDrawerIsOpen: true,
        tripIDs: payload.tripIDs || null,
        tripCaseID: payload.ticketingCaseID || null,
        tripType: payload.type || null,
        tripParties: payload.parties || [],
        tripOffers: [],
        tripDisabled: payload.disabled || false,
        tripVisibleParties: payload.tripVisibleParties || [],
        tripIsCollapsed: {}
      };

    case TYPES.CLOSE_TRIP_DRAWER:
      return {
        ...state,
        tripDrawerIsOpen: false
      };

    case TYPES.UPDATE_TRIP_PARTY:
      return {
        ...state,
        tripParties: state.tripParties.map((p, index) =>
          index === payload.index || p.person?.id === payload.personId
            ? { ...p, ...payload.data }
            : p
        )
      };

    case TYPES.UPDATE_TRIP_PARTIES:
      if (payload.isNew) {
        return {
          ...state,
          tripParties: [...state.tripParties, payload.party]
        };
      } else {
        return {
          ...state,
          tripParties: state.tripParties.filter((_, index) => index !== payload.index)
        };
      }

    case TYPES.UPDATE_TRIP_DATA:
      return {
        ...state,
        tripData: {
          ...(state.tripData || {}),
          ...payload
        }
      };

    case TYPES.COLLAPSE_TRIP:
      return {
        ...state,
        tripIsCollapsed: {
          ...state.tripIsCollapsed,
          [payload.tripID]: state.tripIsCollapsed[payload.tripID] ? false : true
        }
      };

    case TYPES.GET_TRIP.SUCCESS:
      if (payload) {
        const {
          id,
          parties,
          port,
          vessel,
          offers,
          master_offers,
          started_at,
          created_at,
          created_by,
          updated_at,
          updated_by,
          is_bulk
        } = payload;

        const tripOffers = _orderBy(
          _reduce(
            master_offers || offers,
            (acc, { routes, transportation_type, ...offer }) => {
              const ticket = {
                ...offer,
                rotation_id: payload.rotation_id,
                is_bulk,
                participants_count: parties?.length,
                trip_id: id,
                transportation_type,
                routes: routes.map(r => {
                  const { arrival, departure, from_airport, to_airport, from, to, ...rest } = r;

                  return {
                    arrival,
                    departure,
                    from: transportation_type === 'air' ? from_airport : from,
                    to: transportation_type === 'air' ? to_airport : to,
                    ...rest
                  };
                })
              };

              acc.push(ticket);
              return acc;
            },
            []
          ),
          ['accepted', 'routes[0].departure'],
          ['desc', 'asc']
        );

        const formattedParties = parties.map(person => ({
          person,
          port,
          vessel,
          trip_id: id,
          started_at,
          type: person?.pivot?.ticketing_type || null
        }));

        return {
          ...state,
          logDetails: {
            created_at,
            created_by,
            updated_at,
            updated_by
          },
          tripIDs: !state.tripIDs ? [id] : state.tripIDs,
          tripParties:
            state.tripIDs?.length === 1 || !state.tripIDs
              ? formattedParties
              : [...state.tripParties, ...formattedParties],
          tripOffers:
            state.tripIDs?.length === 1 || !state.tripIDs
              ? tripOffers
              : [...state.tripOffers, ...tripOffers],
          tripData: {
            port,
            vessel,
            started_at
          }
        };
      } else {
        return state;
      }
    //

    case TYPES.CREATE_TRIP.SUCCESS:
      return {
        ...state,
        bulkModeEnabledTicketingCases: {
          ...state.bulkModeEnabledTicketingCases,
          [payload.tripCaseID]: false
        },
        selectedTicketingRotations: {
          ...state.selectedTicketingRotations,
          [payload.tripCaseID]: {}
        },
        shouldRefetchTicketingCases: true
      };

    case TYPES.DELETE_TRIP.SUCCESS:
    case TYPES.ADD_NEW_TICKET_TO_TRIP.SUCCESS:
    case TYPES.UPDATE_TRIP_TICKET.SUCCESS:
    case TYPES.ACCEPT_TRIP_TICKET.SUCCESS:
    case TYPES.DELETE_TRIP_TICKET.SUCCESS:
    case TYPES.DELETE_TRIP_PARTICIPANT.SUCCESS:
      return { ...state, shouldRefetchTicketingCases: true };

    case TYPES.EDIT_TRIP.START:
      return {
        ...state,
        tripIsEditting: true
      };

    case TYPES.EDIT_TRIP.SUCCESS:
    case TYPES.EDIT_TRIP.ERROR:
      return {
        ...state,
        tripIsEditting: false,
        shouldRefetchTicketingCases: true
      };

    case TYPES.TICKETING_CASE_COLLAPSE:
      return {
        ...state,
        ticketingCase: {
          ...state.ticketingCase,
          [payload.ticketingCaseID]: {
            ...state.ticketingCase[payload.ticketingCaseID],
            isCollapsed: payload.collapsed
          }
        }
      };

    case TYPES.TICKETING_CASE_ALL_COLLAPSE:
      return {
        ...state,
        ticketingCase: {
          ...state.ticketingCase,
          ...state.ticketingCases.reduce(
            (acc, curr) => ({
              ...acc,
              [curr]: {
                ...state.ticketingCase[curr],
                isCollapsed: payload
              }
            }),
            {}
          )
        }
      };

    case TYPES.EDIT_TICKETING_CASE.SUCCESS:
      return {
        ...state,
        ticketingCase: {
          ...state.ticketingCase,
          [payload.ticketingCaseID]: {
            ...state.ticketingCase[payload.ticketingCaseID],
            ...payload.data
          }
        }
      };

    case TYPES.SET_TRIP_PREVENTION_MODAL:
      return {
        ...state,
        preventionModal: !payload ? { open: false, message: '' } : payload
      };

    case TYPES.SET_TRIP_HAS_VALIDATION_ERRORS:
      return {
        ...state,
        tripHasValidationErrors: payload
      };

    case TYPES.GET_TICKET_OFFER_TOTALS.SUCCESS:
    case TYPES.GET_TICKET_OFFER_TOTALS.ERROR:
      return {
        ...state,
        ticketOfferTotals: {
          price: payload?.total_price || 0,
          count: payload?.total_count || 0
        }
      };
    case TYPES.GET_TICKETS_TOTALS.SUCCESS:
      return {
        ...state,
        ticketsTotals: payload || {}
      };
    default:
      return state;
  }
};

export default reducer;
