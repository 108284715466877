import { FC } from 'react';
import { SupplierStateType } from '@/common/types/purchasing.ts';
import clsx from 'clsx';

const selectFlagStyles = {
  pending_approval: {
    bgColor: 'warning',
    textColor: 'white',
    type: 'solid',
    label: 'FOR APPROVAL'
  },
  rejected: {
    bgColor: 'red',
    textColor: 'red',
    type: 'outline',
    label: 'REJECTED'
  },
  canceled_by_company: {
    bgColor: 'red',
    textColor: 'white',
    type: 'solid',
    label: 'CANCELED BY CO.'
  },
  canceled_by_supplier: {
    bgColor: 'red',
    textColor: 'white',
    type: 'solid',
    label: 'CANCELED BY SUP.'
  },
  confirmed: {
    bgColor: 'royal-blue',
    textColor: 'white',
    type: 'solid',
    label: 'CONFIRMED'
  },
  approved: {
    bgColor: 'turquoise',
    textColor: 'white',
    type: 'solid',
    label: 'APPROVED'
  }
};

type SupplierStateProps = {
  supplierState: SupplierStateType;
  className?: string;
};

const SupplierState: FC<SupplierStateProps> = ({ supplierState, className }) => {
  if (!supplierState) return null;

  const { bgColor, textColor, type, label } = selectFlagStyles[supplierState];

  return (
    <div
      className={clsx(
        'd-inline-flex',
        'align-items-center',
        'fs-10',
        'max-w-fit',
        'fw-medium',
        `text-${textColor}`,
        'h-20',
        'px-1',
        'rounded-3',
        'text-nowrap',
        'overflow-hidden',
        {
          [`border border-${bgColor}`]: type === 'outline',
          [`bg-${bgColor}`]: type === 'solid'
        },
        className
      )}
    >
      <span className="text-truncate d-inline-block">{label}</span>
    </div>
  );
};

export default SupplierState;
