import { useSelector } from 'react-redux';
import SelectWithValidation from 'captain-reports/templates/inputs/SelectWithValidation';

import { selectCaptainReportTemplateFuelGrades } from 'captain-reports/store/selectors';

const TemplateFuelGradeField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const fuel_grades = useSelector(selectCaptainReportTemplateFuelGrades);

  return (
    <SelectWithValidation
      field={{
        ...field,
        selectRest: {
          options: fuel_grades,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name
        }
      }}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      {...field}
    />
  );
};

export default TemplateFuelGradeField;
