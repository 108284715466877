import { SelectFieldFunc } from '@/common/types/form';
import { FC } from 'react';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignVesselAssignmentToGroup } from '@/store/settings/selectors';
import SystemGroupSelect from '@/common/components/pms/setup/views/system/specifications/system-details/_components/SystemGroupSelect.tsx';

type GroupFormFieldProps = {
  selectField: SelectFieldFunc;
  value: number | null;
  error: string | null;
  isSubSystem?: boolean;
};

const GroupFormField: FC<GroupFormFieldProps> = ({ value, error, selectField, isSubSystem }) => {
  const isGroupVisible = useAppSelector(selectPmsAssignVesselAssignmentToGroup);

  if (isSubSystem) return null;

  return (
    isGroupVisible && (
      <SystemGroupSelect
        onChange={value => selectField('group')(value)}
        value={value}
        error={error}
      />
    )
  );
};

export default GroupFormField;
