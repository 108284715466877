import { VesselSystemBase } from '@/common/types/vessel-systems';
import { VesselBase } from '@/common/types/vessel';
import { useState } from 'react';
import { FC } from 'react';

import LinkedTabModuleActions from '@/common/components/link-entities/_components/LinkedTabModuleActions';
import LinkedVesselSystems from './LinkedVesselSystems';
import SelectionForm from './SelectionForm';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import link from '@/common/assets/svg/jobs/link.svg';

type LinkedVesselSystemsEntitiesProps = {
  vessel: VesselBase;
  linkedVesselSystems: VesselSystemBase[];
  tabLabel: string;
  addVesselSystem: (vesselSystem: VesselSystemBase) => void;
  removeVesselSystem: (index?: number) => void;
  cancelTabAction: (label: string) => void;
};

const LinkedVesselSystemsEntities: FC<LinkedVesselSystemsEntitiesProps> = ({
  linkedVesselSystems,
  vessel,
  tabLabel,
  addVesselSystem,
  removeVesselSystem,
  cancelTabAction
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isSelectingVesselSystem, setIsSelectingVesselSystem] = useState(
    !linkedVesselSystems?.length ? true : false
  );
  const [showCancelButton, setShowCancelButton] = useState(!linkedVesselSystems?.length);

  return (
    <>
      <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <div
          className={`d-flex align-items-center height-20 ${isSelectingVesselSystem ? 'mb-1' : ''}`}
        >
          <div className="flex-1 form-label mb-0">LINKED VESSEL SYSTEMS</div>
          {tabLabel ? (
            <LinkedTabModuleActions
              showDeleteButton={!showCancelButton && isHovering}
              showCancelButton={showCancelButton}
              onCancel={() => cancelTabAction(tabLabel)}
              onDelete={() => removeVesselSystem()}
            />
          ) : null}
        </div>

        <LinkedVesselSystems
          vesselSystems={linkedVesselSystems}
          removeVesselSystem={index => {
            removeVesselSystem(index);
            setShowCancelButton(false);
          }}
        />
      </div>

      {isSelectingVesselSystem ? (
        <SelectionForm
          linkedVesselSystems={linkedVesselSystems}
          vessel={vessel}
          onSelect={(vesselSystem: VesselSystemBase) => {
            addVesselSystem(vesselSystem);
            setIsSelectingVesselSystem(false);
          }}
        />
      ) : null}

      {!isSelectingVesselSystem && (
        <CircledButton
          className="mt-1"
          icon={link}
          label="Link to vessel system"
          onClick={() => setIsSelectingVesselSystem(true)}
          svgColor="white"
          svgStyle={{ width: 10, height: 10 }}
          size={20}
        />
      )}
    </>
  );
};

export default LinkedVesselSystemsEntities;
