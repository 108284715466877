import { useMemo } from 'react';
import { useFormState } from 'common/utils/hooks/useForm';
import NumberInput from 'common/components/form/inputs/NumberInput';
import { Row, Col } from 'reactstrap';
import {
  selectSelectedSystem,
  selectSparePartsList
} from 'common/components/pms/setup/store/selectors';
import CircledButton from 'common/components/buttons/CircledButton';
import Select from '@/ts-common/components/form/inputs/select';
import { useAppSelector } from '@/store/hooks';
import { SparePartOption } from '@/common/components/selectors/_helpers';

const SparePart = ({ formState, onRemove, sparePartsState }) => {
  const { fields, selectField } = useFormState(formState);
  const selectedSystem = useAppSelector(selectSelectedSystem);
  const sparePartsList = useAppSelector(selectSparePartsList);

  const triggerChange = useMemo(() => {
    const result = sparePartsState.map(el => el?.state?.spare_part_id?.value?.id).filter(el => el);

    return { exclude_ids: result || [], systemId: selectedSystem };
  }, [sparePartsState?.length, selectedSystem, sparePartsList.length]);

  return (
    <Row className="spare-part mb-1">
      <Col xs={9}>
        <Select
          className={`mb-0 fs-12 spare-part-select`}
          placeholder="Select part"
          error={null}
          isAsync
          memoizedRequestParams={{
            path: 'lists',
            params: {
              list: 'spare-parts',
              exclude_ids: triggerChange.exclude_ids,
              vessel_system_id: triggerChange.systemId,
              with_related: true
            }
          }}
          onChange={opt => (opt ? selectField('spare_part_id')(opt) : null)}
          isClearable
          noOptionsMessage={({ inputValue }) =>
            !inputValue.length ? `Search for parts` : `No part found`
          }
          components={{ Option: SparePartOption }}
          getOptionLabel={option => `${option.part_no}. ${option.description}`}
          {...fields.spare_part_id}
        />
      </Col>
      <Col xs={3}>
        <div className="d-flex align-items-center">
          <NumberInput
            placeholder="Add no."
            className="mb-0 spare-part-input me-1"
            onChange={e => selectField('quantity')(e.target.value)}
            allowNegative={false}
            decimalScale={0}
            {...fields.quantity}
          />
          <CircledButton
            onClick={onRemove}
            type="remove"
            className="job-circled-btn "
            svgStyle={{ height: 6, width: 6 }}
            style={{ width: 16, height: 16 }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SparePart;
