import { useCallback, useState } from 'react';

type SortingStateType = {
  [k: string]: 'asc' | 'desc' | undefined;
};

const useCustomSorting = () => {
  const [sorting, setSorting] = useState<SortingStateType | null>(null);

  const handleColumnSorting = useCallback((key: string) => {
    setSorting(prevSorting => {
      if (!key) return prevSorting;

      const prevColumnSortingOrder = prevSorting?.[key];

      if (prevColumnSortingOrder) {
        if (prevColumnSortingOrder === 'asc') return { [key]: 'desc' };
        if (prevColumnSortingOrder === 'desc') return {};
      }

      return { [key]: 'asc' };
    });
  }, []);

  return { sorting, handleColumnSorting };
};

export default useCustomSorting;
