import SystemDetails from './system-details';
import SpareParts from './spare-parts';
import Jobs from './jobs';
import permissions from '@/common/utils/permissions/constants';
import AuthCheck from '@/components/permissions/AuthCheck';

const Specifications = ({ selectedTab, systemFormType }) => {
  return selectedTab ? null : (
    <div className="pms__container gray-scrollbar right-side mt-1">
      <div className="px-2 pb-2">
        <AuthCheck permissions={[permissions.office.pms.library.systems.view]}>
          <SystemDetails systemFormType={systemFormType} />
        </AuthCheck>
        {systemFormType === 'sub' || systemFormType === 'system' ? null : (
          <>
            <SpareParts systemFormType={systemFormType} />
            <Jobs systemFormType={systemFormType} />
          </>
        )}
      </div>
    </div>
  );
};

export default Specifications;
