import { Status } from '@/common/types/enums';
import { FC } from 'react';

type JobStatusProps = {
  activeStatus?: Status;
  className?: string;
};

const JobStatus: FC<JobStatusProps> = ({ activeStatus, className = '' }) => {
  return activeStatus ? (
    <span className={`view-status view-status--${activeStatus.color} ${className}`}>
      {activeStatus.name}
    </span>
  ) : null;
};

export default JobStatus;
