import { FC } from 'react';

import deactivateIcon from 'common/assets/svg/actions/deactivate.svg';
import activateIcon from 'common/assets/svg/common/check-circle.svg';

import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';

type ActivationModalProps = {
  isOpen: boolean;
  isActive: boolean;
  onAccept: () => void;
  onCancel: () => void;
  jobTitle?: string;
};

const ActivationModal: FC<ActivationModalProps> = ({
  isOpen,
  isActive,
  onAccept,
  onCancel,
  jobTitle
}) => {
  return (
    <DangerousActionModal
      isOpen={isOpen}
      icon={isActive ? deactivateIcon : activateIcon}
      onAccept={onAccept}
      onCancel={onCancel}
      cancelButtonText="CANCEL"
      acceptButtonText={isActive ? 'DEACTIVATE' : 'ACTIVATE'}
      acceptButtonBackground={isActive ? 'red' : 'turquoise'}
      header={`${isActive ? 'Deactivate' : 'Activate'} Job`}
      svgColor={isActive ? 'red' : 'turquoise'}
    >
      Are you sure you want to {isActive ? 'deactivate' : 'activate'}{' '}
      <strong>&quot;{jobTitle}&quot;</strong> for this vessel assignment?
    </DangerousActionModal>
  );
};

export default ActivationModal;
