import { get, post } from '@/api';
import { CaptainReportsAlarmsDue, CaptainReportSummary } from '@/common/types/captain-reports';
import { Event } from '@/common/types/events';

type createOffHireEventFromStoppageReportParamsType = {
  id: number;
  template_id: number;
  name: string;
};

export const createOffHireEventFromStoppageReport = async ({
  id,
  ...rest
}: createOffHireEventFromStoppageReportParamsType) => {
  return (await post<Event>(`/captain-reports/${id}/stoppage/off-hire`, rest)).data;
};

export type GetCaptainReportsAlarmsDueParams = { vessel_id?: number };

export const getCaptainReportsAlarmsDue = async (params: GetCaptainReportsAlarmsDueParams) => {
  return (await get<CaptainReportsAlarmsDue>(`/captain-report-alarms-due`, params)).data;
};

export type GetCaptainReportSummaryParams = { report_id: number; vessel_id: number };

export const getCaptainReportSummary = async (params: { report_id: number; vessel_id: number }) => {
  return (
    await get<CaptainReportSummary>(
      `/vessels/${params.vessel_id}/captain-reports/summary?report_id=${params.report_id}`
    )
  ).data;
};
