import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table, { useTable } from 'common/components/table';
import edit from 'common/assets/svg/actions/edit.svg';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import Form from './Form';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import bin from 'common/assets/svg/common/bin.svg';
import { useDispatch } from 'react-redux';
import { deleteSystemAttributesGroup } from 'common/components/pms/setup/store/actions';
import permissions from '@/common/utils/permissions/constants';

const SystemAttributes = ({ label, actionDrawer }) => {
  const [activeGroup, setActiveGroup] = useState(null);

  const dispatch = useDispatch();

  const table = useTable({
    label,
    columns: [
      {
        header: 'Name',
        key: 'name',
        width: 4,
        canFilter: false,
        sort: true
      },
      {
        header: 'Attributes',
        key: 'attributes',
        width: 8,
        canFilter: false,
        sort: false
      },
      { key: 'actions', field: 'actions' }
    ]
  });

  return (
    <Table
      loader
      hideTableFilters
      hideTableSearch
      drawer={
        <Form
          drawer={actionDrawer}
          refetchData={table.refetchData}
          active={activeGroup}
          setActive={setActiveGroup}
        />
      }
      rows={{
        attributes: data => (
          <NamesInTooltip names={data.attributes.map(attr => attr.name)} maxLimit={3} />
        ),
        actions: data => ({
          options: [
            {
              label: 'Edit System Attributes Group',
              icon: edit,
              onClick: () => {
                setActiveGroup(data);
                actionDrawer.open();
              },
              permissions: [permissions.office.pms.configuration.system_attributes.edit]
            },
            {
              label: 'Delete System Attributes Group',
              icon: bin,
              preventionModal: true,
              svgStyle: { width: 11, height: 11 },
              permissions: [permissions.office.pms.configuration.system_attributes.edit],
              modalProps: {
                onAccept: async () => {
                  await dispatch(deleteSystemAttributesGroup({ id: data.id }));
                  table.refetchData();
                },
                header: 'Delete System Attributes Group',
                actionText: 'DELETE',
                action: 'remove',
                actionHoverColor: 'delete',
                body: (
                  <div className="text-center">
                    You are about to delete <strong>{data.name}</strong>.
                    <br />
                    Are you sure you want to continue?
                  </div>
                )
              }
            }
          ]
        })
      }}
      {...table}
    />
  );
};

SystemAttributes.propTypes = {
  label: PropTypes.string.isRequired,
  actionDrawer: DrawerState.isRequired
};

export default SystemAttributes;
