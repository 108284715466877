import { stringField, optionField, booleanField, numberField } from 'common/utils/form/fieldTypes';

const config = {
  part_no: stringField({ required: true }),
  part_position_no: stringField(),
  part_unit: optionField(),
  description: stringField(),
  ihm_related: booleanField({ initialValue: false }),
  drawing_no: stringField(),
  drawing_file: optionField(),
  attachments: optionField({ initialValue: [] }),
  vessel_system_group_id: optionField(),

  comments: stringField(),
  submaker_id: optionField(),
  submaker_part_no: stringField(),
  nox_technical_file_imo_no: stringField(),
  minimum_required_rob: numberField(),
  optimal_rob: numberField(),
  is_rob_requirement_official: booleanField({ initialValue: false })
};

export default config;
