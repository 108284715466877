import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useFormState } from 'utils/hooks';

import CollapseBox from 'common/components/collapse/CollapseBox';
import CircledButton from 'common/components/buttons/CircledButton';
import { selectInventoryDepletionMethod } from 'common/components/mga/store/selectors';
import Information from 'common/components/general/Information';
import { getMgaDepletionMethodInfo } from 'common/utils/depletion-method';
import infoLineIcon from 'common/assets/svg/common/info-line.svg';

import _get from 'lodash/get';
import { useMemo } from 'react';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import Fifo from './fifo';

const AccountGroup = ({ formState, startedAt }) => {
  const { fields, subStates, addSubform, removeSubform } = useFormState(formState);
  const itemsSubState = subStates('items');
  const inventoryDepletionMethodLabel = useSelector(selectInventoryDepletionMethod);
  const depletionMethodInfo = getMgaDepletionMethodInfo(inventoryDepletionMethodLabel);

  const pricePerUnitTotal = useMemo(() => {
    return itemsSubState.reduce((acc, curr) => {
      const total =
        (strToNumber(curr?.state?.price_per_unit?.value) || 0) *
        (strToNumber(curr?.state?.quantity?.value) || 0);

      return acc + total;
    }, 0);
  }, [itemsSubState]);

  const account = _get(fields, 'account.value');

  return (
    <CollapseBox
      className={`bg-light-gray rounded-lg mb-1`}
      header={
        <div className="fs-12  text-violet mb-0">
          <Row className="fs-12 text-primary mt-1 mb-1 align-items-center">
            <Col
              className={`${fields.is_crew.value ? 'ps-3' : ''} `}
            >{`${account?.code}. ${account?.name}`}</Col>

            {!account?.is_parent_of_crew_accounts ? (
              <>
                <Col xs="auto" className="cpe-6">
                  <strong>$</strong>
                </Col>
                <Col xs={2} className="me-3 pe-2 d-flex justify-content-end fw-medium">
                  <div className="cpe-4">{numberToStr(pricePerUnitTotal, 2, 2)}</div>
                </Col>
              </>
            ) : null}
          </Row>
        </div>
      }
      innerExpandButton={null}
      bodyClassName="rounded-lg"
      headerClassName="cms-12 cme-6 px-0 mb-0"
      defaultOpen={true}
      disableCollapse
      hideExpandButton
      bodyInnerClassName="cps-12 pe-0 cpb-4"
    >
      <div className="d-flex align-items-center text-violet fs-12 mb-2 cpb-6" id="depletion-method">
        <Information
          className={'cme-12'}
          svgStyle={{ width: 14, height: 14 }}
          tooltipClassname="min-width-fit max-width-412"
          svgIcon={infoLineIcon}
          message={depletionMethodInfo.subTitle}
        />
        <div>*Add Items in your inventory | Inventory Depletion Method:</div>
        <div className="fw-bold cms-4 me-1 cpe-6">{depletionMethodInfo.name || '-'}</div>
      </div>

      {inventoryDepletionMethodLabel === 'fifo' ? (
        <Fifo
          subState={itemsSubState}
          removeSubform={removeSubform}
          accountId={account?.id}
          startedAt={startedAt}
        />
      ) : null}

      <CircledButton
        onClick={() => addSubform('items')}
        type={'add'}
        style={{ width: 16, height: 16 }}
        label="Add item"
        svgStyle={{ width: 6, height: 6 }}
        className="cmt-2 mb-1"
      />
    </CollapseBox>
  );
};

export default AccountGroup;
