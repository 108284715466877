import VesselCiiReferenceYearlyTooltip from '@/common/components/general/VesselCiiReferenceYearlyTooltip';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';

import { numberToStr } from '@/ts-common/utils/numbers';
import { CiiDataType } from '@/common/types/reports-enviromental';

type BodyCellCiiValueProps<T, C> = {
  column: C;
  data: T;
  className?: string;
};

function BodyCellCiiValue<
  T extends { cii?: CiiDataType },
  C extends { key: string; data_key?: string }
>({ data, column, className }: BodyCellCiiValueProps<T, C>) {
  const dataValue = _get(data, column.data_key || column.key);
  const ciiValue = (
    dataValue && _isObject(dataValue) && 'cii' in dataValue ? dataValue : null
  ) as CiiDataType | null;

  const referenceBoundaries = _get(ciiValue, 'reference.boundaries');
  const referenceYear = _get(ciiValue, 'reference.year');
  const referenceCii = _get(ciiValue, 'reference.cii');
  const rating = _get(ciiValue, 'rating');
  const cii = _get(ciiValue, 'cii');

  return ciiValue ? (
    <VesselCiiReferenceYearlyTooltip
      className={className}
      placement="right"
      boundaries={referenceBoundaries}
      year={referenceYear}
      cii={referenceCii}
      renderComponent={() => (
        <div className="d-flex align-items-center">
          {cii !== null ? (
            <>
              <div className={`cii-rating-${rating} fw-bold`}>{rating}</div>
              {rating ? <div className="cms-4 cme-4">-</div> : null}
            </>
          ) : null}
          <div>{numberToStr(cii, 2, 2)}</div>
        </div>
      )}
    />
  ) : (
    '-'
  );
}

export default BodyCellCiiValue;
