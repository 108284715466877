import { useEffect, useCallback } from 'react';
import {
  getReviewProcessNotes,
  deleteReviewProcessNote
} from 'common/components/review-process/store/actions';
import { useDispatch } from 'react-redux';
import ShadowBox from 'common/components/general/ShadowBox';
import Header from 'common/components/jobs/_base/modules/notes/components/Header';
import { PreviewHtml } from 'common/components/form/inputs/Editor';
import PropTypes from 'prop-types';
import { NoteState } from 'common/entities/notes/NoteTypes';
import { NoteEntityTypes } from 'common/entities/notes/NoteTypes';
import Form from 'common/components/review-process/components/notes/form/index';
import Tag from '@/common/components/general/Tag.tsx';
import { useAppSelector } from '@/store/hooks';

const Preview = ({
  note,
  isLoading,
  activeNote,
  setActiveNote,
  reviewProcessId,
  setShowForm,
  fields,
  selectField,
  loadValues,
  closeForm,
  index
}) => {
  const dispatch = useDispatch();

  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const load = useCallback(() => {
    loadValues(note);
  }, [loadValues, note]);

  const onEdit = () => {
    setShowForm(false);
    setActiveNote(note);
  };

  const onDelete = async () => {
    try {
      if (reviewProcessId) {
        await dispatch(deleteReviewProcessNote({ id: reviewProcessId, note_id: note.id })).unwrap();

        const res = await dispatch(getReviewProcessNotes({ id: reviewProcessId })).unwrap();

        if (!res?.length) {
          setShowForm(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    load();
  }, [load]);

  if (
    activeNote &&
    (reviewProcessId ? activeNote.id === note.id : activeNote?.index === note?.index)
  ) {
    return (
      <Form
        isLoading={isLoading}
        setActiveNote={setActiveNote}
        activeNote={activeNote}
        note={note}
        reviewProcessId={reviewProcessId}
        fields={fields}
        selectField={selectField}
        loadValues={loadValues}
        closeForm={closeForm}
      />
    );
  }

  return (
    <div className="mb-3">
      <Header note={note} onEdit={onEdit} onDelete={onDelete} />

      <ShadowBox className="d-flex flex-column h-100p position-relative p-1" color="light-1" flat>
        <div className="flex-1  px-2 pb-2">
          <PreviewHtml value={note?.comments} />
        </div>
        {isOnBoard ? null : (
          <div className="flex-1  px-2">
            {note?.tags?.length ? (
              <Tag
                className="mb-0"
                tags={note.tags.map(t => t?.name)}
                type={'note'}
                invisible
                disabled
              />
            ) : null}
          </div>
        )}
      </ShadowBox>
    </div>
  );
};

Header.propTypes = {
  note: NoteState,
  activeNote: NoteState,
  closeForm: PropTypes.func,
  setActiveNote: PropTypes.func,
  isLoading: PropTypes.bool,
  reviewProcessId: PropTypes.number.isRequired,
  entityType: NoteEntityTypes,
  setShowForm: PropTypes.func,
  index: PropTypes.number
};

export default Preview;
