import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorBoundaryFallback from '../../ErrorBoundaryFallback';

import { useAppSelector } from '@/store/hooks';
import { selectAccount } from '@/store/account/selectors-ts';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  enabledReleaseStages: ['production', 'staging', 'preproduction'],
  plugins: [new BugsnagPluginReact()],
  appVersion: import.meta.env.VITE_APP_VERSION,
  releaseStage: import.meta.env.VITE_RELEASE_STAGE
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const BugsnagHOC = props => {
  const [showRenderErrorBoundaryFallback, setShowRenderErrorBoundaryFallback] = useState(true);

  const account = useAppSelector(selectAccount);

  if (account) Bugsnag.setUser(`${account.id}`, account.email, account.full_name);

  const Fallback = () =>
    showRenderErrorBoundaryFallback ? <ErrorBoundaryFallback history={props.history} /> : null;

  useEffect(() => {
    window.addEventListener('vite:preloadError', event => {
      // https://vite.dev/guide/build.html#load-error-handling
      event.preventDefault();
      event.stopPropagation();
      setShowRenderErrorBoundaryFallback(false);

      if (
        window.confirm(
          'The page content has been updated. A refresh is required to see the latest changes. Would you like to refresh now?'
        )
      ) {
        window.location.reload();
      }
    });
  }, []);

  return <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>;
};

export default BugsnagHOC;
