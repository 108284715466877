import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';

import FileContainer from './FileContainer';
import ProgressLoader from './ProgressLoader';

import { BlobFile, Size } from '@/ts-common/types/files';
import { FC, ReactNode, useCallback } from 'react';

import add from '@/ts-common/assets/svg/actions/add.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import Tooltip from '@/ts-common/components/general/Tooltip';

type UploadedButtonTypes = {
  size: Size;
  onUpload: (acceptedFiles: BlobFile[]) => void;
  isLoading: boolean;
  uploadError?: string | null;
  uploadText?: ReactNode;
  singleUpload?: boolean;
  color?: string;
  accept?: string;
};

const spacing: {
  [key in 'lg' | 'sm' | 'xs']: string;
} = { lg: '26%', sm: '10%', xs: '10%' };

const UploadedButton: FC<UploadedButtonTypes> = ({
  size,
  onUpload,
  isLoading,
  uploadError,
  uploadText,
  singleUpload,
  color,
  accept
}) => {
  const { tooltipID, avoidRender } = useTooltipID();
  const buttonSize = size === 'full' ? 'sm' : size;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => onUpload(acceptedFiles)
  });

  const getSelectedFiles = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) return Array.from(event.target.files);

    return [];
  }, []);

  return (
    <div
      className={`d-flex flex-column ${buttonSize === 'lg' && !singleUpload ? 'mb-1' : ''} text-${
        uploadError ? 'red' : color
      }`}
      data-testid="files-upload-button"
    >
      <div className="d-flex align-items-center">
        <FileContainer size={buttonSize} id={tooltipID}>
          <Button
            active={isDragActive}
            className="d-flex align-items-center justify-content-center"
            style={{ padding: spacing[buttonSize] }}
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              onChange={e => onUpload(getSelectedFiles(e))}
              disabled={isLoading}
              accept={accept ? accept : undefined}
              multiple={singleUpload ? false : true}
            />
            <Icon className="d-flex align-items-center justify-content-center">
              <SvgRender
                className="justify-content-center"
                src={add}
                style={{ width: '100%', height: '100%' }}
              />
            </Icon>
            {isLoading && <ProgressLoader size={buttonSize} />}
          </Button>
        </FileContainer>
        {(buttonSize === 'lg' || uploadText) && (
          <div className="ms-1 text-primary fs-12 fw-medium">
            {uploadText || `Upload file${!singleUpload ? '(s)' : ''}`}
          </div>
        )}
      </div>

      {uploadError && buttonSize === 'lg' && (
        <FileContainer size={buttonSize} height="initial">
          <div className="text-truncate text-nowrap fs-10 fw-normal cmt-2 cursor-pointer">
            {uploadError}
          </div>
        </FileContainer>
      )}

      {!avoidRender && uploadError ? (
        <Tooltip fade={false} target={tooltipID} innerClassName="fs-12">
          {uploadError}
        </Tooltip>
      ) : null}
    </div>
  );
};

const Button = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-width: 1px;
  border-color: currentColor;
  border-style: ${({ active }: { active?: boolean }) => (active ? 'solid' : 'dashed')};
  border-radius: inherit;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? 'rgba(53,64,105,0.1)' : 'rgba(53, 64, 105, 0.05)'};
  cursor: pointer;

  &:hover {
    border-style: solid;
  }
`;

const Icon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: white;
  padding: 25%;
`;

export default UploadedButton;
