import { useEffect } from 'react';
import _isString from 'lodash/isString';

const blockedElements = ['redactor-dropdown', 'SingleDatePicker_picker', 'notifications-wrapper'];

function useOnClickOutside(
  ref: React.RefObject<HTMLElement> | string,
  handler: (event: MouseEvent | TouchEvent) => void,
  onClickInside?: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const currentRef = _isString(ref) ? document.querySelector(ref) : ref.current;

    const containsBlockedElement = (element: HTMLElement | null): boolean => {
      if (!element) {
        return false;
      }

      return (
        blockedElements.some(className => element.classList.contains(className)) ||
        blockedElements.some(className => element.closest(`.${className}`))
      );
    };

    const listener = (event: MouseEvent | TouchEvent) => {
      const targetElement = event.target as HTMLElement;

      // Do nothing if clicking ref's element or descendent elements
      if (
        !currentRef ||
        currentRef.contains(targetElement) ||
        containsBlockedElement(targetElement)
      ) {
        if (onClickInside) {
          onClickInside(event);
        }
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener, true);
    document.addEventListener('touchstart', listener, true);

    return () => {
      document.removeEventListener('mousedown', listener, true);
      document.removeEventListener('touchstart', listener, true);
    };
  }, [ref, handler, onClickInside]);
}

export default useOnClickOutside;
