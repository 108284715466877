import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import _get from 'lodash/get';
import {
  selectBunkerConsumptions,
  selectConsumptionBunkerId,
  selectConsumptionFuelGrade,
  selectPreviousCaptainReportBunkerPerFuelGrade,
  selectViscosityOfFuelConsumedOptions
} from 'captain-reports/store/selectors';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import * as reportActions from 'captain-reports/store/actions';
import useValue from 'captain-reports/utils/useValue';
import SelectWithValidation from 'captain-reports/templates/inputs/SelectWithValidation';
import { components } from 'react-select';
import { selectRob } from 'captain-reports/templates/components/helpers';
import { strToNumber } from 'common/utils/numbers';
import { FC } from 'react';
import { FuelGradeCategoryLabel } from '@/common/types/enums';

const ViscosityOfFuelConsumedField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue] = useActions([reportActions.setValue]);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );

  const bunkerId = useSelector(state =>
    selectConsumptionBunkerId(state, 'consumptions', subGroupIndex)
  );
  const bunkerConsumptions = useSelector(state =>
    selectBunkerConsumptions(state, 'consumptions', bunkerId)
  );

  const options = useSelector(state => {
    const fuelGradeOptions = selectViscosityOfFuelConsumedOptions(
      state,
      fuelGrade?.id,
      fuelGrade?.category
    );

    if (
      !!value &&
      !fuelGradeOptions.find(f => strToNumber(f.value) === strToNumber(value)) &&
      fuelGradeOptions?.length
    ) {
      // append the saved viscosity when it doesn't exist in the viscosity options
      return [...fuelGradeOptions, { name: value, value }];
    }

    return fuelGradeOptions;
  });

  const onChange = viscosity => {
    setValue({
      key: field.key,
      value: viscosity,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  };

  return options?.length ? (
    <SelectWithValidation
      field={{
        ...field,
        selectRest: {
          isAsync: false,
          options,
          onChange,
          value,
          fuelGrade,
          components: { Option, SingleValue },
          getOptionValue: option => option.value,
          getOptionLabel: option => option.name,
          menuClassName: 'viscosity-of-fuel-consumed-field-menu',
          filterOption: option => !bunkerConsumptions.find(b => b.viscosity.value === option.value)
        }
      }}
      sectionLabel={sectionLabel}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      sectionActiveTab={sectionActiveTab}
      className="mt-1"
    />
  ) : (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default ViscosityOfFuelConsumedField;

const Option = ({ children, ...props }) => {
  const { name, suffix } = props?.data;
  const fuelGradeCategory = props?.selectProps?.fuelGrade?.category;

  return (
    <components.Option {...props} className="d-flex flex-column justify-content-center w-100p">
      <ViscosityOption name={name} fuelGradeCategory={fuelGradeCategory} suffix={suffix} />
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  const { name } = props?.data;
  const fuelGradeCategory = props?.selectProps?.fuelGrade?.category;
  const fuelGradeId = props?.selectProps?.fuelGrade?.id;
  const prevBunker = useSelector(state =>
    selectPreviousCaptainReportBunkerPerFuelGrade(state, fuelGradeId)
  );
  const hfo = _get(prevBunker, 'rob_hfo.value');
  const lfo = _get(prevBunker, 'rob_lfo.value');

  const suffix = selectRob({ hfo, lfo }, name, fuelGradeCategory);

  return (
    <components.SingleValue
      className="d-flex flex-column justify-content-center flex-1 overflow-hidden text-truncate"
      {...props}
    >
      <ViscosityOption name={name} fuelGradeCategory={fuelGradeCategory} suffix={suffix} />
    </components.SingleValue>
  );
};

export const ViscosityOption: FC<{
  name: string;
  suffix?: string | null;
  fuelGradeCategory: FuelGradeCategoryLabel;
  className?: string;
}> = ({
  name,
  suffix,
  fuelGradeCategory,
  className = 'd-inline-block text-nowrap text-truncate'
}) => {
  const hideSuffix =
    fuelGradeCategory === 'go' || suffix === null || suffix === undefined || suffix === '-';

  return (
    <div className={className}>
      <span>
        {name} {hideSuffix || !name ? null : <span className="fw-bold fs-10 cms-4 cme-4">-</span>}
      </span>
      {hideSuffix || !name ? null : (
        <span className="fw-bold fs-10">{fuelGradeCategory === 'go' ? '' : `${suffix} mt`}</span>
      )}
    </div>
  );
};
